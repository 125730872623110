import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
    selector: 'confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ConfirmationDialogComponent {
    dialogTitle: string;
    dialogText: string;

    constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
        this.dialogTitle = data.dialogTitle;
        this.dialogText = data.dialogText;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
