import {Component, OnInit, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import {FuseContractsContractFormDialogComponent} from '../contract-form/contract-form.component';
import {Contract} from '../contract.model';

@Component({
  selector: 'match-contract-form',
  templateUrl: './match-contract-form.component.html',
  styleUrls: ['./match-contract-form.component.scss']
})
export class MatchContractFormComponent implements OnInit {

    matchFinalFormGroup: FormGroup;
    private borrowComponent: FuseContractsContractFormDialogComponent;
    private loanComponent: FuseContractsContractFormDialogComponent;

    borrowContract: Contract;
    loanContract: Contract;
    contractList = [];
    displayedColumns = ['side', 'counterPartyName', 'symbol', 'quantity', 'amount', 'rate', 'rounding', 'margin'];
    loadingSubmit = false;

    borrowTitle = 'Borrow'
    loanTitle = 'Loan'

    @ViewChild('borrowComponent') set borrowForm(content: FuseContractsContractFormDialogComponent) {
        if (content) {
            this.borrowComponent = content;
        }
    }
    @ViewChild('loanComponent') set loanForm(content: FuseContractsContractFormDialogComponent) {
        if (content) {
            this.loanComponent = content;
        }
    }



    constructor() {}


    ngOnInit() {}

    step(stepper: MatStepper) {
        if (stepper.selectedIndex === 0) {
            this.borrowContract = new Contract(this.borrowComponent.contractForm.getRawValue());
            this.loanComponent.setInternalAccount(this.borrowComponent.internalAccount);
            this.loanComponent.setSymbol(this.borrowContract.symbol);
            this.loanComponent.contractForm.get('quantity').setValue(this.borrowContract.quantity);
            this.loanComponent.quote = this.borrowComponent.quote;
            this.loanComponent.selectedRoundRule = this.borrowComponent.selectedRoundRule;
            this.loanComponent.contract.quantity = this.borrowContract.quantity;
            this.loanComponent.selectedMargin = this.borrowComponent.selectedMargin;
            this.loanComponent.updateContractValue();
            this.borrowContract.setRoundRule(this.borrowComponent.selectedRoundRule);
            this.borrowTitle = 'Borrow ' + this.borrowContract.quantity + ' ' + this.borrowContract.symbol.toUpperCase() + ' from ' +
                this.borrowComponent.externalAccount.dtcId + ' at ' + this.borrowContract.rate;
        } else if (stepper.selectedIndex === 1) {
            this.loanContract = new Contract(this.loanComponent.contractForm.getRawValue());
            this.loanComponent.setContraAccount(this.borrowComponent.externalAccount);
            this.loanContract.setRoundRule(this.loanComponent.selectedRoundRule);
            this.loanTitle = 'Loan ' + this.loanContract.quantity + ' ' + this.loanContract.symbol.toUpperCase() + ' to ' +
                this.loanComponent.externalAccount.dtcId + ' at ' + this.loanContract.rate;
        }
        this.contractList = [];
        if (this.borrowContract) {
            this.contractList.push(this.borrowContract);
        }
        if (this.loanContract) {
            this.contractList.push(this.loanContract);
        }
    }

    setBorrowSymbol(symbol: string) {
        this.borrowComponent.setSymbol(symbol);
    }

    submit() {
        this.loadingSubmit = true;
        this.borrowComponent.submit();
        this.loanComponent.submit();
    }

}
