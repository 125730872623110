import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {environment} from '../../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Return} from '../return.model';
import {Contract} from '../../contracts/contract.model';
import {ContractsService} from '../../contracts/contracts.service';



import {ReturnHoldingService} from '../returnholding.service';
import {AngularFirestore} from 'angularfire2/firestore';
import {UserService} from '../../../../../auth/user/user.service';
import * as moment from 'moment';
import {now} from 'moment';

@Injectable()
export class ReturnDetailService implements OnDestroy{

    sourceContract: Contract;
    routeParams: any;
    return: Return;
    borrowContracts: Contract[];
    onReturnChanged: BehaviorSubject<any> = new BehaviorSubject({});
    readonly endpoint = environment.verticalApiEndpoint + 'returns/';
    contractsAddedSub: Subscription;
    init = false;
    onInitListener: BehaviorSubject<any> = new BehaviorSubject(false);
    errorState = false;
    error: any;

    constructor(
        private http: HttpClient,
        private contractService: ContractsService,
        private returnHoldingService: ReturnHoldingService,
        private firestore: AngularFirestore,
        private userService: UserService,
        public router: Router
    )
    {
        //console.log('initial value of on init listener: ', this.onInitListener.value);
        this.onInitListener.next(false);

    }

    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.error = null;
        this.errorState = false;
        this.routeParams = route.params;
        //console.log('route params for detail: ', this.routeParams);

        return new Promise((resolve, reject) => {

            Promise.all([
                this.getReturn()
            ]).then(
                () => {
                    resolve();
                }, (err) => {
                    this.errorState = true;
                    this.error = err.error.error.errors[0].message;
                    resolve();
                }
            );
        });
    }

    getReturn(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            if ( this.routeParams.returnId === 'new' )
            {
                this.onReturnChanged.next(false);
                resolve(false);
            }
            else
            {
                //console.log('returnId: ', this.routeParams.returnId);
                const returnIdNumber: number = Number(this.routeParams.returnId);
                this.return = this.returnHoldingService.getReturnByContractHistoryId(returnIdNumber);
                //console.log('return: ', this.return);
                if (this.return === null) {
                    this.errorState = true;
                    resolve(null);
                } else {
                    this.updateComment();
                    this.listenForContracts();
                    resolve(this.return);
                }

            }
        });
    }

    listenForContracts() {
        // this.contractsAddedSub =
        //     this.contractService.currentContractListObs.subscribe(
        //         contracts => {
        //                 if (contracts.length !== 0) {
        //                     const contractId: number = this.contractService.getContractById(contracts, this.return.contractId);
        //                     if (contractId) {
        //                         this.sourceContract = this.contractService.contracts[contractId];
        //                         this.borrowContracts = this.contractService.getContractsWithCusip(this.return.cusip, 'B')
        //                             .sort((a, b) => {
        //                                 return a.rate < b.rate ? -1 : 1;
        //                             });
        //                         //console.log('borrow contracts are now: ', this.borrowContracts);
        //                         this.init = true;
        //                         this.onInitListener.next(true);
        //                     } else {
        //                         //console.log('Couldnt find contract id: ', this.return.contractId);
        //                         this.errorState = true;
        //                     }
        //
        //                 }
        //         }
        // );
    }

    updateComment(): void {
        this.firestore.collection('returns').doc('' + this.return.contractHistoryId).set({
            comments: 'Last opened by ' + this.userService.getUserName() + ' at ' + moment().format('h:mm a')
        }, {
            merge: true
        });
    }

    submitReturn(returnElement: any) {
        if (returnElement.returnQty) {
            //console.log('submitting: ', returnElement);
            this.contractService.return(
                returnElement.contractId,
                returnElement.returnQty,
                returnElement.specFlag,
                returnElement.batchCode,
                returnElement.parentReturnId
            );
        }
    }

    ngOnDestroy(): void {
        if (this.contractsAddedSub) {
            this.contractsAddedSub.unsubscribe();
        }
        this.errorState = false;
        this.error = null;
    }


    // clearInUseBy() {
    //     const json = {
    //         returnId: this.return.returnHoldingId
    //     };
    //     //console.log('clear in use: ', this.endpoint + this.return.returnHoldingId + '/clearInUse');
    //     this.http.put(this.endpoint + this.return.returnHoldingId + '/clearInUse', JSON.stringify(json))
    //         .subscribe((resp) => {
    //             //console.log('clear resp: ', resp);
    //         }, (err) => {
    //             //console.log('error clear: ', err);
    //         });
    // }
}
