import { Injectable } from '@angular/core';
import {CounterParty} from './counterparty.model';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {InternalUser} from '../../../../auth/user/user.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {CounterPartyAccount} from '../accounts/account.model';
import {LimitProfile} from '../limitprofiles/limitprofile.model';
import {catchError, map} from 'rxjs/operators';

@Injectable()
export class CounterpartyService implements Resolve<any> {

    public UNKNOWN_COUNTERPARTY_ID = -1;

    private _internalCounterParties: CounterParty[];
    private _externalCounterParties: CounterParty[];

    private _accountContraMap: {
        [contraAccountId: number]: CounterParty
    } = {};

    private _allCounterParties: CounterParty[];

    private _combinedCounterParties: CounterParty[];

    endpoint = environment.verticalApiEndpoint + 'counterparty';

    constructor(private http: HttpClient) {
    }


    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getCounterPartyList()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getCounterPartyList(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this.http.get(this.endpoint)
                .subscribe((response: any) => {
                    //console.log('resp (counterparties?): ', response);
                    const counterpartArray: CounterParty[] = [];
                    for (const newCounterParty of response.items) {
                        counterpartArray.push(new CounterParty(newCounterParty));
                    }
                    this.allCounterParties = counterpartArray;
                    resolve(response);
                }, reject);
        });
    }

    get internalCounterParties(): CounterParty[] {
        return this._internalCounterParties;
    }

    set internalCounterParties(value: CounterParty[]) {
        this._internalCounterParties = value;
    }

    get externalCounterParties(): CounterParty[] {
        return this._externalCounterParties;
    }

    set externalCounterParties(value: CounterParty[]) {
        this._externalCounterParties = value;
    }

    get allCounterParties(): CounterParty[] {
        return this._allCounterParties;
    }

    set combinedCounterParties(value: CounterParty[]) {
        this._combinedCounterParties = value;
    }

    get combinedCounterParties(): CounterParty[] {
        return this._combinedCounterParties;
    }

    set allCounterParties(value: CounterParty[]) {
        this._allCounterParties = value;
        for (const counterParty of value) {
            for (const account of counterParty.accounts) {
                this._accountContraMap[account.counterPartyId] = counterParty;
            }
        }
    }

    public getAccountByDtcId(dtcId: string): CounterPartyAccount {
        for (const contra of this.combinedCounterParties) {
            for (const acct of contra.accounts) {
                if (acct.dtcId === dtcId) {
                    return acct;
                }
            }
        }
        return null;
    }

    public getAccountByDtcIdOrName(query: string): CounterPartyAccount {
        for (const contra of this.combinedCounterParties) {
            for (const acct of contra.accounts) {
                if (acct.dtcId.toLocaleUpperCase() === query.toLocaleUpperCase() || acct.name.toLocaleUpperCase() === query.toLocaleUpperCase()) {
                    return acct;
                }
            }
        }
        return null;
    }

    public getAccountByLoanetIdOrName(query: string): CounterPartyAccount {
        for (const contra of this.combinedCounterParties) {
            for (const acct of contra.accounts) {
                if (acct.loanetId.toLocaleUpperCase() === query.toLocaleUpperCase() || acct.name.toLocaleUpperCase() === query.toLocaleUpperCase()) {
                    return acct;
                }
            }
        }
        return null;
    }

    public getAccountByAccountId(accountId: number): CounterPartyAccount {
        for (const contra of this.combinedCounterParties) {
            for (const acct of contra.accounts) {
                if (acct.accountId === accountId) {
                    return acct;
                }
            }
        }
        return null;
    }

    public getInternalAccountByDtcId(dtcId: string): CounterPartyAccount {
        for (const contra of this.combinedCounterParties) {
            for (const acct of contra.accounts) {
                if (acct.dtcId === dtcId) {
                    return acct;
                }
            }
        }
        return null;
    }

    public getAllAccounts(): CounterPartyAccount[]{
        const counterPartyAccounts = [];

        for (const contra of this.combinedCounterParties) {
            for (const acct of contra.accounts) {
                counterPartyAccounts.push(acct);
            }
        }
        return counterPartyAccounts;
    }

    public getCounterPartyLimitProfile(counterPartyId: number, internal: boolean): Observable<LimitProfile> {
        // /vertical/v1/limit/{limitProfileId}
        const endpoint = environment.verticalApiEndpoint + 'counterparty/' + counterPartyId + '/limit/' + internal;
        return this.http
            .get(endpoint)
            .pipe(
                map((body: any) => body),
                catchError(() => of('Error, could not load limit profile.'))
            );
    }

    public getAccountLimitProfile(accountId: number,
                                   internal: boolean): Observable<LimitProfile> {
        const endpoint = environment.verticalApiEndpoint + 'account/' + accountId + '/limit/' + internal;
        return this.http
            .get(endpoint)
            .pipe(
                map((body: any) => body),
                catchError(() => of('Error, could not load account limit profile.'))
            );
    }

    public getExternalCounterPartyById(counterPartyId: number): CounterParty {
        for (const contra of this.externalCounterParties) {
            if (contra.counterPartyId === counterPartyId) {
                return contra;
            }
        }
        return null;
    }


    public getCounterPartyById(counterPartyId: number): CounterParty {
        for (const contra of this.combinedCounterParties) {
            if (contra.counterPartyId === counterPartyId) {
                return contra;
            }
        }
        return null;
    }

    get accountContraMap(): { [p: number]: CounterParty } {
        return this._accountContraMap;
    }

    public getContraByContraAccountId(contraAccountId: number): CounterParty {
        return this._accountContraMap[contraAccountId];
    }
}
