import {Component, Inject, LOCALE_ID, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {UserService} from '../../../../../auth/user/user.service';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import {GridOptions, ColDef, ColumnApi, GridApi} from 'ag-grid-community';
import {MatSnackBar} from '@angular/material';
import {ConstantsService} from '../../../../../shared/constants/constants.service';
import {Identifiable} from '../../../../../shared/firestore-collection.service';
import {SwitcherService} from '../../../../../shared/switcher/switcher.service';
import {QuoteService} from '../../../instruments/quotes/quote.service';
import {ContractsService} from '../contracts.service';
import {ContractReviewService} from './contract-review.service';
import {Subscription} from 'rxjs';
import moment, {Moment} from 'moment';
import {formatCurrency, formatNumber} from '@angular/common';


@Component({
    selector: 'app-contract-review',
    templateUrl: './contract-review.html',
    styleUrls: ['./contract-review.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class ContractReviewComponent implements OnInit, OnDestroy {

    gridOptions: GridOptions = <GridOptions>{};
    title: string;
    unreviewedDate: string;
    calendarDate;

    defaultColDef = {
        editable: false,
        enableCellChangeFlash: true,
        sortable: true,
        filter: true,
        resizable: true
    };

    gridColumnApi: ColumnApi;
    gridApi: GridApi;

    constructor(public userService: UserService,
                public contractReviewService: ContractReviewService,
                public snackBar: MatSnackBar,
                public switcherService: SwitcherService,
                public quoteService: QuoteService,
                @Inject(LOCALE_ID) private locale: string) {

        this.gridOptions = {
            columnDefs: this.getColumnDefs(),
            rowSelection: 'multiple',
            rowClassRules: {
                // 'invalid-prototype': (params) => {
                //     const prototype: ContractPrototype = params.data;
                //     if (prototype.quantity) {
                //         return (!(prototype.isValid()) && prototype.status !== '');
                //     } else {
                //         return isNaN(prototype.quantity);
                //     }
                // }
            },
            enableRangeSelection: true,
            floatingFilter: true,
            suppressRowClickSelection: true,
        };


        this.contractReviewService.getUnReviewedList().then();


    }

    ngOnInit() {
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridOptions.api = params.api;
        this.autoSizeAll(params);
        this.gridApi.showNoRowsOverlay();
    }

    autoSizeAll(event) {
        this.gridColumnApi = event.columnApi;
        this.gridColumnApi.autoSizeColumns(this.gridColumnApi.getAllColumns());
    }

    onCellClicked(event) {
        this.gridApi.setRowData([]);
        this.gridApi.showLoadingOverlay();
        this.contractReviewService.getReviewList({startOn: new Date(event.node.data.starton)}).then(() => {
                this.gridApi.hideOverlay();
            }
        );
    }

    onDatePicked(event) {
        this.gridApi.setRowData([]);
        this.gridApi.showLoadingOverlay();
        this.title = event.value;
        this.calendarDate = null;
        let date = new Date(event.value);
        let formatted = moment.utc(date, 'X').format('YYYY/MM/DD');
        this.contractReviewService.getReviewList({startOn: moment.utc(date, 'X').format('YYYY-MM-DD')}).then(data => {
                this.gridApi.hideOverlay();
                this.gridApi.setRowData(data);
            }
        );
    }

    dateChanged(event) {
        this.gridApi.setRowData([]);
        this.gridApi.showLoadingOverlay();
        let date: Moment = event.value;
        this.title = moment.utc(date, 'X').format('MM-DD-YYYY');
        this.unreviewedDate = '';
        this.contractReviewService.getReviewList({startOn: moment.utc(date, 'X').format('YYYY-MM-DD')}).then(
            data => {
                this.gridApi.hideOverlay();
                this.gridApi.setRowData(data);
            }
        );
    }

    private getColumnDefs(): ColDef[] {
        const colDefs = [];
        colDefs.push(
            {
                headerName: '',
                field: 'selected',
                headerCheckboxSelection: true,
                maxWidth: 40,
                minWidth: 40,
                filter: false,
                suppressMenu: true,
                checkboxSelection: true
            },
            {
                headerName: 'Side',
                field: 'side',
                maxWidth: 75,
                minWidth: 75,
            },
            {
                headerName: 'Account',
                field: 'depositoryNo',
                hide: true,
                maxWidth: 75,
                minWidth: 75,
            },
            {
                headerName: 'Contra',
                field: 'contraDepositoryNo',
                maxWidth: 100,
                minWidth: 100,
            },
            {
                headerName: 'Symbol',
                field: 'symbol',
                filter: 'agTextColumnFilter',
                maxWidth: 75,
                minWidth: 75,
            },
            {
                headerName: 'CUSIP',
                field: 'cusip',
                filter: 'agTextColumnFilter',
                maxWidth: 140,
                minWidth: 140,
            },
            {
                headerName: 'ContractNo',
                field: 'contractNo',
                filter: 'agTextColumnFilter',
                maxWidth: 140,
                minWidth: 140,
            },
            {
                headerName: 'Borrow Qty',
                field: 'borrowQuantity',
                type: 'numericColumn',
                valueFormatter: (params) => {
                    if (params.value === null) {
                        return '';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                },
                maxWidth: 140,
                minWidth: 140,
            },
            {
                headerName: 'Borrow Amount',
                field: 'borrowAmount',
                type: 'numericColumn',
                valueFormatter: (params) => {
                    if (params.value === null) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$', undefined, '1.0-0');
                    }
                },
                maxWidth: 140,
                minWidth: 140,
            },
            {
                headerName: 'Loan Qty',
                field: 'loanQuantity',
                type: 'numericColumn',
                valueFormatter: (params) => {
                    if (params.value === null) {
                        return '';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                },
                maxWidth: 140,
                minWidth: 140,
            },
            {
                headerName: 'Loan Amount',
                field: 'loanAmount',
                type: 'numericColumn',
                valueFormatter: (params) => {
                    if (params.value === null) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$', undefined, '1.0-0');
                    }
                },
                maxWidth: 140,
                minWidth: 140,
            },
            // {
            //     headerName: 'Quantity',
            //     field: 'quantity',
            //     valueFormatter: this.numberFormatter,
            //     valueParser: this.numberParser
            // },
            // {
            //     headerName: 'Amount',
            //     field: 'amount',
            // },
            {
                headerName: 'Rate',
                field: 'rate',
                valueFormatter: (params) => {
                    if (params.value === null) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');
                    }
                },
                maxWidth: 100,
                minWidth: 100,
            },
            {
                headerName: 'PRC',
                field: 'profitCenter',
                maxWidth: 70,
                minWidth: 70,
            },
            {
                headerName: 'Opened',
                field: 'createdOn',
                maxWidth: 175,
                minWidth: 175,
            },
            {
                headerName: 'Approved By',
                field: 'approverNickname',
                maxWidth: 175,
                minWidth: 175,
            },
            {
                headerName: 'Reviewed By',
                field: 'reviewer',
                maxWidth: 140,
                minWidth: 140,
            },
            {
                headerName: 'Reviewed On',
                field: 'reviewedOn',
                maxWidth: 175,
                minWidth: 175,
            },
            {
                headerName: 'Contract History ID',
                field: 'contractHistoryId',
                hide: true
            },
            {
                headerName: 'Comment',
                field: 'comment',
                editable: true,
                maxWidth: 200,
                minWidth: 200,
            }
        );
        return colDefs;
    }

    private getUnReviewedColumnDefs(): ColDef[] {
        const colDefs = [];
        colDefs.push(
            {
                headerName: 'Trade Date',
                field: 'tradedate'
            },
            {
                headerName: 'Unreviewed Contracts',
                field: 'unreviewedcontracts',
                hide: true
            },
            {
                headerName: 'StartOn',
                field: 'starton',
                hide: true
            },
        );
        return colDefs;
    }

    public getRowNodeId(data) {
        return data.contractId;
    }

    public getUnReviewedRowNodeId(data) {
        return data.starton;
    }

    ngOnDestroy(): void {
    }

    numberFormatter(params) {
        if (params.value) {
            return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1');
        } else {
            return params.value;
        }
    }

    numberParser(params) {
        return Number(params.newValue);
    }

    reviewSelectedContracts() {
        const items: any[] = this.gridApi.getSelectedRows();
        if (items.length > 0) {
            let records = [];

            for (let i in items) {
                records.push(
                    {
                        contractHistoryId: items[i].contractHistoryId,
                        contractId: items[i].contractId,
                        comment: items[i].comment,
                        sourceLoginId: this.userService.user.loginId,
                    }
                );
            }

            let day = items[0].startOn.dayOfMonth;
            let month = items[0].startOn.monthValue - 1;
            let year = items[0].startOn.year;
            let date = new Date(Date.UTC(year, month, day));
            let formatted = moment.utc(date, 'X').format('YYYY-MM-DD');

            let body = {
                items: records,
                startOn: formatted
            };

            this.contractReviewService.submitReview(body).then(data => {
                    this.gridApi.setRowData(data);
                    this.contractReviewService.getUnReviewedList().then();
                }
            );
        }
    }
}



