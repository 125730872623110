import {AfterViewInit, Component, ElementRef, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ColDef, ColumnApi, GridApi, GridOptions} from 'ag-grid-community';
import {Subscription} from 'rxjs';
import {SwitcherService} from '../../../../shared/switcher/switcher.service';
import {UserService} from '../../../../auth/user/user.service';
import moment from 'moment';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {SnapshotCashRates} from '../../trading/quick-symbol-summary/SnapshotCashRates';


@Component({
    selector: 'app-lendingpit',
    templateUrl: './lendingpit.component.html',
    styleUrls: ['./lendingpit.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class LendingpitComponent implements OnInit, AfterViewInit, OnDestroy {

    LendingpitForm: FormGroup;
    detailGridOptions: GridOptions = <GridOptions>{};
    detailGridColumnApi: ColumnApi;
    detailGridApi: GridApi;
    detailsColumnDefs: any[] = [];
    rateData = [];
    disableButtons = false;
    today = moment.utc(new Date(), 'X').format('MM-DD-YY');

    @ViewChild('symQtyRef') symQtyRef: ElementRef;
    @ViewChild('formDiv') formDiv: ElementRef;


    autoGroupColumnDef = {
        headerName: 'Symbol',
        minWidth: 115,
        width: 115,
        maxWidth: 115,
        resizable: false,
        hide: false,
        filter: 'agTextColumnFilter',
        field: 'symbol',
        editable: false,
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
            suppressCount: true,
            suppressDoubleClickExpand: true,
            suppressEnterExpand: true
        },
    };

    detailsDefaultColDef = {
        editable: true,
        enableCellChangeFlash: true,
        sortable: true,
        filter: true,
        resizable: true,
        tooltipComponent: 'customTooltip',
    };


    AstecID: number;
    date: string;
    cusip: string;
    ISIN: string;
    TradingSymbol: string;
    ContractTypeID: string;
    LoanStageID: string;
    CollateralTypeID: string;
    CollateralCurrencyID: string;
    Tickets: number;
    Units: number;
    MarketValueUSD: number;
    Age: number;
    LoanRateAvg: number;
    LoanRateMax: number;
    LoanRateMin: number;
    LoanRateStdev: number;


    constructor(private formBuilder: FormBuilder,
                @Inject(LOCALE_ID) private locale: string,
                private switcherService: SwitcherService,
                public userService: UserService,
                private httpClient: HttpClient) {

        this.LendingpitForm = this.createLendingPitForm();

        this.detailGridOptions = {
            enableGroupEdit: true,
            enableRangeSelection: true
        };

        this.defineColumns();
    }

    createLendingPitForm() {
        return this.formBuilder.group({
            symbolOrCusip: [null],

        });
    }

    getDetailColDefs(): ColDef[] {
        const columnDefs = [];
        columnDefs.push(
            // {headerName: 'AstecID', field: 'astecid', filter: 'agTextColumnFilter', width: 120, filterParams: {newRowsAction: 'keep'}},
            // {headerName: 'Date', field: 'date', filter: 'agTextColumnFilter', width: 120, filterParams: {newRowsAction: 'keep'}},
            // {headerName: 'Cusip', field: 'cusip', filter: 'agTextColumnFilter', width: 120, filterParams: {newRowsAction: 'keep'}},
            // {headerName: 'ISIN', field: 'isin', filter: 'agTextColumnFilter', width: 120, filterParams: {newRowsAction: 'keep'}},
            // {headerName: 'TradingSymbol', field: 'tradingsymbol', filter: 'agTextColumnFilter', width: 120, filterParams: {newRowsAction: 'keep'}},
            // {headerName: 'ContractTypeID', field: 'contracttypeid', filter: 'agTextColumnFilter', width: 120, filterParams: {newRowsAction: 'keep'}},
            // {headerName: 'LoanStageID', field: 'loanstageid', filter: 'agTextColumnFilter', width: 120, filterParams: {newRowsAction: 'keep'}},
            // {headerName: 'CollateralTypeID', field: 'collateraltypeid', filter: 'agTextColumnFilter', width: 120, filterParams: {newRowsAction: 'keep'}},
            // {headerName: 'CollateralCurrencyID', field: 'collateralcurrencyid', filter: 'agTextColumnFilter', width: 120, filterParams: {newRowsAction: 'keep'}},
            // {headerName: 'Tickets', field: 'tickets', filter: 'agTextColumnFilter', width: 120, filterParams: {newRowsAction: 'keep'}},
            // {headerName: 'Units', field: 'units', filter: 'agTextColumnFilter', width: 120, filterParams: {newRowsAction: 'keep'}},
            // {headerName: 'MarketValueUSD', field: 'marketvalueusd', filter: 'agTextColumnFilter', width: 120, filterParams: {newRowsAction: 'keep'}},
            // {headerName: 'Age', field: 'age', filter: 'agTextColumnFilter', width: 120, filterParams: {newRowsAction: 'keep'}},
            // {headerName: 'LoanRateAvg', field: 'loanrateavg', filter: 'agTextColumnFilter', width: 120, filterParams: {newRowsAction: 'keep'}},
            // {headerName: 'LoanRateMax', field: 'loanratemax', filter: 'agTextColumnFilter', width: 120, filterParams: {newRowsAction: 'keep'}},
            // {headerName: 'LoanRateMin', field: 'loanratemin', filter: 'agTextColumnFilter', width: 120, filterParams: {newRowsAction: 'keep'}},
            // {headerName: 'LoanRateStdev', field: 'loanratestdev', filter: 'agTextColumnFilter', width: 120, filterParams: {newRowsAction: 'keep'}},
            // {headerName: 'Capture Time', field: 'captureTime', filter: 'agTextColumnFilter', width: 120, filterParams: {newRowsAction: 'keep'}},
            // {headerName: 'Collateral Currency ID', field: 'collateralCurrencyId', filter: 'agTextColumnFilter', width: 120, filterParams: {newRowsAction: 'keep'}},

            {headerName: 'Symbol', field: 'symbol', filter: 'agTextColumnFilter', width: 100, minWidth: 100, maxWidth: 100, filterParams: {newRowsAction: 'keep'}},
            {headerName: 'Cusip', field: 'cusip', filter: 'agTextColumnFilter', width: 120, minWidth: 120, maxWidth: 120, filterParams: {newRowsAction: 'keep'}},
            {headerName: 'Description', field: 'description', filter: 'agTextColumnFilter', width: 350, minWidth: 350, maxWidth: 350, filterParams: {newRowsAction: 'keep'}},
            {headerName: 'Rebate Avg', field: 'rebateAvg', filter: 'agTextColumnFilter', width: 120, minWidth: 120, maxWidth: 120, filterParams: {newRowsAction: 'keep'}},
            {headerName: 'New Avg', field: 'newRebateAvg', filter: 'agTextColumnFilter', width: 120, minWidth: 120, maxWidth: 120, filterParams: {newRowsAction: 'keep'}},
            {headerName: 'Rebate High', field: 'rebateHigh', filter: 'agTextColumnFilter', width: 120, minWidth: 120, maxWidth: 120, filterParams: {newRowsAction: 'keep'}},
            {headerName: 'New High', field: 'newRebateHigh', filter: 'agTextColumnFilter', width: 120, minWidth: 120, maxWidth: 120, filterParams: {newRowsAction: 'keep'}},
            {headerName: 'Rebate Low', field: 'rebateLow', filter: 'agTextColumnFilter', width: 120, minWidth: 120, maxWidth: 120, filterParams: {newRowsAction: 'keep'}},
            {headerName: 'New Low', field: 'newRebateLow', filter: 'agTextColumnFilter', width: 120, minWidth: 120, maxWidth: 120, filterParams: {newRowsAction: 'keep'}},
            {headerName: 'Close Price', field: 'closePrice', filter: 'agTextColumnFilter', width: 120, minWidth: 120, maxWidth: 120, filterParams: {newRowsAction: 'keep'}},

            // {headerName: 'Trade Date', field: 'tradeDate', filter: 'agTextColumnFilter', width: 120, filterParams: {newRowsAction: 'keep'}},
            // {headerName: 'Valid', field: 'valid', filter: 'agTextColumnFilter', width: 120, filterParams: {newRowsAction: 'keep'}},

        );
        return columnDefs;
    }

    dateComparator(date1, date2) {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        if (date1 && date2) {
            return momentDate1.diff(momentDate2);
        }
    }

    ngAfterViewInit(): void {

    }

    ngOnDestroy() {
        this.detailGridApi.destroy();
    }

    ngOnInit() {

    }

    resetAfterSubmission() {
        this.LendingpitForm.reset();
        this.LendingpitForm.get('symbolOrCusip').markAsPristine();
        this.rateData = [];
    }

    submitLendingpit() {

        let symbols = [];
        const rates: SnapshotCashRates[] = [];
        const lendingpitBatch = this.LendingpitForm.getRawValue();

        if (lendingpitBatch.symbolOrCusip && lendingpitBatch.symbolOrCusip !== '') {
            symbols = lendingpitBatch.symbolOrCusip.trim().replace(/(^[ \t]*\n)/gm, '').split('\n');
        }

        const json = {
            items: symbols
        };

        if (symbols.length > 0) {
            this.disableButtons = true;
            this.detailGridApi.showLoadingOverlay();
            this.httpClient.post(environment.verticalApiEndpoint + 'sls/cashrates', json)
                .subscribe((response: any) => {
                    response.items.forEach(item => rates.push(item));
                    this.rateData = rates;
                    this.disableButtons = false;
                });
        }
    }

    defineColumns() {
        this.detailsColumnDefs = this.getDetailColDefs();
    }


    onGridReady(params) {
        this.detailGridApi = params.api;
        this.detailGridColumnApi = params.columnApi;
        this.detailGridOptions.api = params.api;
    }

    resetGridData() {
        this.detailGridApi.onFilterChanged();
    }

    getRowNodeId(data) {
        return data.cusip;
    }

}
