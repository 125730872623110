import {Component, Inject, Input, LOCALE_ID, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {Router} from '@angular/router';
import {MatSnackBar} from '@angular/material';
import {UserService} from '../../../../auth/user/user.service';
import {ShortRatesService} from './shortRates.service';
import {RowNode} from 'ag-grid-community';

@Component({
    selector: 'app-shortRates-list',
    templateUrl: './shortRates-list.component.html',
    styleUrls: ['./shortRates-list.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})

export class ShortRatesListComponent implements OnInit {

    columnDefs: any[];
    gridApi: any;

    defaultColDef = {
        sortable: true,
        filter: true
    };

    getRowStyle = (params) => {
        if (params.node.group) {
            return {'font-weight': 'bold'};
        }
    };

    constructor(public shorRatesService: ShortRatesService,
                public snackBar: MatSnackBar,
                @Inject(LOCALE_ID) private locale: string,
                public userService: UserService) {
    }

    ngOnInit() {
        this.shorRatesService.getShortRates().then(rates => {
            this.columnDefs = [
                {headerName: 'Symbol', field: 'symbol', filter: 'agSetColumnFilter', width: 150, minWidth: 150},
                {headerName: 'Cusip', field: 'cusip', filter: 'agSetColumnFilter', width: 150, minWidth: 150},
                {
                    headerName: 'Rate', field: 'rate', filter: 'agSetColumnFilter', width: 100, minWidth: 100,
                    editable: (params) => {
                        return ((params.data['source'] == 'L') || (params.data['source'] == 'I'));
                    }
                },
                {headerName: 'Source', field: 'source', filter: 'agSetColumnFilter', width: 75, minWidth: 75},
            ]
            ;
        });

    }

    onGridReady(params) {
        this.gridApi = params.api;
    }

    getRowNodeId(data) {
        return data.cusip;
    }

    cellEditingStopped(event) {
        if (event.value !== '') {
            //console.log(event);
            this.shorRatesService.editedShortRates.push(event.data);
        }
    }
}
