import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {InternalUser} from '../../../../auth/user/internal-user';

@Injectable()
export class UserListService implements Resolve<any>{

    endpoint = environment.verticalApiEndpoint + 'users';
    disabledEndpoint = environment.verticalApiEndpoint + 'user/setDisabled';

    users: InternalUser[] = [
    ];

  constructor(private http: HttpClient) { }

    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getUserList()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getUserList(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this.http.get(this.endpoint)
                .subscribe((response: any) => {
                    //console.log('resp (users?): ', response);
                    // this.users = response.items;
                    const userArray: InternalUser[] = [];
                    for (const newUser of response.items) {
                        userArray.push(new InternalUser(newUser));
                    }
                    this.users = userArray;
                    resolve(response);
                }, reject);
        });
    }

    toggleUserDisabled(user: InternalUser) {
        user.disabled = !user.disabled;
        //console.log('post this user: ', user);
        return new Promise((resolve, reject) => {
            this.http.put(this.disabledEndpoint, user)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
}
