import {EventEmitter, Injectable, Output} from '@angular/core';
import {FirestoreCollectionService} from './firestore-collection.service';
import {HighlightColor} from '../main/content/operations/user-preferences/accounts-preferences/highlight.color';
import {HttpClient} from '@angular/common/http';
import {AngularFirestore} from 'angularfire2/firestore';
import {AngularFireAuth} from 'angularfire2/auth';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConfigSettingsService extends FirestoreCollectionService<any> {
    collectionName = 'config';

    public switcherDepositoryNumbers;
    public enforcePasswordPolicy;

    onSwitcherDepositoryNumbersChange: BehaviorSubject<string[]> = new BehaviorSubject(null);

    constructor(private httpClient: HttpClient,
                private firestoreClient: AngularFirestore,
                private afAuthClient: AngularFireAuth) {
        super(httpClient, firestoreClient, afAuthClient);
    }

    public bindConfigSettings() {
        this.firestoreClient.collection(this.collectionName).doc('settings')
            .snapshotChanges().subscribe((data) => {
            if (data.payload.exists) {
                this.switcherDepositoryNumbers = data.payload.get('switcherDepositoryNumbers');
                this.enforcePasswordPolicy = data.payload.get('enforcePasswordPolicy');

                if (this.switcherDepositoryNumbers) {
                    this.emitSwitcherDepositoryNumbers();
                }
            }
        });
    }

    public emitSwitcherDepositoryNumbers() {
        this.onSwitcherDepositoryNumbersChange.next(this.switcherDepositoryNumbers);
    }


}
