import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ConstantsService} from '../../../../shared/constants/constants.service';
import {DtcTransactionsFilterService} from './dtc-transactions-filter.service';

@Component({
    selector: 'app-dtc-transactions-filter',
    templateUrl: './dtc-transactions-filter.component.html',
    styleUrls: ['./dtc-transactions-filter.component.scss']
})
export class DtcTransactionsFilterComponent implements OnInit, OnDestroy {
    filterChangedSubscription: Subscription;
    clearFilterControlsSubscription: Subscription;


    constructor(private fb: FormBuilder,
                public filterService: DtcTransactionsFilterService,
                public constantsService: ConstantsService) {
        this.filterChangedSubscription = filterService.setFilterControlsEvent.subscribe(filter => this.setFilterControls(filter));
        this.clearFilterControlsSubscription = filterService.clearFilterControlsEvent.subscribe(applyFilter => this.clearFilter(applyFilter));
    }

    public formFilter: FormGroup;

    ngOnInit() {
        this.formFilter = this.fb.group({
            contraDtcId: [],
            reason: [],
            action: []
        });
        this.setFilterControls(this.filterService.currentFilter);
    }

    ngOnDestroy(): void {
        this.filterChangedSubscription.unsubscribe();
        this.clearFilterControlsSubscription.unsubscribe();
    }

    applyFilter() {
        const contraArray = this.formFilter.get('contraDtcId').value !== null ? this.formFilter.get('contraDtcId').value.toString().trim().toUpperCase().split(' ', 50) : null;
        const contraDepositoryNumbers = [];

        const reasonsArray = this.formFilter.get('reason').value !== null ? this.formFilter.get('reason').value.toString().trim().toUpperCase().split(' ', 50) : null;
        const reasons = [];

        const actionsArray = this.formFilter.get('action').value !== null ? this.formFilter.get('action').value.toString().trim().toUpperCase().split(' ', 50) : null;
        const actions = [];


        if (contraArray !== null && contraArray.length > 0) {
            contraArray.forEach(function (value) {
                if (value.length > 0) {
                    contraDepositoryNumbers.push(value);
                }
            });
        }

        if (reasonsArray !== null && reasonsArray.length > 0) {
            reasonsArray.forEach(function (value) {
                if (value.length > 0) {
                    reasons.push(value);
                }
            });
        }

        if (actionsArray !== null && actionsArray.length > 0) {
            actionsArray.forEach(function (value) {
                if (value.length > 0) {
                    actions.push(value);
                }
            });
        }

        const filter = {
            contraDtcId: {
                filterType: 'set',
                values: contraDepositoryNumbers.length > 0 ? contraDepositoryNumbers : null
            },
            reason: {
                filterType: 'set',
                values: reasons.length > 0 ? reasons : null
            },
            action: {
                filterType: 'set',
                values: actions.length > 0 ? actions : null
            }
        };
        this.filterService.filterChanged(filter);
    }

    clearFilter(applyFilter: boolean) {
        if (this.formFilter) {
            this.formFilter.reset();
            if (applyFilter) {
                this.applyFilter();
            }
        }
    }

    setFilterControls(filter: any[]) {
        if (this.formFilter) {
            this.formFilter.reset();
            if (filter) {
                const columns = Object.keys(filter);
                for (const id in columns) {
                    const controlName = columns[id].toString();
                    const control = this.formFilter.get(controlName);

                    if (control) {
                        switch (controlName) {
                            case 'contraDtcId': {
                                let val = '';
                                if (filter['contraDtcId'] && filter['contraDtcId'].values) {
                                    val += filter['contraDtcId'].values.toString().replace(/,/g, ' ') + ' ';
                                }
                                control.setValue(val);
                                break;
                            }
                            default:
                                if (filter[columns[id]].values) {
                                    control.setValue(filter[columns[id]].values.toString().replace(/,/g, ' '));
                                }
                        }
                    }
                }
            }
        }
    }

    textInput(input: string) {
        this.applyFilter();
        return input.toUpperCase();
    }
}

