import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CounterpartyLimitsComponent } from './counterparty-limit/counterparty-limits.component';
import {RouterModule, Routes} from '@angular/router';
import {FuseContractsComponent} from '../../trading/contracts/contracts.component';
import {AuthGuard} from '../../../../auth/auth.guard';
import {RoundRulesService} from '../constants/roundrules/round-rules.service';
import {BenchmarksService} from '../constants/benchmarks/benchmarks.service';
import {ContractReviewComponent} from '../../trading/contracts/review/contract-review.component';
import { CounterpartyLimitsFilterComponent } from './counterparty-limit/counterparty-limits-filter.component';
import {AgGridModule} from 'ag-grid-angular';
import {FuseContractsModule} from '../../trading/contracts/contracts.module';
import {CdkTableModule} from '@angular/cdk/table';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule, MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule, MatRadioModule,
    MatRippleModule, MatSelectModule, MatSidenavModule, MatSnackBarModule, MatStepperModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule
} from '@angular/material';
import {FuseSharedModule} from '../../../../../@fuse/shared.module';
import {FuseConfirmDialogModule} from '../../../../../@fuse/components';
import {SharedModule} from '../../../../shared';
import {ContractContextMenuComponent} from '../../trading/contracts/contract-context-menu/contract-context-menu.component';
import {StatusSelectComponent} from '../../trading/contracts/contract-context-menu/status-select/status-select.component';
import {ApprovalComponent} from '../../trading/contracts/contract-context-menu/status-select/approval.component';

const routes: Routes = [
    {
        path: 'risk/counterparty-limits',
        component: CounterpartyLimitsComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
  declarations: [CounterpartyLimitsComponent, CounterpartyLimitsFilterComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseContractsModule,
        CdkTableModule,
        MatCardModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatSidenavModule,
        MatTableModule,
        MatToolbarModule,
        MatSelectModule,
        MatRadioModule,
        MatTabsModule,
        MatStepperModule,
        MatExpansionModule,
        MatSnackBarModule,
        FuseSharedModule,
        FuseConfirmDialogModule,
        SharedModule,
        AgGridModule.withComponents([ContractContextMenuComponent, StatusSelectComponent, ApprovalComponent]),
        MatAutocompleteModule,
        MatTooltipModule,
    ]
})

export class RiskModule { }




