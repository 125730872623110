import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CdkTableModule } from '@angular/cdk/table';

import {
    MatAutocompleteModule, MatBottomSheet, MatBottomSheetContainer, MatBottomSheetModule,
    MatButtonModule, MatButtonToggleModule, MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule, MatDividerModule, MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule, MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule,
    MatRippleModule,
    MatSelect, MatSelectModule,
    MatSidenavModule, MatSlideToggleModule, MatSnackBarModule, MatStepperModule,
    MatTableModule, MatTabsModule,
    MatToolbarModule, MatTooltipModule
} from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';
import { FuseConfirmDialogModule } from '@fuse/components';
import { FuseContractsComponent } from './contracts.component';
import { ContractsService } from './contracts.service';
import { FuseContractsContractListComponent } from './contract-list/contract-list.component';
import { FuseContractsSelectedBarComponent } from './selected-bar/selected-bar.component';
import { FuseContractsContractFormDialogComponent } from './contract-form/contract-form.component';

import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {AuthGuard} from '../../../../auth/auth.guard';
import {SharedModule} from '../../../../shared';
import {ContractContextMenuComponent} from './contract-context-menu/contract-context-menu.component';
import {StatusSelectComponent} from './contract-context-menu/status-select/status-select.component';
import {FuseContractsReRateFormDialogComponent} from './rerate-form/rerate-form.component';
import {FuseContractsReturnFormDialogComponent} from './return-form/return-form.component';
import {FuseContractsRecallFormDialogComponent} from './recall-form/recall-form.component';
import { MatchContractFormComponent } from './match-contract-form/match-contract-form.component';
import {RoundRulesService} from '../../operations/constants/roundrules/round-rules.service';
import {BenchmarksService} from '../../operations/constants/benchmarks/benchmarks.service';
import { ContractDetailPreferencesComponent } from './contract-detail-preferences/contract-detail-preferences.component';
import {ContractDetailsPreferenceRenameComponent} from './contract-detail-preferences/rename-form/rename-form.component';
import { ContractImportComponent } from './import/contract-import/contract-import.component';
import {AgGridModule} from 'ag-grid-angular';
import {ApprovalComponent} from './contract-context-menu/status-select/approval.component';
import {ContractFilterComponent} from '../../../../contract-filter/contract-filter.component';
import {TradeFormComponent} from '../trade-form/trade-form.component';
import {BulkImportComponent} from './bulk/bulk-import.component';
import {ContractReviewComponent} from './review/contract-review.component';
import {ContractDetailsComponent} from './contract-details/contract-details.component';
import {FxContractDetailsComponent} from './contract-details/fxcontract-details.component';
import {ContractDetailFilterComponent} from './contract-details/contract-detail-filter.component';
import {FuseMainModule} from '../../../main.module';
import {QuickTradeComponent} from '../quick-trade/quick-trade.component';
import {QuickSymbolSummaryComponent} from '../quick-symbol-summary/quick-symbol-summary.component';
import {ContractsReFxFormDialogComponent} from './reFx-form/reFx-form.component';
import {BuyInFormComponent} from './buy-in-form/buy-in-form.component';

const routes: Routes = [
    {
        path     : 'contracts',
        component: FuseContractsComponent,
        canActivate: [AuthGuard],
        resolve: {
            roundRules: RoundRulesService,
            benchmarks: BenchmarksService
        },
    },
    {
        path     : 'contracts/:selectedSymbol',
        component: FuseContractsComponent,
        canActivate: [AuthGuard],
        resolve: {
            roundRules: RoundRulesService,
            benchmarks: BenchmarksService
        },
    },
    {
        path: 'contract-details',
        component: ContractDetailsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'fxcontract-details',
        component: FxContractDetailsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'contract-details/:selectedSymbol',
        component: ContractDetailsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'import',
        component: ContractImportComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'bulk',
        component: BulkImportComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'review',
        component: ContractReviewComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations   : [
        FuseContractsComponent,
        FuseContractsContractListComponent,
        FuseContractsSelectedBarComponent,
        FuseContractsContractFormDialogComponent,
        ContractContextMenuComponent,
        StatusSelectComponent,
        ApprovalComponent,
        FuseContractsReRateFormDialogComponent,
        FuseContractsReturnFormDialogComponent,
        FuseContractsRecallFormDialogComponent,
        ContractsReFxFormDialogComponent,
        MatchContractFormComponent,
        ContractDetailPreferencesComponent,
        ContractDetailsPreferenceRenameComponent,
        ContractImportComponent,
        ContractFilterComponent,
        TradeFormComponent,
        QuickSymbolSummaryComponent,
        BulkImportComponent,
        ContractReviewComponent,
        ContractDetailsComponent,
        ContractDetailFilterComponent,
        QuickTradeComponent,
        FxContractDetailsComponent,
        BuyInFormComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        CdkTableModule,
        MatCardModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatSidenavModule,
        MatTableModule,
        MatToolbarModule,
        MatSelectModule,
        MatRadioModule,
        MatTabsModule,
        MatStepperModule,
        MatExpansionModule,
        MatSnackBarModule,
        FuseSharedModule,
        FuseConfirmDialogModule,
        SharedModule,
        AgGridModule.withComponents([ContractContextMenuComponent, StatusSelectComponent, ApprovalComponent]),
        MatDividerModule,
        MatProgressBarModule,
        MatAutocompleteModule,
        MatBottomSheetModule,
        MatSlideToggleModule,
        MatButtonToggleModule,
        MatTooltipModule,
        MatProgressSpinnerModule,

    ],
    providers      : [
        ContractsService,
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
        MatBottomSheet
    ],
    entryComponents: [
        FuseContractsContractFormDialogComponent,
        FuseContractsReRateFormDialogComponent,
        FuseContractsReturnFormDialogComponent,
        FuseContractsRecallFormDialogComponent,
        ContractDetailsPreferenceRenameComponent,
        ContractsReFxFormDialogComponent,
        ContractFilterComponent,
        TradeFormComponent,
        QuickSymbolSummaryComponent,
        BuyInFormComponent
    ],
    bootstrap: [FuseContractsComponent],
    exports: [
        FuseContractsContractFormDialogComponent,
        MatchContractFormComponent,
        FuseContractsComponent,
        ContractFilterComponent,
        TradeFormComponent,
        QuickSymbolSummaryComponent,
        FuseContractsContractListComponent,
        ContractReviewComponent,
        ContractDetailFilterComponent
    ]
})
export class FuseContractsModule
{
}
