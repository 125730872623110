import {AfterViewInit, Component, Inject, LOCALE_ID, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FuseContractsContractFormDialogComponent} from '../../../trading/contracts/contract-form/contract-form.component';
import {MatchContractFormComponent} from '../../../trading/contracts/match-contract-form/match-contract-form.component';
import {Quote} from '../../../instruments/quotes/quote.model';
import {MatDialog, MatSort} from '@angular/material';
import {Subscription} from 'rxjs';
import {StatusSelectComponent} from '../../../trading/contracts/contract-context-menu/status-select/status-select.component';
import {ApprovalComponent} from '../../../trading/contracts/contract-context-menu/status-select/approval.component';
import {ContractsService, ProfitLoss} from '../../../trading/contracts/contracts.service';
import {UserService} from '../../../../../auth/user/user.service';
import {HttpClient} from '@angular/common/http';
import {QuoteService} from '../../../instruments/quotes/quote.service';
import {ContractDetailPreferencesService} from '../../../trading/contracts/contract-detail-preferences/contract-detail-preferences.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NavTabService} from '../../../../nav-tab/nav-tab.service';
import {SwitcherService} from '../../../../../shared/switcher/switcher.service';
import {Contract} from '../../../trading/contracts/contract.model';
import {GridOptions, RowNode} from 'ag-grid-community';
import {ContractDetailPreferences} from '../../../trading/contracts/contract-detail-preferences/contract-detail-preferences';
import {formatCurrency, formatNumber, formatPercent} from '@angular/common';
import {AmountAggregate, QuantityAggregate, RateAggregate, RebateAggregate} from '../../../trading/contracts/contract-details/contract-details.component';
import {CounterpartyLimitsFilterService} from './counterparty-limits-filter.service';

export interface CounterPartyLimitsAggregates {
    totalBorrowLimit: number;
    totalBorrowAmount: number;
    borrowPercentageUsed: number;
    totalLoanLimit: number;
    totalLoanAmount: number;
    loanPercentageUsed: number;
}

@Component({
  selector: 'app-counterparty-limit',
  templateUrl: './counterparty-limits.component.html',
  styleUrls: ['./counterparty-limits.component.scss']
})
export class CounterpartyLimitsComponent implements OnInit, OnDestroy, AfterViewInit {


    @ViewChild('dialogContent') dialogContent: TemplateRef<any>;

    @ViewChild('borrowComponent') set borrowForm(content: FuseContractsContractFormDialogComponent) {

    }

    @ViewChild('loanComponent') set loanForm(content: FuseContractsContractFormDialogComponent) {

    }

    @ViewChild(MatchContractFormComponent) set matchForm(content: MatchContractFormComponent) {

    }

    private gridApi;
    private gridColumnApi;
    public postSort;
    public init = false;
    public collapse = false;
    public tabId: string;
    public summarySelectedSymbol: string;
    quote: Quote;

    user: any;
    selectedContracts: any[];
    checkboxes: {};

    quantityAggregates: QuantityAggregate[];
    quantityAggregateColumns;

    amountAggregates: AmountAggregate[];
    amountAggregateColumns;

    rateAggregates: RateAggregate[];
    rateAggregateColumns;

    rebateAggregates: RebateAggregate[];
    rebateAggregateColumns;

    getRowStyle;
    gridOptions: GridOptions = <GridOptions>{};

    @ViewChild(MatSort) sort: MatSort;

    onContractsChangedSubscription: Subscription;
    onContractsAddedSubscription: Subscription;
    onSelectedContractsChangedSubscription: Subscription;
    onUserDataChangedSubscription: Subscription;
    onContractsRemovedSubscription: Subscription;
    onDepositoryNoSwitchedSubscription: Subscription;
    onSelectedSummarySymbolChangedSubscription: Subscription;
    onFilterChangedSubscription: Subscription;
    onEffectiveDateChanged: Subscription;
    dialogRef: any;
    rowClassRules: any;


    sideBar = {
        toolPanels: ['columns'],
        defaultToolPanel: ''
    };

    // BEGIN TEST DATA
    columnDefs: any[] = [];

    // detailCellRendererParams;

    contractComponents = {
        statusEditor: StatusSelectComponent,
        approvalComponent: ApprovalComponent
    };

    pinnedBottomRowData;
    counterPartyLimitsAggregates: CounterPartyLimitsAggregates = {
        totalBorrowLimit: 0,
        totalBorrowAmount: 0,
        borrowPercentageUsed: 0,
        totalLoanLimit: 0,
        totalLoanAmount: 0,
        loanPercentageUsed: 0
    };

    private symbolFilter = null;
    private counterPartyName = null;

    public columnDefaults = {
        enableCellChangeFlash: true,
        sortable: true,
        filter: false,
        resizable: true,
        cellStyle: ContractDetailPreferencesService.styleZero,
        floatingFilter: false,
        floatingFilterComponentParams: {
            suppressFilterButton: true
        },
        enableColResize: true,
        menuTabs: ['filterMenuTab']
    };


    constructor(
        public contractsService: ContractsService,
        public userService: UserService,
        public dialog: MatDialog,
        private httpClient: HttpClient,
        @Inject(LOCALE_ID) private locale: string,
        public quoteService: QuoteService,
        public contractDetailPreferenceService: ContractDetailPreferencesService,
        public router: Router,
        public navTabService: NavTabService,
        public filterService: CounterpartyLimitsFilterService,
        public switcherService: SwitcherService,
        public preferenceService: ContractDetailPreferencesService,
        public activatedRoute: ActivatedRoute
    ) {

        this.gridOptions = {
            context: this,
            isExternalFilterPresent: this.isExternalFilterPresent,
            doesExternalFilterPass: this.doesExternalFilterPass
        };

        this.setColumnDefs();

        this.rowClassRules = {
            'contract-warning': function (params) {
                const status = params.data.contractStatus;
                return status === 'Warning';
            },
        };


        // this.detailCellRendererParams = {
        //     detailGridOptions: {
        //         columnDefs: [
        //             {field: 'eventName'},
        //             {field: 'occuredOn'},
        //             {field: 'description'},
        //             {field: 'status', filter: 'agSetColumnFilter', filterParams: {newRowsAction: 'keep'}}
        //         ],
        //         onGridReady: function (params) {
        //             // //console.log('Grid ready!');
        //             params.api.sizeColumnsToFit();
        //         },
        //         onFirstDataRendered: function (params) {
        //             // //console.log('First data rendered!');
        //             const statusFilterComponent = params.api.getFilterInstance('status');
        //             statusFilterComponent.setModel({
        //                 type: 'set',
        //                 values: ['M']
        //             });
        //             params.api.onFilterChanged();
        //         },
        //         enableColResize: true,
        //         enableFilter: true,
        //         floatingFilter: false
        //     },
        //     getDetailRowData: (params: any) => {
        //         //console.log('Opening detail view! ', params);
        //         const httpOptions = {
        //             params: {
        //                 date: this.contractsService.effectiveDate.format()
        //             }
        //         };
        //         const endpoint = environment.verticalApiEndpoint + 'contracts/' + params.data.contractId + '/history';
        //         this.httpClient.get(endpoint, httpOptions)
        //             .subscribe((details: any) => {
        //                 // //console.log('details: ', details);
        //                 const contractDetails: ContractHistory[] = [];
        //                 for (const contractDetail of details.items) {
        //                     const contractDetailObj = new ContractHistory(contractDetail);
        //                     contractDetails.push(contractDetailObj);
        //                 }
        //                 params.successCallback(contractDetails);
        //             }, (error) => {
        //                 // //console.log('Couldnt get details: ', error);
        //             });
        //
        //     }
        // };

        this.postSort = (rowNodes) => {
            if (this.init) {
                this.addModifiedSort();
            }
        };

        this.getRowStyle = (params) => {
            if (params.node.rowPinned) {
                return {'font-weight': 'bold'};
            }
        };

    }

    setColumnDefs(){
        const columnDefs = [];
        columnDefs.push(
            {
                headerName: 'Firm', field: 'firm', minWidth: 150, suppressSizeToFit: true, filter: 'agTextColumnFilter',
                filterParams: {newRowsAction: 'keep'}, sort: 'asc'
            },
            {
                headerName: 'Account', field: 'account', minWidth: 80, suppressSizeToFit: true, filter: 'agSetColumnFilter',
                filterParams: {newRowsAction: 'keep'}, type: 'numericColumn'
            },
            {
                headerName: 'Counter Party', field: 'counterParty', minWidth: 80, suppressSizeToFit: true, filter: 'agSetColumnFilter',
                filterParams: {newRowsAction: 'keep'}, type: 'numericColumn'
            },

            {
                headerName: 'Borrow',
                marryChildren: true,
                children: [
                    {
                        headerName: 'Limit', field: 'bLimit',
                        type: 'numericColumn',
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return 0;
                            } else {
                                return formatCurrency(params.value, this.locale, '$', undefined, '1.0-0');
                            }
                        },
                        filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}, minWidth: 100
                    },
                    {
                        headerName: 'Amount', field: 'bAmount',
                        type: 'numericColumn',
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return 0;
                            } else {
                                return formatCurrency(params.value, this.locale, '$', undefined, '1.0-0');
                            }
                        },
                        filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}, minWidth: 100
                    },
                    {
                        headerName: 'Used %', field: 'bPerUsed',
                        type: 'numericColumn',
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return 0;
                            } else {
                                return formatPercent((params.value / 100), this.locale, '1.2-2');
                            }
                        },
                        filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}, minWidth: 80,
                        cellStyle: params => this.styleBorrowUsedPercent(params)
                    }
                ]
            },

            {
                headerName: 'Loan',
                marryChildren: true,
                children: [
                    {
                        headerName: 'Limit', field: 'lLimit',
                        type: 'numericColumn',
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return 0;
                            } else {
                                return formatCurrency(params.value, this.locale, '$', undefined, '1.0-0');
                            }
                        },
                        filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}, minWidth: 100
                    },
                    {
                        headerName: 'Amount', field: 'lAmount',
                        type: 'numericColumn',
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return 0;
                            } else {
                                return formatCurrency(params.value, this.locale, '$', undefined, '1.0-0');
                            }
                        },
                        filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}, minWidth: 100
                    },
                    {
                        headerName: 'Used %', field: 'lPerUsed',
                        type: 'numericColumn',
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return 0;
                            } else {
                                return formatPercent((params.value / 100), this.locale, '1.2-2');
                            }
                        },
                        filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}, minWidth: 80,
                        cellStyle: params => this.styleLoanUsedPercent(params)
                    }
                ]
            }

        );

        this.columnDefs = columnDefs;
    }

    styleBorrowUsedPercent(params){
        if (params.data.bPerUsed >= this.filterService.currentFilter.critical.values){
            return {'background-color': '#cf0d0d', 'color': 'white'};
        }
        else if (params.data.bPerUsed >= this.filterService.currentFilter.warn.values){
            return {'background-color': '#f2f200'};
        }
    }

    styleLoanUsedPercent(params){
        if (params.data.lPerUsed >= this.filterService.currentFilter.critical.values){
            return {'background-color': '#cf0d0d', 'color': 'white'};
        }
        else if (params.data.lPerUsed >= this.filterService.currentFilter.warn.values){
            return {'background-color': '#f2f200'};
        }
    }

    onGridSizeChanged(params) {
        setTimeout(() => {
            // get the current grids width
            const gridWidth = document.getElementById('counterparty-limits').offsetWidth;

            // keep track of which columns to hide/show
            const columnsToShow = [];
            const columnsToHide = [];

            // iterate over all columns (visible or not) and work out
            // now many columns can fit (based on their minWidth)
            let totalColsWidth = 0;
            const allColumns = params.columnApi.getAllColumns();
            for (let i = 0; i < allColumns.length; i++) {
                const column = allColumns[i];
                totalColsWidth += column.getMinWidth();
                if (totalColsWidth > gridWidth) {
                    columnsToHide.push(column.colId);
                } else {
                    columnsToShow.push(column.colId);
                }
            }

            params.api.sizeColumnsToFit();
        });
    }


    disable(minLength: number, maxLength: number, onlyLoans: boolean, onlyBorrows: boolean, reqApproval: boolean): boolean {
        const selected = this.contractsService.getSelected();
        for (const contract of selected) {
            if (contract.contractStatus !== 'Open') {
                return true;
            }

            if (reqApproval && contract.contractStatusId === 0) {
                return true;
            }

            if (onlyLoans && contract.side === 'B') {
                return true;
            } else if (onlyBorrows && contract.side === 'L') {
                return true;
            }
        }
        if (maxLength === null) {
            return !(selected.length >= minLength);
        } else {
            return !(selected.length >= minLength && selected.length <= maxLength);
        }
    }


    ngOnInit() {
        // this.onDepositoryNoSwitchedSubscription = this.switcherService.depositoryNoSwitched.subscribe(sub => {
        //     this.setFilters();
        // });

        // this.onSelectedSummarySymbolChangedSubscription = this.contractsService.symbolSelected.subscribe(symbol => {
        //     this.summarySelectedSymbol = symbol;
        //     this.navTabService.saveSelectedSymbol(this.tabId, symbol);
        //     if (this.gridApi) {
        //         this.setFilters();
        //     }
        // });

        // this.onEffectiveDateChanged = this.contractsService.onNewContractArray.subscribe(contracts => {
        //     if (this.onContractsAddedSubscription) {
        //         this.onContractsAddedSubscription.unsubscribe();
        //     }
        //     if (this.onContractsChangedSubscription) {
        //         this.onContractsChangedSubscription.unsubscribe();
        //     }
        //     if (this.onContractsRemovedSubscription) {
        //         this.onContractsRemovedSubscription.unsubscribe();
        //     }
        //     if (this.onUserDataChangedSubscription) {
        //         this.onContractsAddedSubscription.unsubscribe();
        //     }
        //
        //     if (this.contractsService.isEffectiveDateToday()) {
        //         this.setupContractSubscriptions();
        //     } else {
        //         this.gridApi.setRowData(contracts.filter((c => this.depositoryFilter(c))));
        //         this.gridApi.setFilterModel(this.filterService.currentFilter);
        //     }
        //
        //     this.gridApi.setRowData(contracts.filter((c => this.depositoryFilter(c))));
        //     this.getQuote(this.summarySelectedSymbol);
        // });
        setTimeout(() => {
            this.gridApi.setRowData([
                {
                    firm: 'Lek Securities',
                    account: '0294',
                    counterParty: '0512',
                    bLimit: 60000000,
                    bAmount: 66055700,
                    bPerUsed: 110.092833333333,
                    lLimit: 60000000,
                    lAmount: 42224270,
                    lPerUsed: 70.3737833333333
                },
                {
                    firm: 'Aldo\'s Pizza',
                    account: '6079',
                    counterParty: '5128',
                    bLimit: 200,
                    bAmount: 100,
                    bPerUsed: 50.00,
                    lLimit: 200,
                    lAmount: 170,
                    lPerUsed: 85.00
                },
                {
                    firm: 'Dunder Mifflin',
                    account: '6079',
                    counterParty: '5128',
                    bLimit: 2000,
                    bAmount: 1800,
                    bPerUsed: 90.00,
                    lLimit: 5000,
                    lAmount: 3800,
                    lPerUsed: 76.00
                }
            ]);
        }, 50);


        this.activatedRoute.params.subscribe(params => {
            this.tabId = params['id'];
            // this.summarySelectedSymbol = params['selectedSymbol'];
            if (this.gridApi) {
                // this.setFilters();
            } else {
                if (this.summarySelectedSymbol && this.summarySelectedSymbol !== 'none' && this.summarySelectedSymbol !== 'Total') {
                    // this.getQuote(this.summarySelectedSymbol);
                } else {
                    // this.quote = undefined;
                }
            }
        });
    }

    ngAfterViewInit(): void {
    }

    getQuote(symbol: string) {
        this.quoteService.getQuote(symbol, this.contractsService.effectiveDate)
            .subscribe((quote) => {
                if (quote) {
                    this.quote = new Quote(quote);
                } else {
                    this.quote = undefined;
                }
            });
    }

    setFilters() {
        // this.gridApi.setRowData([]);
        // this.summarySelectedSymbol = this.navTabService.getSelectedSymbol(this.tabId);
        // this.gridApi.setRowData(this.contractsService.currentCollection.filter((c => this.depositoryFilter(c))));
        // if (this.summarySelectedSymbol && this.summarySelectedSymbol != 'none' && this.summarySelectedSymbol != 'Total') {
        //     this.getQuote(this.summarySelectedSymbol);
        // } else {
        //     this.quote = undefined;
        // }
        this.gridApi.setFilterModel(this.navTabService.getFilter(this.tabId));
        this.filterService.setFilterControls(this.navTabService.getFilter(this.tabId));
    }

    isExternalFilterPresent() {
        return true;
    }

    doesExternalFilterPass(node) {
        // @ts-ignore
        if (node.data.lPerUsed >= this.context.filterService.currentFilter.creditUsed.values || node.data.bPerUsed >= this.context.filterService.currentFilter.creditUsed.values){
            return true;
        }

        return false;

        // switch (ageType) {
        //     case 'below30': return node.data.age < 30;
        //     case 'between30and50': return node.data.year === 2012 || node.data.country === 'United States';
        //     case 'above50': return node.data.age > 50;
        //     case 'dateAfter2008': return asDate(node.data.date) > new Date(2008,1,1);
        //     default: return true;
        // }
    }

    addModifiedSort() {
        const sort = this.gridApi.getSortModel();
        let sideSort = false;
        const modifiedSort = false;
        let rateSort = false;
        for (let i = 0; i < sort.length; i++) {

            const item = sort[i];
            if (item.colId === 'side') {
                sideSort = true;
            }
            if (item.colId === 'rateSort') {
                rateSort = true;
            }
        }
        if (sideSort && !rateSort) {
            sort.push({colId: 'rateSort', sort: 'asc'});
            //console.log('Setting sort model: ', sort);
            this.gridApi.setSortModel(sort);
            return;
        }
        if (!modifiedSort) {
            sort.push({colId: 'modifiedOn', sort: 'desc'});
            //console.log('Setting sort model: ', sort);
            this.gridApi.setSortModel(sort);
        }

    }

    autoSizeAll(event) {
        if (event.columnApi) {
            this.gridColumnApi = event.columnApi;
            const allColumnIds = [];
            this.gridColumnApi.getAllColumns().forEach(function (column) {
                allColumnIds.push(column.colId);
            });
            this.gridColumnApi.autoSizeColumns(allColumnIds);
        }
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.contractsService.init = true;
        this.init = true;
        this.setupContractSubscriptions();
        this.filterService.clearFilterControls(true);

        this.onFilterChangedSubscription = this.filterService.filterChangedEvent.subscribe(filter => {
            // let title: string;
            // if (filter.firms.values && filter.firms.values.length === 1) {
            //     this.summarySelectedSymbol = filter.symbol.values[0].toUpperCase();
            //     title = filter.symbol.values.toString().replace(/,/g, ' ') + ' ';
            //     this.navTabService.setTitle(this.tabId, title);
            // } else if (filter.cusip.values && filter.cusip.length === 1) {
            //     this.summarySelectedSymbol = filter.cusip.values[0].toUpperCase();
            //     title = filter.cusip.values.toString().replace(/,/g, ' ') + ' ';
            //     this.navTabService.setTitle(this.tabId, title);
            // } else {
            //     this.summarySelectedSymbol = 'none';
            //     this.navTabService.setTitle(this.tabId, 'Counterparty Limits');
            // }
            // this.navTabService.saveSelectedSymbol(this.tabId, this.summarySelectedSymbol);


            this.navTabService.saveFilter(this.tabId, filter);
            this.setFilters();
        });

        const filter = this.navTabService.getFilter(this.tabId);
        if (filter) {
            // this.gridApi.setFilterModel(filter);
            // this.filterService.setFilterControls(filter);
            // this.filterService.currentFilter = filter;
            setTimeout(() => {
                this.setFilters();
            }, 0);

        } else {
            this.filterService.clearFilterControls(true);
        }
    }

    contractAdded(contracts: Contract[]) {
        if (this.gridApi) {
            if (contracts.length > 0) {
                const newRows: RowNode[] = [];
                const newContracts: Contract[] = [];
                for (const contract of contracts) {
                    const rowNode = this.gridApi.getRowNode(contract.primaryKey);
                    if (rowNode === undefined) {
                        newContracts.push(contract);
                    }
                }
                this.gridApi.updateRowData({
                    add: newContracts
                });
                for (const contract of newContracts) {
                    const rowNode = this.gridApi.getRowNode(String(contract.primaryKey));
                    newRows.push(rowNode);
                }
                this.gridApi.flashCells({
                    rowNodes: newRows
                });
                this.selectedContracts = [];
                this.contractsService.setSelected([]);
                this.aggregateData();
            }
        }
        this.checkboxes = {};
        contracts.map(contract => {
            this.checkboxes[contract.primaryKey] = false;
        });
    }

    contractChanged(contracts: Contract[]) {
        if (this.gridApi) {
            this.gridApi.updateRowData({
                update: contracts
            });
            this.aggregateData();
        }
    }

    contractRemoved(contracts: Contract[]) {
        if (this.gridApi) {
            this.gridApi.updateRowData({
                remove: contracts
            });
            this.aggregateData();
        }
    }

    setupContractSubscriptions() {
        // this.setFilters();
        //
        // this.gridApi.setRowData([]);
        // this.gridApi.setRowData(this.contractsService.currentCollection.filter((c => this.depositoryFilter(c))));
        //
        // this.onContractsAddedSubscription =
        //     this.contractsService.onCollectionAdded.subscribe(contracts => this.contractAdded(contracts));
        //
        // this.onContractsChangedSubscription =
        //     this.contractsService.onCollectionChanged.subscribe(contracts => this.contractChanged(contracts));
        //
        // this.onContractsRemovedSubscription =
        //     this.contractsService.onCollectionRemoved.subscribe(contracts => this.contractRemoved(contracts));
        //
        // this.onSelectedContractsChangedSubscription =
        //     this.contractsService.onSelectedContractsChanged.subscribe((selectedcontracts) => {
        //         for (const id in this.checkboxes) {
        //             if (!this.checkboxes.hasOwnProperty(id)) {
        //                 continue;
        //             }
        //
        //             this.checkboxes[id] = selectedcontracts.includes(id);
        //         }
        //         this.selectedContracts = selectedcontracts;
        //     });
        //
        // this.onUserDataChangedSubscription =
        //     this.contractsService.onUserDataChanged.subscribe(user => {
        //         this.user = user;
        //     });
        //
        //
        // let prefs = this.navTabService.getPreferences(this.tabId);
        // this.setState(prefs);
    }

    onSelectionChanged(event) {
        this.contractsService.setSelected(this.gridApi.getSelectedRows());
    }

    saveTabPreferences(preferences: ContractDetailPreferences) {
        // this.navTabService.savePreferences(this.tabId, preferences);
        // this.navTabService.setTitle(this.tab)
    }

    onFilterChanged(event) {
        this.aggregateData();
        this.localPreferenceChange();
    }

    onSortChanged($event: any) {
        this.localPreferenceChange();
    }

    localPreferenceChange() {
        const name = this.tabId;
        const state = this.gridColumnApi.getColumnState();
        const sort = this.gridApi.getSortModel();
        const filter = this.gridApi.getFilterModel();
        const prefs: ContractDetailPreferences = new ContractDetailPreferences({
            name: name,
            state: state,
            sort: sort,
            filter: filter
        });
        this.navTabService.savePreferences(this.tabId, prefs);
    }

    onRowDataChanged(event) {
        if (this.gridApi) {
            this.aggregateData();
        }
    }

    ngOnDestroy() {
        // this.onContractsAddedSubscription.unsubscribe();
        // this.onContractsChangedSubscription.unsubscribe();
        // this.onSelectedContractsChangedSubscription.unsubscribe();
        // this.onUserDataChangedSubscription.unsubscribe();
        // this.onContractsRemovedSubscription.unsubscribe();
        this.onFilterChangedSubscription.unsubscribe();
        this.contractsService.showTradeForm = false;
        this.quoteService.quote = null;
        this.gridApi.setFilterModel({});
        this.gridApi.destroy();
        // this.onDepositoryNoSwitchedSubscription.unsubscribe();
        // this.onSelectedSummarySymbolChangedSubscription.unsubscribe();
        // this.onFilterChangedSubscription.unsubscribe();
    }

    aggregateData() {
        let totalBorrowLimit = 0;
        let totalBorrowAmount = 0;
        // let borrowPercentageUsed = 0;
        let totalLoanLimit = 0;
        let totalLoanAmount = 0;
        // let loanPercentageUsed = 0;

        this.gridApi.forEachNodeAfterFilter((rowNode: RowNode) => {
            totalBorrowLimit += rowNode.data.bLimit;
            totalBorrowAmount += rowNode.data.bAmount;

            totalLoanLimit += rowNode.data.lLimit;
            totalLoanAmount += rowNode.data.lAmount;
        });

        this.counterPartyLimitsAggregates.totalBorrowLimit = totalBorrowLimit;
        this.counterPartyLimitsAggregates.totalBorrowAmount = totalBorrowAmount;
        this.counterPartyLimitsAggregates.totalLoanLimit = totalLoanLimit;
        this.counterPartyLimitsAggregates.totalLoanAmount = totalLoanAmount;

        this.counterPartyLimitsAggregates.borrowPercentageUsed = (totalBorrowAmount / totalBorrowLimit) * 100;
        this.counterPartyLimitsAggregates.loanPercentageUsed = (totalLoanAmount / totalLoanLimit) * 100;

        this.updatePinnedRows();
    }


    getRowNodeId(data) {
        return data.primaryKey;
    }


    cellEditingStopped(event) {
        //console.log('cell editing stopped: ', event);
        if (event.colDef.headerName === 'PRC') {
            // //console.log('contract: ', event.data.contractId);
            // //console.log('new prc: ', event.value);
            if (event.value === '') {
                return;
            }
            if (event.value.length === 1 && this.contractsService.isEffectiveDateToday()) {
                this.contractsService.profitCenterAdjust(event.data, event.value);
            }
        }
    }

    updatePinnedRows(): void {
        const rows = [];
        rows.push({
            firm: 'Total',
            bLimit: this.counterPartyLimitsAggregates.totalBorrowLimit,
            bAmount: this.counterPartyLimitsAggregates.totalBorrowAmount,
            bPerUsed: this.counterPartyLimitsAggregates.borrowPercentageUsed,
            lLimit: this.counterPartyLimitsAggregates.totalLoanLimit,
            lAmount: this.counterPartyLimitsAggregates.totalLoanAmount,
            lPerUsed: this.counterPartyLimitsAggregates.loanPercentageUsed
        });

        // rows.push({
        //     symbol: 'Inventory',
        //     borrowQuantity: 'Total Borrow',
        //     loanQuantity: 'Total Loan',
        //     borrowAmount: 'Total Borrow $',
        //     loanAmount: 'Total Loan $',
        //     contractNo: 'Net',
        //     pendingSignRecall: 'Total Recall',
        //     pendingSideReturn: 'Total Return',
        //     borrowRate: 'B Blended',
        //     loanRate: 'L Blended',
        // });
        // rows.push({
        //     symbol: this.quantityAggregates[0].inventory,
        //     borrowQuantity: this.quantityAggregates[0].borrowQuantity,
        //     loanQuantity: this.quantityAggregates[0].loanQuantity,
        //     borrowAmount: this.amountAggregates[0].borrowAmount,
        //     loanAmount: this.amountAggregates[0].loanAmount,
        //     contractNo: this.amountAggregates[0].netAmount,
        //     amountFormat: (params) => formatCurrency(params.value, this.locale, '$'),
        //     pendingSignRecall: this.quantityAggregates[0].pendingSignRecallQuantity,
        //     pendingSideReturn: this.quantityAggregates[0].pendingSideReturnQuantity,
        //     borrowRecall: this.quantityAggregates[0].borrowRecallQuantity,
        //     loanRecall: this.quantityAggregates[0].loanRecallQuantity,
        //     borrowReturn: this.quantityAggregates[0].borrowReturnQuantity,
        //     loanReturn: this.quantityAggregates[0].loanReturnQuantity,
        //     borrowRate: this.rateAggregates[0].avgBorrowRate,
        //     loanRate: this.rateAggregates[0].avgLoanRate,
        //
        // });
        // rows.push({
        //     borrowAmount: 'Funding',
        //     loanAmount: 'Match',
        //     contractNo: 'Total P&L'
        // });
        // rows.push({
        //     borrowAmount: this.rebateAggregates[0].fundingToday,
        //     loanAmount: this.rebateAggregates[0].rebateToday,
        //     contractNo: this.rebateAggregates[0].totalProfitLoss,
        //     amountFormat: (params) => formatCurrency(params.value, this.locale, '$')
        // });
        this.pinnedBottomRowData = rows;
        this.gridApi.setPinnedBottomRowData(this.pinnedBottomRowData);
    }

    setState(prefs: ContractDetailPreferences): void {
        if (this.gridColumnApi && this.gridApi) {
            if (prefs) {
                if (prefs.state) {
                    this.gridColumnApi.setColumnState(prefs.state);
                } else {
                    this.gridColumnApi.setColumnState(this.contractDetailPreferenceService.defaultPreferences.state);
                }

                if (prefs.sort) {
                    this.gridApi.setSortModel(prefs.sort);
                } else {
                    this.gridApi.setSortModel(this.contractDetailPreferenceService.defaultPreferences.sort);
                }

                this.gridApi.onSortChanged();
                this.gridApi.onFilterChanged();
            }
        }
    }

}
