import { Injectable } from '@angular/core';
import {Moment} from 'moment';
import * as moment from 'moment';
import {Contract} from '../../../trading/contracts/contract.model';

@Injectable({
  providedIn: 'root'
})
export class CounterpartyLimitsService {

    effectiveDate: Moment;
    maxDate: Moment;

  constructor() {
      this.effectiveDate = moment();
      this.maxDate = moment();
  }

    setEffectiveDate(event) {
        this.effectiveDate = event.value;

        // if (this.isEffectiveDateToday()) {
        //     this.allowInput = true;
        //     this.currentCollection = this.collectionData;
        //     this.onNewContractArray.emit(this.currentCollection);
        // } else {
        //     this.allowInput = false;
        //     this.getHistoricalContractList();
        // }
        // if (this.quoteService.quote) {
        //     const symbol = this.quoteService.quote.symbol;
        //     this.quoteService.updateQuote(symbol, this.effectiveDate);
        // }
        // this.onEffectiveDateChanged.next(this.allowInput);
    }

    isEffectiveDateToday(): boolean {
        return this.effectiveDate.isSame(moment(), 'day');
    }

    getHistoricalContractList() {
        // const httpOptions = {
        //     params: {
        //         date: this.effectiveDate.format()
        //     }
        // };
        // this.onNewContractArray.emit([]);
        // this.httpClient.get(this.endpoint, httpOptions)
        //     .subscribe((resp: any) => {
        //         const contractsArr = [];
        //         // I have to rebuild a new contract array for some unknown reason to push updates..
        //         // //console.log('CONTRACTS: ', resp.items);
        //         for (const contract of resp.items) {
        //             contract.startOn = new Timestamp(new Date(contract.startOn).getTime() / 1000, 0);
        //             const newContract = new Contract(contract);
        //             newContract.primaryKey = newContract.contractId;
        //             contractsArr.push(newContract);
        //         }
        //         this.historicalCollection = contractsArr;
        //         this.currentCollection = this.historicalCollection;
        //         this.onNewContractArray.emit(this.currentCollection);
        //     }, (err) => {
        //         //console.log('err getting list db: ', err);
        //     });
    }
}
