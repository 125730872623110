import {AfterViewInit, Component, ViewChild, ViewContainerRef} from '@angular/core';
import {ICellEditorAngularComp, ICellRendererAngularComp} from 'ag-grid-angular';
import {CounterPartyAccount} from '../accounts/account.model';
import {FplService} from './fpl.service';
import {FplAccount} from './fplAccount.model';


@Component({
    selector: 'child-cell',
    template: `
        <mat-select [(ngModel)]="this.selectedAccountId"
                    [ngModelOptions]="{standalone: true}"
                    (ngModelChange)="updateLinkedAccount($event)"
                    disableOptionCentering>
            <mat-option *ngFor="let account of this.fplService.unassignedSlsAccounts" [value]="account.accountId">
                {{account.name}}
            </mat-option>
        </mat-select>
    `,
})



export class FplSlsAccountDropDownComponent implements ICellEditorAngularComp {
    public params: any;
    selectedAccountId;
    selectedAccount: FplAccount;

    constructor(public fplService: FplService) {
    }

    getValue(): any {
        return this.selectedAccountId;
    }

    agInit(params: any): void {
        this.selectedAccount = params.data;
        this.selectedAccountId = this.selectedAccount.accountId;
    }

    updateLinkedAccount(event){
        this.selectedAccount.accountId = parseInt(event, 10);
        this.fplService.updateIndividualFplAccount(this.selectedAccount);
    }
}





@Component({
    selector: 'child-cell',
    template: `
        <p style="margin-block-start: 0em; margin-block-end: 0em;">{{accountName}}</p>
    `
})
export class FplSlsAccountRendererComponent implements ICellRendererAngularComp {
    private params: any;
    public accountName: string;

    constructor(public fplService: FplService) {
    }

    agInit(params: any): void {
        this.params = params;
        this.setAccountName();
    }

    refresh(params: any): boolean {
        this.params = params;
        this.setAccountName();

        return true;
    }

    setAccountName() {
        if (this.params.data && this.params.data.accountId != 0){
            this.accountName = this.fplService.unassignedSlsAccounts.filter((element) => element.accountId == this.params.data.accountId)[0].name;
        }
        else{
            this.accountName = '';
        }
    }
}
