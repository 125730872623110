import {AfterViewInit, Component, Inject, LOCALE_ID, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {ColDef, GridApi} from 'ag-grid-community';
import {formatNumber} from '@angular/common';
import {SwitcherService} from '../../../../shared/switcher/switcher.service';
import {Subscription} from 'rxjs';
import {DtcSummaryService} from '../dtc-summary/dtc-summary.service';
import {DtcSummary} from '../dtc-summary/dtc-summary';
import {DtcSummaryFilterService} from '../dtc-summary-filter/dtc-summary-filter.service';
import {ActivatedRoute} from '@angular/router';
import {NavTabService} from '../../../nav-tab/nav-tab.service';
import Split from 'split.js';

@Component({
  selector: 'combined-dtc-summary',
  templateUrl: './combined-dtc-summary.component.html',
  styleUrls: ['./combined-dtc-summary.component.scss']
})
export class CombinedDtcSummaryComponent implements OnInit, AfterViewInit, OnDestroy {

    gridApi;
    colDefs: ColDef[];
    defaultColDef: any;
    onDepositoryNoChanged: Subscription;
    onDTCSummaryAdded: Subscription;
    onDTCSummaryChanged: Subscription;
    onDTCSummaryRemoved: Subscription;
    onFilterChangedSubscription: Subscription;
    onShowDetailsSubscription: Subscription;
    selectedSymbol = '';
    tabId;
    showDetailsPanel = false;
    splitControl;

    constructor(protected dtcSummaryService: DtcSummaryService,
                @Inject(LOCALE_ID) private locale: string,
                public switcherService: SwitcherService,
                public dtcSummaryFilterService: DtcSummaryFilterService,
                public activatedRoute: ActivatedRoute,
                public navTabService: NavTabService) {
        this.colDefs = [
            {
                headerName: 'Symbol',
                field: 'symbol',
                pinned: true,
                filter: 'agSetColumnFilter',
                sort: 'asc',
                minWidth: 120
            },
            {
                headerName: 'CUSIP',
                field: 'cusip',
                pinned: true,
                filter: 'agSetColumnFilter',
                minWidth: 120
            },
            {
                headerName: 'Quantity',
                field: 'quantity',
                cellRenderer: (params) => {
                    if (params.value === null) {
                        return '0';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                },
                filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                minWidth: 120
            },
            {
                headerName: 'Made New Borrow',
                field: 'madeNewBorrow',
                cellRenderer: (params) => {
                    if (params.value === null) {
                        return '0';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                }, filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                minWidth: 120
            },
            {
                headerName: 'Made New Loan',
                field: 'madeNewLoan',
                cellRenderer: (params) => {
                    if (params.value === null) {
                        return '0';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                }, filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                minWidth: 120
            },
            {
                headerName: 'Made Return Borrow',
                field: 'madeReturnBorrow',
                cellRenderer: (params) => {
                    if (params.value === null) {
                        return '0';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                }, filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                minWidth: 120
            },
            {
                headerName: 'Made Return Loan',
                field: 'madeReturnLoan',
                cellRenderer: (params) => {
                    if (params.value === null) {
                        return '0';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                }, filter: 'agNumberColumnFilter',
                type: 'numericColumn',
                minWidth: 120
            },
            {
                headerName: 'Pending New Borrow',
                field: 'pendNewBorrow',
                cellRenderer: (params) => {
                    if (params.value === null) {
                        return '0';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                }, filter: 'agNumberColumnFilter', hide: true,
                type: 'numericColumn',
                minWidth: 120
            },
            {
                headerName: 'Pending New Loan',
                field: 'pendNewLoan',
                cellRenderer: (params) => {
                    if (params.value === null) {
                        return '0';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                }, filter: 'agNumberColumnFilter', hide: true,
                type: 'numericColumn',
                minWidth: 120
            },
            {
                headerName: 'Pending Return Borrow',
                field: 'pendReturnBorrow',
                cellRenderer: (params) => {
                    if (params.value === null) {
                        return '0';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                }, filter: 'agNumberColumnFilter', hide: true,
                type: 'numericColumn',
                minWidth: 120
            },
            {
                headerName: 'Pending Return Loan',
                field: 'pendReturnLoan',
                cellRenderer: (params) => {
                    if (params.value === null) {
                        return '0';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                }, filter: 'agNumberColumnFilter', hide: false,
                type: 'numericColumn',
                minWidth: 120
            }
        ];
        this.defaultColDef = {
            enableCellChangeFlash: true,
            sortable: true,
            filter: true,
            resizable: true
        };
    }

    ngOnInit() {
        this.activatedRoute.params.subscribe(params => {
            this.tabId = params['id'];
            const filter = this.navTabService.getFilter(this.tabId);
            if (this.gridApi) {
                this.gridApi.setFilterModel(filter);
            }
            if (filter) {
                this.dtcSummaryFilterService.setFilterControls(filter);
                this.dtcSummaryFilterService.currentFilter = filter;
            } else {
                this.dtcSummaryFilterService.clearFilterControls(false);
            }
        });

        this.onShowDetailsSubscription = this.dtcSummaryFilterService.showDetailsPanelEvent.subscribe(value => this.showDetails(value));
    }

    ngAfterViewInit(): void {
        this.applyTabPreferences();
    }

    applyTabPreferences() {
        const tabPrefs = this.navTabService.getTabPreferences(this.tabId);
        if (tabPrefs && tabPrefs['showDetails']) {
            this.showDetails(tabPrefs['showDetails']);
            this.dtcSummaryFilterService.setDetailToggle(tabPrefs['showDetails']);
        }
    }


    onGridSizeChanged(params) {
        // get the current grids width
        var gridWidth = document.getElementById('summary').offsetWidth;

        // keep track of which columns to hide/show
        var columnsToShow = [];
        var columnsToHide = [];

        // iterate over all columns (visible or not) and work out
        // now many columns can fit (based on their minWidth)
        var totalColsWidth = 0;
        var allColumns = params.columnApi.getAllColumns();
        for (var i = 0; i < allColumns.length; i++) {
            var column = allColumns[i];
            totalColsWidth += column.getMinWidth();
            if (totalColsWidth > gridWidth) {
                columnsToHide.push(column.colId);
            } else {
                columnsToShow.push(column.colId);
            }
        }

        params.api.sizeColumnsToFit();
    }

    ngOnDestroy(): void {
        this.onDepositoryNoChanged.unsubscribe();
        this.onDTCSummaryAdded.unsubscribe();
        this.onDTCSummaryChanged.unsubscribe();
        this.onDTCSummaryRemoved.unsubscribe();
        this.onFilterChangedSubscription.unsubscribe();
        this.onShowDetailsSubscription.unsubscribe();

        this.dtcSummaryService.clearSubscriptions();
    }

    onFilterChanged(event) {
        this.localPreferenceChange();
    }

    localPreferenceChange() {
        const filter = this.dtcSummaryFilterService.currentFilter;
        this.navTabService.saveFilter(this.tabId, filter);
    }

    onRowSelectionChanged(event) {
        this.selectedSymbol = (event.node.aggData || event.node.data).symbol || event.node.key;
        this.dtcSummaryFilterService.symbolSelected.emit(this.selectedSymbol);
        this.navTabService.saveSelectedSymbol(this.tabId, this.selectedSymbol);
    }

    showDetails(value: boolean) {
        if (value) {
            this.showDetailsPanel = true;
            this.splitControl =
                Split(['#summary', '#details'], {
                    sizes: [50, 50],
                    direction: 'vertical',
                    gutterSize: 15,
                });
            this.dtcSummaryFilterService.symbolSelected.emit(this.selectedSymbol);
            this.navTabService.saveTabPreferences(this.tabId, {showDetails: true});
        } else {
            this.showDetailsPanel = false;
            this.splitControl.destroy();
            Split(['#summary'], {
                sizes: [100],
                direction: 'vertical',
                gutterSize: 0,
            });
            this.navTabService.saveTabPreferences(this.tabId, {showDetails: false});
        }
    }

    onGridReady(params) {
        this.gridApi = params.api;

        this.onDepositoryNoChanged = this.switcherService.depositoryNoSwitched.subscribe(depositoryNo => {
            this.gridApi.setRowData(this.dtcSummaryService.collectionData.filter(dtcSummary => this.depositoryFilter(dtcSummary)));
        });
        this.setupSubscriptions();

        this.onFilterChangedSubscription = this.dtcSummaryFilterService.filterChangedEvent.subscribe(filter => {
            this.gridApi.setFilterModel(filter);
            if (filter.symbol.values && filter.symbol.values.length > 0) {
                this.selectedSymbol = filter.symbol.values[0].toUpperCase();
            } else if (filter.cusip.values && filter.cusip.values.length > 0) {
                this.selectedSymbol = filter.cusip.values[0].toUpperCase();
            } else {
                this.selectedSymbol = '-';
            }
            this.navTabService.saveSelectedSymbol(this.tabId, this.selectedSymbol);
        });

        const filter = this.navTabService.getFilter(this.tabId);
        if (filter) {
            this.gridApi.setFilterModel(filter);
            this.dtcSummaryFilterService.setFilterControls(filter);
            this.dtcSummaryFilterService.currentFilter = filter;
        } else {
            this.dtcSummaryFilterService.clearFilterControls(false);
        }

    }

    getRowNodeId(data) {
        return data.primaryKey;
    }

    depositoryFilter(dtcSummary: DtcSummary): boolean {
        return dtcSummary.dtcId == this.switcherService.getSelectedDepositoryNo();
    }

    setupSubscriptions() {
        // this.gridApi.setRowData([]);
        // this.gridApi.setRowData(this.dtcSummaryService.collectionData.filter(dtcSummary => this.depositoryFilter(dtcSummary)));
        this.onDTCSummaryAdded = this.dtcSummaryService.onCollectionAdded.subscribe(dtcSummaries => {
            const newSummaries: DtcSummary[] = [];
            for (const dtcSummary of dtcSummaries.filter(s => this.depositoryFilter(s))) {
                const rowNode = this.gridApi.getRowNode(dtcSummary.primaryKey);
                if (rowNode === undefined) {
                    newSummaries.push(dtcSummary);
                }
            }
            this.gridApi.updateRowData({
                add: newSummaries
            });

        });

        this.onDTCSummaryChanged = this.dtcSummaryService.onCollectionChanged.subscribe(dtcSummaries => {
            this.gridApi.updateRowData({update: dtcSummaries.filter(s => this.depositoryFilter(s))});
        });

        this.onDTCSummaryRemoved = this.dtcSummaryService.onCollectionRemoved.subscribe(dtcSummaries => {
            this.gridApi.updateRowData({remove: dtcSummaries.filter(s => this.depositoryFilter(s))});
            this.gridApi.flashCells();
        });

        const selectedSymbol = this.navTabService.getSelectedSymbol(this.tabId);
        if (selectedSymbol) {
            this.dtcSummaryFilterService.symbolSelected.emit(selectedSymbol);
        } else {
            this.dtcSummaryFilterService.symbolSelected.emit('-');
        }
    }
}
