import {FplAccount} from '../fpl/fplAccount.model';

export class CounterPartyAccount {
    accountId: number;
    name: string;
    dtcId: string;
    accountNo: string;
    counterPartyId: number;
    accountStatusId: number;
    accountCategoryId: number;
    stopOrderEntry: boolean;
    hubMsgs: boolean;
    limitProfileId: number;
    clearingDestinationId: number;
    startOn: Date;
    endOn: Date;
    createdOn: Date;
    modifiedOn: Date;
    loanetId: string;
    lendLimit: number;
    borrowLimit: number;
    deleted: boolean;
    highlightColor: string;
    intelliclearAccount: FplAccount;

   constructor(account?) {
       account = account || {};
       this.accountId = account.accountId || 0;
       this.name = account.name || '';
       this.accountNo = account.accountNo || '';
       this.dtcId = account.dtcId || '';
       this.counterPartyId = Number(account.counterPartyId) || 0;
       this.accountStatusId = account.accountStatusId || 0;
       this.accountCategoryId = account.accountCategoryId || 0;
       this.stopOrderEntry = account.stopOrderEntry || false;
       this.hubMsgs = account.hubMsgs || false;
       this.limitProfileId = account.limitProfileId || 0;
       this.clearingDestinationId = account.clearingDestinationId || 2;
       this.startOn = account.startOn || undefined;
       this.endOn = account.endOn || undefined;
       this.createdOn = account.createdOn || undefined;
       this.modifiedOn = account.modifiedOn || undefined;
       this.loanetId = account.loanetId || '';
       this.lendLimit = account.lendLimit || 0;
       this.borrowLimit = account.borrowLimit || 0;
       this.deleted = account.deleted || false;
       this.intelliclearAccount = account.intelliclearAccount;
   }

}
