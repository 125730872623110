import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../../../auth/auth.guard';
import {EntitiesComponent} from './entities.component';
import {EntityService} from './entity.service';
import {EntityListComponent} from './entity-list/entity-list.component';
import {EntityDetailComponent} from './entity-detail/entity-detail.component';
import {FuseSharedModule} from '../../../../../@fuse/shared.module';
import {
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule, MatSelectModule, MatSidenavModule,
    MatTableModule,
    MatTabsModule
} from '@angular/material';
import {CdkTableModule} from '@angular/cdk/table';
import {EntityDetailService} from './entity-detail/entity-detail.service';
import {AddressComponent} from '../address/address.component';
import {CounterpartiesModule} from '../counterparties/counterparties.module';
import {CounterpartyService} from '../counterparties/counterparty.service';
import {AgGridModule} from 'ag-grid-angular';

const routes: Routes = [
    {
        path     : 'entities',
        component: EntitiesComponent,
        canActivate: [AuthGuard],
        resolve  : {
            data: EntityService
        }
    },
    {
        path: 'entity/:entityId',
        component: EntityDetailComponent,
        canActivate: [AuthGuard],
        resolve: {
            data: EntityDetailService,
            counterParties: CounterpartyService
        }
    }
];


@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FuseSharedModule,
        MatCardModule,
        MatIconModule,
        MatTableModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        CdkTableModule,
        MatTabsModule,
        MatSelectModule,
        CounterpartiesModule,
        AgGridModule.withComponents([]),
        MatSidenavModule,
    ],
  declarations: [
      EntitiesComponent,
      EntityListComponent,
      EntityDetailComponent,
      AddressComponent,
  ],
    providers: [
        EntityService,
        EntityDetailService
    ]
})
export class EntitiesModule { }
