import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {formatCurrency, formatNumber} from '@angular/common';
import * as moment from 'moment';
import {GridApi} from 'ag-grid-community';
import {TransactionHistoryService} from '../transaction-history.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-transaction-grid',
    templateUrl: './transaction-grid.component.html',
    styleUrls: ['./transaction-grid.component.scss']
})
export class TransactionGridComponent implements OnInit {


    public defaultColDefs = {};
    public columnDefaults = {
        enableCellChangeFlash: true,
        sortable: true,
        filter: true,
        resizable: true,

        floatingFilterComponentParams: {
            suppressFilterButton: true
        }
    };
    public gridApi: GridApi;
    transactionSubscription: Subscription;

    dateComparator(date1, date2) {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        if (date1 && date2) {
            return momentDate1.diff(momentDate2);
        }
    }

    constructor(
        public transactionHistoryService: TransactionHistoryService,
        @Inject(LOCALE_ID) private locale: string
    ) {
        this.setDefaultColDef();
        this.transactionSubscription = this.transactionHistoryService.getQueryCompleteEmitter().subscribe(()=> {this.gridApi.setRowData(this.transactionHistoryService.transactionList);})
    }

    ngOnInit() {
    }

    onGridReady(params) {
        this.gridApi = params.api;
}


    setDefaultColDef(): void {
        const columnDefs = [];
        columnDefs.push(
            {
                headerName: 'Symbol', field: 'symbol', width: 150,
                filterParams: {
                    defaultOption: 'equals', newRowsAction: 'keep'
                },
                filter: 'agTextColumnFilter', lockPinned: true,

                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                headerName: 'Cusip', field: 'cusip', filterParams: {newRowsAction: 'keep'}, filter: 'agTextColumnFilter', width: 150,
                lockPinned: true,
            },
            {
                headerName: 'Side', field: 'side', width: 50, suppressSizeToFit: true, filter: 'agTextColumnFilter',
                filterParams: {
                    newRowsAction: 'keep', side: {
                        filterType: 'set', filter: 'B', type: 'equals'
                    }
                },
                suppressMenu: true, headerTooltip: 'Side',
                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {headerName: 'Account', field: 'depositoryNo', width: 100, suppressSizeToFit: true, filter: 'agTextColumnFilter', filterParams: {newRowsAction: 'keep'}},
            {
                headerName: 'Contra', field: 'contraDepositoryNo', width: 100, suppressSizeToFit: true, filter: 'agTextColumnFilter',
                filterParams: {newRowsAction: 'keep'}
            },
            {
                headerName: 'Rate', field: 'rate', width: 90, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                cellFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');
                    }
                }, filter: 'agNumberColumnFilter'
            },
            {
                headerName: 'Borrow Qty', field: 'borrowQuantity', width: 100, suppressSizeToFit: true,
                cellFormatter: (params) => {
                    if (params.value === null) {
                        return '';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                },
                type: 'numericColumn', filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}
            },
            {
                headerName: 'Loan Qty', field: 'loanQuantity', width: 100, suppressSizeToFit: true,
                cellFormatter: (params) => {
                    if (params.value === null) {
                        return '';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                },

                type: 'numericColumn', filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}
            },
            {
                headerName: 'Borrow Amt', field: 'borrowAmount', type: 'numericColumn', filter: 'agNumberColumnFilter',
                cellFormatter: (params) => {
                    if (params.value === null) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$', undefined, '1.0-0');
                    }
                },
                width: 150, filterParams: {newRowsAction: 'keep'}
            },
            {
                headerName: 'Loan Amt', field: 'loanAmount', type: 'numericColumn', filter: 'agNumberColumnFilter', width: 125,
                cellFormatter: (params) => {
                    if (params.value === null) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$', undefined, '1.0-0');
                    }
                },
                maxWidth: 150, filterParams: {newRowsAction: 'keep'}
            },
            {
                headerName: 'Contract No.',
                field: 'contractNo',
                suppressSizeToFit: true,
                width: 150,
                filter: 'agTextColumnFilter',
                filterParams: {newRowsAction: 'keep'},
            },
            {
                headerName: 'PRC', field: 'profitCenter', width: 70, suppressSizeToFit: true,
                filter: 'agTextColumnFilter', filterParams: {newRowsAction: 'keep'},
                editable: false
            },
            {headerName: 'Transaction', field: 'transactionType', filter: 'agTextColumnFilter', width: 150, hide: false, filterParams: {newRowsAction: 'keep'}},
            {
                headerName: 'TransactionTime', field: 'transactionTime', hide: false, filterParams: {newRowsAction: 'keep'}, sort: 'desc'
            },
        );
        this.defaultColDefs = columnDefs;
    }
}
