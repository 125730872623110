import {Component, Input, OnInit} from '@angular/core';
import {Address} from './address.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

    @Input() address: Address;
    form: FormGroup;
    formErrors: any;

  constructor(private formBuilder: FormBuilder) {
      this.formErrors = {
          address1   : {},
          address2  : {},
          city      : {},
          state     : {},
          postal: {},
          countryCode   : {}
      };
  }

  ngOnInit() {
      // Reactive Form
      this.form = this.formBuilder.group({
          address1   : [this.address.address1, [Validators.required, Validators.maxLength(100)]],
          address2  : [this.address.address2, [Validators.required, Validators.maxLength(100)]],
          city      : [this.address.city, [Validators.required, Validators.maxLength(50)]],
          state     : [this.address.state, [Validators.required, Validators.maxLength(20)]],
          postal: [this.address.postal, [Validators.required, Validators.maxLength(5)]],
          countryCode   : [this.address.countryCode, [Validators.required, Validators.maxLength(3)]]
      });
  }

}
