import {parseIntAutoRadix} from '@angular/common/src/i18n/format_number';

export class FplAccount {
    fplAccountId: number;
    accountId: number;
    correspondentCode: string;
    office: string;
    accountNumber: string;
    accountType: string;
    status: string;
    name: string;
    revSplit: number;

    constructor(account?) {
        this.fplAccountId = parseInt(account.fplAccountId);
        this.accountId = parseInt(account.accountId);
        this.correspondentCode = account.corr;
        this.office = account.office;
        this.accountNumber = account.acctNo;
        this.accountType = account.acctType;
        this.status = account.status;
        this.name = account.name;
        this.revSplit = account.revSplit;
    }
}
