import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {MatCardModule, MatDatepickerModule, MatIconModule, MatInputModule, MatMenuModule, MatSidenavModule, MatTabsModule} from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseNavigationModule, FuseSearchBarModule, FuseShortcutsModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { FuseContentModule } from 'app/main/content/content.module';
import { FuseFooterModule } from 'app/main/footer/footer.module';
import { FuseNavbarModule } from 'app/main/navbar/navbar.module';
import { FuseQuickPanelModule } from 'app/main/quick-panel/quick-panel.module';
import { FuseToolbarModule } from 'app/main/toolbar/toolbar.module';
import { FuseMainComponent } from './main.component';
import {UserService} from '../auth/user/user.service';
import {CounterpartyService} from './content/operations/counterparties/counterparty.service';
import {QuoteService} from './content/instruments/quotes/quote.service';
import {ConstantsModule} from './content/operations/constants/constants.module';
import {NavTabComponent} from './nav-tab/nav-tab.component';
import {FuseContractsModule} from './content/trading/contracts/contracts.module';
import {QuickTradeComponent} from './content/trading/quick-trade/quick-trade.component';

@NgModule({
    declarations: [
        FuseMainComponent,
        NavTabComponent,
    ],
    imports: [
        RouterModule,
        MatSidenavModule,
        FuseSharedModule,
        FuseThemeOptionsModule,
        FuseNavigationModule,
        FuseSearchBarModule,
        FuseSidebarModule,
        FuseContentModule,
        FuseFooterModule,
        FuseNavbarModule,
        FuseQuickPanelModule,
        FuseToolbarModule,
        ConstantsModule,
        MatTabsModule,
        MatIconModule,
        MatMenuModule,
        MatInputModule,
        MatDatepickerModule,
        FuseContractsModule,
        MatCardModule,
    ],
    exports: [
        FuseMainComponent,
    ],
    providers: [
        UserService,
        CounterpartyService,
        QuoteService,
    ]
})
export class FuseMainModule
{
}
