import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FuseSharedModule } from '@fuse/shared.module';

import { FuseContentComponent } from 'app/main/content/content.component';
import {FuseContractsModule} from './trading/contracts/contracts.module';
import {FusePositionsModule} from './trading/positions/positions.module';
import {CounterpartiesModule} from './operations/counterparties/counterparties.module';
import {EntitiesModule} from './operations/entities/entities.module';
import {UsersModule} from './operations/users/users.module';
import {AccountsModule} from './operations/accounts/accounts.module';
import {ReturnsModule} from './trading/returns/returns.module';
import {RecallsModule} from './trading/recalls/recalls.module';
import {DtcModule} from './dtc/dtc.module';
import {InstrumentsModule} from './operations/instruments/instruments.module';
import {SummaryModule} from '../../summary/summary.module';
import {TransactionHistoryModule} from './operations/transaction-history/transaction-history.module';
import {CombinedContractsModule} from './trading/combined-contracts/combined-contracts.module';
import {FplModule} from './operations/fpl/fpl.module';
import {UserPreferencesModule} from './operations/user-preferences/user-preferences.module';
import {RiskModule} from './operations/risk/risk.module';
import {LcorModule} from './operations/lcor/lcor.module';
import {ReleaseNotesModule} from './release-notes/release_notes.module';
import {SegMemosegModule} from './operations/seg-memoseg/seg-memoseg.module';
import {ReportsModule} from './reports/reports.module';
import { DrawerContainerComponent } from './trading/trade-form/drawer-container/drawer-container.component';
import {ShortRatesModule} from './operations/short-rates/shortRates.module';
import {SecurityMasterModule} from './operations/securitymaster/securitymaster.module';
import {LendingpitModule} from './operations/lendingpit/lendingpit.module';
import {ContractManagementModule} from './operations/contract-management/contract-management.module';
import {BuyInsModule} from './buy-ins/buy-ins.module';

@NgModule({
    declarations: [
        FuseContentComponent,
        DrawerContainerComponent
    ],
    imports     : [
        RouterModule,
        FuseContractsModule,
        FusePositionsModule,
        FuseSharedModule,
        CounterpartiesModule,
        EntitiesModule,
        UsersModule,
        AccountsModule,
        ReturnsModule,
        RecallsModule,
        DtcModule,
        InstrumentsModule,
        SecurityMasterModule,
        SummaryModule,
        TransactionHistoryModule,
        CombinedContractsModule,
        FplModule,
        ReportsModule,
        UserPreferencesModule,
        RiskModule,
        LcorModule,
        ReleaseNotesModule,
        SegMemosegModule,
        ShortRatesModule,
        LendingpitModule,
        ContractManagementModule,
        BuyInsModule
    ],
    exports: [
        FuseContentComponent,
        DrawerContainerComponent
    ],
    providers: [

    ]
})
export class FuseContentModule
{
}
