import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {AccountService} from '../account.service';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import {NavTabItem} from '../../../../nav-tab/nav-tab-item';
import {NavTabService} from '../../../../nav-tab/nav-tab.service';

@Component({
    selector: 'app-account-list',
    templateUrl: './account-list.component.html',
    styleUrls: ['./account-list.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class AccountListComponent implements OnInit {

    columnDefs: any[];
    gridApi: any;
    @Input() counterPartyId;
    getContextMenuItems;

    constructor(public accountService: AccountService,
                private router: Router,
                public navTabService: NavTabService) {
    }

    ngOnInit() {
        this.columnDefs = [
            {headerName: 'Name', field: 'name', filter: 'agTextColumnFilter'},
            {headerName: 'DTC Id', field: 'dtcId', filter: 'agNumberColumnFilter'},
            {headerName: 'Loanet Id', field: 'loanetId', filter: 'agNumberColumnFilter'},
            {field: 'counterPartyId', hide: true, filter: 'agNumberColumnFilter'}
        ];


        this.getContextMenuItems = function getContextMenuItems(params) {
            const result = [
                {
                    name: params.node.data.deleted ? 'Enable Account' : 'Disable Account',
                    action: function() {
                        // window.alert('Alerting about ' + params.value);
                        //console.log('params: ', params);
                        params.api.accountService.disableAccount(params.node.data.accountId)
                            .then((resp) => {
                                params.api.updateRowData({remove: [params.node.data]});
                            })
                            .catch((error) => {
                                //console.log('err: ', error);
                            });
                    }
                },
            ];
            return result;
        };
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
        this.gridApi.accountService = this.accountService;
        this.setFilter();
        // if (this.counterPartyId) {
        //     // //console.log('filtering1');
        //     const filter = {
        //         counterPartyId: {
        //             filter: this.counterPartyId,
        //             filterType: 'number',
        //             type: 'equals'
        //         }
        //     };
        //     this.gridApi.setFilterModel(filter);
        // }
    }

    getRowNodeId(data) {
        return data.accountId;
    }


    onRowClicked(event) {


        if (event.node) {
            const accountId = this.getRowNodeId(event.node.data);
            // this.router.navigate(['accounts/' + accountId], {
            //     replaceUrl: true,
            // });


            const symbol = event.node.data.name + ' - ' + event.node.data.dtcId;
            const navTabItem = new NavTabItem('accounts', symbol, 'item', 'business_center',
                '/accounts/' + accountId, null, null, null,
                null,
                symbol);

            this.navTabService.addTab(navTabItem);
        }
    }

    setFilter() {
        if (this.counterPartyId) {
            const counterPartyIdFilterComponent = this.gridApi.getFilterInstance('counterPartyId');
            counterPartyIdFilterComponent.setModel({
                type: 'equals',
                filter: this.counterPartyId,
                filterTo: null,
                newRowsAction: 'keep'
            });
            this.gridApi.onFilterChanged();
        }
    }

    onFirstDataRendered(params) {
        this.gridApi = params.api;
        this.setFilter();
        //
        // if (this.counterPartyId) {
        //     // //console.log('filtering2');
        //     const filter = {
        //         counterPartyId: {
        //             filter: this.counterPartyId,
        //             filterType: 'number',
        //             type: 'equals'
        //         }
        //     };
        //     this.gridApi.setFilterModel(filter);
        // }
    }
}
