import {AfterViewInit, Component, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {EventEmitter} from '@angular/core';
import {ContractFilterService} from './contract-filter.service';
import {forEach} from '@angular/router/src/utils/collection';
import {ConstantsService} from '../shared/constants/constants.service';
import {ContractsService} from '../main/content/trading/contracts/contracts.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-contract-filter',
    templateUrl: './contract-filter.component.html',
    styleUrls: ['./contract-filter.component.scss']
})

export class ContractFilterComponent implements OnInit, OnDestroy {
    filterChangedSubscription: Subscription;
    clearFilterControlsSubscription: Subscription;
    setDetailToggleSubscription: Subscription;
    showDetailsToggle;
    showDetails = false;
    showDetailsButtonText = 'Show Details';

    constructor(private fb: FormBuilder, public filterService: ContractFilterService, public constantsService: ConstantsService, public contractsService: ContractsService) {
        this.filterChangedSubscription = filterService.setFilterControlsEvent.subscribe(filter => this.setFilterControls(filter));
        this.clearFilterControlsSubscription = filterService.clearFilterControlsEvent.subscribe(applyFilter => this.clearFilter(applyFilter));
        this.setDetailToggleSubscription = filterService.setDetailToggleEvent.subscribe(toggleValue => this.setDetailToggle(toggleValue));
    }

    public formFilter: FormGroup;

    ngOnInit() {
        this.formFilter = this.fb.group({
            symbol: [], contractNo: [], side: [], contraDepositoryNo: [], contraLoanetId: [], status: [], profitCenter: []
        });
        this.showDetailsToggle = new FormControl();
        this.setFilterControls(this.filterService.currentFilter);
    }

    ngOnDestroy(): void {
        this.filterChangedSubscription.unsubscribe();
        this.clearFilterControlsSubscription.unsubscribe();
        this.setDetailToggleSubscription.unsubscribe();
    }

    applyFilter() {
        var symbolCusipArray = this.formFilter.get('symbol').value !== null ? this.formFilter.get('symbol').value.toString().trim().toUpperCase().split(' ', 50) : null;
        var symbolArray = [];
        var cusipArray = [];
        var contractNoArray = this.formFilter.get('contractNo').value !== null ? this.formFilter.get('contractNo').value.toString().toString().trim().toUpperCase().split(' ', 50) : null;
        var contractNumbers = [];
        var contraDepositoryArray = this.formFilter.get('contraDepositoryNo').value !== null ? this.formFilter.get('contraDepositoryNo').value.toString().trim().toUpperCase().split(' ', 10) : null;
        var contraDepositoryNumbers = [];

        var contraLoanetIdArray = this.formFilter.get('contraLoanetId').value !== null ? this.formFilter.get('contraLoanetId').value.toString().trim().toUpperCase().split(' ', 10) : null;
        var contraLoanetIds = [];

        var side: [] = this.formFilter.get('side').value !== null ? this.formFilter.get('side').value : [];
        var profitCenters = this.formFilter.get('profitCenter').value !== null ? this.formFilter.get('profitCenter').value : [];
        var profitCenterArray = [];

        if (profitCenters != null && profitCenters.length > 0) {
            profitCenters.forEach(function (value) {
                if (value === 'Blank') {
                    profitCenterArray.push(null);
                } else {
                    profitCenterArray.push(value);
                }
            });
        }

        if (symbolCusipArray !== null && symbolCusipArray.length > 0) {
            symbolCusipArray.forEach(function (value) {
                if (value.length > 0) {
                    if (value.length === 9) {
                        cusipArray.push(value);
                    } else {
                        symbolArray.push(value);
                    }
                }
            });
        }

        if (contractNoArray !== null && contractNoArray.length > 0) {
            contractNoArray.forEach(function (value) {
                if (value.length == 9) {
                    contractNumbers.push(value);
                }
            });
        }

        if (contraDepositoryArray !== null && contraDepositoryArray.length > 0) {
            contraDepositoryArray.forEach(function (value) {
                if (value.length > 0) {
                    contraDepositoryNumbers.push(value);
                }
            });
        }

        if (contraLoanetIdArray !== null && contraLoanetIdArray.length > 0) {
            contraLoanetIdArray.forEach(function (value) {
                if (value.length > 0) {
                    contraLoanetIds.push(value);
                }
            });
        }

        var filter = {
            symbol: {
                filterType: 'set',
                values: symbolArray.length > 0 ? symbolArray : null
            },
            cusip: {
                filterType: 'set',
                values: cusipArray.length > 0 ? cusipArray : null
            },
            contractNo: {
                filterType: 'set',
                values: contractNumbers.length > 0 ? contractNumbers : null
            },
            contraDepositoryNo: {
                filterType: 'set',
                values: contraDepositoryNumbers.length > 0 ? contraDepositoryNumbers : null
            },
            contraLoanetId: {
                filterType: 'set',
                values: contraLoanetIds.length > 0 ? contraLoanetIds : null
            },
            profitCenter: {
                filterType: 'set',
                values: profitCenterArray.length > 0 ? profitCenterArray : null
            }
        };
        this.filterService.filterChanged(filter);
    }

    clearFilter(applyFilter: boolean) {
        if (this.formFilter) {
            this.formFilter.reset();
            if (applyFilter) {
                this.applyFilter();
            }
        }
    }

    showDetailView() {
        if (this.showDetails) {
            this.showDetailsButtonText = 'Hide Details';
        } else {
            this.showDetailsButtonText = 'Show Details';
        }
        this.filterService.showDetails(this.showDetails);
    }

    setDetailToggle(value: boolean) {
        this.showDetails = value;
    }

    setFilterControls(filter: any[]) {
        if (this.formFilter) {
            this.formFilter.reset();
            if (filter) {
                let columns = Object.keys(filter);
                for (let id in columns) {
                    let controlName = columns[id].toString();
                    if (controlName === 'cusip') {
                        controlName = 'symbol';
                    }
                    let control = this.formFilter.get(controlName);
                    if (control) {
                        switch (controlName) {
                            case'profitCenter': {
                                let pcArray = [];
                                if (filter[columns[id]].values) {
                                    filter[columns[id]].values.forEach(function (value) {
                                        if (value === null) {
                                            pcArray.push('Blank');
                                        } else {
                                            pcArray.push(value);
                                        }
                                    });

                                    control.setValue(pcArray);
                                }
                                break;
                            }
                            case 'symbol':
                            case 'cusip': {
                                let val = '';
                                if (filter['symbol'] && filter['symbol'].values) {
                                    val += filter['symbol'].values.toString().replace(/,/g, ' ') + ' ';
                                }
                                if (filter['cusip'] && filter['cusip'].values) {
                                    val += filter['cusip'].values.toString().replace(/,/g, ' ') + ' ';
                                }
                                control.setValue(val);
                                break;
                            }
                            default:
                                if (filter[columns[id]].values) {
                                    control.setValue(filter[columns[id]].values.toString().replace(/,/g, ' '));
                                }
                        }
                    }
                }
            }
        }
    }

    textInput(input: string) {
        return input.toUpperCase();
    }
}
