import {Component, Inject, LOCALE_ID, OnDestroy, OnInit} from '@angular/core';
import {ReportsService, ShortLoanInterest} from '../reports.service';
import {GridApi} from 'ag-grid-community';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {formatCurrency, formatNumber} from '@angular/common';

@Component({
    selector: 'app-stock-loan-interest-report',
    templateUrl: './stock-loan-interest-report.component.html',
    styleUrls: ['./stock-loan-interest-report.component.scss']
})
export class StockLoanInterestReportComponent implements OnInit, OnDestroy {


    public defaultColDefs = {};
    public columnDefaults = {
        enableCellChangeFlash: true,
        sortable: true,
        filter: true,
        resizable: true,

        floatingFilterComponentParams: {
            suppressFilterButton: true
        }
    };
    public gridApi: GridApi;
    public shortLoanData: ShortLoanInterest[];
    private filterSubscription: Subscription;
    private pinnedBottomRowData;
    getRowStyle;

    dateComparator(date1, date2) {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        if (date1 && date2) {
            return momentDate1.diff(momentDate2);
        }
    }

    constructor(
        private reportsService: ReportsService,
        @Inject(LOCALE_ID) private locale: string
    ) {
        this.setDefaultColDef();

        this.getRowStyle = (params) => {
            if (params.node.rowPinned) {
                return {'font-weight': 'bold'};
            }
        };
    }

    ngOnInit() {
        this.filterSubscription = this.reportsService.shortLoanInterestChangeFilter.subscribe((val) => {
            this.reportsService.getShortLoanInterestReportData(val.fromDate, val.toDate).then((response) => {
                this.shortLoanData = response;
                this.aggregateData();
            });
        });

        this.reportsService.getShortLoanInterestReportData(
            this.reportsService.getShortLoanInterestFilter().fromDate,
            this.reportsService.getShortLoanInterestFilter().toDate).then((response) => {
            this.shortLoanData = response;
            this.aggregateData();
        });

    }

    onGridReady(params) {
        this.gridApi = params.api;
    }

    setDefaultColDef(): void {
        const columnDefs = [];
        this.defaultColDefs = columnDefs;

        columnDefs.push(
            {
                headerName: 'Date', field: 'date',
                sort: 'desc', width: 120, resizable: true,
                filter: 'agTextColumnFilter',
                comparator: this.dateComparator,
                valueFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return moment(params.value).format('MM/DD/YYYY');
                    }
                },
                pinnedRowCellRenderer: (params) => {
                    return 'Total';
                }
            },
            {
                headerName: 'Symbol', field: 'symbol', width: 120,
                filterParams: {
                    defaultOption: 'equals', newRowsAction: 'keep'
                },
                filter: 'agTextColumnFilter', lockPinned: true,

                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                headerName: 'Shares', field: 'shares', width: 140, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                cellFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    }
                }, filter: 'agNumberColumnFilter', aggFunc: 'sum',
            },
            {
                headerName: 'Price', field: 'price', width: 110, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                cellFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');
                    }
                }, filter: 'agNumberColumnFilter',
                pinnedRowCellRenderer: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');
                    }
                }
            },
            {
                headerName: 'Amount', field: 'amount', width: 130, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                cellFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');
                    }
                }, filter: 'agNumberColumnFilter', aggFunc: 'sum',
                pinnedRowCellRenderer: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');
                    }
                }
            },
            {
                headerName: 'Rate', field: 'rate', width: 90, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                cellFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');
                    }
                }, filter: 'agNumberColumnFilter',
                pinnedRowCellRenderer: (params) => {
                    return '';
                }
            },
            {
                headerName: 'Charge', field: 'charge', width: 140, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                cellFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');
                    }
                }, filter: 'agNumberColumnFilter', aggFunc: 'sum',
                pinnedRowCellRenderer: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');
                    }
                }
            },
            {
                headerName: 'QtyFrom7359', field: 'qtyFrom7359', width: 140, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                cellFormatter: (params) => {
                    if (params.value === undefined || params.value === null) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.0-0');
                    }

                }, filter: 'agNumberColumnFilter', aggFunc: 'sum',
                pinnedRowCellRenderer: (params) => {
                    if (params.value === undefined || params.value === null) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.0-0');
                    }
                }
            },

            {
                headerName: 'RateFrom7359', field: 'rateFrom7359', width: 90, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                cellFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');
                    }
                }, filter: 'agNumberColumnFilter',
                pinnedRowCellRenderer: (params) => {
                    return '';
                }
            },
            {
                headerName: 'CostFrom7359', field: 'costFrom7359', width: 140, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                cellFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');
                    }
                }, filter: 'agNumberColumnFilter', aggFunc: 'sum',
                pinnedRowCellRenderer: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');
                    }
                }
            },
            {
                headerName: 'FromFpl', field: 'fromFpl', width: 90, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                cellFormatter: (params) => {
                    if (params.value === undefined || params.value === null) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.0-0');
                    }
                }, filter: 'agNumberColumnFilter', aggFunc: 'sum',
                pinnedRowCellRenderer: (params) => {
                    if (params.value === undefined || params.value === null) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.0-0');
                    }
                }
            },
            {
                headerName: 'FplPrice', field: 'fplPrice', width: 90, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                cellFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');
                    }
                }, filter: 'agNumberColumnFilter',
                pinnedRowCellRenderer: (params) => {
                    return '';
                }
            },
            {
                headerName: 'FplAmount', field: 'fplAmount', width: 130, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                cellFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');
                    }
                }, filter: 'agNumberColumnFilter', aggFunc: 'sum',
                pinnedRowCellRenderer: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');
                    }
                }
            },
            {
                headerName: 'FpLRate', field: 'fplRate', width: 90, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                cellFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');
                    }
                }, filter: 'agNumberColumnFilter',
                pinnedRowCellRenderer: (params) => {
                    return '';
                }
            },
            {
                headerName: 'FplCost', field: 'fplCost', width: 90, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                cellFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');
                    }
                }, filter: 'agNumberColumnFilter', aggFunc: 'sum',
                pinnedRowCellRenderer: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');
                    }
                }
            },
            {
                headerName: 'ProfitLoss', field: 'profitLoss', width: 140, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                cellFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');
                    }
                }, filter: 'agNumberColumnFilter', aggFunc: 'sum',
                pinnedRowCellRenderer: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');
                    }
                }
            },
        );
    }

    onRowDataChanged(event) {
        if (this.gridApi) {
            this.aggregateData();
        }
    }

    aggregateData() {
        const result = {
            //price: 0,
            amount: 0,
            charge: 0,
            qtyFrom7359: 0,
            costFrom7359: 0,
            fromFpl: 0,
            fplAmount: 0,
            fplCost: 0,
            profitLoss: 0,
        };

        this.gridApi.forEachNodeAfterFilter(function (node, index) {
            if (node.group) {
                return;
            }
            const data = node.group ? node.aggData : node.data;

            //result.price += data.price;
            result.amount += data.amount;
            result.charge += data.charge;
            result.qtyFrom7359 += data.qtyFrom7359;
            result.costFrom7359 += data.costFrom7359;
            result.fromFpl += data.fromFpl;
            result.fplAmount += data.fplAmount;
            result.fplCost += data.fplCost;
            result.profitLoss += data.profitLoss;
        });

        this.pinnedBottomRowData = result;
        this.updatePinnedRows();
    }

    updatePinnedRows() {
        const rows = [];
        rows.push({
            date: '',
            symbol: '',
            shares: '',
            //price: '', //this.pinnedBottomRowData.price,
            amount: this.pinnedBottomRowData.amount,
            rate: '',
            charge: this.pinnedBottomRowData.charge,
            qtyFrom7359: this.pinnedBottomRowData.qtyFrom7359,
            rateFrom7359: '',
            costFrom7359: this.pinnedBottomRowData.costFrom7359,
            fromFpl: this.pinnedBottomRowData.fromFpl,
            fplPrice: '',
            fplAmount: this.pinnedBottomRowData.fplAmount,
            fplRate: '',
            fplCost: this.pinnedBottomRowData.fplCost,
            profitLoss: this.pinnedBottomRowData.profitLoss,
        });

        this.gridApi.setPinnedBottomRowData(rows);
    }

    ngOnDestroy() {
        this.filterSubscription.unsubscribe();
    }

    onFilterChanged($event){
        this.aggregateData();
    }
}


