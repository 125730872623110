import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SegMemosegComponent } from './seg-memoseg.component';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../../../auth/auth.guard';
import {CdkTableModule} from '@angular/cdk/table';
import {
    MatAutocompleteModule, MatBottomSheetModule,
    MatButtonModule, MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule, MatDividerModule, MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule, MatProgressBarModule, MatRadioModule,
    MatRippleModule, MatSelectModule, MatSidenavModule, MatSlideToggleModule, MatSnackBarModule, MatStepperModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule
} from '@angular/material';
import {FuseSharedModule} from '../../../../../@fuse/shared.module';
import {FuseConfirmDialogModule} from '../../../../../@fuse/components';
import {SharedModule} from '../../../../shared';
import {AgGridModule} from 'ag-grid-angular';
import {ContractContextMenuComponent} from '../../trading/contracts/contract-context-menu/contract-context-menu.component';
import {StatusSelectComponent} from '../../trading/contracts/contract-context-menu/status-select/status-select.component';
import {ApprovalComponent} from '../../trading/contracts/contract-context-menu/status-select/approval.component';

const routes: Routes = [
    {
        path     : 'seg-memoseg',
        component: SegMemosegComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
  declarations: [
      SegMemosegComponent
  ],
  imports: [
      RouterModule.forChild(routes),
      CdkTableModule,
      MatCardModule,
      MatButtonModule,
      MatCheckboxModule,
      MatDatepickerModule,
      MatFormFieldModule,
      MatIconModule,
      MatInputModule,
      MatMenuModule,
      MatRippleModule,
      MatSidenavModule,
      MatTableModule,
      MatToolbarModule,
      MatSelectModule,
      MatRadioModule,
      MatTabsModule,
      MatStepperModule,
      MatExpansionModule,
      MatSnackBarModule,
      FuseSharedModule,
      FuseConfirmDialogModule,
      SharedModule,
      AgGridModule.withComponents([ContractContextMenuComponent, StatusSelectComponent, ApprovalComponent]),
      MatDividerModule,
      MatProgressBarModule,
      MatAutocompleteModule,
      MatBottomSheetModule,
      MatSlideToggleModule,
      MatButtonToggleModule,
      MatTooltipModule
  ]
})
export class SegMemosegModule { }
