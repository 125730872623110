import {AfterViewChecked, Component, Inject, ViewEncapsulation} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

// @ViewChild('focusThis') focusThis;

// @ts-ignore
@Component({
    selector     : 'contracts-details-preferences-rename-form-dialog',
    templateUrl  : './rename-form.component.html',
    styleUrls    : ['./rename-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContractDetailsPreferenceRenameComponent implements AfterViewChecked
{
    dialogTitle: string;
    renameForm: FormGroup;
    name: string;

    constructor(public dialogRef: MatDialogRef<ContractDetailsPreferenceRenameComponent>,
                @Inject(MAT_DIALOG_DATA) private data: any,
                private formBuilder: FormBuilder) {
        if (data.name) {
            this.name = data.name;
        }
        this.renameForm = this.createRenameForm();
    }

    createRenameForm()
    {
        return this.formBuilder.group({
            name: [this.name, Validators.required]
        });
    }

    ngAfterViewChecked() {

    }
}
