import {AfterViewInit, Attribute, Component, ElementRef, Inject, Input, OnDestroy, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, ValidationErrors, Validators} from '@angular/forms';
import {ErrorStateMatcher, MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {Contract} from '../contracts/contract.model';
import {UserService} from '../../../../auth/user/user.service';
import {CounterParty} from '../../operations/counterparties/counterparty.model';
import {Observable, Subscription} from 'rxjs';
import {LimitProfile} from '../../operations/limitprofiles/limitprofile.model';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Quote} from '../../instruments/quotes/quote.model';
import {CounterPartyAccount} from '../../operations/accounts/account.model';
import {RoundRule} from '../../operations/constants/roundrules/roundrule.model';
import {Benchmark} from '../../operations/constants/benchmarks/benchmark.model';
import {formatCurrency} from '@angular/common';
import {ContractsService} from '../contracts/contracts.service';
import {CounterpartyService} from '../../operations/counterparties/counterparty.service';
import {RoundRulesService} from '../../operations/constants/roundrules/round-rules.service';
import {BenchmarksService} from '../../operations/constants/benchmarks/benchmarks.service';
import {QuoteService} from '../../instruments/quotes/quote.service';
import {Moment} from 'moment';
import {SwitcherService} from '../../../../shared/switcher/switcher.service';
import {FplPosition} from '../../operations/fpl/fplAccountPosition.model';
import {FplService} from '../../operations/fpl/fpl.service';


@Component({
    selector: 'fpl-return-form',
    templateUrl: './fpl-return-form.component.html',
    styleUrls: ['./fpl-return-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class FplReturnFormComponent implements OnDestroy, AfterViewInit {
    contractForm: FormGroup;

    a: FormGroupDirective;
    action: string;
    contract: Contract;
    quote: Quote;
    loadingQuote: boolean;
    loadingSubmit: boolean;
    validSymbol: boolean;
    fplAccountPosition: FplPosition;
    symbol: string;

    constructor(
        public userService: UserService,
        private formBuilder: FormBuilder,
        private httpClient: HttpClient,
        private contractService: ContractsService,
        public counterpartiesService: CounterpartyService,
        public quoteService: QuoteService,
        public fplService: FplService,
        private snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<FplReturnFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.loadingQuote = false;
        this.fplAccountPosition = data.fplAccountPos;
        this.contract = new Contract({});
        this.contractForm = this.createContractForm();
    }

    ngOnDestroy() {
    }

    setSymbol(symbol: string): void {
        this.symbol = symbol;
        this.contractForm.get('symbol').setValue(symbol);
    }

    parseNumberText(num: string, field: string): number {
        const newNum: string = num.replace(/\D/g,
            '');
        const parsed = parseInt(newNum, 10);

        if (field === 'quantity') {
            this.contract.quantity = parsed;
        }
        return parsed;
    }

    createContractForm() {
        return this.formBuilder.group({
            symbol: [],
            quantity: ['', [Validators.max(this.fplAccountPosition.borrowQuantity), Validators.min(100)]],
            rate: [null, Validators.compose([Validators.min(-999), Validators.max(999)])],
            amount: [],
            visibleAmount: []
        });
    }

    submit(tradeForm: FormGroupDirective) {
        const contractData = this.contractForm.getRawValue();
        this.setSymbol(contractData.symbol);

        const contract = {
            accountId: this.counterpartiesService.getAccountByLoanetIdOrName('0294').accountId,
            amount: contractData.amount,
            batchCode: 'W',
            specFlag: 'P',
            contraAccountId: this.fplAccountPosition.accountId,
            contraDepositoryNo: '0294',
            depositoryNo: '0294',
            divRate: '100',
            margin: '102',
            quantity: contractData.quantity,
            rate: contractData.rate,
            symbol: contractData.symbol,
            side: 'B',
            roundRuleValue: '1'
        };

        this.fplAccountPosition.amountToReturn = contractData.quantity;
        this.fplAccountPosition.borrowPrice = contractData.rate;
        //console.log(this.fplAccountPosition);
        this.fplService.returnToFplAccountPosition(this.fplAccountPosition).then();
        this.resetForm(tradeForm);
        this.dialogRef.close();
    }


    ngAfterViewInit(): void {
        this.setSymbol(this.fplAccountPosition.symbol);
        this.contractForm.controls['quantity'].setValue(this.fplAccountPosition.borrowQuantity);
        this.contract.quantity = this.fplAccountPosition.borrowQuantity;
    }

    clearAllValidationErrors() {
        for (let name in this.contractForm.controls) {
            if (name !== 'symbol') {
                this.contractForm.controls[name].setErrors(null);
            }
        }
    }

    resetForm(tradeForm: FormGroupDirective) {
        const result = [];
        Object.keys(this.contractForm.controls).forEach(key => {

            const controlErrors: ValidationErrors = this.contractForm.get(key).errors;
            if (controlErrors) {
                Object.keys(controlErrors).forEach(keyError => {
                    result.push({
                        'control': key,
                        'error': keyError,
                        'value': controlErrors[keyError]
                    });
                });
            }
        });

        Object.keys(this.contractForm.controls).forEach(key => {

            const controlErrors: ValidationErrors = this.contractForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    //console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });

        this.clearAllValidationErrors();
        tradeForm.resetForm();
        this.setSymbol(this.fplAccountPosition.symbol);
        this.contractForm.get('symbol').markAsTouched();
        this.contract = new Contract({});
    }

}
