import {EventEmitter, Injectable, Output} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {AngularFirestore} from 'angularfire2/firestore';
import {AngularFireAuth} from 'angularfire2/auth';
import {ShortRate} from './shortRates.model';
import moment from 'moment';
import {RowNode} from 'ag-grid-community';

@Injectable()
export class ShortRatesService implements Resolve<any> {

    endpoint = environment.verticalApiEndpoint;
    shortRates;
    editedShortRates: ShortRate[] = [];

    constructor(private httpClient: HttpClient) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getShortRates()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getShortRates(): Promise<ShortRate[]> {
        return new Promise((resolve, reject) => {
            this.httpClient.get(this.endpoint + 'shortRates/get')
                .subscribe((response: any) => {
                    this.shortRates = [];
                    for (const shortRate of response.items) {
                        this.shortRates.push(new ShortRate(shortRate));
                    }
                    resolve(this.shortRates);
                }, reject);
        });
    }

    saveShortRates(): Promise<ShortRate> {

        for (var rate of this.editedShortRates) {
            rate.date = new Date(Date.now());
            rate.source = 'I';
        }

        const json = {
            items: this.editedShortRates
        };


        return new Promise((resolve, reject) => {
            this.httpClient.post(this.endpoint + 'shortRates/update', json)
                .subscribe((response: any) => {
                    this.shortRates = [];
                    this.editedShortRates = [];
                    for (const shortRate of response.items) {
                        this.shortRates.push(new ShortRate(shortRate));
                    }
                    resolve(this.shortRates);
                }, reject);
        });
    }

    makeMinimum() {
        let self = this;
        this.shortRates.forEach(function (shortRate: ShortRate) {
            if(shortRate.source==='L' && shortRate.rate>-2.5){
                shortRate.rate = -2.5;
                self.editedShortRates.push(shortRate);
                self.saveShortRates();
            }
        });
    }

}
