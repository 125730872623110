import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {AngularFireAuth} from 'angularfire2/auth';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthInterceptService implements HttpInterceptor {

    constructor(public afAuth: AngularFireAuth) {
    }
    intercept(req: HttpRequest<any>, next: HttpHandler):

        Observable<HttpEvent<any>> {
        let clone: HttpRequest<any>;

        if (req.url.includes(environment.verticalApiEndpoint)) {
            const token = JSON.parse(JSON.stringify(this.afAuth.auth.currentUser)).stsTokenManager.accessToken;
            clone = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });

        } else {

            clone = req.clone({
                setHeaders: {
                    'Content-Type': 'application/json'
                }
            });
        }
        return next.handle(clone);
    }
}
