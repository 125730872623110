import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {CounterpartyService} from '../../counterparties/counterparty.service';
import {CounterPartyAccount} from '../../accounts/account.model';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import {ColorPickerRendererComponent} from './color-picker.component';
import {UserPreferencesService} from '../user-preferences.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-accounts-preferences',
    templateUrl: './accounts-preferences.component.html',
    styleUrls: ['./accounts-preferences.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class AccountsPreferencesComponent implements OnInit, OnDestroy {

    counterPartyAccounts: CounterPartyAccount[] = [];
    columnDefs: any[];
    gridApi: any;
    frameworkComponents = {
        colorPickerRenderer: ColorPickerRendererComponent,
    };
    context = {componentParent: this};
    onAccountPreferenceChanged: Subscription;

    constructor(private counterpartyService: CounterpartyService,
                public accountsPreferencesService: UserPreferencesService) {

        this.counterPartyAccounts = this.counterpartyService.getAllAccounts();

        this.onAccountPreferenceChanged = this.accountsPreferencesService.onAccountPreferenceChange.subscribe(accountsPreferences => {
            if (accountsPreferences) {
                for (const element of accountsPreferences) {
                    this.counterPartyAccounts.map((account) => {
                        return account.accountId === element.accountId ? (account.highlightColor = element.highlightColor) : account;
                    });
                }
                if (this.gridApi) {
                    this.gridApi.redrawRows();
                }
            }
        });
    }

    ngOnInit() {
        this.columnDefs = [
            {headerName: 'Name', field: 'name', filter: 'agTextColumnFilter'},
            {headerName: 'DTC Id - Loanet Id', valueGetter: this.combineDtcLoanet, filter: 'agTextColumnFilter'},
            {headerName: 'Contracts Highlight Color', field: 'color', cellRenderer: 'colorPickerRenderer', width: 100, minWidth: 100}
        ];
    }

    combineDtcLoanet(params) {
        return params.data.dtcId + ' - ' + params.data.loanetId;
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    getRowNodeId(data) {
        return data.accountId;
    }

    ngOnDestroy(): void {
        this.onAccountPreferenceChanged.unsubscribe();
    }

}
