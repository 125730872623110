import {Component, OnDestroy, OnInit} from '@angular/core';
import {ContractsService} from '../../contracts/contracts.service';
import {AngularFireAuth} from 'angularfire2/auth';
import {QuickSymbolSummaryService} from '../../quick-symbol-summary/quickSymbolSummaryService';

@Component({
    selector: 'app-drawer-container',
    templateUrl: './drawer-container.component.html',
    styleUrls: ['./drawer-container.component.scss']
})
export class DrawerContainerComponent implements OnInit{

    constructor(public contractService: ContractsService,
                public quickSymbolSummaryService: QuickSymbolSummaryService,
                public afAuth: AngularFireAuth) {
    }

    ngOnInit() {
    }
}
