import {Identifiable} from '../../../../shared/firestore-collection.service';

export class LcorContract implements Identifiable {

    amount: number;
    batchCode: string;
    batchNo: string;
    chain: string;
    chainId: number;
    contraLoanetId: string;
    contractId: number;
    contractNo: string;
    cusip: string;
    divRate: number;
    enteredBy: number;
    errorMessage: string;
    filled: boolean;
    filledAmount: number;
    filledQuantity: number;
    incomeTrack: string;
    lcorId: number;
    loanetId: string;
    maxPrice: number;
    minQuantity: number;
    minRebate: number;
    minRebateCode: string;
    partial: boolean;
    partialQuantity: number;
    profitCenter: string;
    publicComment: string;
    quantity: number;
    reasonCode: string;
    rebateRate: number;
    requestTime: any;
    responseTime: any;
    seqNumber: any;
    side: string;
    status: string;
    symbol: string;
    timeLimit: number;
    unFilledQuantity: number;

    primaryKey: any;

    constructor(contract){
        this.amount = contract.amount;
        this.batchCode = contract.batchCode;
        this.batchNo = contract.batchNo;
        this.chain = contract.chain;
        this.chainId = contract.chainId;
        this.contraLoanetId = contract.contraLoanetId;
        this.contractId = contract.contractId;
        this.contractNo = contract.contractNo;
        this.cusip = contract.cusip;
        this.divRate = contract.divRate;
        this.enteredBy = contract.enteredBy;
        this.errorMessage = contract.errorMessage;
        this.filled = contract.filled;
        this.filledAmount = contract.filledAmount;
        this.filledQuantity = contract.filledQuantity;
        this.incomeTrack = contract.incomeTrack;
        this.lcorId = contract.lcorId;
        this.loanetId = contract.loanetId;
        this.maxPrice = contract.maxPrice;
        this.minQuantity = contract.minQuantity;
        this.minRebate = contract.minRebate;
        this.minRebateCode = contract.minRebateCode;
        this.partial = contract.partial;
        this.partialQuantity = contract.partialQuantity;
        this.profitCenter = contract.profitCenter;
        this.publicComment = contract.publicComment;
        this.quantity = contract.quantity;
        this.reasonCode = contract.reasonCode;
        this.rebateRate = contract.rebateRate;
        this.requestTime = contract.requestTime;
        this.responseTime = contract.responseTime;
        this.seqNumber = contract.seqNumber;
        this.side = contract.side;
        this.status = contract.status;
        this.symbol = contract.symbol;
        this.timeLimit = contract.timeLimit;
        this.unFilledQuantity = contract.unFilledQuantity;

        this.primaryKey = contract.primaryKey;
    }
}
