import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsComponent } from './reports.component';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../../auth/auth.guard';
import {AgGridModule} from 'ag-grid-angular';
import { StockLoanInterestReportComponent } from './stock-loan-interest-report/stock-loan-interest-report.component';
import {
    MatAutocompleteModule, MatBottomSheetModule,
    MatButtonModule, MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule, MatDividerModule, MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule, MatProgressBarModule, MatRadioModule, MatRippleModule,
    MatSelectModule, MatSidenavModule, MatSlideToggleModule, MatSnackBarModule, MatStepperModule, MatTableModule, MatTabsModule, MatToolbarModule, MatTooltipModule
} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';
import {CdkTableModule} from '@angular/cdk/table';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {FuseConfirmDialogModule} from '../../../../@fuse/components';
import {SharedModule} from '../../../shared';
import { StockLoanInterestReportV2Component} from './test-report/stock-loan-interest-report-v2.component';

const routes: Routes = [
    {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
  declarations: [ReportsComponent, StockLoanInterestReportComponent, StockLoanInterestReportV2Component],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        AgGridModule.withComponents([]),
        MatFormFieldModule,
        ReactiveFormsModule,
        MatSelectModule,
        CdkTableModule,
        MatCardModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatSidenavModule,
        MatTableModule,
        MatToolbarModule,
        MatSelectModule,
        MatRadioModule,
        MatTabsModule,
        MatStepperModule,
        MatExpansionModule,
        MatSnackBarModule,
        FuseSharedModule,
        FuseConfirmDialogModule,
        SharedModule,
        MatDividerModule,
        MatProgressBarModule,
        MatAutocompleteModule,
        MatBottomSheetModule,
        MatSlideToggleModule,
        MatButtonToggleModule,
        MatTooltipModule
    ]
})
export class ReportsModule { }
