import {AfterViewInit, Component, ElementRef, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LcorService} from './lcor.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ColumnApi, GridApi, GridOptions, RowNode} from 'ag-grid-community';
import {formatCurrency, formatNumber} from '@angular/common';
import {LcorContract} from './lcor-contract.model';
import {Subscription} from 'rxjs';
import {SwitcherService} from '../../../../shared/switcher/switcher.service';
import {UserService} from '../../../../auth/user/user.service';
import {NavTabItem} from '../../../nav-tab/nav-tab-item';
import {NavTabService} from '../../../nav-tab/nav-tab.service';
import {LcorPinnedRowRendererComponent} from './lcor-pinned-row-renderer/lcor-pinned-row-renderer.component';
import {Contract} from '../../trading/contracts/contract.model';

@Component({
    selector: 'app-lcor',
    templateUrl: './lcor.component.html',
    styleUrls: ['./lcor.component.scss']
})
export class LcorComponent implements OnInit, AfterViewInit, OnDestroy {

    lcorConrtactImportForm: FormGroup;
    gridOptions: GridOptions = <GridOptions>{};
    lcorGridColumnApi: ColumnApi;
    lcorGridApi: GridApi;
    columnDefs: any[] = [];

    onContractsAddedSubscription: Subscription;
    onContractsChangedSubscription: Subscription;
    onContractsRemovedSubscription: Subscription;
    onDepositoryNoSwitchedSubscription: Subscription;

    @ViewChild('symQtyRef') symQtyRef: ElementRef;
    @ViewChild('formDiv') formDiv: ElementRef;

    private pinnedBottomRowData;
    public frameworkComponents;

    defaultColDef = {
        editable: false,
        enableCellChangeFlash: true,
        sortable: true,
        filter: true,
        resizable: true,
        floatingFilterComponentParams: {suppressFilterButton: true}
    };

    static styleGrayGreen(params) {
        if (params.value === 0) {
            return {'color': '#bfbfbf'};
        } else{
            return {'color': 'Green'};
        }
    }
    static styleGrayRed(params) {
        if (params.value === 0) {
            return {'color': '#bfbfbf'};
        } else{
            return {'color': 'Red'};
        }
    }

    constructor(public lcorService: LcorService,
                private formBuilder: FormBuilder,
                @Inject(LOCALE_ID) private locale: string,
                private switcherService: SwitcherService,
                public userService: UserService,
                private navTabService: NavTabService) {
        this.lcorConrtactImportForm = this.createLcorConrtactImportForm();

        this.gridOptions = {};

        this.switcherService.depositoryNoSwitched.subscribe((value) => {
            this.lcorConrtactImportForm.controls.loanetId.setValue(value);
        });

        this.defineColumns();
        this.frameworkComponents = {
            customPinnedRowRenderer: LcorPinnedRowRendererComponent,
        };
    }

    ngOnInit() {
        this.onDepositoryNoSwitchedSubscription = this.switcherService.depositoryNoSwitched.subscribe(sub => {
            this.resetPinnedRows();
            this.resetGridData();
        });
    }

    ngAfterViewInit(): void {

    }

    createLcorConrtactImportForm() {
        return this.formBuilder.group({
            loanetId: [this.switcherService.getSelectedDepositoryNo()],
            contraLoanetId: ['', Validators.required],
            symbolAndQuantity: ['', Validators.required],
            profitCenter: [''],
            timeLimit: [15, Validators.required],
            publicComment: ['', Validators.maxLength(20)],
            minQuantity: [100],
            minRebate: [-200],
            maxPrice: [99999999],
            divRate: [85],
        });
    }

    resetForm() {
        this.lcorConrtactImportForm = this.createLcorConrtactImportForm();
    }

    resetAfterSubmission() {
        const contra = this.lcorConrtactImportForm.controls.contraLoanetId.value;
        this.lcorConrtactImportForm = this.createLcorConrtactImportForm();
        this.lcorConrtactImportForm.controls.contraLoanetId.setValue(contra);
        this.symQtyRef.nativeElement.focus();
    }

    onProfitCenterChange(event) {
        // this.lcorConrtactImportForm.controls.profitCenter.setValue(event.toUpperCase());
    }

    submitLcorBatch() {
        const lcorBatch = this.lcorConrtactImportForm.getRawValue();

        if (lcorBatch.symbolAndQuantity && lcorBatch.symbolAndQuantity !== '') {
            // get rid of carrage returns and commas, convert tab to space, make uppercase
            const symbolAndQuantityCleaned = lcorBatch.symbolAndQuantity.trim()
                .replace(/(\,|\r)/g, '')
                .replace(/(\t)/g, ' ').toLocaleUpperCase();
            const lines = symbolAndQuantityCleaned.split('\n')
                .map(function (v) {
                    const parts = v.split(' ');
                    if (parts.length === 2) {
                        return ({symCusip: parts[0], quantity: parts[1]});
                    } else {
                        return undefined;
                    }
                }).filter(a => a !== undefined);
            //
            // const symbolAndQuantityTrimmed = lcorBatch.symbolAndQuantity.trim().replace(/(^[ \t]*\n)/gm, '');
            // const symbolAndQuantityLines = symbolAndQuantityTrimmed.split('\n')
            //     .map((val) => ({symCusip: val.split(' ')[0].toLocaleUpperCase(), quantity: val.split(' ')[1]}));

            this.lcorService.submitbatch(lcorBatch.loanetId, lcorBatch.contraLoanetId, lcorBatch.minRebate, lcorBatch.minQuantity, lcorBatch.maxPrice,
                lcorBatch.timeLimit, lcorBatch.divRate, lcorBatch.profitCenter.toLocaleUpperCase(), lcorBatch.publicComment, lines);

            this.resetAfterSubmission();
        }
    }


    defineColumns() {
        this.columnDefs = [
            {
                headerName: 'Time', field: 'requestTime', suppressMovable: true,
                sort: 'desc', minWidth: 80, maxWidth: 120, resizable: true, pinned: true,
                filter: 'agTextColumnFilter',

                valueGetter: function (params) {
                    if (typeof params.data.requestTime === 'string') {
                        return params.data.requestTime;
                    }

                    if (params.data.requestTime && params.data.responseTime) {
                        const requestTime = new Date(params.data.requestTime.year + '-' + params.data.requestTime.month + '-' + params.data.requestTime.dayOfMonth + ' '
                            + params.data.requestTime.hour + ':' + params.data.requestTime.minute + ':' + params.data.requestTime.second + '.000+00:00');

                        const responseTime = new Date(params.data.responseTime.year + '-' + params.data.responseTime.month + '-' + params.data.responseTime.dayOfMonth + ' '
                            + params.data.responseTime.hour + ':' + params.data.responseTime.minute + ':' + params.data.responseTime.second + '.000+00:00');

                        if (requestTime > responseTime) {
                            return requestTime;
                        } else if (responseTime > requestTime) {
                            return responseTime;
                        }
                        return requestTime;
                    } else if (params.data.requestTime) {
                        return new Date(params.data.requestTime.year + '-' + params.data.requestTime.month + '-' + params.data.requestTime.dayOfMonth + ' '
                            + params.data.requestTime.hour + ':' + params.data.requestTime.minute + ':' + params.data.requestTime.second + '.000+00:00');
                    } else if (params.data.responseTime) {
                        return new Date(params.data.responseTime.year + '-' + params.data.responseTime.month + '-' + params.data.responseTime.dayOfMonth + ' '
                            + params.data.responseTime.hour + ':' + params.data.responseTime.minute + ':' + params.data.responseTime.second + '.000+00:00');
                    } else {
                        return null;
                    }
                },
                valueFormatter: (params) => {
                    if (typeof params.value === 'string') {
                        return params.value;
                    }

                    if (params.value === undefined || params.value === null) {
                        return '';
                    } else {
                        return params.value.getHours().toString().padStart(2, '0') + ':' +
                            params.value.getMinutes().toString().padStart(2, '0') + ':' +
                            params.value.getSeconds().toString().padStart(2, '0');
                    }
                },
                comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
                    if (valueA === valueB) {
                        return 0;
                    }
                    return (valueA > valueB) ? 1 : -1;
                },
                pinnedRowCellRenderer: 'customPinnedRowRenderer',
                pinnedRowCellRendererParams: {style: {'color': '#999999', 'text-align': 'right'}},
            },
            {
                headerName: 'Contra', field: 'contraLoanetId', suppressMovable: true,
                minWidth: 80, maxWidth: 120, resizable: true, pinned: true, valueFormatter: '',
                filter: 'agTextColumnFilter', type: 'numericColumn',
                pinnedRowCellRenderer: 'customPinnedRowRenderer',
                pinnedRowCellRendererParams: {style: {'color': 'black', 'text-align': 'left'}},
                colSpan: params => {
                    if (params.data.requestTime && typeof params.data.requestTime === 'string') {
                        return params.data.requestTime === 'Comments:' ? 5 : 1;
                    }
                    return 1;
                },
                cellStyle: params => {
                    if (!params.node.isRowPinned()) {
                        return {cursor: 'pointer'};
                    }
                    return null;
                }
            },
            {
                headerName: 'Symbol', field: 'symbol', suppressMovable: true,
                minWidth: 80, maxWidth: 120, resizable: true, pinned: true, valueFormatter: '',
                filter: 'agTextColumnFilter',
                pinnedRowCellRenderer: 'customPinnedRowRenderer',
                pinnedRowCellRendererParams: {style: {'color': '#999999', 'text-align': 'right'}},
                cellStyle: params => {
                    if (!params.node.isRowPinned()) {
                        return {cursor: 'pointer'};
                    }
                    return null;
                }
            },
            {
                headerName: 'Cusip', field: 'cusip', suppressMovable: true,
                minWidth: 100, maxWidth: 160, resizable: true, pinned: true, valueFormatter: '',
                filter: 'agTextColumnFilter', type: 'numericColumn',
                pinnedRowCellRenderer: 'customPinnedRowRenderer',
                pinnedRowCellRendererParams: {style: {'color': 'black', 'text-align': 'left'}},
                cellStyle: params => {
                    if (!params.node.isRowPinned()) {
                        return {cursor: 'pointer'};
                    }
                    return null;
                }
            },
            {
                headerName: 'Quantity', field: 'quantity', suppressMovable: true, suppressFilter: true,
                minWidth: 80, maxWidth: 160, resizable: true, pinned: true,
                filter: 'agNumberColumnFilter', type: 'numericColumn',
                valueFormatter: (params) => {
                    if (typeof params.value === 'string') {
                        return params.value;
                    }

                    if (params.value === null) {
                        return '';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                },
                pinnedRowCellRenderer: 'customPinnedRowRenderer',
                pinnedRowCellRendererParams: {style: {'color': '#999999', 'text-align': 'right'}},
            },
            {
                headerName: 'Filled Qty', field: 'filledQuantity', suppressMovable: true, suppressFilter: true,
                minWidth: 80, maxWidth: 160, resizable: true, pinned: true,
                filter: 'agTextColumnFilter', type: 'numericColumn',
                cellStyle: LcorComponent.styleGrayGreen,
                valueFormatter: (params) => {
                    if (typeof params.value === 'string') {
                        return params.value;
                    }

                    if (params.value === null) {
                        return '';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                },
                pinnedRowCellRenderer: 'customPinnedRowRenderer',
                pinnedRowCellRendererParams: {style: {'color': 'black', 'text-align': 'left'}},
            },
            {
                headerName: 'Filled $', field: 'filledAmount', suppressMovable: true, suppressFilter: true,
                minWidth: 80, maxWidth: 160, resizable: true, pinned: true,
                filter: 'agTextColumnFilter', type: 'numericColumn',
                cellStyle: LcorComponent.styleGrayGreen,
                valueFormatter: (params) => {
                    if (typeof params.value === 'string') {
                        return params.value;
                    }

                    if (params.value === null) {
                        return 0;
                    } else {
                        return formatCurrency(params.value, this.locale, '$', undefined, '1.0-0');
                    }
                },
                pinnedRowCellRenderer: 'customPinnedRowRenderer',
                pinnedRowCellRendererParams: {style: {'color': '#999999', 'text-align': 'right'}},
            },
            {
                headerName: 'Rebate', field: 'rebateRate', suppressMovable: true, suppressFilter: true,
                minWidth: 80, maxWidth: 160, resizable: true, pinned: true,
                filter: 'agTextColumnFilter', type: 'numericColumn',
                cellStyle: LcorComponent.styleGrayGreen,
                valueFormatter: (params) => {
                    if (typeof params.value === 'string') {
                        return params.value;
                    }

                    if (params.value === null) {
                        return '';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                },
                pinnedRowCellRenderer: 'customPinnedRowRenderer',
                pinnedRowCellRendererParams: {style: {'color': 'black', 'text-align': 'left'}},
                colSpan: params => {
                    if (params.data.requestTime && typeof params.data.requestTime === 'string') {
                        return params.data.requestTime === 'Comments:' ? 5 : 1;
                    }
                    return 1;
                },
            },
            {
                headerName: 'Contract #', field: 'contractNo', suppressMovable: true,
                minWidth: 80, maxWidth: 160, resizable: true, pinned: true, valueFormatter: '',
                filter: 'agTextColumnFilter',
                pinnedRowCellRenderer: 'customPinnedRowRenderer',
                pinnedRowCellRendererParams: {style: {'color': '#999999', 'text-align': 'right'}},
                cellStyle: params => {
                    if (!params.node.isRowPinned()) {
                        return {cursor: 'pointer'};
                    }
                    return null;
                }
            },
            {
                headerName: 'Status', field: 'status', suppressMovable: true, suppressFilter: false,
                minWidth: 60,  pinned: true, valueFormatter: '',
                filter: 'agTextColumnFilter',
                pinnedRowCellRenderer: 'customPinnedRowRenderer',
                pinnedRowCellRendererParams: {style: {'color': 'black', 'text-align': 'left'}},
                cellStyle: params => {
                    if (params && !params.node.isRowPinned()) {
                        if (params.data.status && (params.data.status.toLocaleLowerCase() === 'f' || params.data.status.toLocaleLowerCase() === 'p')) {
                            return {'background-color': '#d7ffd7'}; // green
                            // } else if (params.data.status && (params.data.status.toLocaleLowerCase() === 'r')) {
                            //     return {'background-color': '#ff0d0d', 'color': 'white'}; // red
                            // } else if (params.data.status && (params.data.status.toLocaleLowerCase() === 't')) {
                            //     return {'background-color': '#8d8d8d', 'color': 'white'}; // gray
                        }
                    }
                    return null;
                }
            },
            {
                headerName: 'Unfilled Qty', field: 'unFilledQuantity', suppressMovable: true, suppressFilter: true,
                minWidth: 80, maxWidth: 160, resizable: true, pinned: true,
                filter: 'agTextColumnFilter', type: 'numericColumn',
                cellStyle: LcorComponent.styleGrayRed,
                valueFormatter: (params) => {
                    if (typeof params.value === 'string') {
                        return params.value;
                    }

                    if (params.value === null) {
                        return '';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                },
                pinnedRowCellRenderer: 'customPinnedRowRenderer',
                pinnedRowCellRendererParams: {style: {'color': 'black', 'text-align': 'left'}},
            },
            {
                headerName: 'Message', field: 'errorMessage', suppressMovable: true,
                minWidth: 180, resizable: true, pinned: true, valueFormatter: '',
                filter: 'agTextColumnFilter',
                pinnedRowCellRenderer: 'customPinnedRowRenderer',
                pinnedRowCellRendererParams: {style: {'color': 'black', 'text-align': 'left'}},
            },
            {
                headerName: 'Batch #', field: 'batchNo', suppressMovable: true,
                minWidth: 80, maxWidth: 160, resizable: true, pinned: true, valueFormatter: '',
                filter: 'agTextColumnFilter', type: 'numericColumn',
                pinnedRowCellRenderer: 'customPinnedRowRenderer',
                pinnedRowCellRendererParams: {style: {'color': '#999999', 'text-align': 'right'}},
            }
        ];


    }

    cellDoubleClicked(event) {
        let filter = {};
        let title = '';

        if (event.colDef.field === 'contraLoanetId') {
            if (event.node.data.contraLoanetId) {
                const contraLoanetId = event.node.data.contraLoanetId;
                filter = {
                    contraDepositoryNo: {filterType: 'set', values: [contraLoanetId]}
                };
                title = contraLoanetId;
            }
        } else if (event.colDef.field === 'symbol') {
            if (event.node.data.symbol) {
                const symbol = event.node.data.symbol;
                filter = {
                    symbol: {filterType: 'set', values: [symbol]}
                };
                title = symbol;
            }
        } else if (event.colDef.field === 'cusip') {
            if (event.node.data.cusip) {
                const cusip = event.node.data.cusip;
                filter = {
                    cusip: {filterType: 'set', values: [cusip]}
                };
                title = cusip;
            }
        } else if (event.colDef.field === 'contractNo') {
            if (event.node.data.contractNo) {
                const contractNo = event.node.data.contractNo;
                filter = {
                    contractNo: {filterType: 'set', values: [contractNo]}
                };
                title = contractNo;
            }
        }

        if (event.colDef.field === 'contraLoanetId' || event.colDef.field === 'symbol' || event.colDef.field === 'cusip' || event.colDef.field === 'contractNo') {
            const navTabItem = new NavTabItem('contracts', title ? title : undefined, 'item', 'list',
                '/contract-details', true, null, null,
                {
                    filter: filter
                },
                filter
            );

            this.navTabService.addTab(navTabItem);
        }
    }

    cellClicked(event) {
        if (!event.node.isRowPinned()) {
            this.updatePinnedRows(event.node.data);
        }
    }

    updatePinnedRows(contract: LcorContract): void {
        const rows = [];

        rows.push({
            requestTime: 'Details'
        });

        rows.push({
            requestTime: 'Account:',
            contraLoanetId: contract.loanetId,
            symbol: 'Symbol:',
            cusip: contract.symbol,
            quantity: 'Qty:',
            filledQuantity: contract.quantity,
            filledAmount: 'Filled Qty:',
            rebateRate: contract.filledQuantity,
            contractNo: 'Contract #:',
            status: contract.contractNo,
            errorMessage: '',
            batchNo: '',
        });

        rows.push({
            requestTime: 'Contra:',
            contraLoanetId: contract.contraLoanetId,
            symbol: 'Cusip:',
            cusip: contract.cusip,
            quantity: 'Min Qty:',
            filledQuantity: contract.minQuantity,
            filledAmount: 'Filled $:',
            rebateRate: contract.filledAmount,
            contractNo: 'Seq #:',
            status: contract.seqNumber,
            errorMessage: '',
            batchNo: '',
        });

        rows.push({
            requestTime: 'Side:',
            contraLoanetId: contract.side,
            symbol: 'PC:',
            cusip: contract.profitCenter,
            quantity: 'Max $:',
            filledQuantity: contract.maxPrice,
            filledAmount: 'Rebate:',
            rebateRate: contract.rebateRate,
            contractNo: 'Resp Time:',
            status: contract.responseTime ? this.getTime(contract.responseTime) : '',
            errorMessage: '',
            batchNo: '',
        });

        rows.push({
            requestTime: 'Chain:',
            contraLoanetId: contract.chain,
            symbol: 'Batch Code:',
            cusip: contract.batchCode,
            quantity: 'Min Rebate:',
            filledQuantity: contract.minRebate,
            filledAmount: 'Unfilled:',
            rebateRate: contract.unFilledQuantity,
            contractNo: 'LcorId:',
            status: contract.lcorId,
            errorMessage: '',
            batchNo: '',
        });

        rows.push({
            requestTime: 'Req Time:',
            contraLoanetId: contract.requestTime ? this.getTime(contract.requestTime) : '',
            symbol: 'Div Rate:',
            cusip: contract.divRate,
            quantity: 'Time Limit:',
            filledQuantity: this.convertMinutesToHHMM(contract.timeLimit),
            filledAmount: 'Reason:',
            rebateRate: contract.reasonCode,
            contractNo: 'ChainId:',
            status: contract.chainId,
            errorMessage: '',
            batchNo: '',
        });

        rows.push({
            requestTime: 'Comments:',
            contraLoanetId: contract.publicComment,
            symbol: '',
            cusip: '',
            quantity: '',
            filledQuantity: '',
            filledAmount: 'Error Msg:',
            rebateRate: contract.errorMessage,
            contractNo: '',
            status: '',
            errorMessage: '',
            batchNo: '',
        });

        this.pinnedBottomRowData = rows;
        this.lcorGridApi.setPinnedBottomRowData(this.pinnedBottomRowData);
    }

    depositoryFilter(contract: LcorContract): boolean {
        return (contract.loanetId === this.switcherService.getSelectedDepositoryNo());
    }

    todayFilter(contract: LcorContract): boolean {
        let reqTime, resTime, largerTimeValue;

        if (contract.requestTime) {
            reqTime = new Date(contract.requestTime.year + '-' + contract.requestTime.month + '-' + contract.requestTime.dayOfMonth + ' '
                + contract.requestTime.hour + ':' + contract.requestTime.minute + ':' + contract.requestTime.second + '.000+00:00');
        }
        if (contract.responseTime) {
            resTime = new Date(contract.responseTime.year + '-' + contract.responseTime.month + '-' + contract.responseTime.dayOfMonth + ' '
                + contract.responseTime.hour + ':' + contract.responseTime.minute + ':' + contract.responseTime.second + '.000+00:00');
        }

        if (reqTime && resTime) {
            largerTimeValue = reqTime > resTime ? reqTime : resTime;
        } else if (reqTime) {
            largerTimeValue = reqTime;
        } else if (resTime) {
            largerTimeValue = resTime;
        }

        const todaysDate = new Date();

        if (largerTimeValue) {
            if (largerTimeValue.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    getTime(dateObject) {
        const date = new Date(dateObject.year + '-' + dateObject.month + '-' + dateObject.dayOfMonth + ' '
            + dateObject.hour + ':' + dateObject.minute + ':' + dateObject.second + '.000+00:00');

        return date.getHours().toString().padStart(2, '0') + ':' +
            date.getMinutes().toString().padStart(2, '0') + ':' +
            date.getSeconds().toString().padStart(2, '0');
    }

    resetPinnedRows() {
        const rows = [];
        this.pinnedBottomRowData = rows;
        this.lcorGridApi.setPinnedBottomRowData(this.pinnedBottomRowData);
    }

    onLcorGridReady(params) {
        this.lcorGridApi = params.api;
        this.lcorGridColumnApi = params.columnApi;
        this.gridOptions.api = params.api;
        this.autoSizeAll(params);
        this.setupLcorContractsSubscriptions();
    }


    autoSizeAll(event) {
        this.lcorGridColumnApi = event.columnApi;
        this.lcorGridColumnApi.autoSizeColumns(this.lcorGridColumnApi.getAllColumns());
    }

    setupLcorContractsSubscriptions() {
        this.lcorGridApi.setRowData([]);
        this.lcorGridApi.setRowData(this.lcorService.currentCollection.filter((c => this.depositoryFilter(c))).filter((c => this.todayFilter(c))));

        this.onContractsAddedSubscription =
            this.lcorService.onCollectionAdded.subscribe(contracts => this.contractAdded(contracts));

        this.onContractsChangedSubscription =
            this.lcorService.onCollectionChanged.subscribe(contracts => this.contractChanged(contracts));

        this.onContractsRemovedSubscription =
            this.lcorService.onCollectionRemoved.subscribe(contracts => this.contractRemoved(contracts));

    }

    resetGridData() {
        this.lcorGridApi.setRowData([]);
        this.lcorGridApi.setRowData(this.lcorService.currentCollection.filter((c => this.depositoryFilter(c))).filter((c => this.todayFilter(c))));
    }

    contractAdded(contracts: LcorContract[]) {
        if (this.lcorGridApi) {
            const newRows: RowNode[] = [];
            const newContracts: LcorContract[] = [];
            for (const contract of contracts.filter(c => this.depositoryFilter(c)).filter((c => this.todayFilter(c)))) {
                const rowNode = this.lcorGridApi.getRowNode(contract.primaryKey);
                if (rowNode === undefined) {
                    newContracts.push(contract);
                }
            }
            this.lcorGridApi.updateRowData({
                add: newContracts
            });
            for (const contract of newContracts) {
                const rowNode = this.lcorGridApi.getRowNode(contract.primaryKey);
                newRows.push(rowNode);
            }
            this.lcorGridApi.flashCells({
                rowNodes: newRows
            });
        }
    }

    contractChanged(contracts: LcorContract[]) {
        if (this.lcorGridApi) {
            this.lcorGridApi.updateRowData({
                update: contracts.filter(c => this.depositoryFilter(c)).filter((c => this.todayFilter(c)))
            });
        }
    }

    contractRemoved(contracts: LcorContract[]) {
        if (this.lcorGridApi) {
            this.lcorGridApi.updateRowData({
                remove: contracts.filter(c => this.depositoryFilter(c)).filter((c => this.todayFilter(c)))
            });
        }
    }

    convertMinutesToHHMM(minute: number) {
        let minutes = minute;
        minutes = minutes > 180 ? 180 : minutes < 0 ? 0 : minutes;
        const m = minutes % 60;
        const h = (minutes - m) / 60;
        const HHMM = (h < 10 ? '0' + h.toString() : h) + ':' + (m < 10 ? '0' : '') + m.toString();
        return HHMM;
    }

    public getRowNodeId(data) {
        return data.primaryKey;
    }

    onGridSizeChanged(params) {
        setTimeout(() => {
            // get the current grids width
            const gridWidth = document.getElementById('lcor-grid').offsetWidth;

            // keep track of which columns to hide/show
            const columnsToShow = [];
            const columnsToHide = [];

            // iterate over all columns (visible or not) and work out
            // now many columns can fit (based on their minWidth)
            let totalColsWidth = 0;
            const allColumns = params.columnApi.getAllColumns();
            for (let i = 0; i < allColumns.length; i++) {
                const column = allColumns[i];
                totalColsWidth += column.getMinWidth();
                if (totalColsWidth > gridWidth) {
                    columnsToHide.push(column.colId);
                } else {
                    columnsToShow.push(column.colId);
                }
            }

            params.api.sizeColumnsToFit();
        });
    }

    ngOnDestroy() {
        this.onContractsAddedSubscription.unsubscribe();
        this.onContractsChangedSubscription.unsubscribe();
        this.onContractsRemovedSubscription.unsubscribe();
        this.onDepositoryNoSwitchedSubscription.unsubscribe();
        this.lcorGridApi.destroy();
    }

}
