import {Component, Inject, Input, LOCALE_ID, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {ShortRatesService} from './shortRates.service';


@Component({
    selector: 'app-short-rates',
    templateUrl: './shortRates.component.html',
    styleUrls: ['./shortRates.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class ShortRatesComponent implements OnInit {

    @Input() entityId;

    constructor(public shortRatesService: ShortRatesService,
                @Inject(LOCALE_ID) private locale: string) {
    }

    ngOnInit() {
    }

}
