import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../../../auth/auth.guard';
import {ReturnsComponent} from './returns.component';
import {FuseSharedModule} from '../../../../../@fuse/shared.module';
import {AgGridModule} from 'ag-grid-angular';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import {ReturnHoldingService} from './returnholding.service';
import { ReturnAllocationComponent } from './return-allocation/return-allocation.component';
import {ReturnDetailService} from './return-allocation/return-detail.service';

const routes: Routes = [
    {
        path     : 'returns',
        component: ReturnsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'returns/:returnId/allocation',
        component: ReturnAllocationComponent,
        canActivate: [AuthGuard],
        resolve: {
            return: ReturnDetailService
        }
    }
];

@NgModule({
  imports: [
    CommonModule,
      RouterModule.forChild(routes),
      AgGridModule.withComponents([]),
      FuseSharedModule,
      MatIconModule,
      MatButtonModule,
      MatTableModule,
      MatFormFieldModule,
      MatInputModule,
      MatCheckboxModule,
      MatSelectModule
  ],
  declarations: [
      ReturnsComponent,
      ReturnAllocationComponent
  ],
    providers: [
        ReturnHoldingService,
        ReturnDetailService
    ],
    entryComponents: [
        ReturnAllocationComponent
    ]
})
export class ReturnsModule { }
