import {Component, Inject} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {MatDialog} from '@angular/material';
import {ColorPickerDialogueComponent} from './color-picker-dialogue.component';


@Component({
    selector: 'color-picker-renderer-component',
    template: `
        <span>
            <button mat-raised-button (click)="showDialogue()" class="btn" [style.background-color]="highlightColor"></button>
        </span>
    `,
    styles: [
            `.btn {
            height: 20px;
            line-height: 0.5;
            width: 75px;
        }
        `]
})
export class ColorPickerRendererComponent implements ICellRendererAngularComp {
    public params: any;
    public available: number;
    public accountId: number;
    dialogRef: any;
    highlightColor = '#ffffff';

    constructor(public dialog: MatDialog) {
    }

    agInit(params: any): void {
        this.params = params;
        if (params.data.highlightColor) {
            this.highlightColor = params.data.highlightColor;
        }
    }

    showDialogue() {
        this.dialogRef = this.dialog.open(ColorPickerDialogueComponent, {
            width: '280px',
            height: '400px',
            data: {
                highlightColor: this.highlightColor,
                account: this.params.data
            }
        });
    }

    refresh(): boolean {
        return false;
    }
}
