import { Injectable } from "@angular/core";
import { AngularFireAuth } from "angularfire2/auth";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { catchError, map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { CounterpartyService } from "../../main/content/operations/counterparties/counterparty.service";
import { Router } from "@angular/router";
import { ResourcesService } from "../../main/content/resources/resources.service";
import { UserPreferencesService } from "../../main/content/operations/user-preferences/user-preferences.service";
import moment from "moment";
import { ConfigSettingsService } from "../../shared/config-settings.service";
import { NavTabService } from "../../main/nav-tab/nav-tab.service";

export class InternalUser {
    loginId: number;
    userUid: string;
    personId: number;
    first: string;
    last: string;
    roles: Role[];
    title: string;
    nickName: string;
    salutation: string;
    email: string;
    disabled: boolean;

    constructor(user?) {
        user = user || {};
        this.loginId = user.loginId || 0;
        this.userUid = user.userUid || "";
        this.personId = user.personId || 0;
        this.first = user.first || "";
        this.last = user.last || "";
        this.roles = user.roles || [];
        this.title = user.title || "";
        this.nickName = user.nickName || "";
        this.salutation = user.salutation || "";
        this.email = user.email || "";
        this.disabled = user.disabled || false;
    }
}

export class Role {
    abbr: string;
    description: string;
    name: string;
    roleId: number;
    loginId: number;

    private _enabled: boolean;

    constructor(role) {
        this.abbr = role.abbr;
        this.description = role.description;
        this.name = role.name;
        this.roleId = role.roleId;
    }

    set enabled(value: boolean) {
        this._enabled = value;
    }
}

@Injectable()
export class UserService {
    private endpoint = environment.verticalApiEndpoint + "user";
    public user: InternalUser;
    public userState: Observable<InternalUser | boolean>;

    constructor(
        private afAuth: AngularFireAuth,
        private httpClient: HttpClient,
        private counterparties: CounterpartyService,
        private router: Router,
        private resourcesService: ResourcesService,
        private userPreferencesService: UserPreferencesService,
        private configSettingsService: ConfigSettingsService,
        private navTabService: NavTabService,
    ) {
        this.userState = of(this.user);

        this.afAuth.auth.onAuthStateChanged((user) => {
            if (user === null) {
                //console.log('[UserService] Lock session?');
            }
        });
    }

    updateUserInfo(): Observable<InternalUser | boolean> {
        this.userState = this.httpClient.get(this.endpoint).pipe(
            map((body: any) => this.setUser(body)),
            catchError(() => of(false)),
        );
        return this.userState;
    }

    getUserName(): string {
        if (this.user != null) {
            return this.user.first + " " + this.user.last;
        } else {
            return "";
        }
    }

    getUserNickName(): string {
        if (this.user != null) {
            return this.user.nickName;
        } else {
            return "";
        }
    }

    getUserEmail(): string {
        if (this.user != null) {
            return this.user.email;
        } else {
            return "";
        }
    }

    getInternalEntityName(): string {
        if (this.user != null) {
            return this.counterparties.internalCounterParties[0].name;
        }
    }

    setUser(curUser: any): InternalUser {
        // this.user = {first: curUser.first, last: curUser.last, roles: curUser.roles};
        this.user = new InternalUser(curUser);
        // this.counterparties.internalCounterParties = curUser.internalCounterParties;
        // this.counterparties.externalCounterParties = curUser.externalCounterParties;
        // this.counterparties.combinedCounterParties = curUser.internalCounterParties.concat(curUser.externalCounterParties);

        // bootstrap resources service
        this.userPreferencesService.resetTabPreferences(); // remove if needed to persist user preferences across refreshes and/or logins
        this.resourcesService.initializeResources();
        this.userPreferencesService.bindUserPreferencesUpdates();
        this.configSettingsService.bindConfigSettings();
        this.bindAutoLogout();
        this.bindAutoRefresh();

        return this.user;
    }

    logout(): void {
        this.navTabService.clearTabs();
        this.afAuth.auth.signOut().then(() => {
            //console.log('Signed out');
            this.user = null;
            this.userState = null;
            this.router.navigate(["/pages/auth/login"], { replaceUrl: true });
        });
    }

    requirePermission(permission: string): boolean {
        if (this.user) {
            for (const role of this.user.roles) {
                if (role.name === permission) {
                    return true;
                }
            }
        }
        return false;
    }

    bindAutoLogout() {
        const now = moment();
        let refreshTime = now.clone().hour(20).minute(0).second(0);

        if (now.isAfter(refreshTime)) {
            refreshTime = moment().add(1, "days").hour(20).minute(0).second(0);
        }

        const timeDifference = refreshTime.diff(now, "milliseconds");
        if (timeDifference > 0) {
            setTimeout(() => {
                this.logout();
            }, timeDifference);
        }
    }

    bindAutoRefresh() {
        const now = moment();
        let refreshTime = now.clone().hour(5).minute(0).second(0);

        if (now.isAfter(refreshTime)) {
            refreshTime = moment().add(1, "days").hour(5).minute(0).second(0);
        }

        const timeDifference = refreshTime.diff(now, "milliseconds");
        if (timeDifference > 0) {
            setTimeout(() => {
                window.location.reload();
            }, timeDifference);
        }
    }
}
