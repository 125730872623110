import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
    MatButtonModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule, MatInputModule,
    MatMenuModule,
    MatProgressBarModule, MatProgressSpinnerModule,
    MatToolbarModule
} from '@angular/material';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseToolbarComponent } from 'app/main/toolbar/toolbar.component';
import {FuseNavigationModule, FuseSearchBarModule, FuseShortcutsModule} from '@fuse/components';
import {SwitcherComponent} from '../../shared/switcher/switcher.component';


@NgModule({
    declarations: [
        FuseToolbarComponent,
        SwitcherComponent
    ],
    imports: [
        RouterModule,
        FuseNavigationModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatProgressBarModule,
        MatToolbarModule,
        FuseSharedModule,
        FuseSearchBarModule,
        MatButtonToggleModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule
    ],
    exports     : [
        FuseToolbarComponent
    ]
})
export class FuseToolbarModule
{
}
