export class Restriction {
    restrictionId: string;
    name: string;
    description: string;

    constructor(restriction?) {
        this.restrictionId = restriction.restrictionId;
        this.name = restriction.name;
        this.description = restriction.description;
    }
}
