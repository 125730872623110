import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';
import {FirestoreCollectionService} from '../../../../../shared/firestore-collection.service';
import {AngularFirestore} from 'angularfire2/firestore';
import {AngularFireAuth} from 'angularfire2/auth';
import {BulkRequest} from './bulkrequest.model';
import {UserService} from '../../../../../auth/user/user.service';


export interface ContractPrototypeContainer {
    contractPrototypes: BulkRequest[];
}

@Injectable({
    providedIn: 'root'
})
export class BulkImportService extends FirestoreCollectionService<BulkRequest> {

    private collectionName: string;

    constructor(private httpClient: HttpClient,
                private firestoreClient: AngularFirestore,
                private afAuthClient: AngularFireAuth,
                private userService: UserService) {
        super(httpClient, firestoreClient, afAuthClient);
        const collectionName = 'bulk-import';
        this.collectionName = collectionName;
        super.initializeService(this.collectionName, this.createContractPrototypeCallback);
    }

    public createContractPrototypeCallback(newObj: any): BulkRequest {
        return new BulkRequest(newObj);
    }

    public addBlankRowsToFirebase(numRows: number): Promise<string[]> {
        return new Promise<string[]>((resolve, reject) => {
            let ids = [];
            let promises = [];
            for (let i = 0; i < numRows; i++) {
                promises.push(new Promise<string>((resolve, reject) => {
                    this.firestoreClient.collection(this.collectionName)
                        .add(Object.assign({}, this.generateBlankBulkImportRow())).then(result => {
                        resolve(result.id);
                    }, reject);
                }));
            }
            Promise.all(promises).then(value => {
                ids = value;
                resolve(ids);
            }, reject);
        });
    }

    public deleteRows(rows: BulkRequest[]): void {
        if (rows.length > 0) {

            this.httpClient.post(environment.verticalApiEndpoint + 'bulk/requests/delete', {items: rows})
                .subscribe((response: any) => {
                });
        }
    }

    generateBlankBulkImportRow(): BulkRequest {
        return new BulkRequest({
            bulkId: null,
            contractId: null,
            depositoryNo: null,
            contraDepositoryNo: null,
            loanetId: null,
            contraLoanetId: null,
            accountId: null,
            contraAccountId: null,
            side: null,
            cusip: null,
            contractNo: null,
            symbol: null,
            quantity: 100,
            price: null,
            value: null,
            margin: null,
            divRate: null,
            batchCode: null,
            profitCenter: null,
            rebateRate: null,
            bulkStatus: null,
            fplPositionId: null,
            specFlag: null,
            publicComment: null,
            collateralCode: null,
            enteredBy: this.userService.user.loginId,
            approvedBy: this.userService.user.loginId,
            errorMessage: null,
            roundRuleValue: null,
            primaryKey: null,
        });
    }
}
