export class ContractEvent {

    contractEventId :number;
    name : string;
    description : string;
    abbr : string;

    constructor(contractEvent?) {

        this.contractEventId = contractEvent.contractEventId;
        this.name = contractEvent.name;
        this.description = contractEvent.description;
        this.abbr = contractEvent.abbr;
    }
}
