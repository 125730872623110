import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {CollectionViewer, SelectionChange} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {Injectable} from '@angular/core';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {UserListService} from '../users/user-list.service';
import {Router} from '@angular/router';
import {CounterpartyService} from './counterparty.service';
import {CounterpartyListComponent} from './counterparty-list/counterparty-list.component';
import {FuseContractsContractFormDialogComponent} from '../../trading/contracts/contract-form/contract-form.component';


@Component({
    selector: 'fuse-counterparties',
    templateUrl: './counterparties.component.html',
    styleUrls: ['./counterparties.component.scss'],
    animations   : fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class CounterpartiesComponent implements OnInit {

    constructor(public counterPartyService: CounterpartyService,
                private router: Router) { }

    ngOnInit() {
    }
}
