import {Address} from '../address/address.model';

export class Entity {
    entityId: number;
    name: string;
    internal: boolean;
    entityStatusId: number;
    createdOn: Date;
    modifiedOn: Date;
    addresses: Address[] = null;

    constructor(entity?) {
        {
            entity = entity || {};
            this.entityId = entity.entityId || 0;
            this.name = entity.name || '';
            this.internal = entity.internal || false;
            this.entityStatusId = entity.entityStatusId || undefined;
            this.createdOn = entity.createdOn || undefined;
            this.modifiedOn = entity.modifiedOn || undefined;
        }
    }


}
