import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {AuthGuard} from '../../../../auth/auth.guard';
import {SharedModule} from '../../../../shared';
import {AgGridModule} from 'ag-grid-angular';
import {PositionsComponent} from './positions.component';
import {ContractsService} from '../contracts/contracts.service';
import {MatButtonModule, MatDividerModule, MatIconModule, MatSelectModule, MatTabsModule} from '@angular/material';
import {FuseContractsModule} from '../contracts/contracts.module';
import {RoundRulesService} from '../../operations/constants/roundrules/round-rules.service';
import {BenchmarksService} from '../../operations/constants/benchmarks/benchmarks.service';

const routes: Routes = [
    {
        path     : 'positions',
        component: PositionsComponent,
        canActivate: [AuthGuard],
        resolve: {
            roundRules: RoundRulesService,
            benchmarks: BenchmarksService
        }
    }
];

@NgModule({
    declarations   : [
        PositionsComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,
        SharedModule,
        MatIconModule,
        MatSelectModule,
        AgGridModule.withComponents([]),
        MatTabsModule,
        FuseContractsModule,
        MatButtonModule,
        MatDividerModule
    ],
    providers      : [
        ContractsService,
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    ],
    entryComponents: [
    ],
    bootstrap: [PositionsComponent]
})
export class FusePositionsModule
{
}
