import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../../../auth/auth.guard';
import {CounterpartiesComponent} from './counterparties.component';
import {
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatSelectModule, MatSidenavModule,
    MatTabsModule,
    MatTreeModule
} from '@angular/material';
import {FuseSharedModule} from '../../../../../@fuse/shared.module';
import {CounterpartyListComponent} from './counterparty-list/counterparty-list.component';
import {CounterpartyService} from './counterparty.service';
import {AgGridModule} from 'ag-grid-angular';
import { CounterpartyDetailComponent } from './counterparty-detail/counterparty-detail.component';
import {CounterpartyDetailService} from './counterparty-detail/counterparty-detail.service';
import {RoundRulesService} from '../constants/roundrules/round-rules.service';
import {CounterPartyTypeService} from './counterparty-type.service';
import {StatusService} from '../constants/status/status.service';
import {CurrencyService} from '../constants/currency/currency.service';
import {EntityService} from '../entities/entity.service';
import {AccountsModule} from '../accounts/accounts.module';
import {AccountService} from '../accounts/account.service';

const routes: Routes = [
    {
        path     : 'counterparties',
        component: CounterpartiesComponent,
        canActivate: [AuthGuard],
        resolve  : {
            counterParties: CounterpartyService
        }
    },
    {
        path     : 'counterparties/:counterPartyId',
        component: CounterpartyDetailComponent,
        canActivate: [AuthGuard],
        resolve  : {
            counterParty: CounterpartyDetailService,
            roundRules: RoundRulesService,
            counterPartyType: CounterPartyTypeService,
            status: StatusService,
            currency: CurrencyService,
            entities: EntityService,
            accounts: AccountService
        }
    }
];



@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatIconModule,
        FuseSharedModule,
        MatCardModule,
        MatProgressBarModule,
        AgGridModule.withComponents([]),
        MatTabsModule,
        MatButtonModule,
        MatInputModule,
        MatCheckboxModule,
        MatSelectModule,
        AccountsModule,
        MatSidenavModule
    ],
  declarations: [
      CounterpartiesComponent,
      CounterpartyListComponent,
      CounterpartyDetailComponent
  ],
    providers: [
        CounterpartyDetailService,
        CounterPartyTypeService
    ],
    exports: [
        CounterpartyListComponent
    ]
})
export class CounterpartiesModule { }
