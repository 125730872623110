import {Identifiable} from '../../../../../shared/firestore-collection.service';

export class HighlightColor {

    accountId: number;
    highlightColor: string;

    constructor(accountId, highlightColor) {
        this.accountId = accountId;
        this.highlightColor = highlightColor;
    }

}
