import { NgModule } from '@angular/core';
import {CommonModule, TitleCasePipe} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../../../auth/auth.guard';

import {UserListComponent} from './user-list.component';
import {MatButtonModule, MatCheckboxModule, MatIconModule, MatInputModule, MatSidenavModule, MatTabsModule} from '@angular/material';
import {FuseSharedModule} from '../../../../../@fuse/shared.module';
import {AgGridModule} from 'ag-grid-angular';
import {UserListService} from './user-list.service';
import { UserDetailComponent } from './user-detail/user-detail.component';
import {UserDetailService} from './user-detail/user-detail.service';
import { UserRolesComponent } from './user-detail/user-roles/user-roles.component';
import {UserRolesService} from './user-detail/user-roles/user-roles.service';

const routes: Routes = [
    {
        path     : 'users',
        component: UserListComponent,
        canActivate: [AuthGuard],
        resolve: {
            users: UserListService
        }
    },
    {
        path     : 'users/:userUid',
        component: UserDetailComponent,
        canActivate: [AuthGuard],
        resolve: {
            user: UserDetailService
        }
    },
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatIconModule,
        FuseSharedModule,
        AgGridModule.withComponents([]),
        MatTabsModule,
        MatButtonModule,
        MatInputModule,
        MatCheckboxModule,
        MatSidenavModule
    ],
  declarations: [
      UserListComponent,
      UserDetailComponent,
      UserRolesComponent
  ],
    providers: [
        UserListService,
        UserDetailService,
        UserRolesService,
        TitleCasePipe
    ]
})
export class UsersModule { }
