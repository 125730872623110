import {AfterViewInit, Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {FplService} from './fpl.service';
import {BorrowButtonRenderer} from './borrowbutton.component';
import {Subscription} from 'rxjs';
import {DtcTransaction} from '../../dtc/dtc-transactions/dtc-transaction';
import {FplPosition} from './fplAccountPosition.model';
import {FplTradeFormComponent} from '../../trading/trade-form/fpl-trade-form.component';
import {MatDialog} from '@angular/material';
import {ReturnButtonRenderer} from './returnbutton.component';
import {Contract} from '../../trading/contracts/contract.model';
import {formatCurrency, formatNumber} from '@angular/common';
import {DtcSummaryService} from '../../dtc/dtc-summary/dtc-summary.service';
import Split from 'split.js';


@Component({
    selector: 'app-fpl-account-positions',
    templateUrl: './fplAccountPositions.component.html',
    styleUrls: ['./fplAccountPositions.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})


export class FplAccountPositionsComponent implements OnInit, OnDestroy, AfterViewInit {
    onAccountPositionAdded: Subscription;
    onAccountPositionChanged: Subscription;
    onAccountPositionRemoved: Subscription;
    dialogRef: any;
    columnDefs: any[];
    gridApi: any;
    frameworkComponents = {
        borrowButtonRenderer: BorrowButtonRenderer,
        returnButtonRenderer: ReturnButtonRenderer
    };
    gridOptions = {
        enableRangeSelection: true
    };

    context = {componentParent: this};
    unlinkedFilter = true;
    splitControl;

    defaultColDef = {
        enableCellChangeFlash: true,
        sortable: true,
        filter: true,
    };

    autoGroupColumnDef = {
        headerName: 'IClear Account',
        minWidth: 200,
        width: 200,
        resizable: false,
        pinned: true,
        filter: 'agTextColumnFilter',
        field: 'iClearAccount',
        cellRendererParams: {
            suppressCount: true,
            suppressDoubleClickExpand: false,
            suppressEnterExpand: false
        },
    };

    fplAvailableStyle(params) {
        if (params.value < 0) {
            return {'color': 'red'};
        } else {
            return {'color': 'black'};
        }

    }

    getRowStyle = (params) => {
        if (params.node.group) {
            return {'font-weight': 'bold'};
        }
    };

    constructor(public fplService: FplService,
                @Inject(LOCALE_ID) private locale: string,
                public dialog: MatDialog,
                public dtcPositionService: DtcSummaryService) {
    }

    ngOnInit() {
        this.columnDefs = [
            {field: 'iClearAccount', hide: true, rowGroup: true, width: 200, minWidth: 200},
            // {field: 'accountNumber', hide: true, rowGroup: true},
            // {field: 'accountNumber', hide: true},
            // {headerName: 'IClear Account', field: 'iClearAccount', filter: 'agTextColumnFilter', width: 160, minWidth: 160},
            {headerName: 'SLS Account', field: 'name', filter: 'agSetColumnFilter', width: 120, minWidth: 120},
            {
                headerName: 'Split', field: 'revSplit', filter: 'agSetColumnFilter', editable: false, width: 65, minWidth: 65,
                type: 'numericColumn', valueFormatter: (params) => {
                    if (params.value === 0) {
                        return '';
                    }
                }
            },
            {headerName: 'Symbol', field: 'symbol', filter: 'agTextColumnFilter', width: 100, minWidth: 100},
            {headerName: 'Cusip', field: 'cusip', filter: 'agTextColumnFilter', width: 100, minWidth: 100},
            {
                headerName: 'Qty', field: 'quantity', width: 100, minWidth: 100, type: 'numericColumn',
                valueFormatter: (params) => {
                    //console.log(params);
                    if (params.value === null) {
                        return 0;
                    } else if (params.value === undefined) {
                        return '';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                }
            },
            {
                headerName: 'LPit30', field: 'rebateAvg', width: 100, minWidth: 100, lockVisible: true, type: 'numericColumn',
                valueFormatter: (params) => {
                    if (params.value === undefined) {
                        return null;
                    } else {
                        return formatCurrency(params.value, this.locale, '');
                    }
                }
            },
            {
                headerName: 'LPitNew', field: 'newRebateAvg', width: 100, minWidth: 100, lockVisible: true, type: 'numericColumn',
                valueFormatter: (params) => {
                    if (params.value === undefined) {
                        return null;
                    } else {
                        return formatCurrency(params.value, this.locale, '');
                    }
                }
            },
            {
                headerName: 'Borrowed', field: 'borrowQuantity', width: 100, minWidth: 100, type: 'numericColumn',
                valueFormatter: (params) => {
                    //console.log(params);
                    if (params.value === null) {
                        return 0;
                    } else if (params.value === undefined) {
                        return '';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                }
            },
            {
                headerName: 'Available', field: 'available', width: 100, minWidth: 100, cellStyle: this.fplAvailableStyle, type: 'numericColumn',
                valueFormatter: (params) => {
                    //console.log(params);
                    if (params.value === null) {
                        return 0;
                    } else if (params.value === undefined) {
                        return '';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                }
            },
            {
                headerName: '', field: 'borrowQuantity', cellRenderer: 'borrowButtonRenderer', width: 100, minWidth: 200, filter: false, lockVisible: true
            },
            // {headerName: '', field: 'borrowQuantity', cellRenderer: 'returnButtonRenderer', width: 100, minWidth: 200, filter: false}
            // {headerName: 'Returned', field: 'returnQuantity'},
        ];

        this.setupSubscriptions();
    }

    ngAfterViewInit(): void {

        // this.splitControl =
        //     Split(['#positions', '#details'], {
        //         sizes: [50, 50],
        //         direction: 'vertical',
        //         gutterSize: 15,
        //     });
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.fplService.initializeService(
            this.fplService.collectionName,
            this.fplService.createTransactionCallback
        );
        this.setupSubscriptions();
    }

    getRowNodeId(data) {
        return data.primaryKey;
    }

    unlinkedAccountFilter(position: FplPosition): boolean {
        if (this.unlinkedFilter) {
            return (position.name !== null);
        } else {
            return true;
        }
    }

    refreshDataFilters() {
        this.gridApi.setRowData([]);
        this.gridApi.setRowData(this.fplService.collectionData.filter(p => this.unlinkedAccountFilter(p)));
    }

    setupSubscriptions() {
        this.refreshDataFilters();
        this.onAccountPositionAdded = this.fplService.onCollectionAdded.subscribe(fplPositions => {
            const newPositions: FplPosition[] = [];
            for (const fplPosition of fplPositions.filter(p => this.unlinkedAccountFilter(p))) {
                const rowNode = this.gridApi.getRowNode(fplPosition.primaryKey);
                if (rowNode === undefined) {
                    newPositions.push(fplPosition);
                }
            }
            this.gridApi.updateRowData({
                add: newPositions
            });
            this.gridApi.redrawRows();

        });
        this.onAccountPositionChanged = this.fplService.onCollectionChanged.subscribe(fplPositions => {
            this.gridApi.updateRowData({update: fplPositions});
            this.gridApi.redrawRows();
        });
        this.onAccountPositionRemoved = this.fplService.onCollectionRemoved.subscribe(fplPositions => {
            this.gridApi.updateRowData({remove: fplPositions});
            this.gridApi.redrawRows();
        });

    }

    ngOnDestroy(): void {
        this.onAccountPositionAdded.unsubscribe();
        this.onAccountPositionChanged.unsubscribe();
        this.onAccountPositionRemoved.unsubscribe();
        this.gridApi.destroy();
    }

    showTradeForm(params) {
        this.dialogRef = this.dialog.open(FplTradeFormComponent, {
            width: '300px',
            height: '400px',
            data: {
                fplAccountPos: this.gridApi.getRowNode(params.primaryKey).data,
            }
        });
    }
}
