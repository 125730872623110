import {Injectable} from '@angular/core';
import {FirestoreCollectionService} from '../../../../shared/firestore-collection.service';
import {DtcSummary} from './dtc-summary';
import {HttpClient} from '@angular/common/http';
import {AngularFirestore, QueryFn} from 'angularfire2/firestore';
import {AngularFireAuth} from 'angularfire2/auth';
import {BehaviorSubject, Subscription} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DtcSummaryService extends FirestoreCollectionService<DtcSummary> {

    collectionName = 'dtc-summary';

    collectionMap = new Map<string, DtcSummary>();

    private mapAddedSub: Subscription;
    private mapModifiedSub: Subscription;
    private mapRemovedSub: Subscription;
    public mapAlert: BehaviorSubject<any> = new BehaviorSubject(this.collectionMap);

    constructor(private httpClient: HttpClient,
                private firestoreClient: AngularFirestore,
                private afAuthClient: AngularFireAuth) {
        super(httpClient, firestoreClient, afAuthClient);
    }

    createSummaryCallback(newObj: any): DtcSummary {
        return new DtcSummary(newObj);
    }

    getSummary(cusip: string, dtcId: string): DtcSummary {
        const key: string = dtcId + ',' + cusip;
        const summary = this.collectionMap.get(key);
        if (summary) {
            return summary;
        } else {
            return new DtcSummary({
                madeNewBorrow: 0,
                madeNewLoan: 0,
                madeReturnBorrow: 0,
                madeReturnLoan: 0,
                quantity: 0
            });
        }
    }

    getDtcQuantityBySymbolAndDtcId(symbol: string, dtcId: string): number{
        let qty = null;
        this.collectionMap.forEach(pos=>{
            if(pos.dtcId===dtcId && pos.symbol===symbol){
                qty = pos.quantity;
            }
        })
        return qty;
    }

    clearSubscriptions(): void {
        super.clearSubscriptions();
        this.mapAddedSub.unsubscribe();
        this.mapModifiedSub.unsubscribe();
        this.mapRemovedSub.unsubscribe();
    }

    addMapSubscriptions(): void {
        this.mapAddedSub =
            this.onCollectionAdded.subscribe(
                (next: DtcSummary[]) => {
                    for (const summary of next) {
                        const key: string = summary.dtcId + ',' + summary.cusip;
                        this.collectionMap.set(key, summary);
                    }
                    this.mapAlert.next(this.collectionMap);
                }
            );

        this.mapModifiedSub =
            this.onCollectionChanged.subscribe(
                (next: DtcSummary[]) => {
                    for (const summary of next) {
                        const key: string = summary.dtcId + ',' + summary.cusip;
                        this.collectionMap.set(key, summary);
                    }
                    this.mapAlert.next(this.collectionMap);
                }
            );

        this.mapRemovedSub =
            this.onCollectionRemoved.subscribe(
                (next: DtcSummary[]) => {
                    for (const summary of next) {
                        const key: string = summary.dtcId + ',' + summary.cusip;
                        this.collectionMap.delete(key);
                    }
                    this.mapAlert.next(this.collectionMap);
                }
            );
    }


    initializeService(collection: string, createCallback): void {
        super.initializeService(collection, createCallback);
        this.addMapSubscriptions();
    }
}
