import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../../../auth/auth.guard';
import {AccountListComponent} from './account-list/account-list.component';
import { AccountDetailComponent } from './account-detail/account-detail.component';
import { AccountsComponent } from './accounts.component';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSidenavModule,
    MatTabsModule
} from '@angular/material';
import {FuseSharedModule} from '../../../../../@fuse/shared.module';
import {AgGridModule} from 'ag-grid-angular';
import {AccountService} from './account.service';
import {AccountDetailService} from './account-detail/account-detail.service';
import {CounterpartyService} from '../counterparties/counterparty.service';
import {StatusService} from '../constants/status/status.service';
import {AccountCategoryService} from './account-category.service';

const routes: Routes = [
    {
        path     : 'accounts',
        component: AccountsComponent,
        canActivate: [AuthGuard],
        resolve: {
            accounts: AccountService
        }
    },
    {
        path     : 'accounts/:accountId',
        component: AccountDetailComponent,
        canActivate: [AuthGuard],
        resolve: {
            account: AccountDetailService,
            counterParties: CounterpartyService,
            status: StatusService,
            accountCategories: AccountCategoryService
        }
    },

];


@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatIconModule,
        MatButtonModule,
        FuseSharedModule,
        AgGridModule.withComponents([]),
        MatTabsModule,
        MatInputModule,
        MatSelectModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        MatSidenavModule
    ],
  declarations: [
      AccountListComponent,
      AccountDetailComponent,
      AccountsComponent
  ],
    providers: [
        AccountService,
        AccountDetailService,
        AccountCategoryService
    ],
    exports: [
        AccountListComponent
    ]
})
export class AccountsModule { }
