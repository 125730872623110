import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Recall} from '../recall.model';
import {Contract} from '../../contracts/contract.model';
import {environment} from '../../../../../../environments/environment';
import {ContractsService} from '../../contracts/contracts.service';

@Injectable()
export class RecallDetailService implements OnDestroy{

    sourceContract: Contract;
    routeParams: any;
    recall: Recall;
    loanContracts: Contract[];
    onRecallChanged: BehaviorSubject<any> = new BehaviorSubject({});
    readonly endpoint = environment.verticalApiEndpoint + 'recalls/';
    contractsAddedSub: Subscription;
    init = false;
    onInitListener: BehaviorSubject<any> = new BehaviorSubject(false);
    errorState = false;
    error: any;

    constructor(
        private http: HttpClient,
        private contractService: ContractsService,
        public router: Router
    )
    {
        //console.log('initial value of on init listener: ', this.onInitListener.value);
        this.onInitListener.next(false);

    }

    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.error = null;
        this.errorState = false;
        this.routeParams = route.params;
        //console.log('route params for detail: ', this.routeParams);

        return new Promise((resolve, reject) => {

            Promise.all([
                this.getRecall()
            ]).then(
                () => {
                    resolve();
                }, (err) => {
                    this.errorState = true;
                    this.error = err;
                    resolve();
                }
            );
        });
    }

    getRecall(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            if ( this.routeParams.returnId === 'new' )
            {
                this.onRecallChanged.next(false);
                resolve(false);
            }
            else
            {
                this.http.get(this.endpoint + this.routeParams.returnId)
                    .subscribe((response: any) => {
                        this.recall = new Recall(response);
                        this.listenForContracts();
                        //console.log('Recall is now: ', this.recall);
                        this.onRecallChanged.next(this.recall);
                        resolve(response);
                    }, (err: any) => {
                        //console.log('err: ', err);
                        reject(err);
                    });
            }
        });
    }

    listenForContracts() {
        // this.contractsAddedSub =
        //     this.contractService.currentContractListObs.subscribe(
        //         contracts => {
        //                 if (contracts.length !== 0) {
        //                     const contractId = this.contractService.getContractById(contracts, this.recall.contractId);
        //                     this.sourceContract = this.contractService.contracts[contractId];
        //                     this.loanContracts = this.contractService.getContractsWithCusip(this.recall.cusip, 'L');
        //                     //console.log('loan contracts are now: ', this.loanContracts);
        //                     this.init = true;
        //                     this.onInitListener.next(true);
        //                 }
        //         }
        // );
    }

    submitReturn(returnElement: any) {
        if (returnElement.returnQty) {
            //console.log('submitting: ', returnElement);
            this.contractService.return(
                returnElement.contractId,
                returnElement.returnQty,
                returnElement.specFlag,
                returnElement.batchCode,
                returnElement.parentReturnId
            );
        }
    }

    ngOnDestroy(): void {
        this.contractsAddedSub.unsubscribe();
        this.errorState = false;
        this.error = null;
    }


    // clearInUseBy() {
    //     const json = {
    //         returnId: this.return.returnHoldingId
    //     };
    //     //console.log('clear in use: ', this.endpoint + this.return.returnHoldingId + '/clearInUse');
    //     this.http.put(this.endpoint + this.return.returnHoldingId + '/clearInUse', JSON.stringify(json))
    //         .subscribe((resp) => {
    //             //console.log('clear resp: ', resp);
    //         }, (err) => {
    //             //console.log('error clear: ', err);
    //         });
    // }
}
