import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

export class ShortLoanInterest {
    amount: number;
    charge: number;
    costFrom7359: number;
    date: Date;
    fplAmount: number;
    fplCost: number;
    fplPrice: number;
    fromFpl: number;
    fplRate: number;
    price: number;
    profitLoss: number;
    qtyFrom7359: number;
    rate: number;
    rateFrom7359: number;
    shares: number;
    symbol: string;

    constructor(item: any) {
        if (item.date) {
            const day = item.date.dayOfMonth;
            const month = item.date.monthValue - 1; // Month is 0-indexed
            const year = item.date.year;
            const minutes = item.date.minute;
            const hours = item.date.hour;
            const seconds = item.date.second;
            this.date = new Date(year, month, day, hours, minutes, seconds);
        }

        this.amount = item.amount;
        this.charge = item.charge;
        this.costFrom7359 = item.costFrom7359;
        this.fplAmount = item.fplAmount;
        this.fplCost = item.fplCost;
        this.fplPrice = item.fplPrice;
        this.fromFpl = item.fromFpl;
        this.price = item.price;
        this.profitLoss = item.profitLoss;
        this.qtyFrom7359 = item.qtyFrom7359;
        this.rate = item.rate;
        this.rateFrom7359 = item.rateFrom7359;
        this.fplRate = item.fplRate;
        this.shares = item.shares;
        this.symbol = item.symbol;
    }
}

export class ShortLoanInterestTest {
    sliId: number;
    date: Date;
    corr: string;
    office: string;
    acctNo: string;
    acctType: string;
    subAcctNo: string;
    symbol: string;
    symNo: string;
    holdType: string;
    price: number;
    sclose: number;
    smv: number;
    totalFromFpl: number;
    fplRate: number;
    fplPrice: number;
    totalQtyFrom7359: number;
    totalSclose: number;
    stockloanInterestRate: number;
    rateFrom7359: number;
    weight: number;
    qtyFrom7359: number;
    qtyFromFpl: number;
    costFromFpl: number;
    cost7359: number;
    charge: number;
    profitLoss: number;


    constructor(item: any) {
        if (item.date) {
            const day = item.date.dayOfMonth;
            const month = item.date.monthValue - 1; // Month is 0-indexed
            const year = item.date.year;
            const minutes = item.date.minute;
            const hours = item.date.hour;
            const seconds = item.date.second;
            this.date = new Date(year, month, day, hours, minutes, seconds);
        }
       this.sliId = item.sliId;
       this.corr = item.corr;
       this.office = item.office;
       this.acctNo = item.acctNo;
       this.acctType = item.acctType;
       this.subAcctNo = item.subAcctNo;
       this.symbol  = item.symbol;
       this.symNo  = item.symNo;
       this.holdType = item.holdType;
       this.price =  item.price;
       this.sclose = item.sclose;
       this.smv = item.smv;
       this.totalFromFpl = item.totalFromFpl;
       this.fplRate  = item.fplRate;
       this.fplPrice = item.fplPrice;
       this.totalQtyFrom7359  = item.totalQtyFrom7359;
       this.totalSclose  = item.totalSclose;
       this.stockloanInterestRate = item.stockloanInterestRate;
       this.rateFrom7359 = item.rateFrom7359;
       this.weight = item.weight;
       this.qtyFrom7359  = item.qtyFrom7359;
       this.qtyFromFpl = item.qtyFromFpl;
       this.costFromFpl  = item.costFromFpl;
       this.cost7359 = item.cost7359;
       this.charge = item.charge;
       this.profitLoss = item.profitLoss;

    }
}


@Injectable({
  providedIn: 'root'
})
export class ReportsService {

    @Output() shortLoanInterestChangeFilter = new EventEmitter();
    @Output() reportGroupChanged = new EventEmitter();
    private shortLoanInterestFilter;

    constructor(private httpClient: HttpClient) { }

    getShortLoanInterestReportData(fromDate: Date, toDate: Date): Promise<any> {
        const endpoint = environment.verticalApiEndpoint + 'reports/shortLoanInterest';
        const items = [];


        return new Promise((resolve, reject) => {
            const body = {
                fromDate: fromDate,
                toDate: toDate
            };
            //console.log(body);
            this.httpClient.post(endpoint, body).subscribe((response: any) => {
                for (const item of response.items) {
                    items.push(new ShortLoanInterest(item));
                }
                resolve(items);
            }, reject);
        });
    }

    getShortLoanInterestNewReportData(fromDate: Date, toDate: Date): Promise<any> {
        const endpoint = environment.verticalApiEndpoint + 'reports/shortLoanInterestTest';
        const items = [];

        return new Promise((resolve, reject) => {
            const body = {
                fromDate: fromDate,
                toDate: toDate
            }
            this.httpClient.post(endpoint, body).subscribe((response: any) => {
                for (const item of response.items) {
                    items.push(new ShortLoanInterestTest(item));
                }
                resolve(items);
            }, reject);
        });
    }

    updateShortLoanInterestFilters(fromDate, toDate) {
        this.shortLoanInterestFilter = {
            fromDate: fromDate,
            toDate: toDate
        };

        this.shortLoanInterestChangeFilter.emit(this.shortLoanInterestFilter);
    }

    getShortLoanInterestFilter(){
        return this.shortLoanInterestFilter;
    }

    changeReportGrouping(grouping:String){
        this.reportGroupChanged.emit(grouping);
    }

}
