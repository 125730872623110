import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../auth/auth.guard';
import {SummaryComponent} from './summary.component';

const routes: Routes = [
    {
        path: 'summary',
        component: SummaryComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
    declarations: [
        SummaryComponent
    ],
    exports: [
        SummaryComponent
    ],
    imports: [
        RouterModule.forChild(routes)
    ]
})
export class SummaryModule {
}
