import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {QuoteService} from '../../instruments/quotes/quote.service';
import {ContractsService} from '../../trading/contracts/contracts.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Quote} from '../../instruments/quotes/quote.model';
import {HttpClient} from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-instruments',
    templateUrl: './instruments.component.html',
    styleUrls: ['./instruments.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class InstrumentsComponent implements OnInit, OnDestroy {

    symbolSearch: string;
    quote: Quote;
    quoteForm: FormGroup;
    errorMsg: string;
    formAction: string;
    showUpdateContractsToggle = false;
    updateContracts = false;

    constructor(public quoteService: QuoteService,
                public contractsService: ContractsService,
                private formBuilder: FormBuilder,
                private snackBar: MatSnackBar) {
    }

    ngOnInit() {
        this.quoteService.clearQuote();
        this.formAction = 'update';
    }

    ngOnDestroy(): void {
        this.quoteService.clearQuote();
    }

    updateQuote() {
        this.quoteService.getQuote(this.symbolSearch, this.contractsService.effectiveDate)
            .subscribe(
                (quote) => {
                    this.formAction = 'update';
                    this.showUpdateContractsToggle = true;
                    this.updateContracts = false;

                    if (quote) {
                        quote.oldSymbol = quote.symbol;
                        quote.oldCusip = quote.cusip;
                        this.quote = new Quote(quote);
                        this.quoteForm = this.createQuoteForm();
                        this.errorMsg = undefined;
                    } else {
                        this.quote = undefined;
                        this.quoteForm = undefined;
                        this.errorMsg = 'Symbol not found.';
                    }
                }
            );
    }

    createQuoteForm(): FormGroup {
        return this.formBuilder.group({
            closeDate: [this.quote.closeDate, Validators.required],
            closePrice: [this.quote.closePrice, Validators.required],
            cusip: [this.quote.cusip, Validators.required],
            description: [this.quote.description, Validators.required],
            modified: [this.quote.modified],
            oldCusip: [this.quote.oldCusip],
            oldSymbol: [this.quote.oldSymbol],
            // status: [this.quote.status],
            symbol: [this.quote.symbol, Validators.required],
            updateSource: [this.quote.updateSource]
        });
    }

    updateContractsChange(event): void{
        if (this.updateContracts === true){
            this.quoteForm.controls.oldCusip.setValidators([Validators.required]);
            this.quoteForm.controls.oldCusip.updateValueAndValidity();

            this.quoteForm.controls.oldSymbol.setValidators([Validators.required]);
            this.quoteForm.controls.oldSymbol.updateValueAndValidity();
        }
        else if (this.updateContracts === false){
            this.quoteForm.controls.oldCusip.setValidators([]);
            this.quoteForm.controls.oldCusip.updateValueAndValidity();

            this.quoteForm.controls.oldSymbol.setValidators([]);
            this.quoteForm.controls.oldSymbol.updateValueAndValidity();
        }

    }

    update(): void {
        const quote: Quote = new Quote(this.quoteForm.getRawValue());
        //console.log('quote: ', quote);
        this.quoteService.updateInstrument(quote)
            .subscribe(
                (next) => {
                    //console.log('success: ', next);
                    this.snackBar.open('Instrument updated.', 'Dismiss');
                    this.quoteForm.markAsPristine();
                }, (err) => {
                    //console.log('err: ', err);
                    this.snackBar.open('Update failed.', 'Dismiss');
                }
            );
    }

    add(): void {
        const quote: Quote = new Quote(this.quoteForm.getRawValue());
        //console.log('quote: ', quote);
        this.quoteService.addInstrument(quote)
            .subscribe(
                (next) => {
                    //console.log('success: ', next);
                    this.snackBar.open('Instrument added.', 'Dismiss');
                    this.quoteForm.markAsPristine();
                }, (err) => {
                    //console.log('err: ', err);
                    this.snackBar.open('Add failed.', 'Dismiss');
                }
            );
    }

    cancel(): void {
        this.formAction = 'update';
        this.updateContracts = false;
        this.showUpdateContractsToggle = false;
        this.quote = undefined;
        this.quoteForm = undefined;
    }

    addNewInstrument() {
        this.formAction = 'new';
        this.updateContracts = false;
        this.showUpdateContractsToggle = false;
        this.errorMsg = undefined;
        this.symbolSearch = undefined;
        this.quoteForm = undefined;
        this.quote = undefined;

        setTimeout( () => {
            this.quote = new Quote({active: true});
            this.quote.closeDate = undefined;
            this.quoteForm = this.createQuoteForm();
        }, 50);
    }
}
