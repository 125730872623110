import {AfterContentChecked, AfterViewInit, Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {SwitcherService} from './switcher.service';
import {NavTabService} from '../../main/nav-tab/nav-tab.service';
import {ConfigSettingsService} from '../config-settings.service';
import {MatButtonToggle} from '@angular/material';

@Component({
    selector: 'app-switcher',
    templateUrl: './switcher.component.html',
    styleUrls: ['./switcher.component.scss']
})
export class SwitcherComponent implements OnInit, AfterContentChecked {

    depositoryNumbers = [];
    @ViewChild('btnRef') buttonRef: MatButtonToggle;

    constructor(public switcherService: SwitcherService,
                public configSettingsService: ConfigSettingsService,
                public navTabService: NavTabService) {
    }

    ngOnInit() {
        this.configSettingsService.onSwitcherDepositoryNumbersChange.subscribe((depositoryNumbers: string[]) => {
            if (depositoryNumbers){
                // this.buttonRef._buttonElement.nativeElement.click();
                this.depositoryNumbers = depositoryNumbers;
                // this.switcherService.switchAccountWithSelected();
                setTimeout(() => {
                    this.buttonRef._buttonElement.nativeElement.click();
                }, 0);
            }
        });
    }

    ngAfterContentChecked() {

    }

}
