import {AfterViewInit, Attribute, Component, ElementRef, Input, OnDestroy, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, ValidationErrors, Validators} from '@angular/forms';
import {ErrorStateMatcher, MatSnackBar} from '@angular/material';
import {Contract} from '../contracts/contract.model';
import {UserService} from '../../../../auth/user/user.service';
import {CounterParty} from '../../operations/counterparties/counterparty.model';
import {Observable, Subscription} from 'rxjs';
import {LimitProfile} from '../../operations/limitprofiles/limitprofile.model';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Quote} from '../../instruments/quotes/quote.model';
import {CounterPartyAccount} from '../../operations/accounts/account.model';
import {RoundRule} from '../../operations/constants/roundrules/roundrule.model';
import {Benchmark} from '../../operations/constants/benchmarks/benchmark.model';
import {formatCurrency} from '@angular/common';
import {ContractsService} from '../contracts/contracts.service';
import {CounterpartyService} from '../../operations/counterparties/counterparty.service';
import {RoundRulesService} from '../../operations/constants/roundrules/round-rules.service';
import {BenchmarksService} from '../../operations/constants/benchmarks/benchmarks.service';
import {QuoteService} from '../../instruments/quotes/quote.service';
import {Moment} from 'moment';
import {SwitcherService} from '../../../../shared/switcher/switcher.service';
import {TradeFormMode, TradeFormParams} from './TradeFormParams';
import {FplPosition} from '../../operations/fpl/fplAccountPosition.model';
import {THIS_EXPR} from '@angular/compiler/src/output/output_ast';
import {FplService} from '../../operations/fpl/fpl.service';

export const _filter = (opt: CounterPartyAccount[], value: string): CounterPartyAccount[] => {
    const filterValue = value.toUpperCase();
    if (opt) {
        return opt.filter(item => item.loanetId.toUpperCase().includes(filterValue) || item.name.toUpperCase().includes(filterValue));
    } else {
        return null;
    }
};

@Component({
    selector: 'trade-form',
    templateUrl: './trade-form.component.html',
    styleUrls: ['./trade-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})


export class TradeFormComponent implements OnDestroy, AfterViewInit {
    contractForm: FormGroup;
    a: FormGroupDirective;
    action: string;
    contract: Contract;
    quote: Quote;
    loadingQuote: boolean;
    validSymbol: boolean;
    internalLimit: LimitProfile;
    internalLimitLoading: boolean;
    externalCounterParty: CounterParty;
    externalCounterPartyMatch: CounterParty;
    externalCounterPartyLimit: LimitProfile;
    externalCounterPartyLimitMatch: LimitProfile;
    externalCounterPartyLimitLoading: boolean;
    externalCounterPartyLimitLoadingMatch: boolean;
    externalAccount: CounterPartyAccount;
    externalAccountMatch: CounterPartyAccount;
    externalAccountLimitLoading: boolean;
    externalAccountLimitLoadingMatch: boolean;
    externalAccountLimit: LimitProfile;
    externalAccountLimitMatch: LimitProfile;
    marginList: number[] = [];
    selectedMargin: number;
    selectedMarginMatch: number;
    selectedRoundRule: RoundRule;
    selectedRoundRuleMatch: RoundRule;
    floatingRebateType: boolean;
    selectedBenchmark: Benchmark;
    selectedBenchmarkMatch: Benchmark;
    minExpiration: Date;
    loadingSubmit = false;
    roundRulesSubscription: Subscription;
    benchmarksSubscription: Subscription;
    depositoryNoSubscription: Subscription;
    onSelectedSummarySymbolChangedSubscription: Subscription;
    onOpenTradeFormSubscription: Subscription;
    contraAccounts: Observable<CounterParty[]>;
    contraAccountsMatch: Observable<CounterParty[]>;
    loanChecked = false;
    borrowChecked = false;
    isMatch = false;
    isFpl = false;
    fplPosition: FplPosition;
    hideSubmit;
    maxQuantity: number;
    @Input() symbol: string;
    @Input() params: TradeFormParams;

    // MATCH contract fields
    matchContract: Contract;

    counterpartyCurrency: string = 'USD';
    counterpartyCurrencyMatch: string = 'USD';


    @ViewChild('externalCounterParty') contraElement: ElementRef;
    @ViewChild('externalCounterPartyMatch') contraElementMatch: ElementRef;

    checkAccountLimits: ErrorStateMatcher = {
        isErrorState: (control: FormControl) => {
            let internalError = false;
            let externalContraError = false;
            if (this.internalLimit && this.externalCounterPartyLimit && this.externalAccountLimit) {
                let internalLimit = 0;
                let externalLimit = 0;
                if (this.contract.side === 'B') {
                    internalLimit = this.internalLimit.borrowLimit - this.internalLimit.borrow;
                    externalLimit = this.externalBorrowLimit();
                } else if (this.contract.side === 'L') {
                    internalLimit = this.internalLimit.lendLimit - this.internalLimit.lend;
                    externalLimit = this.externalLoanLimit();
                }

                if (this.contract.amount > internalLimit) {
                    this.contractForm.controls['account'].setErrors({'invalid': true});
                    internalError = true;
                } else {
                    this.contractForm.controls['account'].setErrors(null);
                }

                if (this.contract.amount > externalLimit) {
                    this.contractForm.controls['externalCounterParty'].setErrors({'invalid': true});
                    externalContraError = true;
                } else {
                    this.contractForm.controls['externalCounterParty'].setErrors(null);
                }

                if ((this.contract.quantity > this.maxQuantity) && this.isFpl) {
                    this.contractForm.controls['quantity'].setErrors({'invalid': true});
                    internalError = true;
                } else {
                    this.contractForm.controls['quantity'].setErrors(null);
                }
            }
            return (internalError || externalContraError);
        }
    };

    checkAccountLimitsMatch: ErrorStateMatcher = {
        isErrorState: (control: FormControl) => {
            let internalError = false;
            let externalContraError = false;
            if (this.internalLimit && this.externalCounterPartyLimitMatch && this.externalAccountLimitMatch) {
                let internalLimit;
                let externalLimit;

                internalLimit = this.internalLimit.lendLimit - this.internalLimit.lend;
                externalLimit = this.externalLoanLimitMatch();

                if (this.matchContract.amount > internalLimit) {
                    this.contractForm.controls['account'].setErrors({'invalid': true});
                    internalError = true;
                } else {
                    this.contractForm.controls['account'].setErrors(null);
                }

                if (this.matchContract.amount > externalLimit) {
                    this.contractForm.controls['externalCounterPartyMatch'].setErrors({'invalid': true});
                    externalContraError = true;
                } else {
                    this.contractForm.controls['externalCounterPartyMatch'].setErrors(null);
                }
            }
            return (internalError || externalContraError);
        }
    };

    constructor(
        public userService: UserService,
        private formBuilder: FormBuilder,
        private httpClient: HttpClient,
        private contractService: ContractsService,
        public counterpartiesService: CounterpartyService,
        public roundRulesService: RoundRulesService,
        public benchmarkService: BenchmarksService,
        public quoteService: QuoteService,
        public switcherService: SwitcherService,
        private snackBar: MatSnackBar,
        public fplService: FplService
    ) {
        this.minExpiration = new Date();
        this.minExpiration.setDate(this.minExpiration.getDate() + 1);

        this.contractForm = this.createContractForm();
        this.initialFormSetup();

        if (this.counterpartiesService.internalCounterParties &&
            this.counterpartiesService.internalCounterParties[0]) {
            if (this.counterpartiesService.internalCounterParties[0].accounts) {
                let acct = this.counterpartiesService.getInternalAccountByDtcId(this.switcherService.getSelectedDepositoryNo());
                if (acct) {
                    this.setInternalAccount(acct);
                }
            }
        }

        this.roundRulesSubscription =
            this.roundRulesService.onRoundRulesLoaded
                .subscribe(roundRules => {
                    if (roundRules[0]) {
                        this.contract.roundRuleValue = roundRules[0].roundRuleValue;
                        this.contractForm.get('roundRuleValue').setValue(this.contract.roundRuleValue);
                        this.contractForm.get('roundRuleValueMatch').setValue(this.contract.roundRuleValue);
                    }
                });

        this.benchmarksSubscription =
            this.benchmarkService.onBenchmarksLoaded
                .subscribe(benchmarks => {
                    if (benchmarks[0]) {
                        this.contract.benchmarkId = benchmarks[0].benchmarkId;
                        this.selectedBenchmark = benchmarks[0];
                        this.contractForm.get('benchmarkRate').setValue(this.contract.benchmarkId);
                        this.contractForm.get('benchmarkRateMatch').setValue(this.contract.benchmarkId);
                    }
                });

        this.depositoryNoSubscription = this.switcherService.depositoryNoSwitched.subscribe(depositoryNo => {
            let dep = depositoryNo;
            if (this.isFpl) {
                dep = '0294';
            }
            let acc = this.counterpartiesService.getInternalAccountByDtcId(dep);
            if (acc) {
                this.setInternalAccount(acc);
            }
        });

        this.onSelectedSummarySymbolChangedSubscription = this.contractService.symbolSelected.subscribe(symbol => {
            if (symbol != '-' && symbol != 'Total') {
                this.contractForm.get('symbol').setValue(symbol);
                this.contractForm.get('symbolMatch').setValue(symbol);
            }
        });

        this.onOpenTradeFormSubscription = this.contractService.showTradePanel.subscribe(params => {
            this.configureTradeWindow(params);
        });
    }

    initialFormSetup() {
        this.action = 'new';
        this.loadingQuote = false;
        this.contractForm.reset();
        this.quote = null;
        this.contract = new Contract({});
        this.matchContract = new Contract({});
        this.borrowChecked = false;
        this.loanChecked = false;
        this.contractForm.get('divRate').setValue(100);
        this.contractForm.get('divRateMatch').setValue(100);
        this.contractForm.get('margin').setValue(102);
        this.contractForm.get('marginMatch').setValue(102);
        this.contractForm.get('roundRuleValue').setValue(1);
        this.contractForm.get('roundRuleValueMatch').setValue(1);
        this.contractForm.get('externalCounterParty').setValue(null);
        this.fplPosition = null;
        this.maxQuantity = 100000000;
    }

    configureTradeWindow(params: TradeFormParams) {
        this.initialFormSetup();
        this.isFpl = params.tradeFormMode === TradeFormMode.Fpl;
        this.borrowChecked = params.tradeFormMode === TradeFormMode.Fpl;
        this.setContractSide();
        if (params.tradeFormMode === TradeFormMode.Fpl) {
            this.fplPosition = params.fplPosition;
            this.setFplMode();
        }
    }

    setFplMode() {
        this.setSymbol(this.fplPosition.symbol);
        this.maxQuantity = this.fplPosition.available;
        let acc = this.counterpartiesService.getInternalAccountByDtcId('0294');
        if (acc) {
            this.setInternalAccount(acc);
        }
        let contra = this.counterpartiesService.getAccountByAccountId(this.fplPosition.accountId);
        this.contractForm.get('externalCounterParty').setValue(contra.loanetId);
        this.contractForm.get('batchCode').setValue('W');
        this.contractForm.get('specFlag').setValue('P');
        this.verifyContraInput();
    }

    ngOnDestroy() {
        this.roundRulesSubscription.unsubscribe();
        this.benchmarksSubscription.unsubscribe();
        this.depositoryNoSubscription.unsubscribe();
        this.onOpenTradeFormSubscription.unsubscribe();
        // this.onSelectedSummarySymbolChangedSubscription.unsubscribe();
    }

    updateInternalLimit(accountId: number): void {
        this.internalLimitLoading = true;
        this.counterpartiesService.getAccountLimitProfile(accountId, true).subscribe((limitProfile: any) => {
            this.internalLimitLoading = false;
            this.internalLimit = new LimitProfile(limitProfile);
        });
    }


    setInternalAccount(account: CounterPartyAccount): void {
        this.contractForm.get('accountId').setValue(account.accountId);
        this.contractForm.get('depositoryNo').setValue(account.dtcId);
        this.updateInternalLimit(account.accountId);
    }

    setSymbol(symbol: string): void {
        this.symbol = symbol;
        this.contractForm.get('symbol').setValue(symbol);
        this.contractForm.get('symbolMatch').setValue(symbol);
        // this.quoteService.updateQuote(this.symbol, this.contractService.effectiveDate);
        if (symbol !== '') {
            this.quoteService.getQuote(this.symbol, this.contractService.effectiveDate)
                .subscribe((quote) => {
                    if (quote) {
                        this.quote = new Quote(quote);
                        if (!quote.upToDate || this.quote.blocked) {
                            this.contractForm.controls['symbol'].setErrors({'invalid': true});
                            this.validSymbol = false;
                            this.loadingQuote = false;
                            return;
                        }
                        // this.contractForm.get('price').setValue(quote.closePrice);
                        // if (this.isMatch) {
                        //     this.contractForm.get('priceMatch').setValue(quote.closePrice);
                        // }
                        // this.quoteService.setQuote(new Quote(quote));
                        // this.contractForm.get('price').setValue(quote.closePrice);
                        // this.contractForm.get('priceMatch').setValue(quote.closePrice);
                        this.updateContractValue();
                        if (this.isMatch) {
                            this.updateContractValueMatch();
                        }
                    } else {
                        this.contractForm.controls['symbol'].setErrors({'invalid': true});
                        // this.quoteService.setQuote(
                        //     new Quote({
                        //         symbol: symbol.toUpperCase(),
                        //         description: 'N/A',
                        //     })
                        // );
                        this.quote = undefined;
                        this.validSymbol = false;
                        this.loadingQuote = false;
                    }
                }, (error) => {
                    this.updateContractValue();
                    if (this.isMatch) {
                        this.updateContractValueMatch();
                    }
                    this.contractForm.controls['symbol'].setErrors({'invalid': true});
                    this.quote = null;
                    this.validSymbol = false;
                    this.loadingQuote = false;
                });
        }
    }

    setRebateType(rebateType: string) {
        //console.log('rebateType: ', rebateType);
        if (rebateType === 'FX') {
            this.floatingRebateType = false;
        } else {
            this.floatingRebateType = true;
        }
    }

    marginUpdate(margin: number): void {
        this.selectedMargin = margin;
        this.contract.margin = margin;
        this.updateContractValue();
    }

    marginUpdateMatch(margin: number): void {
        //console.log('margin: ', margin);
        this.selectedMarginMatch = margin;
        this.matchContract.margin = margin;
        this.updateContractValueMatch();
    }

    roundSelectUpdate(roundRule: number): void {
        this.contract.roundRuleValue = roundRule;
        for (const roundRuleLoop of this.roundRulesService.roundRules) {
            if (roundRuleLoop.roundRuleValue === roundRule) {
                this.selectedRoundRule = roundRuleLoop;
                break;
            }
        }
        this.updateContractValue();
    }

    roundSelectUpdateMatch(roundRule: number): void {
        this.matchContract.roundRuleValue = roundRule;
        for (const roundRuleLoop of this.roundRulesService.roundRules) {
            if (roundRuleLoop.roundRuleValue === roundRule) {
                this.selectedRoundRuleMatch = roundRuleLoop;
                break;
            }
        }
        this.updateContractValue();
    }

    benchmarkSelectUpdate(benchmarkId: number): void {
        for (const benchmarkLoop of this.benchmarkService.benchmarks) {
            if (benchmarkLoop.benchmarkId === benchmarkId) {
                this.selectedBenchmark = benchmarkLoop;
                break;
            }
        }
    }

    updateContractValue(): void {
        // need quote price, margin recallQty, rounding rule, quantity
        //console.log('Calling update contract value!');
        if (this.quote && this.selectedRoundRule && this.contract.quantity && this.selectedMargin) {
            let amount: number = this.quote.closePrice * (this.selectedMargin / 100);
            //console.log('amount pre-round: ', amount);
            if (this.selectedRoundRule.value !== 0) {
                amount = Math.ceil(amount * (1 / this.selectedRoundRule.value)) / (1 / this.selectedRoundRule.value);
            }
            //console.log('amount post-round: ', amount);
            this.contract.amount = amount * this.contract.quantity;
            // this.contractForm.get('benchmarkRate').setValue(this.contract.benchmarkId);
            this.contractForm.get('amount').setValue(this.contract.amount);
            this.contractForm.get('visibleAmount').setValue(formatCurrency(this.contract.amount, 'en-US', 'USD'));
        } else {
            //console.log('Not ready to update value yet');
        }
    }

    updateContractValueMatch(): void {
        // need quote price, margin recallQty, rounding rule, quantity
        //console.log('Calling update contract value!');
        this.matchContract.quantity = this.contract.quantity;
        if (this.quote && this.selectedRoundRuleMatch && this.matchContract.quantity && this.selectedMarginMatch) {
            let amount: number = this.quote.closePrice * (this.selectedMarginMatch / 100);
            if (this.selectedRoundRuleMatch.value !== 0) {
                amount = Math.ceil(amount * (1 / this.selectedRoundRuleMatch.value)) / (1 / this.selectedRoundRuleMatch.value);
            }
            this.matchContract.amount = amount * this.matchContract.quantity;
            // this.contractForm.get('benchmarkRate').setValue(this.contract.benchmarkId);
            this.contractForm.get('amountMatch').setValue(this.matchContract.amount);
            this.contractForm.get('visibleAmountMatch').setValue(formatCurrency(this.matchContract.amount, 'en-US', 'USD'));
        } else {
            //console.log('Not ready to update value yet');
        }
    }

    parseNumberText(num: string, field: string): any {
        let newNum;
        let parsed;

        if (field === 'quantity') {
            newNum = num.replace(/\D/g,
                '');
            parsed = parseInt(newNum, 10);
            this.contract.quantity = parsed;
            this.updateContractValue();
            if (this.isMatch) {
                this.matchContract.quantity = parsed;
                this.updateContractValueMatch();
            }
        } else if (field === 'margin') {
            this.updateContractValue();
        }

        if (field === 'quantityMatch') {
            newNum = num.replace(/\D/g,
                '');
            parsed = parseInt(newNum, 10);
            this.matchContract.quantity = parsed;
            this.updateContractValueMatch();
        } else if (field === 'marginMatch') {
            this.updateContractValueMatch();
        }

        if (field == 'exchangeRate') {
            let pattern = new RegExp('^\\d+(?:\\.\\d{0,4})?$');
            if(pattern.test(num)){
                parsed = num;
                this.contract.contraFxRate = parsed;
            }else{
                parsed = num.substr(0,num.length-1);
                this.contract.contraFxRate = parsed;
            }
        }

        if (field == 'exchangeRateMatch') {
            let pattern = new RegExp('^\\d+(?:\\.\\d{0,4})?$');
            if(pattern.test(num)){
                parsed = num;
                this.matchContract.contraFxRate = parsed;
            }else{
                parsed = num.substr(0,num.length-1);
                this.matchContract.contraFxRate = parsed;
            }
        }

        return parsed;
    }

    parseSymbolText(symbol: string): string {
        const upperSymbol = symbol.toUpperCase();
        return upperSymbol;
    }

    createContractForm() {
        return this.formBuilder.group({
            account: [],
            accountId: [],
            depositoryNo: [],
            internalLimit: [],
            externalCounterParty: [],
            externalCounterPartyMatch: [],
            contraAccount: [],
            contraAccountMatch: [],
            contraAccountId: [],
            contraAccountIdMatch: [],
            contraDepositoryNo: [],
            contraDepositoryNoMatch: [],
            contraLimit: [],
            symbol: [],
            symbolMatch: [],
            quantity: [],
            quantityMatch: [],
            rate: [null, Validators.compose([Validators.min(-999.999), Validators.max(999.999)])],
            rateMatch: [null, Validators.compose([Validators.min(-999.999), Validators.max(999.999)])],
            margin: [Validators.compose([Validators.min(100), Validators.max(999)])],
            marginMatch: [Validators.compose([Validators.min(100), Validators.max(999)])],
            roundRuleValue: [],
            roundRuleValueMatch: [],
            amount: [],
            amountMatch: [],
            visibleAmount: [{disabled: true}],
            visibleAmountMatch: [{disabled: true}],
            floatingRebateType: [],
            benchmarkRate: [],
            benchmarkRateMatch: [],
            // price: [],
            // priceMatch: [],
            exchangeRate: [],
            exchangeRateMatch: [],
            fee: [],
            feeMatch: [],
            profitCenter: [],
            profitCenterMatch: [],
            batchCode: [],
            batchCodeMatch: [],
            specFlag: [],
            specFlagMatch: [],
            publicComment: [],
            publicCommentMatch: [],
            expireOn: [],
            expireOnMatch: [],
            divRate: [],
            divRateMatch: [],
            loanCheckbox: null,
            borrowCheckbox: null,
            side: null,
            // collateralCode:[],
            // collateralCodeMatch:[]
        });
    }

    setExpireOn(event): void {
        const moment: Moment = event.value;
        this.contractForm.get('expireOn').setValue(moment.format());
    }

    setExpireOnMatch(event): void {
        const moment: Moment = event.value;
        this.contractForm.get('expireOnMatch').setValue(moment.format());
    }

    submit(tradeForm: FormGroupDirective) {
        const contractData = this.contractForm.getRawValue();
        this.setSymbol(contractData.symbol);
        this.submitTrade(tradeForm);

        //
        // if (this.isFpl && this.fplPosition.office !== 'FPL ') {
        //     this.fplPosition.amountToBorrow = contractData.quantity;
        //     this.fplService.movePositiontoFpl(this.fplPosition).then(p => {
        //         this.fplPosition = new FplPosition(p);
        //         this.submitTrade(tradeForm);
        //     });
        // } else {
        //     this.submitTrade(tradeForm);
        // }
    }

    submitTrade(tradeForm: FormGroupDirective) {
        const contractData = this.contractForm.getRawValue();

        // if (this.isFpl && this.fplPosition.office !== 'FPL ') {
        //     this.fplPosition.amountToBorrow = contractData.quantity;
        //     this.fplService.movePositiontoFpl(this.fplPosition).then(p => {
        //         this.fplPosition = new FplPosition(p);
        //         //console.log(this.fplPosition);
        //     });
        // }


        const contract = {
            accountId: this.isFpl ? this.counterpartiesService.getAccountByLoanetIdOrName('0294').accountId :
                this.counterpartiesService.getAccountByLoanetIdOrName(this.switcherService.getSelectedDepositoryNo()).accountId,
            amount: contractData.amount,
            benchmarkRate: contractData.benchmarkRate,
            contraAccountId: contractData.contraAccountId,
            contraDepositoryNo: this.isFpl ? '0294' : contractData.contraDepositoryNo,
            depositoryNo: this.isFpl ? '0294' : this.switcherService.getSelectedDepositoryNo(),
            divRate: contractData.divRate,
            expireOn: contractData.expireOn,
            margin: contractData.margin,
            profitCenter: contractData.profitCenter,
            publicComment: contractData.publicComment,
            quantity: contractData.quantity,
            rate: contractData.rate,
            symbol: this.quote.symbol,
            side: this.contract.side,
            roundRuleValue: contractData.roundRuleValue,

            batchCode: contractData.batchCode ? contractData.batchCode.toUpperCase() : undefined,
            specFlag: contractData.specFlag ? contractData.specFlag.toUpperCase() : undefined,

            contraCurrencyId: this.counterpartyCurrency,
            contraFxRate: contractData.exchangeRate,
            fee: contractData.fee,

            // batchCode: this.isFpl ? 'W' : undefined,
            // specFlag: this.isFpl ? 'P' : undefined,
            //

            fplPositionId: this.isFpl ? this.fplPosition.fplPositionId : 0
        };

console.log(contract);

        const contractMatch = {
            accountId: this.isFpl ? this.counterpartiesService.getAccountByLoanetIdOrName('0294').accountId :
                this.counterpartiesService.getAccountByLoanetIdOrName(this.switcherService.getSelectedDepositoryNo()).accountId,
            amount: contractData.amountMatch,
            benchmarkRate: contractData.benchmarkRateMatch,
            contraAccountId: contractData.contraAccountIdMatch,
            contraDepositoryNo: contractData.contraDepositoryNoMatch,
            depositoryNo: this.isFpl ? '0294' : this.switcherService.getSelectedDepositoryNo(),
            divRate: contractData.divRateMatch,
            expireOn: contractData.expireOnMatch,
            margin: contractData.marginMatch,
            profitCenter: contractData.profitCenterMatch,
            publicComment: contractData.publicCommentMatch,
            quantity: contractData.quantity,
            rate: contractData.rateMatch,
            symbol: this.quote.symbol,
            side: this.matchContract.side,
            roundRuleValue: contractData.roundRuleValueMatch,
            batchCode: contractData.batchCodeMatch ? contractData.batchCodeMatch : undefined,
            specFlag: contractData.specFlagMatch ? contractData.specFlagMatch : undefined,
            contraCurrencyId: this.counterpartyCurrencyMatch,
            contraFxRate: contractData.exchangeRateMatch,
            fee: contractData.feeMatch,
        };

        console.log(contractMatch);

        this.contractService.updateContract(contract)
            .then((resp) => {
                //console.log('response: ', resp);
                if (this.isMatch) {
                    this.contractService.updateContract(contractMatch)
                        .then((resp) => {
                            //console.log('response: ', resp);
                            this.snackBar.open('Submitted Successfully', null, {
                                verticalPosition: 'bottom',
                                horizontalPosition: 'end',
                                panelClass: 'snackbarMatch',
                                duration: 2000,
                            });
                            this.loadingSubmit = false;
                            this.resetForm(tradeForm);
                            this.contraElement.nativeElement.focus();
                        });
                } else {
                    this.snackBar.open('Submitted Successfully', null, {
                        verticalPosition: 'bottom',
                        horizontalPosition: 'end',
                        panelClass: 'snackbar',
                        duration: 2000,
                    });
                    this.loadingSubmit = false;
                    this.resetForm(tradeForm);
                    this.contraElement.nativeElement.focus();
                }
            })
            .catch((error) => {
                console.log(error.error.error.message);
                this.snackBar.open(error.error.error.message, null, {
                    verticalPosition: 'bottom',
                    horizontalPosition: 'end',
                    panelClass: 'snackbar',
                    duration: 4000,
                });
                this.loadingSubmit = false;
            });
    }


    private finalContractValidation(contract: any): boolean {
        let isValid = true;
        if (contract.amount < 100) {
            isValid = false;
            this.contractForm.controls['quantity'].setErrors({
                Reason: 'Contract amount below minimum'
            });
        }
        return isValid;
    }


    private _filterGroup(value: string): CounterParty[] {
        if (value) {
            return this.counterpartiesService.combinedCounterParties
                .map(contra => ({
                    counterPartyId: contra.counterPartyId,
                    entityId: contra.entityId,
                    name: contra.name,
                    hasLoanNet: contra.hasLoanNet,
                    canReturnOnRecall: contra.canReturnOnRecall,
                    canRecallPartial: contra.canRecallPartial,
                    occMemberId: contra.occMemberId,
                    bic: contra.bic,
                    billingReference: contra.billingReference,
                    complianceReference: contra.complianceReference,
                    currencyId: contra.currencyId,
                    createdOn: contra.createdOn,
                    modifiedOn: contra.modifiedOn,
                    counterPartyTypeId: contra.counterPartyTypeId,
                    counterPartyStatusId: contra.counterPartyStatusId,
                    defaultRoundRuleValue: contra.defaultRoundRuleValue,
                    defaultMargin: contra.defaultMargin,
                    accounts: _filter(contra.accounts, value),
                    shortCode: contra.shortCode,
                    lendLimit: contra.lendLimit,
                    borrowLimit: contra.borrowLimit,
                    bizEmail: contra.bizEmail,
                    opsEmail: contra.opsEmail,
                    internal: contra.internal
                }))
                .filter(contra => contra.accounts && contra.accounts.length > 0);
        }

        return this.counterpartiesService.combinedCounterParties;
    }

    verifyContraInput(): void {
        let dtcInput: string;
        dtcInput = this.contractForm.get('externalCounterParty').value;
        const contraAccount: CounterPartyAccount = this.counterpartiesService.getAccountByLoanetIdOrName(dtcInput);

        if (contraAccount) {
            this.setContraAccount(contraAccount);
            this.contractForm.patchValue({externalCounterParty: contraAccount.loanetId + ' - ' + contraAccount.name});
        } else {
            this.externalCounterPartyLimit = null;
            this.contractForm.controls['externalCounterParty'].setErrors({'invalid': true});
        }
    }

    verifyContraMatchInput() {
        let dtcInput: string;
        dtcInput = this.contractForm.get('externalCounterPartyMatch').value;
        const contraAccount: CounterPartyAccount = this.counterpartiesService.getAccountByLoanetIdOrName(dtcInput.toUpperCase());

        if (contraAccount) {
            this.setContraAccountMatch(contraAccount);
            this.updateContractValueMatch();
            this.contractForm.patchValue({externalCounterPartyMatch: contraAccount.loanetId + ' - ' + contraAccount.name});
        } else {
            this.externalCounterPartyLimitMatch = null;
            this.contractForm.controls['externalCounterPartyMatch'].setErrors({'invalid': true});
        }
    }

    ngAfterViewInit(): void {
        this.contraAccounts = this.contractForm.get('externalCounterParty')!.valueChanges
            .pipe(
                map(value => this._filterGroup(value))
            );
        setTimeout(() => {
            if (this.contraElement) {
                this.contraElement.nativeElement.focus();
            }
        }, 0);

        this.contraAccountsMatch = this.contractForm.get('externalCounterPartyMatch')!.valueChanges
            .pipe(
                map(value => this._filterGroup(value))
            );
        setTimeout(() => {
            if (this.contraElementMatch) {
                this.contraElementMatch.nativeElement.focus();
            }
        }, 0);


    }

    externalLimitLoading(): boolean {
        return this.externalAccountLimitLoading || this.externalCounterPartyLimitLoading;
    }

    externalLimitMatchLoading(): boolean {
        return this.externalAccountLimitLoadingMatch || this.externalCounterPartyLimitLoadingMatch;
    }


    externalBorrowLimit(): number {
        // this.externalCounterPartyLimit.borrowLimit - this.externalCounterPartyLimit.borrow
        // return lesser of contra limit or account limit - the current borrow amount
        if (!(this.externalLimitLoading())) {
            const contraLimitLeft = this.externalCounterPartyLimit.borrowLimit - this.externalCounterPartyLimit.borrow;
            const acctLimitLeft = this.externalAccountLimit.borrowLimit - this.externalAccountLimit.borrow;
            return acctLimitLeft > contraLimitLeft ? contraLimitLeft : acctLimitLeft;
        }
        return 0;
    }

    externalLoanLimit(): number {
        // this.externalCounterPartyLimit.borrowLimit - this.externalCounterPartyLimit.borrow
        // return lesser of contra limit or account limit - the current borrow amount
        if (!(this.externalLimitLoading()) && this.externalCounterPartyLimit) {
            let contraLimitLeft;
            let acctLimitLeft;
            contraLimitLeft = this.externalCounterPartyLimit.lendLimit - this.externalCounterPartyLimit.lend;
            acctLimitLeft = this.externalAccountLimit.lendLimit - this.externalAccountLimit.lend;
            return acctLimitLeft > contraLimitLeft ? contraLimitLeft : acctLimitLeft;
        }
        return 0;
    }

    externalLoanLimitMatch(): number {
        if (!(this.externalLimitMatchLoading()) && this.externalCounterPartyLimitMatch) {
            let contraLimitLeft;
            let acctLimitLeft;
            contraLimitLeft = this.externalCounterPartyLimitMatch.lendLimit - this.externalCounterPartyLimitMatch.lend;
            acctLimitLeft = this.externalAccountLimitMatch.lendLimit - this.externalAccountLimitMatch.lend;
            return acctLimitLeft > contraLimitLeft ? contraLimitLeft : acctLimitLeft;
        }
        return 0;

    }

    setContraAccount(contraAccount: CounterPartyAccount): void {
        if (contraAccount.counterPartyId === this.counterpartiesService.UNKNOWN_COUNTERPARTY_ID) {
            this.contractForm.get('externalCounterParty').setErrors({'invalid': true});
            return;
        }

        this.contractForm.get('contraAccountId').setValue(contraAccount.accountId);
        this.contractForm.get('contraDepositoryNo').setValue(contraAccount.dtcId);
        // Get some limits
        this.externalCounterPartyLimitLoading = true;
        this.externalAccountLimitLoading = true;
        const contra: CounterParty = this.counterpartiesService.getCounterPartyById(contraAccount.counterPartyId);
        this.counterpartyCurrency = contra.currencyId;
        this.contract.contraCurrencyId = this.counterpartyCurrency;
        if(this.counterpartyCurrency!='USD' || this.isFpl){
            this.contractForm.get('batchCode').setValue('W');
            this.contractForm.get('specFlag').setValue('P');
        }else{
            this.contractForm.get('batchCode').setValue('');
            this.contractForm.get('specFlag').setValue('');
        }
        this.counterpartiesService.getCounterPartyLimitProfile(contraAccount.counterPartyId, false)
            .subscribe((limitProfile: any) => {
                this.externalCounterPartyLimitLoading = false;
                this.externalCounterPartyLimit = new LimitProfile(limitProfile);
            });
        this.counterpartiesService.getAccountLimitProfile(contraAccount.accountId, false)
            .subscribe((limitProfile: any) => {
                this.externalAccountLimitLoading = false;
                this.externalAccountLimit = new LimitProfile(limitProfile);
            });
        if (contra) {
            this.contractForm.get('margin').setValue(contra.defaultMargin * 100);
            this.contractForm.get('roundRuleValue').setValue(contra.defaultRoundRuleValue);
            this.roundSelectUpdate(contra.defaultRoundRuleValue);
            this.marginUpdate(contra.defaultMargin * 100);
        }
    }


    setContraAccountMatch(contraAccount: CounterPartyAccount): void {
        // if the acct belongs to unknown counterparty hten its not good
        if (contraAccount.counterPartyId === this.counterpartiesService.UNKNOWN_COUNTERPARTY_ID) {
            this.contractForm.get('externalCounterPartyMatch').setErrors({'invalid': true});
            return;
        }

        this.contractForm.get('contraAccountIdMatch').setValue(contraAccount.accountId);
        this.contractForm.get('contraDepositoryNoMatch').setValue(contraAccount.dtcId);
        // Get some limits
        this.externalCounterPartyLimitLoadingMatch = true;
        this.externalAccountLimitLoadingMatch = true;
        const contra: CounterParty = this.counterpartiesService.getCounterPartyById(contraAccount.counterPartyId);
        this.counterpartyCurrencyMatch = contra.currencyId;
        this.matchContract.contraCurrencyId = this.counterpartyCurrencyMatch;
        if(this.counterpartyCurrencyMatch!='USD'){
            this.contractForm.get('batchCodeMatch').setValue('W');
            this.contractForm.get('specFlagMatch').setValue('P');
        }else{
            this.contractForm.get('batchCodeMatch').setValue('');
            this.contractForm.get('specFlagMatch').setValue('');
        }
        this.counterpartiesService.getCounterPartyLimitProfile(contraAccount.counterPartyId, false)
            .subscribe((limitProfile: any) => {
                //console.log('[LimitProfile] limit: ', limitProfile);
                this.externalCounterPartyLimitLoadingMatch = false;
                this.externalCounterPartyLimitMatch = new LimitProfile(limitProfile);
            });
        this.counterpartiesService.getAccountLimitProfile(contraAccount.accountId, false)
            .subscribe((limitProfile: any) => {
                //console.log('[LimitProfile] limit: ', limitProfile);
                this.externalAccountLimitLoadingMatch = false;
                this.externalAccountLimitMatch = new LimitProfile(limitProfile);
            });
        if (contra) {
            this.contractForm.get('marginMatch').setValue(contra.defaultMargin * 100);
            this.contractForm.get('roundRuleValueMatch').setValue(contra.defaultRoundRuleValue);
            this.roundSelectUpdateMatch(contra.defaultRoundRuleValue);
            this.marginUpdateMatch(contra.defaultMargin * 100);
        }
    }

    loanToggle(e) {
        this.loanChecked = e.checked;
        this.setContractSide();
    }

    borrowToggle(e) {
        this.borrowChecked = e.checked;
        this.setContractSide();
    }

    setContractSide() {
        if (this.borrowChecked && this.loanChecked) {
            this.contract.side = 'B';
            this.matchContract.side = 'L';
            this.isMatch = true;
            this.updateContractValueMatch();
        } else if (this.borrowChecked) {
            this.contract.side = 'B';
            this.isMatch = false;
            this.clearMatchValidationErrors();
        } else if (this.loanChecked) {
            this.contract.side = 'L';
            this.isMatch = false;
            this.clearMatchValidationErrors();
        } else {
            this.contract.side = null;
            this.isMatch = false;
        }
    }

    clearAllValidationErrors() {
        for (let name in this.contractForm.controls) {
            this.contractForm.controls[name].setErrors(null);
        }
    }

    clearMatchValidationErrors() {
        this.contractForm.controls['externalCounterPartyMatch'].setErrors(null);
        this.contractForm.controls['contraAccountMatch'].setErrors(null);
        this.contractForm.controls['contraAccountIdMatch'].setErrors(null);
        this.contractForm.controls['contraDepositoryNoMatch'].setErrors(null);
        this.contractForm.controls['symbolMatch'].setErrors(null);
        this.contractForm.controls['quantityMatch'].setErrors(null);
        this.contractForm.controls['rateMatch'].setErrors(null);
        this.contractForm.controls['quantityMatch'].setErrors(null);
        this.contractForm.controls['marginMatch'].setErrors(null);
        this.contractForm.controls['roundRuleValueMatch'].setErrors(null);
        this.contractForm.controls['amountMatch'].setErrors(null);
        this.contractForm.controls['visibleAmountMatch'].setErrors(null);
        this.contractForm.controls['benchmarkRateMatch'].setErrors(null);
        this.contractForm.controls['profitCenterMatch'].setErrors(null);
        this.contractForm.controls['publicCommentMatch'].setErrors(null);
        this.contractForm.controls['expireOnMatch'].setErrors(null);
        this.contractForm.controls['divRateMatch'].setErrors(null);
        this.contractForm.controls['exchangeRate'].setErrors(null);
        this.contractForm.controls['exchangeRateMatch'].setErrors(null);
        this.contractForm.controls['fee'].setErrors(null);
        this.contractForm.controls['feeMatch'].setErrors(null);
    }

    setMatchRate(value) {
        if (this.isFpl && this.isFpl) {
            this.contractForm.controls['rateMatch'].setValue(value);
            this.contractForm.controls['rateMatch'].setErrors(null);
        }
    }

    public findInvalidControls() {
        const invalid = [];
        const controls = this.contractForm.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        console.log(invalid);
        console.log(this.contractForm);
    }
    resetForm(tradeForm: FormGroupDirective) {
        const result = [];
        Object.keys(this.contractForm.controls).forEach(key => {

            const controlErrors: ValidationErrors = this.contractForm.get(key).errors;
            if (controlErrors) {
                Object.keys(controlErrors).forEach(keyError => {
                    result.push({
                        'control': key,
                        'error': keyError,
                        'value': controlErrors[keyError]
                    });
                });
            }
        });

        Object.keys(this.contractForm.controls).forEach(key => {

            const controlErrors: ValidationErrors = this.contractForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    //console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });

        this.clearAllValidationErrors();
        tradeForm.resetForm();
        this.quote = null;
        this.externalCounterPartyLimitMatch = null;
        this.externalCounterPartyLimit = null;
        this.contractForm.get('margin').setValue(102);
        this.contractForm.get('marginMatch').setValue(102);
        this.contractForm.get('divRate').setValue(100);
        this.contractForm.get('divRateMatch').setValue(100);
        this.contractForm.get('roundRuleValue').setValue(1);
        this.contractForm.get('roundRuleValueMatch').setValue(1);
        this.contractForm.get('fee').setValue(null);
        this.contractForm.get('feeMatch').setValue(null);
        this.contractForm.get('batchCode').setValue('');
        this.contractForm.get('batchCodeMatch').setValue('');
        this.contractForm.get('specFlag').setValue('');
        this.contractForm.get('specFlagMatch').setValue('');
        this.contractForm.get('rate').markAsPristine();
        this.contractForm.get('rateMatch').markAsPristine();
        this.counterpartyCurrency = 'USD';
        this.counterpartyCurrencyMatch = 'USD';
        this.contractForm.get('margin').setValue(102);
        this.contractForm.get('marginMatch').setValue(102);
        this.contract = new Contract({});
        this.matchContract = new Contract({});
        this.setContractSide();
        if (this.isFpl) {
            this.setFplMode();
        }
    }
}

