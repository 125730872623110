import { Injectable } from '@angular/core';
import {Benchmark} from './benchmark.model';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../../../../environments/environment';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';

@Injectable()
export class BenchmarksService implements Resolve<any>{

    private _benchmarks: Benchmark[] = undefined;
    onBenchmarksLoaded: BehaviorSubject<any> = new BehaviorSubject([]);
    endpoint = environment.verticalApiEndpoint + 'constants/benchmarks';


  constructor(private httpClient: HttpClient) {
  }

    updateBenchmarks(): void {
      if (this._benchmarks === undefined) {
          this.getBenchmarks().subscribe((benchmarks: any) => {
              this.benchmarks = benchmarks.items;
              this.onBenchmarksLoaded.next(this.benchmarks);
          });
      }
    }

    private getBenchmarks(): Observable<Benchmark[]> {
        if (this._benchmarks === undefined) {
            return this.httpClient
                .get(this.endpoint)
                .pipe(
                    map((body: any) => body),
                    catchError(() => of('Error, could not load round rules.'))
                );
        }
    }


    get benchmarks(): Benchmark[] {
        return this._benchmarks;
    }


    set benchmarks(value: Benchmark[]) {
        this._benchmarks = value;
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.updateBenchmarks()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }
}
