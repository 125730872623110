import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Entity} from './entity.model';
import {environment} from '../../../../../environments/environment';

@Injectable()
export class EntityService implements Resolve<any> {

    entities: Entity[] = [];
    onEntitiesLoaded: BehaviorSubject<any> = new BehaviorSubject([]);
    onEntityChanged: BehaviorSubject<any> = new BehaviorSubject([]);

  constructor(private httpClient: HttpClient) {
  }

    getRemoteEntities(): void {
        const endpoint = environment.verticalApiEndpoint + 'entity';
        this.httpClient.get(endpoint)
            .subscribe((entities: any) => {
                this.entities = entities.items;
                this.onEntitiesLoaded.next(this.entities);
                //console.log('Entities: ', this.entities);
            }, error => {
               //console.log('Error getting entities: ', error);
            });
    }

    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getRemoteEntities()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }


}
