import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import 'hammerjs';

import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {fuseConfig} from './fuse-config';
import {AppComponent} from './app.component';
import {FuseMainModule} from './main/main.module';
import {environment} from '../environments/environment';
import {AngularFireModule} from 'angularfire2';
import {AngularFirestoreModule} from 'angularfire2/firestore';
import {AngularFireAuthModule} from 'angularfire2/auth';
import {AuthGuard} from './auth/auth.guard';
import {SharedModule} from './shared/shared.module';
import {AuthInterceptService} from './auth/auth-intercept.service';
import {ConfirmationDialogComponent} from './shared/confirmation-dialog/confirmation-dialog.component';
import {MatButtonModule, MatButtonToggleModule, MatCardModule, MatCheckboxModule, MatDialogModule, MatFormFieldModule, MatIconModule, MatToolbarModule} from '@angular/material';
import {CustomTooltip} from './main/content/trading/contracts/bulk/toolTip';
import {AgGridModule} from 'ag-grid-angular';
import {CheckboxRenderer} from './main/content/operations/contract-management/checkbox-renderer.component';
import { ChangePasswordComponent } from './change-password/change-password.component';


const appRoutes: Routes = [
    {
        path: 'pages',
        loadChildren: './main/content/pages/pages.module#FusePagesModule'
    },
    {
        path: '**',
        redirectTo: 'combined-contracts'
    }
];

@NgModule({
    declarations: [
        AppComponent,
        ConfirmationDialogComponent,
        CustomTooltip,
        CheckboxRenderer,
        ChangePasswordComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        TranslateModule.forRoot(),
        AngularFireModule.initializeApp(environment.firebase),
        // Fuse Main and Shared modules
        FuseModule.forRoot(fuseConfig),
        FuseSharedModule,
        FuseMainModule,
        AngularFirestoreModule,
        AngularFireAuthModule,
        SharedModule,
        MatToolbarModule,
        MatIconModule,
        MatDialogModule,
        MatButtonModule,
        MatCardModule,
        MatFormFieldModule,
        MatCheckboxModule,
        MatButtonToggleModule,
        AgGridModule.withComponents([CustomTooltip,CheckboxRenderer])
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptService,
            multi: true
        }
    ],
    entryComponents: [
        ConfirmationDialogComponent
    ]
})
export class AppModule {
}
