import * as moment from 'moment-timezone';

export class ContractReview {
    createdOn: string;
    startOn: string;
    side : string;
    symbol: string;
    cusip: string;
    depositoryNo: string;
    contraDepositoryNo:string;
    profitCenter: string;
    contractNo: string;
    rate: number;
    borrowQuantity: number;
    borrowAmount: number;
    loanQuantity: number;
    loanAmount: number;
    amount: number;
    quantity: number
    comment: string;
    reviewer: string;
    reviewedOn: string;
    contractId: number;
    contractHistoryId:number;
    sourceLoginId: number;
    approverNickname: string

    constructor(contractReview) {
        this.side = contractReview.side;
        this.symbol = contractReview.symbol;
        this.cusip = contractReview.cusip;
        this.depositoryNo = contractReview.depositoryNo;
        this.contraDepositoryNo = contractReview.contraDepositoryNo;
        this.profitCenter = contractReview.profitCenter;
        this.contractNo = contractReview.contractNo;
        this.rate = contractReview.rate;
        this.borrowQuantity = contractReview.borrowQuantity;
        this.borrowAmount = contractReview.borrowAmount;
        this.loanQuantity = contractReview.loanQuantity;
        this.loanAmount = contractReview.loanAmount;
        this.amount = contractReview.amountAggregates;
        this.quantity = contractReview.quantity;
        this.comment = contractReview.comment;
        this.reviewer = contractReview.reviewer;
        this.contractId = contractReview.contractId;
        this.contractHistoryId = contractReview.contractHistoryId;
        this.sourceLoginId = contractReview.sourceLoginId;
        this.startOn = contractReview.startOn;
        this.createdOn = contractReview.createdOn;
        this.approverNickname = contractReview.approverNickname;

        if(contractReview.reviewedOn) {
            this.reviewedOn = moment.utc(this.convertDate(contractReview.reviewedOn)).tz('America/New_York').format('lll');
        }

        if(contractReview.createdOn) {
            this.createdOn = moment.utc(this.convertDate(contractReview.createdOn)).tz('America/New_York').format('lll');
        }

        if (this.side === 'L') {
            this.borrowAmount = null;
            this.borrowQuantity = null;
        } else {
            this.loanAmount = null;
            this.loanQuantity = null;
        }
    }

    convertDate(date): Date{
        var day = date.dayOfMonth;
        var month = date.monthValue - 1; // Month is 0-indexed
        var year = date.year;
        var minutes = date.minute;
        var hours = date.hour;
        var seconds = date.second;
        return new Date(Date.UTC(year, month, day, hours, minutes, seconds));
    }
}
