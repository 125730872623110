import {Identifiable} from '../../../../../../shared/firestore-collection.service';

export class ContractPrototype implements ContractPrototype, Identifiable {
    borrowContra: string;
    borrowRate: number;
    comments: string;
    conduit: string;
    cusip: string;
    loanContra: string;
    loanRate: number;
    pcPrice: number;
    price: number;
    value: number;
    primaryKey: any;
    profitCenter: string;
    quantity: number;
    status: string;
    symbol: string;
    loanBatchCode: string;
    loanSpecFlag: string;

    constructor(prototype: any) {
        this.borrowContra = prototype.borrowContra;
        this.borrowRate = prototype.borrowRate;
        this.comments = prototype.comments;
        this.conduit = prototype.conduit;
        this.cusip = prototype.cusip;
        this.loanContra = prototype.loanContra;
        this.loanRate = prototype.loanRate;
        this.pcPrice = prototype.pcPrice;
        this.price = prototype.price;
        this.value = prototype.value;
        this.primaryKey = prototype.primaryKey;
        this.profitCenter = prototype.profitCenter;
        this.quantity = prototype.quantity;
        this.status = prototype.status;
        this.symbol = prototype.symbol;
        this.loanBatchCode = prototype.loanBatchCode;
        this.loanSpecFlag = prototype.loanSpecFlag;
    }

    public isValid(): boolean {
        return (this.status === 'Valid');
    }
}
