import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function confirmPasswordValidator(passwordControlName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!passwordControlName) {
      throw new Error('confirmPasswordValidator requires a password control name to compare to');
    }

    const passwordControl = control.root.get(passwordControlName);
    if (!passwordControl) {
      return null; // Handle missing control gracefully (optional)
    }

    if (!control.value) {
        return null; // Don't show error if confirmPassword us empty
    }

    // if (control.value.length < 5) {
    //     return null; // Don't show error if length of confirmPassword is less then 5
    // }

    if (passwordControl.value !== control.value) {
      return { passwordsDontMatch: true };
    }
    return null; // Pass validation
  };
}