import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {CounterPartyAccount} from './account.model';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';

export class CounterPartyAccountCategory {

    accountCategoryId: number;
    name: string;
    description: string;
    abbr: string;

    constructor(accountCategory) {
        this.abbr = accountCategory.abbr;
        this.name = accountCategory.name;
        this.description = accountCategory.description;
        this.accountCategoryId = accountCategory.accountCategoryId;
    }


}

@Injectable()
export class AccountCategoryService implements Resolve<any> {

    categories: CounterPartyAccountCategory[] = [];
    endpoint = environment.verticalApiEndpoint + 'constants/accountCategory';

    constructor(private httpClient: HttpClient) {
    }

    getAccountCategories(): void {
        this.httpClient.get(this.endpoint)
            .subscribe((resp: any) => {
                const accountArray: CounterPartyAccountCategory[] = [];
                for (const accountCategory of resp.items) {
                    accountArray.push(new CounterPartyAccountCategory(accountCategory));
                }
                this.categories = accountArray;
            }, error => {
                console.log('Error getting account categories: ', error);
            });
    }

    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getAccountCategories()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }


}
