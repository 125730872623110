import {Component, Inject, LOCALE_ID, OnDestroy, OnInit} from '@angular/core';
import {ColDef, GridApi} from 'ag-grid-community';
import {Subscription} from 'rxjs';
import {DtcTransactionsService} from '../dtc-transactions/dtc-transactions.service';
import {SwitcherService} from '../../../../shared/switcher/switcher.service';
import {formatCurrency, formatNumber} from '@angular/common';
import {DtcTransaction} from '../dtc-transactions/dtc-transaction';
import {NavTabService} from '../../../nav-tab/nav-tab.service';
import {ActivatedRoute} from '@angular/router';
import {DtcSummaryFilterService} from '../dtc-summary-filter/dtc-summary-filter.service';
import * as moment from 'moment-timezone';
import {DtcTransactionsFilterService} from '../dtc-transactions-filter/dtc-transactions-filter.service';

@Component({
  selector: 'app-combined-dtc-transactions',
  templateUrl: './combined-dtc-transactions.component.html',
  styleUrls: ['./combined-dtc-transactions.component.scss']
})
export class CombinedDtcTransactionsComponent implements OnInit, OnDestroy {

    gridApi: GridApi;
    colDefs: ColDef[];
    defaultColDef: any;
    public summarySelectedSymbol: string;
    public tabId: string;
    onDepositoryNoChanged: Subscription;
    onDTCTransactionAdded: Subscription;
    onDTCTransactionChanged: Subscription;
    onDTCTransactionRemoved: Subscription;
    onSummaryFilterChangedSubscription: Subscription;
    onTransactionsFilterChangedSubscription: Subscription;
    onSelectedSummarySymbolChangedSubscription: Subscription;

    constructor(public dtcTransactionService: DtcTransactionsService,
                @Inject(LOCALE_ID) private locale: string,
                public switcherService: SwitcherService,
                public navTabService: NavTabService,
                public activatedRoute: ActivatedRoute,
                public dtcSummaryFilterService: DtcSummaryFilterService,
                public dtcTransactionsFilterService: DtcTransactionsFilterService
    ) {
        this.colDefs = [
            {
                headerName: 'Symbol',
                field: 'symbol',
                filter: 'agSetColumnFilter',
                minWidth: 120
            },
            {
                headerName: 'CUSIP',
                field: 'cusip',
                filter: 'agSetColumnFilter',
                minWidth: 120
            },
            {
                headerName: 'Quantity',
                field: 'quantity',
                cellRenderer: (params) => {
                    if (params.value === null) {
                        return '0';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                },
                minWidth: 120,
                type: 'numericColumn'
            },
            {
                headerName: 'Amount',
                field: 'amount',
                cellRenderer: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$');
                    }
                },
                minWidth: 120,
                type: 'numericColumn'
            },
            {
                headerName: 'Contra',
                field: 'contraDtcId',
                filter: 'agSetColumnFilter',
                minWidth: 120
            },
            {
                headerName: 'Occurred At',
                field: 'transactTime',
                cellRenderer: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return moment.utc(params.value, 'X').tz('America/New_York').format('MM/DD hh:mm:ss');
                    }
                },
                sort: 'desc',
                minWidth: 120
            },
            {
                headerName: 'Reason Code',
                field: 'reason',
                filter: 'agSetColumnFilter',
                minWidth: 120
            },
            {
                headerName: 'Status',
                field: 'status',
                minWidth: 120
            },
            {
                headerName: 'Action',
                field: 'action',
                filter: 'agSetColumnFilter',
                minWidth: 120
            }
        ];
        this.defaultColDef = {
            enableCellChangeFlash: true,
            sortable: true,
            filter: true,
            resizable: true
        };
    }

    ngOnInit() {
        this.onSelectedSummarySymbolChangedSubscription = this.dtcSummaryFilterService.symbolSelected.subscribe(symbol => {
            this.summarySelectedSymbol = symbol;
            this.navTabService.saveSelectedSymbol(this.tabId, symbol);
            if (this.gridApi){
                this.setFilters(false);
            }
        });

        this.activatedRoute.params.subscribe(params => {
            this.tabId = params['id'];
            this.summarySelectedSymbol = params['selectedSymbol'];
            if (this.gridApi) {
                this.setFilters(false);
            }
        });
    }


    onGridReady(params): void {
        this.gridApi = params.api;
        this.dtcTransactionService.initializeService(
            this.dtcTransactionService.collectionName,
            this.dtcTransactionService.createTransactionCallback
        );

        this.onDepositoryNoChanged = this.switcherService.depositoryNoSwitched.subscribe(depositoryNo => {
            this.gridApi.setRowData([]);
            this.gridApi.setRowData(this.dtcTransactionService.collectionData.filter(dtcTransaction => this.depositoryFilter(dtcTransaction)));
        });

        this.onSummaryFilterChangedSubscription = this.dtcSummaryFilterService.filterChangedEvent.subscribe(filter => {
            if (filter.symbol.values && filter.symbol.values[0]) {
                this.summarySelectedSymbol = filter.symbol.values[0].toUpperCase();
            } else if (filter.cusip.values && filter.cusip.values[0]) {
                this.summarySelectedSymbol = filter.cusip.values[0].toUpperCase();
            } else {
                this.summarySelectedSymbol = 'none';
            }
            this.navTabService.saveSelectedSymbol(this.tabId, this.summarySelectedSymbol);

            const transactionsFilter = this.dtcTransactionsFilterService.currentFilter;

            this.navTabService.saveFilter(this.tabId, transactionsFilter ? transactionsFilter : {});
            this.setFilters(false);

            if (this.summarySelectedSymbol === 'none'){
                // clear transactions filter..
                this.dtcTransactionsFilterService.clearFilterControls(true);
            }
        });

        this.onTransactionsFilterChangedSubscription = this.dtcTransactionsFilterService.filterChangedEvent.subscribe(filter => {
            this.setFilters(false);
        });

        this.setupSubscriptions();
    }

    onGridSizeChanged(params) {
        // get the current grids width
        var gridWidth = document.getElementById('summary').offsetWidth;

        // keep track of which columns to hide/show
        var columnsToShow = [];
        var columnsToHide = [];

        // iterate over all columns (visible or not) and work out
        // now many columns can fit (based on their minWidth)
        var totalColsWidth = 0;
        var allColumns = params.columnApi.getAllColumns();
        for (var i = 0; i < allColumns.length; i++) {
            var column = allColumns[i];
            totalColsWidth += column.getMinWidth();
            if (totalColsWidth > gridWidth) {
                columnsToHide.push(column.colId);
            } else {
                columnsToShow.push(column.colId);
            }
        }

        params.api.sizeColumnsToFit();
    }

    setFilters(stateReloaded: boolean) {
        this.gridApi.setRowData([]);
        this.summarySelectedSymbol = this.navTabService.getSelectedSymbol(this.tabId);
        this.gridApi.setRowData(this.dtcTransactionService.collectionData.filter(dtcTransaction => this.depositoryFilter(dtcTransaction)));

        const transactionsFilter = this.dtcTransactionsFilterService.currentFilter;
        this.navTabService.saveFilter(this.tabId, transactionsFilter ? transactionsFilter  : {});

        if (stateReloaded) {
            setTimeout(() => { this.gridApi.setFilterModel(transactionsFilter); }, 50);
        }
        else {
            this.gridApi.setFilterModel(transactionsFilter);
        }
    }

    depositoryFilter(dtcTransaction: DtcTransaction): boolean {
        if (this.summarySelectedSymbol) {
            return (dtcTransaction.dtcId === this.switcherService.getSelectedDepositoryNo() &&
                (dtcTransaction.symbol === this.summarySelectedSymbol || dtcTransaction.cusip === this.summarySelectedSymbol));
        } else {
            return false;
        }
    }

    getRowNodeId(data) {
        return data.dtcTranId;
    }

    ngOnDestroy(): void {
        this.onDepositoryNoChanged.unsubscribe();
        this.onDTCTransactionAdded.unsubscribe();
        this.onDTCTransactionChanged.unsubscribe();
        this.onDTCTransactionRemoved.unsubscribe();
        this.onSummaryFilterChangedSubscription.unsubscribe();
        this.onTransactionsFilterChangedSubscription.unsubscribe();
        this.onSelectedSummarySymbolChangedSubscription.unsubscribe();

        this.dtcTransactionService.clearSubscriptions();
    }

    setupSubscriptions() {
        this.setFilters(true);

        this.onDTCTransactionAdded = this.dtcTransactionService.onCollectionAdded.subscribe(dtcTransactions => {
            const newSummaries: DtcTransaction[] = [];
            for (const dtcTransaction of dtcTransactions.filter(t => this.depositoryFilter(t))) {
                const rowNode = this.gridApi.getRowNode(dtcTransaction.primaryKey);
                if (rowNode === undefined) {
                    newSummaries.push(dtcTransaction);
                }
            }
            this.gridApi.updateRowData({
                add: newSummaries
            });

        });

        this.onDTCTransactionChanged = this.dtcTransactionService.onCollectionChanged.subscribe(dtcTransactions => {
            this.gridApi.updateRowData({update: dtcTransactions.filter(t => this.depositoryFilter(t))});
        });
        this.onDTCTransactionRemoved = this.dtcTransactionService.onCollectionRemoved.subscribe(dtcTransactions => {
            this.gridApi.updateRowData({remove: dtcTransactions.filter(t => this.depositoryFilter(t))});
            this.gridApi.flashCells();
        });
    }
}
