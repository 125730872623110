import {Component, Inject, LOCALE_ID, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialog} from '@angular/material';
import {Subscription} from 'rxjs';
import 'ag-grid-community';
import {fuseAnimations} from '@fuse/animations';


import {HttpClient} from '@angular/common/http';
import {formatCurrency, formatNumber} from '@angular/common';
import {ContractsService} from '../contracts/contracts.service';
import {UserService} from '../../../../auth/user/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {RowNode} from 'ag-grid-community';
import {Contract} from '../contracts/contract.model';
import {DtcSummaryService} from '../../dtc/dtc-summary/dtc-summary.service';
import {NavTabComponent} from '../../../nav-tab/nav-tab.component';
import {NavTabService} from '../../../nav-tab/nav-tab.service';
import {NavTabItem} from '../../../nav-tab/nav-tab-item';
import {ContractDetailPreferences} from '../contracts/contract-detail-preferences/contract-detail-preferences';
import {SwitcherService} from '../../../../shared/switcher/switcher.service';

@Component({
    selector: 'fuse-positions',
    templateUrl: './positions.component.html',
    styleUrls: ['./positions.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class PositionsComponent implements OnInit, OnDestroy {

    private gridApi;
    gridColumnApi;
    public init = false;
    public collapse = false;

    // contracts: any;
    user: any;

    rowSelection = 'single';

    onContractsAddedSubscription: Subscription;
    onContractsChangedSubscription: Subscription;
    onContractsRemovedSubscription: Subscription;
    onUserDataChangedSubscription: Subscription;
    onDepositoryNoSwitchedSubscription: Subscription;
    onPositionModified: Subscription;

    dialogRef: any;
    selectedGrouping = 'cusip';
    pinnedBottomRowData;

    getRowStyle;

    // summaryRowData: any[] = [];
    columnDefs: any[] = [];

    autoGroupColumnDef = {
        headerName: 'Symbol',
        minWidth: 175,
        resizable: false,
        pinned: true,
        filter: 'agTextColumnFilter',
        field: 'symbol',
        cellRendererParams: {
            suppressCount: true
        },
        valueFormatter: (params) => params.value
    };

    getContextMenuItems;
    sideBar = {
        toolPanels: ['columns'],
        defaultToolPanel: ''
    };

    defaultColDef = {};

    constructor(
        public contractsService: ContractsService,
        public dtcPositionService: DtcSummaryService,
        public userService: UserService,
        public dialog: MatDialog,
        private httpClient: HttpClient,
        @Inject(LOCALE_ID) private locale: string,
        public navTabService: NavTabService,
        public activatedRoute: ActivatedRoute,
        public switcherService: SwitcherService
    ) {
        this.defaultColDef = {
            enableCellChangeFlash: true,
            sortable: true,
            filter: true,
            resizable: true,

            cellStyle: PositionsComponent.styleZero,
            floatingFilterComponentParams: {
                suppressFilterButton: true,
                color: 'red'
            }
        };

        this.getRowStyle = (params) => {
            if (params.node.rowPinned) {
                return {'font-weight': 'bold'};
            }
        };

        this.getContextMenuItems = function getContextMenuItems(params) {
            return [
                {
                    name: 'Export to CSV',
                    action: function () {
                        params.api.exportDataAsCsv({
                            shouldRowBeSkipped: function (row) {
                                if (!!row.node.data) {
                                    return true;
                                } else {
                                    // return (row.node.field === this.grouping);
                                    return row.node.level !== 0;
                                }
                            },
                            processCellCallback: function (row) {
                                if (typeof row.value === 'string') {
                                    let updateValue: string = row.value;
                                    updateValue = updateValue.replace(/->/g, ' ').trim();
                                    row.value = updateValue;
                                }
                                return row.value;
                            }
                        });
                    }
                },
                {
                    name: 'Export to Excel',
                    action: function () {
                        params.api.exportDataAsExcel({
                            shouldRowBeSkipped: function (row) {
                                if (!!row.node.data) {
                                    return true;
                                } else {
                                    // return (row.node.field === this.grouping);
                                    return row.node.level !== 0; // false; // row.api.selectedGrouping !== row.node.field;
                                }
                            },
                            processCellCallback: function (row) {
                                if (typeof row.value === 'string') {
                                    let updateValue: string = row.value;
                                    updateValue = updateValue.replace(/->/g, ' ').trim();
                                    row.value = updateValue;
                                }
                                return row.value;
                            }
                        });
                    }
                },
            ];
        };
    }


    ngOnInit() {
        // this.activatedRoute.params.subscribe(params => {
        //    this.currentDtcId = params['dtcId'];
        //    this.setupContractSubscriptions(this.currentDtcId);
        // });
        // this.onDepositoryNoSwitchedSubscription = this.switcherService.depositoryNoSwitched.subscribe(depositoryNo => {
        //     this.gridApi.setRowData([]);
        //     this.gridApi.rowData = this.contractsService.currentCollection;
        // });
        this.onDepositoryNoSwitchedSubscription = this.switcherService.depositoryNoSwitched.subscribe(sub => {
            this.gridApi.setRowData([]);
            this.gridApi.setRowData(this.contractsService.currentCollection.filter((c => this.depositoryFilter(c))));
        });
    }

    autoSizeAll(event) {
        this.gridColumnApi = event.columnApi;
        const allColumnIds = [];
        const columns = this.gridColumnApi.getAllColumns();
        if (columns !== undefined) {
            this.gridColumnApi.getAllColumns().forEach(function (column) {
                allColumnIds.push(column.colId);
            });
            this.gridColumnApi.autoSizeColumns(allColumnIds);
        }
    }

    onGridReady(params) {
        this.gridApi = params.api;
        // this.gridColumnApi = params.columnApi;
        this.gridApi.dtcPositionService = this.dtcPositionService;
        // this.contractsService.gridApi = params.api;
        this.contractsService.init = true;
        this.init = true;
        this.setupContractSubscriptions();
        // this.dtcPositionService.addComponentGridApi(this.gridApi);
        this.dtcPositionService.initializeService(
            this.dtcPositionService.collectionName,
            this.dtcPositionService.createSummaryCallback
        );
        this.defineColumns();

        // this.gridApi.sizeColumnsToFit();
        // this.summarize();
        // this.gridApi.selectedGrouping = this.selectedGrouping;
        // this.autoSizeAll(params);
        this.aggregateContracts();
    }

    depositoryFilter(contract: Contract): boolean {
        return contract.depositoryNo == this.switcherService.getSelectedDepositoryNo();
    }

    contractAdded(contracts: Contract[]) {
        if (this.gridApi) {
            const newRows: RowNode[] = [];
            const newContracts: Contract[] = [];
            for (const contract of contracts.filter(c => this.depositoryFilter(c))) {
                const rowNode = this.gridApi.getRowNode(contract.contractId);
                if (rowNode === undefined) {
                    newContracts.push(contract);
                }
            }
            this.gridApi.updateRowData({
                add: newContracts
            });
            for (const contract of newContracts) {
                const rowNode = this.gridApi.getRowNode(String(contract.primaryKey));
                newRows.push(rowNode);
            }
            this.gridApi.flashCells({
                rowNodes: newRows
            });
            this.aggregateContracts();
        }
    }

    contractChanged(contracts: Contract[]) {
        if (this.gridApi) {
            this.gridApi.updateRowData({
                update: contracts.filter(c => this.depositoryFilter(c))
            });
        }
        this.aggregateContracts();
    }

    contractRemoved(contracts: Contract[]) {
        if (this.gridApi) {
            this.gridApi.updateRowData({
                remove: contracts.filter(c => this.depositoryFilter(c))
            });
        }
        this.aggregateContracts();
    }

    setupContractSubscriptions() {
        this.gridApi.setRowData([]);
        this.gridApi.setRowData(this.contractsService.currentCollection.filter((c => this.depositoryFilter(c))));
        // this.onContractsAddedSubscription =
        //     this.contractsService.onCollectionAdded.subscribe(contracts => {
        //         if (this.gridApi) {
        //             const newRows: RowNode[] = [];
        //             const newContracts: Contract[] = [];
        //             for (const contract of contracts) {
        //                 const rowNode = this.gridApi.getRowNode(contract.contractId);
        //                 if (rowNode === undefined) {
        //                     newContracts.push(contract);
        //                 }
        //             }
        //             this.gridApi.updateRowData({
        //                 add: newContracts
        //             });
        //             for (const contract of newContracts) {
        //                 const rowNode = this.gridApi.getRowNode(String(contract.primaryKey));
        //                 newRows.push(rowNode);
        //             }
        //             this.gridApi.flashCells({
        //                 rowNodes: newRows
        //             });
        //             this.aggregateContracts();
        //         }
        //     });

        // this.onContractsChangedSubscription =
        //     this.contractsService.onCollectionChanged.subscribe(contracts => {
        //         if (this.gridApi) {
        //             this.gridApi.updateRowData({
        //                 update: contracts
        //             });
        //         }
        //         this.aggregateContracts();
        //     });

        //
        // this.onContractsRemovedSubscription =
        //     this.contractsService.onCollectionRemoved.subscribe(contracts => {
        //         if (this.gridApi) {
        //             this.gridApi.updateRowData({
        //                 remove: contracts
        //             });
        //         }
        //         this.aggregateContracts();
        //     });
        //

        this.onContractsAddedSubscription =
            this.contractsService.onCollectionAdded.subscribe(contracts => this.contractAdded(contracts));

        this.onContractsChangedSubscription =
            this.contractsService.onCollectionChanged.subscribe(contracts => this.contractChanged(contracts));

        this.onContractsRemovedSubscription =
            this.contractsService.onCollectionRemoved.subscribe(contracts => this.contractRemoved(contracts));

        this.onUserDataChangedSubscription =
            this.contractsService.onUserDataChanged.subscribe(user => {
                this.user = user;
            });

        this.onPositionModified =
            this.dtcPositionService.mapAlert
                .subscribe(
                    () => {
                        this.gridApi.refreshClientSideRowModel('aggregate');
                    }
                );
    }

    onRowDoubleClicked(event) {
        if (event.colDef) {
            if (event.colDef.headerName === 'Symbol') {
                return;
            }
        }
        // filterType: 'text',
        // //                 type: 'equals'
        //
        if (event.node) {
            const symbol = (event.node.aggData || event.node.data).symbol || event.node.key;
            const depositoryNo = (event.node.aggData || event.node.data).depositoryNo || event.node.parent.key;
            let navTabItem = new NavTabItem('contracts', symbol, 'item', 'test',
                '/contracts', true, null, new ContractDetailPreferences({
                    name: symbol, filter: {
                        symbol: {
                            type: 'equals',
                            filter: symbol,
                            filterType: 'text'
                        }
                    }
                }));

            this.navTabService.addTab(navTabItem);
            //
            // this.router.navigate(['/contracts/#@!#'], {
            //     replaceUrl: true,
            //     queryParams: {
            //         symbol: symbol,
            //     }
            // });
        }
    };

    groupContractAgg(nodes) {

        const result = {
            borrowRate: 0,
            loanRate: 0,
            spread: 0,
            borrowQuantity: 0,
            loanQuantity: 0,
            imbalance: 0,
            borrowAmount: 0,
            loanAmount: 0,
            cash: 0,
            dailyRebate: 0,
            pendingBorrowRecall: 0,
            pendingLoanRecall: 0,
            pendingRecall: 0,
            pendingBorrowReturn: 0,
            pendingLoanReturn: 0,
            pendingReturn: 0,
            pending: 0,
            symbol: '',
            dtcQuantity: 0,
            madeNewBorrow: 0,
            madeNewLoan: 0,
            madeReturnBorrow: 0,
            madeReturnLoan: 0,
        };
        nodes.forEach(function (node) {
            const data = node.group ? node.aggData : node.data;
            if (node.data) {
                if (data.contractStatus !== 'Warning' && data.contractStatus !== 'Open') {
                    return;
                }
                if (data.side === 'B') {
                    result.borrowRate += (data.borrowQuantity * data.rate);

                    result.pendingBorrowRecall += data.pendingRecall;
                    result.pendingBorrowReturn += data.pendingReturn;
                } else {
                    result.loanRate += (data.loanQuantity * data.rate);
                    result.pendingLoanRecall += data.pendingRecall;
                    result.pendingLoanReturn += data.pendingReturn;
                }

                if (node.gridApi.dtcPositionService) {
                    const position = node.gridApi.dtcPositionService.getSummary(data.cusip, data.depositoryNo);
                    result.dtcQuantity = position.quantity;
                    result.madeNewBorrow = position.madeNewBorrow;
                    result.madeNewLoan = position.madeNewLoan;
                    result.madeReturnBorrow = position.madeReturnBorrow;
                    result.madeReturnLoan = position.madeReturnLoan;
                }
            } else {
                result.borrowRate += (data.borrowQuantity * data.borrowRate);
                result.loanRate += (data.loanQuantity * data.loanRate);
            }
            result.cash += data.cash;
            result.dailyRebate += data.dailyRebate;
            result.borrowQuantity += data.borrowQuantity;
            result.loanQuantity += data.loanQuantity;
            result.imbalance = result.borrowQuantity - result.loanQuantity;
            result.borrowAmount += data.borrowAmount;
            result.loanAmount += data.loanAmount;
            // result.pendingReturn += data.pendingReturn;

            result.symbol = data.symbol;

        });
        // moved outside loop to correct summary bug
        result.pendingReturn += result.pendingBorrowReturn - result.pendingLoanReturn;
        result.pendingRecall += result.pendingLoanRecall - result.pendingBorrowRecall;
        // result.dailyRebate += result.dailyRebate;

        if (result.borrowRate !== 0) {
            result.borrowRate = result.borrowRate / (result.borrowQuantity);
        }
        if (result.loanRate !== 0) {
            result.loanRate = result.loanRate / (result.loanQuantity);
        }
        result.spread = result.loanRate - result.borrowRate;
        result.cash = result.loanAmount - result.borrowAmount;
        result.pending = result.pendingRecall - result.pendingReturn;

        return result;
    }


    aggregateContracts() {
        const result = {
            borrowRate: 0,
            loanRate: 0,
            spread: 0,
            borrowQuantity: 0,
            loanQuantity: 0,
            imbalance: 0,
            borrowAmount: 0,
            loanAmount: 0,
            cash: 0,
            dailyRebate: 0,
            pendingBorrowRecall: 0,
            pendingLoanRecall: 0,
            pendingRecall: 0,
            pendingBorrowReturn: 0,
            pendingLoanReturn: 0,
            pendingReturn: 0,
            pending: 0,
            symbol: '',
            dtcQuantity: 0,
            madeNewBorrow: 0,
            madeNewLoan: 0,
            madeReturnBorrow: 0,
            madeReturnLoan: 0,
        };

        this.gridApi.forEachNodeAfterFilter(function (node, index) {
            if (node.group) {
                return;
            }
            const data = node.group ? node.aggData : node.data;
            if (node.data) {
                if (data.contractStatus !== 'Warning' && data.contractStatus !== 'Open') {
                    return;
                }
                if (data.side === 'B') {
                    result.borrowRate += (data.borrowQuantity * data.rate);
                    result.pendingBorrowRecall += data.pendingRecall;
                    result.pendingBorrowReturn += data.pendingReturn;
                } else {
                    result.loanRate += (data.loanQuantity * data.rate);
                    result.pendingLoanRecall += data.pendingRecall;
                    result.pendingLoanReturn += data.pendingReturn;
                }

                if (node.gridApi.dtcPositionService) {
                    const position = node.gridApi.dtcPositionService.getSummary(data.cusip, data.depositoryNo);
                    result.dtcQuantity = position.quantity;
                    result.madeNewBorrow = position.madeNewBorrow;
                    result.madeNewLoan = position.madeNewLoan;
                    result.madeReturnBorrow = position.madeReturnBorrow;
                    result.madeReturnLoan = position.madeReturnLoan;
                }
            } else {
                result.borrowRate += (data.borrowQuantity * data.borrowRate);
                result.loanRate += (data.loanQuantity * data.loanRate);
            }
            result.cash += data.cash;
            result.dailyRebate += data.dailyRebate;
            result.borrowQuantity += data.borrowQuantity;
            result.loanQuantity += data.loanQuantity;
            result.imbalance = result.borrowQuantity - result.loanQuantity;
            result.borrowAmount += data.borrowAmount;
            result.loanAmount += data.loanAmount;
            // result.pendingReturn += data.pendingReturn;
            result.symbol = data.symbol;

        });
        // moved outside loop to correct summary bug
        result.pendingReturn += result.pendingBorrowReturn - result.pendingLoanReturn;
        result.pendingRecall += result.pendingLoanRecall - result.pendingBorrowRecall;
        // result.dailyRebate += result.dailyRebate;
        if (result.borrowRate !== 0) {
            result.borrowRate = result.borrowRate / (result.borrowQuantity);
        }
        if (result.loanRate !== 0) {
            result.loanRate = result.loanRate / (result.loanQuantity);
        }
        result.spread = result.loanRate - result.borrowRate;
        result.cash = result.loanAmount - result.borrowAmount;
        result.pending = result.pendingRecall - result.pendingReturn;

        this.pinnedBottomRowData = result;
        this.updatePinnedRows();
    }


    updatePinnedRows() {
        const rows = [];
        rows.push({
            symbol: 'Total',
            borrowRate: this.pinnedBottomRowData.borrowRate,
            loanRate: this.pinnedBottomRowData.loanRate,
            spread: this.pinnedBottomRowData.spread,
            borrowQuantity: this.pinnedBottomRowData.borrowQuantity,
            loanQuantity: this.pinnedBottomRowData.loanQuantity,
            imbalance: this.pinnedBottomRowData.imbalance,
            borrowAmount: this.pinnedBottomRowData.borrowAmount,
            loanAmount: this.pinnedBottomRowData.loanAmount,
            cash: this.pinnedBottomRowData.cash,
            dailyRebate: this.pinnedBottomRowData.dailyRebate,
            pendingBorrowRecall: 0,
            pendingLoanRecall: 0,
            pendingRecall: this.pinnedBottomRowData.pendingRecall,
            pendingBorrowReturn: 0,
            pendingLoanReturn: 0,
            pendingReturn: this.pinnedBottomRowData.pendingReturn,
            pending: 0,
            dtcQuantity: 0,
            madeNewBorrow: 0,
            madeNewLoan: 0,
            madeReturnBorrow: 0,
            madeReturnLoan: 0
        });

        this.gridApi.setPinnedBottomRowData(rows);
    }

    autoSize(event) {
        this.gridApi = event.api;
        this.gridApi.sizeColumnsToFit();
    }

    defineColumns() {

        this.columnDefs = [
            // {headerName: 'Acct', field: 'depositoryNo', enableRowGroup: true, rowGroup: true, filter: 'agTextColumnFilter', filterParams: {newRowsAction: 'keep'}},
            {
                headerName: 'Symbol', field: 'symbol', filterParams: {
                    defaultOption: 'equals', newRowsAction: 'keep'
                }, filter: 'agTextColumnFilter',
                sort: 'asc', rowGroup: true, showRowGroup: 'symbol', enableRowGroup: true, minWidth: 250,
                hide: true, resizable: true, pinned: true, valueFormatter: '', cellStyle: {'color': 'blue'}
            },
            {
                headerName: 'Cusip', field: 'cusip', filterParams: {
                    defaultOption: 'equals', newRowsAction: 'keep'
                }, filter: 'agTextColumnFilter', hide: true
            },
            {
                headerName: 'Cusip', field: 'cusip', filterParams: {
                    defaultOption: 'equals', newRowsAction: 'keep'
                }, filter: 'agTextColumnFilter',
                hide: true, cellStyle: {'color': 'blue'}
            },
            {
                headerName: 'Contra', field: 'counterPartyName', filterParams: {
                    defaultOption: 'contains', newRowsAction: 'keep'
                }, filter: 'agTextColumnFilter',
                showRowGroup: 'counterPartyName', enableRowGroup: true, hide: true, valueFormatter: ''
            },
            {
                headerName: 'DTC', field: 'contraDepositoryNo', width: 80, suppressSizeToFit: true, filter: 'agTextColumnFilter',
                filterParams: {newRowsAction: 'keep'}, enableRowGroup: true, hide: true, valueFormatter: ''
            },
            {
                headerName: 'PRC', field: 'profitCenter', enableRowGroup: true, hide: true, valueFormatter: ''
            },
            {
                headerName: 'Rates',
                marryChildren: true,
                children: [
                    {
                        headerName: 'Borrow', field: 'borrowRate', type: 'numericColumn', minWidth: 92, cellStyle: {color: 'inherit'},
                        valueFormatter: (params) => {
                            if (params.value === undefined) {
                                return 0;
                            } else {
                                return formatCurrency(params.value, this.locale, '');
                            }
                        },
                        filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}
                    },
                    {
                        headerName: 'Loan', field: 'loanRate', type: 'numericColumn', minWidth: 90, cellStyle: {color: 'inherit'},
                        valueFormatter: (params) => {
                            if (params.value === undefined) {
                                return 0;
                            } else {
                                return formatCurrency(params.value, this.locale, '');
                            }
                        },
                        filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}
                    },
                    {
                        headerName: 'Spread', field: 'spread', type: 'numericColumn', minWidth: 90, cellStyle: {color: 'inherit'},
                        valueFormatter: (params) => {
                            if (params.value === undefined) {
                                return 0;
                            } else {
                                return formatCurrency(params.value, this.locale, '');
                            }
                        }, filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}
                    },
                ]
            },
            {
                headerName: 'Amount',
                marryChildren: true,
                children: [

                    {
                        headerName: 'Borrow', field: 'borrowAmount', type: 'numericColumn', filter: 'agNumberColumnFilter', minWidth: 105,
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return 0;
                            } else {
                                return formatCurrency(params.value, this.locale, '$', undefined, '1.0-0');
                            }
                        }, filterParams: {newRowsAction: 'keep'}
                    },
                    {
                        headerName: 'Loan', field: 'loanAmount', type: 'numericColumn', filter: 'agNumberColumnFilter', minWidth: 105,
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return 0;
                            } else {
                                return formatCurrency(params.value, this.locale, '$', undefined, '1.0-0');
                            }
                        }, filterParams: {newRowsAction: 'keep'}
                    },
                    {
                        headerName: 'Cash', field: 'cash', type: 'numericColumn', filter: 'agNumberColumnFilter', minWidth: 105, cellStyle: PositionsComponent.styleNegZero,
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return '';
                            } else {
                                return formatCurrency(params.value, this.locale, '$', undefined, '1.0-0');
                            }
                        },

                        filterParams: {newRowsAction: 'keep'}
                    },
                    {
                        headerName: 'Rebate', field: 'dailyRebate', type: 'numericColumn', filter: 'agNumberColumnFilter',
                        minWidth: 105, cellStyle: PositionsComponent.styleNegZero,
                        valueFormatter: (params) => {
                            if (params.value === undefined) {
                                return '';
                            } else {
                                return formatCurrency(params.value, this.locale, '$');
                            }
                        }, filterParams: {newRowsAction: 'keep'}
                    },
                ]
            },
            {
                headerName: 'Quantity',
                marryChildren: true,
                children: [
                    {
                        headerName: 'Borrow', field: 'borrowQuantity', minWidth: 105,
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return 0;
                            } else {
                                return formatNumber(params.value, this.locale);
                            }
                        }, type: 'numericColumn', filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}
                    },
                    {
                        headerName: 'Loan', field: 'loanQuantity', minWidth: 105,
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return 0;
                            } else {
                                return formatNumber(params.value, this.locale);
                            }
                        }, type: 'numericColumn', filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}
                    },
                    {
                        headerName: 'Imbal.', field: 'imbalance', headerToolTip: 'Imbalance = (Borrow - Loan) quantity', minWidth: 100, cellStyle: PositionsComponent.styleNegZero,
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return 0;
                            } else {
                                return formatNumber(params.value, this.locale);
                            }
                        }, type: 'numericColumn', filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}

                    }
                ]
            }
        ];

        this.columnDefs.push(
            {
                headerName: 'DTC',
                marryChildren: true,
                children: [
                    {
                        headerName: 'Qty', field: 'dtcQuantity',
                        type: 'numericColumn',
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return 0;
                            } else {
                                return formatNumber(params.value, this.locale);
                            }
                        },
                        filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}, cellStyle: PositionsComponent.styleNegZero
                    },
                    {
                        headerName: 'NB', headerTooltip: 'Made New Borrow',
                        field: 'madeNewBorrow',
                        type: 'numericColumn',
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return 0;
                            } else {
                                return formatNumber(params.value, this.locale);
                            }
                        },
                        filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}, cellStyle: PositionsComponent.styleNegZero
                    },
                    {
                        headerName: 'NL', headerTooltip: 'Made New Loan',
                        field: 'madeNewLoan',
                        type: 'numericColumn', filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}, cellStyle: PositionsComponent.styleNegZero
                    },
                    {
                        headerName: 'RB', headerTooltip: 'Made Return Borrow',
                        field: 'madeReturnBorrow',
                        type: 'numericColumn',
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return 0;
                            } else {
                                return formatNumber(params.value, this.locale);
                            }
                        },
                        filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}, cellStyle: PositionsComponent.styleNegZero
                    },
                    {
                        headerName: 'RL', headerTooltip: 'Made Return Loan',
                        field: 'madeReturnLoan',
                        type: 'numericColumn',
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return 0;
                            } else {
                                return formatNumber(params.value, this.locale);
                            }
                        },
                        filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}, cellStyle: PositionsComponent.styleNegZero
                    }
                ]
            }
        );

        this.columnDefs.push(
            {
                headerName: 'Activity',
                marryChildren: true,
                children: [
                    {
                        headerName: 'Recall', field: 'pendingRecall', filter: 'agNumberColumnFilter',
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return 0;
                            } else {
                                return formatNumber(params.value, this.locale);
                            }
                        },
                        type: 'numericColumn',
                        minWidth: 88, cellStyle: PositionsComponent.styleNegZero,
                        filterParams: {
                            defaultOption: 'greaterThan',
                            newRowsAction: 'keep'
                        }

                    },
                    {
                        headerName: 'Return', field: 'pendingReturn', filter: 'agNumberColumnFilter', type: 'numericColumn',
                        valueFormatter: (params) => {
                            if (params.value === null) {
                                return 0;
                            } else {
                                return formatNumber(params.value, this.locale);
                            }
                        },
                        minWidth: 90, cellStyle: PositionsComponent.styleNegZero,
                        filterParams: {
                            defaultOption: 'greaterThan', newRowsAction: 'keep'
                        }
                    }
                ]
            });

        this.gridApi.selectedGrouping = this.selectedGrouping;
    }

    getRowNodeId(data) {
        return data.contractId;
    }

    toggleTradeWindow() {
        this.contractsService.toggleTradeWindow();
    }

    static styleZero(params) {
        if (params.value === 0) {
            return {'color': '#bfbfbf'};
        } else {
            return {'color': 'inherit'};
        }
    }

    static styleNegZero(params) {
        if (params.value === 0) {
            return {'color': '#bfbfbf'};
        } else if (params.value < 0) {
            return {'color': 'red'};
        } else if (params.value > 0) {
            return {'color': 'inherit'};
        }
    }

    ngOnDestroy() {
        this.onContractsAddedSubscription.unsubscribe();
        this.onContractsChangedSubscription.unsubscribe();
        this.onContractsRemovedSubscription.unsubscribe();
        this.onUserDataChangedSubscription.unsubscribe();
        this.onPositionModified.unsubscribe();
        this.onDepositoryNoSwitchedSubscription.unsubscribe();
        this.gridApi.setRowData([]);
        this.gridApi.destroy();
    }

    onFilterChanged(event) {
        this.aggregateContracts();
    }

    onRowDataChanged(event) {
        if (this.gridApi) {
            this.aggregateContracts();
        }
    }
}


// https://www.ag-grid.com/javascript-grid-provided-renderer-change/

