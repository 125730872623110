import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../../../auth/auth.guard';
import {MatButtonModule, MatDividerModule, MatIconModule, MatInputModule, MatSidenavModule, MatDatepickerModule, MatSlideToggleModule, MatSelectModule} from '@angular/material';
import {FuseSharedModule} from '../../../../../@fuse/shared.module';
import {SecurityMasterComponent} from './securitymaster.component';

const routes: Routes = [
    {
        path     : 'securitymaster',
        component: SecurityMasterComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [
        SecurityMasterComponent
    ],
    imports: [
        CommonModule,
        FuseSharedModule,
        RouterModule.forChild(routes),
        MatSidenavModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatDividerModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatSelectModule,
    ]
})
export class SecurityMasterModule {
}
