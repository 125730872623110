export class Status {
    statusId: number;
    name: string;
    description: string;
    abbr: string;

    constructor(status?) {
        status = status || {};
        this.statusId = status.statusId || 1;
        this.name = status.name || '';
        this.description = status.description || '';
        this.abbr = status.abbr || '';
    }
}
