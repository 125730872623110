import {Injectable} from '@angular/core';
import {AngularFirestore} from 'angularfire2/firestore';
import {CounterParty} from '../operations/counterparties/counterparty.model';
import {CounterPartyAccount} from '../operations/accounts/account.model';
import {CounterpartyService} from '../operations/counterparties/counterparty.service';
import {Restriction} from './restriction.model';

@Injectable({
    providedIn: 'root'
})
export class ResourcesService {

    counterpartiesCollectionName = 'resources/valid/counter-parties';
    restrictionsCollectionName = 'resources/valid/restrictions'

    counterParties: CounterParty[];
    restrictions: Restriction[];

    constructor(private firestoreClient: AngularFirestore,
                private counterpartyService: CounterpartyService) {
    }

    public initializeResources() {
        // initialize counter-parties
        this.getCounterParties().subscribe(data => {

            const combinedCounterParties = data.map(e => {
                return new CounterParty(e.payload.doc.data());
            });
            const internalCounterParties = combinedCounterParties.filter(counterParty => counterParty.internal === true);
            const externalCounterParties = combinedCounterParties.filter(counterParty => counterParty.internal === false);

            this.counterpartyService.internalCounterParties = internalCounterParties;
            this.counterpartyService.externalCounterParties = externalCounterParties;
            this.counterpartyService.combinedCounterParties = combinedCounterParties;
        });

        this.getRestrictions().subscribe(data =>{
            this.restrictions = data.map(e => {
                return new Restriction(e.payload.doc.data());
            });
        });
    }

    public getCounterParties(): any {
        return this.firestoreClient.collection(this.counterpartiesCollectionName).snapshotChanges();
    }

    public getRestrictions(): any{
      return this.firestoreClient.collection(this.restrictionsCollectionName).snapshotChanges();
    }

}
