import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../../../auth/auth.guard';
import {FuseSharedModule} from '../../../../../@fuse/shared.module';
import {AgGridModule} from 'ag-grid-angular';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import {RecallHoldingService} from './recallholding.service';
import {RecallAllocationComponent} from './recall-allocation/recall-allocation.component';
import {RecallDetailService} from './recall-allocation/recall-detail.service';
import {RecallsComponent} from './recalls.component';

const routes: Routes = [
    {
        path     : 'recalls',
        component: RecallsComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'recalls/:recallId/allocation',
        component: RecallAllocationComponent,
        canActivate: [AuthGuard],
        resolve: {
            return: RecallDetailService
        }
    }
];

@NgModule({
  imports: [
    CommonModule,
      RouterModule.forChild(routes),
      AgGridModule.withComponents([]),
      FuseSharedModule,
      MatIconModule,
      MatButtonModule,
      MatTableModule,
      MatFormFieldModule,
      MatInputModule,
      MatCheckboxModule,
      MatSelectModule
  ],
  declarations: [
      RecallsComponent,
      RecallAllocationComponent
  ],
    providers: [
        RecallHoldingService,
        RecallDetailService
    ],
    entryComponents: [
        RecallAllocationComponent
    ]
})
export class RecallsModule { }
