import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../../../auth/auth.guard';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule, MatDividerModule,
    MatIconModule,
    MatInputModule,
    MatProgressBarModule,
    MatSelectModule, MatSidenavModule,
    MatTabsModule,
} from '@angular/material';
import {FuseSharedModule} from '../../../../../@fuse/shared.module';
import {FplService} from './fpl.service';
import {AgGridModule} from 'ag-grid-angular';
import {FplAccountsComponent} from './fplAccounts.component';
import { FplAccountsListComponent } from './fpl-accounts-list/fpl-accounts-list.component';
import { FplAccountsDetailComponent } from './fpl-accounts-detail/fpl-accounts-detail.component';
import {FplAccountPositionsComponent} from './fplAccountPositions.component';
import {BorrowButtonRenderer} from './borrowbutton.component';
import {FplTradeFormComponent} from '../../trading/trade-form/fpl-trade-form.component';
import {SharedModule} from '../../../../shared';
import {ReturnButtonRenderer} from './returnbutton.component';
import {FplReturnFormComponent} from '../../trading/returns/fpl-return-form.component';
import {FplSlsAccountDropDownComponent} from './fplSlsAccountDropDown.component';
import {FplSlsAccountRendererComponent} from './fplSlsAccountDropDown.component';
import {FuseContractsModule} from '../../trading/contracts/contracts.module';

const routes: Routes = [
    {
        path: 'fpl/accounts',
        component: FplAccountsComponent,
        canActivate: [AuthGuard],
        resolve: {
            counterParties: FplService
        }
    },
    {
        path     : 'fpl/accounts/:fplAccountId',
        component: FplAccountsDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path     : 'fpl/accountPositions',
        component: FplAccountPositionsComponent,
        canActivate: [AuthGuard]
    }
];


@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatIconModule,
        FuseSharedModule,
        MatCardModule,
        MatProgressBarModule,
        AgGridModule.withComponents([BorrowButtonRenderer, ReturnButtonRenderer, FplSlsAccountDropDownComponent, FplSlsAccountRendererComponent]),
        MatTabsModule,
        MatButtonModule,
        MatInputModule,
        MatCheckboxModule,
        MatSelectModule,
        MatSidenavModule,
        MatDividerModule,
        SharedModule,
        MatAutocompleteModule,
        FuseContractsModule
    ],
    declarations: [
        FplAccountsComponent,
        FplAccountsListComponent,
        FplAccountsDetailComponent,
        FplAccountPositionsComponent,
        BorrowButtonRenderer,
        ReturnButtonRenderer,
        FplTradeFormComponent,
        FplReturnFormComponent,
        FplSlsAccountDropDownComponent,
        FplSlsAccountRendererComponent
    ],
    providers: [
        FplService
    ],
    exports: [
        FplAccountsComponent,
    ],
    entryComponents: [
        FplTradeFormComponent,
        FplReturnFormComponent
    ]
})
export class FplModule {
}
