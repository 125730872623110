import {AfterViewInit, Component, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {EventEmitter} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {CounterParty} from '../../operations/counterparties/counterparty.model';
import {LimitProfile} from '../../operations/limitprofiles/limitprofile.model';
import {RoundRule} from '../../operations/constants/roundrules/roundrule.model';
import {RoundRulesService} from '../../operations/constants/roundrules/round-rules.service';
import {CounterPartyAccount} from '../../operations/accounts/account.model';
import {CounterpartyService} from '../../operations/counterparties/counterparty.service';
import {ConstantsService} from '../../../../shared/constants/constants.service';

@Component({
    selector: 'app-quick-trade',
    templateUrl: './quick-trade.component.html',
    styleUrls: ['./quick-trade.component.scss']
})

export class QuickTradeComponent implements OnInit, OnDestroy {

    showAdditionalDetails = false;
    side: string;
    contra: string;
    profitCenter: string;
    margin: number;
    divRate: number;
    rounding: number;
    specFlag: string;
    batchCode: string;
    contraAccounts: Observable<CounterParty[]>;

    importForm: FormGroup;
    counterPartyLimit: LimitProfile;
    selectedRoundRule: RoundRule;


    constructor(private formBuilder: FormBuilder,
                private roundRulesService: RoundRulesService,
                private counterpartiesService: CounterpartyService,
                private constantsService: ConstantsService) {

        this.importForm = this.createImportForm();

        this.importForm.get('divRate').setValue(100);
        this.importForm.get('margin').setValue(102);
        this.importForm.get('rounding').setValue(1);

        this.divRate = 100;
        this.margin = 102;
        this.rounding = 1;
        this.roundSelectUpdate(this.rounding);
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {

    }

    createImportForm() {
        return this.formBuilder.group({
            symbol:[],
            quantity:[],
            borrowRate:[],
            borrowFrom:[],
            loanRate:[],
            loanTo:[],
            contra: [],
            counterParty: [null, Validators.required],
            divRate: ['', Validators.required],
            profitCenter: [],
            margin: [102, Validators.compose([Validators.required, Validators.min(100), Validators.max(999)])],
            rounding: [1, Validators.required],
            specFlag: [],
            batchCode: [],
            pastedText: []
        });
    }

    roundSelectUpdate(roundRule: number): void {
        // this.contract.roundRuleValue = roundRule;
        for (const roundRuleLoop of this.roundRulesService.roundRules) {
            if (roundRuleLoop.roundRuleValue === roundRule) {
                this.selectedRoundRule = roundRuleLoop;
                // this.rounding = roundRuleLoop;
                break;
            }
        }
        this.updateContractValue();
    }

    marginUpdate(margin: number): void {
        this.margin = margin;
        this.updateContractValue();
    }

    updateContractValue(): void {
        // need quote price, margin recallQty, rounding rule, quantity
        // //console.log('Calling update contract value!');
        // if (this.quote && this.selectedRoundRule && this.contract.quantity && this.margin) {
        //     let amount: number = this.quote.closePrice * (this.selectedMargin / 100);
        //     //console.log('amount pre-round: ', amount);
        //     if (this.selectedRoundRule.value !== 0) {
        //         amount = Math.ceil(amount * (1 / this.selectedRoundRule.value)) / (1 / this.selectedRoundRule.value);
        //     }
        //     //console.log('amount post-round: ', amount);
        //     this.contract.amount = amount * this.contract.quantity;
        //     // this.contractForm.get('benchmarkRate').setValue(this.contract.benchmarkId);
        //     this.contractForm.get('amount').setValue(this.contract.amount);
        //     this.contractForm.get('visibleAmount').setValue(formatCurrency(this.contract.amount, 'en-US', 'USD'));
        // } else {
        //     //console.log('Not ready to update value yet');
        // }
    }

    setContraAccount(contraAccount: CounterPartyAccount): void {
        // if the acct belongs to unknown counterparty hten its not good
        if (contraAccount.counterPartyId === this.counterpartiesService.UNKNOWN_COUNTERPARTY_ID) {
            this.importForm.get('counterParty').setErrors({'invalid': true});
            return;
        }

        const contra: CounterParty = this.counterpartiesService.getCounterPartyById(contraAccount.counterPartyId);
        this.counterpartiesService.getCounterPartyLimitProfile(contraAccount.counterPartyId, false)
            .subscribe((limitProfile: any) => {
                //console.log('[LimitProfile] limit: ', limitProfile);
                // this.externalCounterPartyLimitLoading = false;
                this.counterPartyLimit = new LimitProfile(limitProfile);
            });

        this.counterpartiesService.getAccountLimitProfile(contraAccount.accountId, false)
            .subscribe((limitProfile: any) => {
                //console.log('[LimitProfile] limit: ', limitProfile);
                // this.externalAccountLimitLoading = false;
                this.counterPartyLimit = new LimitProfile(limitProfile);
            });
        if (contra) {
            this.importForm.get('margin').setValue(contra.defaultMargin * 100);
            this.importForm.get('rounding').setValue(contra.defaultRoundRuleValue);
            this.margin = contra.defaultMargin * 100;
            this.rounding = contra.defaultRoundRuleValue;

            this.roundSelectUpdate(contra.defaultRoundRuleValue);
            this.marginUpdate(contra.defaultMargin * 100);
        }
    }
}
