import { Component, OnInit } from "@angular/core";
import {
    FormGroup,
    FormControl,
    Validators,
    FormBuilder,
} from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { MatSnackBar } from "@angular/material";
import { confirmPasswordValidator } from "./confirm-password.validator";
import { ChangePasswordService } from "./change-password.service";

@Component({
    selector: "app-change-password",
    templateUrl: "./change-password.component.html",
    styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit {
    passwordForm: FormGroup;
    password: string = "";
    currentPassword = "";
    confirmPassword = "";
    submitted = false;
    hide1 = "password";
    hide2 = "password";
    hide3 = "password";

    constructor(
        private fb: FormBuilder,
        private changepasswordservice: ChangePasswordService,
    ) {}

    ngOnInit() {
        this.passwordForm = this.fb.group({
            currentPassword: ["", [Validators.required]],
            password: [
                "",
                [
                    Validators.required,
                    Validators.minLength(8),
                    this.noSpecialCharValidator,
                    this.UperLowerAlphaValidator,
                ],
            ],
            confirmPassword: [
                "",
                [
                    Validators.required,
                    Validators.minLength(8),
                    this.noSpecialCharValidator,
                    confirmPasswordValidator("password"),
                    this.UperLowerAlphaValidator,
                ],
            ],
        });
    }

    onSubmit() {
        if (
            this.passwordForm.get("password").value ==
            this.passwordForm.get("confirmPassword").value
        ) {
            //implment all logic here for submission
            this.submitted = true;
            // this.password = this.passwordForm.get("password").value;
            // this.currentPassword =
            //     this.passwordForm.get("currentPassword").value;
            this.changepasswordservice.updateCurrentPass(
                this.passwordForm.get("password").value,
                this.passwordForm.get("currentPassword").value,
            );

            setTimeout(() => {
                this.clearForm();
            }, 2000);
        }
    }

    noSpecialCharValidator(control: FormControl) {
        const hasSpecialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(
            control.value,
        );
        return !hasSpecialChar ? { noSpecialChar: true } : null;
    }

    UperLowerAlphaValidator(control: FormControl) {
        const bothExist = /^(?=.*[A-Z])(?=.*[a-z]).+$/.test(control.value);
        return !bothExist ? { existsBoth: true } : null;
    }

    toggleVisibility(num: number): void {
        if (num == 1) {
            this.hide1 == "password"
                ? (this.hide1 = "string")
                : (this.hide1 = "password");
        } else if (num == 2) {
            this.hide2 == "password"
                ? (this.hide2 = "string")
                : (this.hide2 = "password");
        } else if (num == 3) {
            this.hide3 == "password"
                ? (this.hide3 = "string")
                : (this.hide3 = "password");
        }
    }

    clearForm(): void {
        this.passwordForm.reset({
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
        });
    }
}
