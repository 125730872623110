import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import {environment} from '../../../../../../environments/environment';
import {Address} from '../../address/address.model';

@Injectable()
export class EntityDetailService implements Resolve<any>
{
    routeParams: any;
    entity: any;
    onEntityChanged: BehaviorSubject<any> = new BehaviorSubject({});
    onAddressChanged: BehaviorSubject<any> = new BehaviorSubject({});
    readonly endpoint = environment.verticalApiEndpoint + 'entity/';
    readonly addressEndpoint = '/address';

    constructor(
        private http: HttpClient
    )
    {
    }

    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {

        this.routeParams = route.params;
        //console.log('route params for detail: ', this.routeParams);
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getEntity()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getEntity(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            if ( this.routeParams.entityId === 'new' )
            {
                this.onEntityChanged.next(false);
                resolve(false);
            }
            else
            {
                this.http.get(this.endpoint + this.routeParams.entityId)
                    .subscribe((response: any) => {
                        this.entity = response;
                        this.onEntityChanged.next(this.entity);
                        resolve(response);
                    }, reject);
            }
        });
    }

    /**
     * MODIFY EXISTING
     * @param entity
     */
    saveEntity(entity)
    {
        //console.log('put ENTITY: ', entity);
        return new Promise((resolve, reject) => {
            this.http.put(this.endpoint + entity.entityId, entity)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    /**
     * SAVE NEW
     * @param entity
     */
    addEntity(entity)
    {
        return new Promise((resolve, reject) => {
            this.http.post(this.endpoint, entity)
                .subscribe((response: any) => {
                    //console.log('ADDED: ', response);
                    resolve(response);
                }, reject);
        });
    }


    getEntityAddress(entity) {
        return new Promise((resolve, reject) => {
            if ( this.routeParams.entityId === 'new' )
            {
                this.onAddressChanged.next(false);
                resolve(false);
            }
            else
            {
                this.http.get(this.endpoint + this.routeParams.entityId + this.addressEndpoint)
                    .subscribe((response: any) => {
                        //console.log('Addresses: ', response.items);
                        const addresses: Address[] = [];
                        for (const address of response.items) {
                            addresses.push(new Address(address));
                        }
                        entity.addresses = addresses;
                        resolve(response);
                    }, reject);
            }
        });
    }

    addEntityAddress(address: Address)
    {
        return new Promise((resolve, reject) => {
            this.http.post(this.endpoint + this.routeParams.entityId + this.addressEndpoint, address)
                .subscribe((response: any) => {
                    //console.log('ADDED: ', response);
                    resolve(response);
                }, reject);
        });
    }
}
