import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {MatDialog} from '@angular/material';
import {FplPosition} from './fplAccountPosition.model';
import {FplTradeFormComponent} from '../../trading/trade-form/fpl-trade-form.component';
import {FplReturnFormComponent} from '../../trading/returns/fpl-return-form.component';


@Component({
    selector: 'child-cell',
    template: `
        <span *ngIf="borrowed > 0 && accountId > 0">
            <button mat-raised-button (click)="showReturnWindow($event)" class="btn">Return</button>
        </span>
    `,
    styles: [
            `.btn {
            height: 20px;
            line-height: 0.5;
            width: 75px;
        }
        `
    ]
})
export class ReturnButtonRenderer implements ICellRendererAngularComp {
    public params: any;
    public available: number;
    public accountId: number;
    public borrowed: number;
    public fplAccountPosition: FplPosition;
    dialogRef: any;

    constructor(public dialog: MatDialog) {
    }

    agInit(params: any): void {
        this.params = params;
        this.fplAccountPosition = params.data;
        this.available = this.fplAccountPosition.available;
        this.borrowed = this.fplAccountPosition.borrowQuantity;
        this.accountId = this.fplAccountPosition.accountId;
    }

    showReturnWindow($events) {
        this.dialogRef = this.dialog.open(FplReturnFormComponent, {
            width: '300px',
            height: '400px',
            data: {
                fplAccountPos: this.fplAccountPosition,
            }
        });
    }

    public invokeParentMethod() {
        this.params.context.componentParent.methodFromParent(
            this.fplAccountPosition
        );
    }

    refresh(): boolean {
        return false;
    }
}
