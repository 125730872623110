import {Injectable} from '@angular/core';
import {FirestoreCollectionService} from '../../../../shared/firestore-collection.service';
import {DtcTransaction} from './dtc-transaction';
import {HttpClient} from '@angular/common/http';
import {AngularFirestore} from 'angularfire2/firestore';
import {AngularFireAuth} from 'angularfire2/auth';

@Injectable({
    providedIn: 'root'
})
export class DtcTransactionsService extends FirestoreCollectionService<DtcTransaction> {

    collectionName = 'dtc-transactions';

    constructor(private httpClient: HttpClient,
                private firestoreClient: AngularFirestore,
                private afAuthClient: AngularFireAuth) {
        super(httpClient, firestoreClient, afAuthClient);
    }

    public createTransactionCallback(newObj): DtcTransaction {
        return new DtcTransaction(newObj);
    }
}
