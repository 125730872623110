import {AfterViewInit, Attribute, Component, ElementRef, OnDestroy, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material';
import {Contract} from '../contract.model';
import {UserService} from '../../../../../auth/user/user.service';
import {CounterParty} from '../../../operations/counterparties/counterparty.model';
import {Observable, Subscription} from 'rxjs';
import {LimitProfile} from '../../../operations/limitprofiles/limitprofile.model';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Quote} from '../../../instruments/quotes/quote.model';
import {CounterPartyAccount} from '../../../operations/accounts/account.model';
import {RoundRule} from '../../../operations/constants/roundrules/roundrule.model';
import {Benchmark} from '../../../operations/constants/benchmarks/benchmark.model';
import {formatCurrency} from '@angular/common';
import {ContractsService} from '../contracts.service';
import {CounterpartyService} from '../../../operations/counterparties/counterparty.service';
import {RoundRulesService} from '../../../operations/constants/roundrules/round-rules.service';
import {BenchmarksService} from '../../../operations/constants/benchmarks/benchmarks.service';
import {QuoteService} from '../../../instruments/quotes/quote.service';
import {Moment} from 'moment';
import {SwitcherService} from '../../../../../shared/switcher/switcher.service';

export const _filter = (opt: CounterPartyAccount[], value: string): CounterPartyAccount[] => {
    const filterValue = value.toLowerCase();

    return opt.filter(item => item.dtcId.indexOf(filterValue) === 0);
};

@Component({
    selector: 'fuse-contracts-contract-form-dialog',
    templateUrl: './contract-form.component.html',
    styleUrls: ['./contract-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class FuseContractsContractFormDialogComponent implements OnDestroy, AfterViewInit {
    // event: CalendarEvent;
    contractForm: FormGroup;
    action: string;
    contract: Contract;
    symbol: string;
    quote: Quote;
    loadingQuote: boolean;
    validSymbol: boolean;
    internalAccount: CounterPartyAccount;
    internalLimit: LimitProfile;
    internalLimitLoading: boolean;
    externalCounterParty: CounterParty;
    externalCounterPartyLimit: LimitProfile;
    externalCounterPartyLimitLoading: boolean;
    externalAccount: CounterPartyAccount;
    externalAccountLimitLoading: boolean;
    externalAccountLimit: LimitProfile;
    marginList: number[] = [];
    selectedMargin: number;
    selectedRoundRule: RoundRule;
    floatingRebateType: boolean;
    selectedBenchmark: Benchmark;
    minExpiration: Date;
    loadingSubmit = false;
    roundRulesSubscription: Subscription;
    benchmarksSubscription: Subscription;
    depositoryNoSubscription: Subscription;
    contraAccounts: Observable<CounterParty[]>;


    hideSubmit;
    sideInput: string;

    // MATCH contract fields
    matchContract: Contract;

    @ViewChild('externalCounterParty') contraElement: ElementRef;

    checkAccountLimits: ErrorStateMatcher = {
        isErrorState: (control: FormControl) => {
            let internalError = false;
            let externalContraError = false;
            if (this.internalLimit && this.externalCounterPartyLimit && this.externalAccountLimit) {
                let internalLimit = 0;
                let externalLimit = 0;
                if (this.contractForm.get('side').value === 'B') {
                    internalLimit = this.internalLimit.borrowLimit - this.internalLimit.borrow;
                    externalLimit = this.externalBorrowLimit();
                } else if (this.contractForm.get('side').value === 'L') {
                    internalLimit = this.internalLimit.lendLimit - this.internalLimit.lend;
                    externalLimit = this.externalLoanLimit();
                }

                if (this.contract.amount > internalLimit) {
                    this.contractForm.controls['account'].setErrors({'invalid': true});
                    internalError = true;
                } else {
                    this.contractForm.controls['account'].setErrors(null);
                }

                if (this.contract.amount > externalLimit) {
                    this.contractForm.controls['externalCounterParty'].setErrors({'invalid': true});
                    externalContraError = true;
                } else {
                    this.contractForm.controls['externalCounterParty'].setErrors(null);
                }
            }
            return (internalError || externalContraError);
        }
    };

    constructor(
        public userService: UserService,
        private formBuilder: FormBuilder,
        private httpClient: HttpClient,
        private contractService: ContractsService,
        public counterpartiesService: CounterpartyService,
        public roundRulesService: RoundRulesService,
        public benchmarkService: BenchmarksService,
        public quoteService: QuoteService,
        public switcherService: SwitcherService,
        @Attribute('hideSubmit') hideSubmit: string,
        @Attribute('sideInput') sideInput: string
    ) {
        this.minExpiration = new Date();
        this.minExpiration.setDate(this.minExpiration.getDate() + 1);

        // this.action = data.action;
        this.action = 'new';
        this.loadingQuote = false;

        if (this.quoteService.quote) {
            this.contract = new Contract({
                symbol: this.quoteService.quote.symbol
            });
        } else {
            this.contract = new Contract({});
        }

        this.matchContract = new Contract({});
        this.contractForm = this.createContractForm();
        this.contractForm.get('divRate').setValue(100);
        if (this.counterpartiesService.internalCounterParties[0]) {
            if (this.counterpartiesService.internalCounterParties[0].accounts) {
                let acct = this.counterpartiesService.getInternalAccountByDtcId(this.switcherService.getSelectedDepositoryNo());
                if (acct) {
                    this.setInternalAccount(acct);
                }
            }
        }

        if (hideSubmit !== null) {
            this.hideSubmit = (hideSubmit === 'true');
        } else {
            this.hideSubmit = false;
        }
        if (sideInput) {
            this.sideInput = sideInput;
            this.setSide(this.sideInput);
        }

        this.roundRulesSubscription =
            this.roundRulesService.onRoundRulesLoaded
                .subscribe(roundRules => {
                    if (roundRules[0]) {
                        this.contract.roundRuleValue = roundRules[0].roundRuleValue;
                        this.contractForm.get('roundRuleValue').setValue(this.contract.roundRuleValue);
                    }
                });

        this.benchmarksSubscription =
            this.benchmarkService.onBenchmarksLoaded
                .subscribe(benchmarks => {
                    if (benchmarks[0]) {
                        this.contract.benchmarkId = benchmarks[0].benchmarkId;
                        this.selectedBenchmark = benchmarks[0];
                        this.contractForm.get('benchmarkRate').setValue(this.contract.benchmarkId);
                    }
                });

        this.depositoryNoSubscription = this.switcherService.depositoryNoSwitched.subscribe(depositoryNo => {
            let acc = this.counterpartiesService.getInternalAccountByDtcId(depositoryNo);
            if (acc) {
                this.setInternalAccount(acc);
            }
        });
    }

    ngOnDestroy() {
        this.roundRulesSubscription.unsubscribe();
        this.benchmarksSubscription.unsubscribe();
        this.depositoryNoSubscription.unsubscribe();
    }

    updateInternalLimit(accountId: number): void {
        this.internalLimitLoading = true;
        this.counterpartiesService.getAccountLimitProfile(accountId, true).subscribe((limitProfile: any) => {
            //console.log('[LimitProfile] limit: ', limitProfile);
            this.internalLimitLoading = false;
            this.internalLimit = new LimitProfile(limitProfile);
        });
    }


    setInternalAccount(account: CounterPartyAccount): void {
        //this.contractForm.get('account').setValue(account);
        this.contractForm.get('accountId').setValue(account.accountId);
        this.contractForm.get('depositoryNo').setValue(account.dtcId);
        this.updateInternalLimit(account.accountId);
    }

    setSide(side: string) {
        this.contractForm.get('side').setValue(side);
        this.contract.side = side;
    }

    setSymbol(symbol: string): void {
        //console.log('setting symbol to: ', symbol);
        this.symbol = symbol.toUpperCase();
        this.contractForm.get('symbol').setValue(symbol);
        // this.quoteService.updateQuote(this.symbol, this.contractService.effectiveDate);
        if (symbol !== '') {
            this.quoteService.getQuote(this.symbol, this.contractService.effectiveDate)
                .subscribe((quote) => {
                    if (quote) {
                        this.quoteService.setQuote(new Quote(quote));
                        this.updateContractValue();
                    } else {
                        this.contractForm.controls['symbol'].setErrors({'invalid': true});
                        this.quoteService.setQuote(
                            new Quote({
                                symbol: symbol.toUpperCase(),
                                description: 'N/A',
                            })
                        );
                        this.validSymbol = false;
                        this.loadingQuote = false;
                    }
                }, (error) => {
                    this.updateContractValue();
                    this.contractForm.controls['symbol'].setErrors({'invalid': true});
                    this.quote = null;
                    this.validSymbol = false;
                    this.loadingQuote = false;
                });
        }
    }

    setRebateType(rebateType: string) {
        //console.log('rebateType: ', rebateType);
        if (rebateType === 'FX') {
            this.floatingRebateType = false;
        } else {
            this.floatingRebateType = true;
        }
    }

    marginUpdate(margin: number): void {
        //console.log('margin: ', margin);
        this.selectedMargin = margin;
        this.contract.margin = margin;
        this.updateContractValue();
    }

    roundSelectUpdate(roundRule: number): void {
        this.contract.roundRuleValue = roundRule;
        for (const roundRuleLoop of this.roundRulesService.roundRules) {
            if (roundRuleLoop.roundRuleValue === roundRule) {
                this.selectedRoundRule = roundRuleLoop;
                break;
            }
        }
        this.updateContractValue();
    }

    benchmarkSelectUpdate(benchmarkId: number): void {
        for (const benchmarkLoop of this.benchmarkService.benchmarks) {
            if (benchmarkLoop.benchmarkId === benchmarkId) {
                this.selectedBenchmark = benchmarkLoop;
                break;
            }
        }
    }

    printFormDetails(): void {
        //console.log('contractForm valid: ', this.contractForm.valid);
        //console.log('limit valid: ',);
    }

    updateContractValue(): void {
        // need quote price, margin recallQty, rounding rule, quantity
        //console.log('Calling update contract value!');
        if (this.quoteService.quote && this.selectedRoundRule && this.contract.quantity && this.selectedMargin) {
            let amount: number = this.quoteService.quote.closePrice * (this.selectedMargin / 100);
            //console.log('amount pre-round: ', amount);
            if (this.selectedRoundRule.value !== 0) {
                amount = Math.ceil(amount * (1 / this.selectedRoundRule.value)) / (1 / this.selectedRoundRule.value);
            }
            //console.log('amount post-round: ', amount);
            this.contract.amount = amount * this.contract.quantity;
            // this.contractForm.get('benchmarkRate').setValue(this.contract.benchmarkId);
            this.contractForm.get('amount').setValue(this.contract.amount);
            this.contractForm.get('visibleAmount').setValue(formatCurrency(this.contract.amount, 'en-US', 'USD'));
        } else {
            //console.log('Not ready to update value yet');
        }
    }

    parseNumberText(num: string, field: string): number {
        const newNum: string = num.replace(/\D/g,
            '');
        const parsed = parseInt(newNum, 10);

        if (field === 'quantity') {
            this.contract.quantity = parsed;
            this.updateContractValue();
        } else if (field === 'margin') {
            this.updateContractValue();
        }

        return parsed;
    }


    createContractForm() {
        return this.formBuilder.group({
            side: [this.contract.side],
            account: [],
            accountId: [this.contract.accountId],
            depositoryNo: [this.contract.depositoryNo],
            internalLimit: [this.internalLimit],
            externalCounterParty: [this.externalCounterParty],
            contraAccount: [this.externalAccount],
            contraAccountId: [this.contract.contraAccountId],
            contraDepositoryNo: [this.contract.contraDepositoryNo],
            contraLimit: [this.externalAccountLimit],
            symbol: [this.contract.symbol],
            quantity: [this.contract.quantity],
            rate: [this.contract.rate, Validators.max(999)],
            margin: [this.contract.margin, Validators.compose([Validators.min(100), Validators.max(999)])],
            roundRuleValue: [this.contract.roundRuleValue],
            amount: [{value: this.contract.amount}],
            visibleAmount: [{value: this.contract.amount, disabled: true}],
            floatingRebateType: [this.floatingRebateType],
            benchmarkRate: [this.contract.benchmarkRate],
            profitCenter: [this.contract.profitCenter],
            publicComment: [this.contract.publicComment],
            expireOn: [this.contract.expireOn],
            divRate: [this.contract.divRate, Validators.compose([Validators.min(0), Validators.max(100)])],

        });
    }

    printAccount(): void {
        //console.log(this.contract);
    }

    setExpireOn(event): void {
        const moment: Moment = event.value;
        // //console.log('event: ', event);
        // //console.log('value: ', event.value);
        // //console.log('date: ', moment.format('YYYY-MM-DD'));
        this.contractForm.get('expireOn').setValue(moment.format());
    }

    submit() {
        const contractData = this.contractForm.getRawValue();
        //console.log('contract: ', contractData);
        this.loadingSubmit = true;
        this.contractService.updateContract(contractData)
            .then((resp) => {
                //console.log('response: ', resp);
                this.loadingSubmit = false;
                this.contractForm.reset();
                this.contractService.showTradeForm = false;
            })
            .catch((error) => {
                this.loadingSubmit = false;
            });
    }

    private _filterGroup(value: string): CounterParty[] {
        if (value) {
            return this.counterpartiesService.externalCounterParties
                .map(contra => ({
                    counterPartyId: contra.counterPartyId,
                    entityId: contra.entityId,
                    name: contra.name,
                    hasLoanNet: contra.hasLoanNet,
                    canReturnOnRecall: contra.canReturnOnRecall,
                    canRecallPartial: contra.canRecallPartial,
                    occMemberId: contra.occMemberId,
                    bic: contra.bic,
                    billingReference: contra.billingReference,
                    complianceReference: contra.complianceReference,
                    currencyId: contra.currencyId,
                    createdOn: contra.createdOn,
                    modifiedOn: contra.modifiedOn,
                    counterPartyTypeId: contra.counterPartyTypeId,
                    counterPartyStatusId: contra.counterPartyStatusId,
                    defaultRoundRuleValue: contra.defaultRoundRuleValue,
                    defaultMargin: contra.defaultMargin,
                    accounts: _filter(contra.accounts, value),
                    shortCode: contra.shortCode,
                    lendLimit: contra.lendLimit,
                    borrowLimit: contra.borrowLimit,
                    bizEmail: contra.bizEmail,
                    opsEmail: contra.opsEmail,
                    internal: contra.internal
                }))
                .filter(contra => contra.accounts.length > 0);
        }

        return this.counterpartiesService.externalCounterParties;
    }

    verifyContraInput(): void {
        const dtcInput: string = this.contractForm.get('externalCounterParty').value;
        const contraAccount: CounterPartyAccount = this.counterpartiesService.getAccountByDtcId(dtcInput);
        if (contraAccount) {
            this.setContraAccount(contraAccount);
        } else {
            this.contractForm.get('externalCounterParty').setErrors({'invalid': true});
        }
        // //console.log('account: ', contraAccount);
    }

    ngAfterViewInit(): void {
        this.contraAccounts = this.contractForm.get('externalCounterParty')!.valueChanges
            .pipe(
                map(value => this._filterGroup(value))
            );
        setTimeout(() => {
            this.contraElement.nativeElement.focus();
        }, 0);

    }

    externalLimitLoading(): boolean {
        return this.externalAccountLimitLoading || this.externalCounterPartyLimitLoading;
    }

    externalBorrowLimit(): number {
        // this.externalCounterPartyLimit.borrowLimit - this.externalCounterPartyLimit.borrow
        // return lesser of contra limit or account limit - the current borrow amount
        if (!(this.externalLimitLoading())) {
            const contraLimitLeft = this.externalCounterPartyLimit.borrowLimit - this.externalCounterPartyLimit.borrow;
            const acctLimitLeft = this.externalAccountLimit.borrowLimit - this.externalAccountLimit.borrow;
            return acctLimitLeft > contraLimitLeft ? contraLimitLeft : acctLimitLeft;
        }
        return 0;
    }

    externalLoanLimit(): number {
        // this.externalCounterPartyLimit.borrowLimit - this.externalCounterPartyLimit.borrow
        // return lesser of contra limit or account limit - the current borrow amount
        if (!(this.externalLimitLoading())) {
            const contraLimitLeft = this.externalCounterPartyLimit.lendLimit - this.externalCounterPartyLimit.lend;
            const acctLimitLeft = this.externalAccountLimit.lendLimit - this.externalAccountLimit.lend;
            return acctLimitLeft > contraLimitLeft ? contraLimitLeft : acctLimitLeft;
        }
        return 0;
    }

    setContraAccount(contraAccount: CounterPartyAccount): void {

        // if the acct belongs to unknown counterparty hten its not good
        if (contraAccount.counterPartyId === this.counterpartiesService.UNKNOWN_COUNTERPARTY_ID) {
            this.contractForm.get('externalCounterParty').setErrors({'invalid': true});
            return;
        }

        this.contractForm.get('contraAccountId').setValue(contraAccount.accountId);
        this.contractForm.get('contraDepositoryNo').setValue(contraAccount.dtcId);
        // Get some limits
        this.externalCounterPartyLimitLoading = true;
        this.externalAccountLimitLoading = true;
        const contra: CounterParty = this.counterpartiesService.getExternalCounterPartyById(contraAccount.counterPartyId);
        this.counterpartiesService.getCounterPartyLimitProfile(contraAccount.counterPartyId, false)
            .subscribe((limitProfile: any) => {
                //console.log('[LimitProfile] limit: ', limitProfile);
                this.externalCounterPartyLimitLoading = false;
                this.externalCounterPartyLimit = new LimitProfile(limitProfile);
            });
        this.counterpartiesService.getAccountLimitProfile(contraAccount.accountId, false)
            .subscribe((limitProfile: any) => {
                //console.log('[LimitProfile] limit: ', limitProfile);
                this.externalAccountLimitLoading = false;
                this.externalAccountLimit = new LimitProfile(limitProfile);
            });
        if (contra) {
            this.contractForm.get('margin').setValue(contra.defaultMargin * 100);
            this.contractForm.get('roundRuleValue').setValue(contra.defaultRoundRuleValue);
            this.roundSelectUpdate(contra.defaultRoundRuleValue);
            this.marginUpdate(contra.defaultMargin * 100);
        }
    }

    debugForm(): void {
        //console.log('form: ', this.contractForm);
        //console.log('form errs: ', this.contractForm.errors);
    }
}
