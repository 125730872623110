import {Injectable} from '@angular/core';
import {Quote} from './quote.model';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {Moment} from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import {log} from 'util';

@Injectable()
export class QuoteService {

    loading = false;
    quote: Quote;
    quoteObs: Observable<any>;
    public locked = false;
    private endpoint = environment.verticalApiEndpoint + 'securityMaster/';

    constructor(private httpClient: HttpClient) {
    }

    public getQuote(symbol: string, effectiveDate: Moment): Observable<Quote> {
        this.quoteObs = this.httpClient.get<Quote>(this.endpoint + symbol, {
            params: {
                effectiveDate: effectiveDate.format(),
            }
        });
        return this.quoteObs;
    }

    public setQuote(quote: Quote): void {
        this.quote = quote;
    }

    public clearQuote(): void {
        this.quote = undefined;
    }


    public updateQuote(symbol: string, effectiveDate: Moment): void {
        this.loading = true;
        this.getQuote(symbol, effectiveDate)
            .subscribe((quote) => {
                if (quote) {
                    this.quote = new Quote(quote);
                    this.loading = false;
                } else {
                    // console.log('no quote found?');
                    this.quote = new Quote({
                        symbol: symbol.toUpperCase(),
                        description: 'N/A',
                    });
                    // this.quoteService.quote = null;
                    this.loading = false;
                }
            }, (error) => {
                console.log('error loading quote: ', error);
                this.loading = false;
            });
    }

    public updateInstrument(quote: Quote): Observable<any> {
        const endpointSuffix = 'update';
        return this.httpClient.post(this.endpoint + endpointSuffix, JSON.stringify(quote));
    }

    public addInstrument(quote: Quote): Observable<any> {
        const endpointSuffix = 'add';
        return this.httpClient.post(this.endpoint + endpointSuffix, JSON.stringify(quote));
    }

}
