import { Component } from '@angular/core';
import { ITooltipParams} from 'ag-grid-community';
import { ITooltipAngularComp} from 'ag-grid-angular';

@Component({
    selector: 'tooltip-component',
    template: ` <div class="custom-tooltip" [style.background-color]="color">
    <p>
      <span>{{ error }}</span>
    </p>
  </div>`,
    styles: [
        `
      :host {
        position: absolute;
        width: 150px;
        height: 70px;
        pointer-events: none;
        transition: opacity 1s;
      }

      :host.ag-tooltip-hiding {
        opacity: 0;
      }

      .custom-tooltip p {
        margin: 5px;
      }

      .custom-tooltip p:first-of-type {
        font-weight: bold;
      }
    `,
    ],
})
export class CustomTooltip implements ITooltipAngularComp {
    private params: { color: string } & ITooltipParams;
    public error: string;
    public color: string;

    agInit(params: { color: string } & ITooltipParams): void {
        this.params = params;

        this.error = params.api.getDisplayedRowAtIndex(params.rowIndex).data.errorMessage;
        this.color = this.params.color || 'white';
    }
}
