import { Component, Input, ViewEncapsulation } from '@angular/core';
import {UserService} from '../../../app/auth/user/user.service';

@Component({
    selector     : 'fuse-navigation',
    templateUrl  : './navigation.component.html',
    styleUrls    : ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FuseNavigationComponent
{
    @Input() layout = 'horizontal';
    @Input() navigation: any;

    constructor(public userService: UserService)
    {
    }
}
