import {AfterViewChecked, Component, Inject, ViewChild, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Contract} from '../contract.model';
import * as moment from 'moment-timezone';

import {Moment} from 'moment';
import {CounterpartyService} from '../../../operations/counterparties/counterparty.service';

// @ViewChild('focusThis') focusThis;

@Component({
    selector: 'fuse-contracts-return-form-dialog',
    templateUrl: './return-form.component.html',
    styleUrls: ['./return-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class FuseContractsReturnFormDialogComponent implements AfterViewChecked {
    dialogTitle: string;
    returnForm: FormGroup;
    returnQty: number;
    contract: Contract;
    isFpl: boolean;
    returnOnNewContract: boolean;
    sameDayReturnAck: boolean;

    batchCodes = ['E', 'J', 'X', 'W'];
    specFlags = ['D', '0', 'P'];

    constructor(public dialogRef: MatDialogRef<FuseContractsReturnFormDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private data: any,
                private formBuilder: FormBuilder,
                public counterparties: CounterpartyService) {
        this.contract = data.contract;

        this.returnOnNewContract = (moment.utc(new Date(), 'X').tz('America/New_York').format('MM-DD-YY') === this.contract.startOn);

        this.returnForm = this.createReturnForm();

    }

    createReturnForm() {
        console.log(this.contract);
        this.isFpl = (this.counterparties.getAccountByAccountId(this.contract.contraAccountId).accountCategoryId === 2);
        let bc = 'E';
        let sf = '0';
        if (this.isFpl) {
            bc = 'W';
            sf = 'P';
        }
        return this.formBuilder.group({
            contractId: [this.contract.contractId],
            returnQty: [this.returnQty,
                Validators.compose([Validators.min(0),
                    Validators.max(this.contract.quantity)])],
            batchCode: [bc, Validators.required],
            specFlag: [sf, Validators.required]

        });
    }

    ngAfterViewChecked() {
        // this.recallForm.get('recallQty').
    }
}
