import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CounterParty} from '../counterparty.model';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import { MatSnackBar } from '@angular/material/snack-bar';
import {CounterpartyDetailService} from './counterparty-detail.service';
import {EntityService} from '../../entities/entity.service';
import {CounterPartyTypeService} from '../counterparty-type.service';
import {StatusService} from '../../constants/status/status.service';
import {CurrencyService} from '../../constants/currency/currency.service';
import {RoundRulesService} from '../../constants/roundrules/round-rules.service';
import {Location} from '@angular/common';
import {AccountListComponent} from '../../accounts/account-list/account-list.component';
import {NavTabItem} from '../../../../nav-tab/nav-tab-item';
import {NavTabService} from '../../../../nav-tab/nav-tab.service';

@Component({
  selector: 'app-counterparty-detail',
  templateUrl: './counterparty-detail.component.html',
  styleUrls: ['./counterparty-detail.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class CounterpartyDetailComponent implements OnInit, OnDestroy {

    counterParty: CounterParty = new CounterParty();
    onCounterPartyChanged: Subscription;
    pageType: string;
    counterPartyForm: FormGroup;
    mode = 'counterParty';

    @ViewChild(AccountListComponent) accountListComponent: AccountListComponent;

  constructor(
      private counterPartyDetailService: CounterpartyDetailService,
      private formBuilder: FormBuilder,
      public snackBar: MatSnackBar,
      public entityService: EntityService,
      public counterPartyTypeService: CounterPartyTypeService,
      public statusService: StatusService,
      public currencyService: CurrencyService,
      public roundRuleService: RoundRulesService,
      private location: Location,
      public navTabService: NavTabService
  ) { }

  ngOnInit() {
      this.onCounterPartyChanged =
          this.counterPartyDetailService.onCounterPartyChanged
              .subscribe(counterParty => {
                  //console.log(counterParty);
                  if ( counterParty )
                  {
                      this.counterParty = new CounterParty(counterParty);
                      this.counterPartyDetailService.counterParty = this.counterParty;
                      this.pageType = 'edit';
                  }
                  else
                  {
                      this.pageType = 'new';
                      this.counterParty = new CounterParty();
                  }

                  this.counterPartyForm = this.createCounterPartyForm();
                  // const setId = '' + this.counterParty.entityId;
                  // this.counterPartyForm.controls['entityId'].setValue(setId);
              });
  }

  ngOnDestroy(): void {
      this.onCounterPartyChanged.unsubscribe();
  }

    createCounterPartyForm()
    {
        return this.formBuilder.group({
            entityId: ['' + this.counterParty.entityId],
            name: [this.counterParty.name],
            shortCode: [this.counterParty.shortCode],
            hasLoanNet: [this.counterParty.hasLoanNet],
            canReturnOnRecall: [this.counterParty.canReturnOnRecall],
            canRecallPartial: [this.counterParty.canRecallPartial],
            billingReference: [this.counterParty.billingReference],
            complianceReference: [this.counterParty.complianceReference],
            currencyId: ['' + this.counterParty.currencyId],
            counterPartyTypeId: [this.counterParty.counterPartyTypeId],
            counterPartyStatusId: [this.counterParty.counterPartyStatusId],
            defaultRoundRuleValue: [this.counterParty.defaultRoundRuleValue],
            defaultMargin: [this.counterParty.defaultMargin],
            lendLimit: [this.counterParty.lendLimit],
            borrowLimit: [this.counterParty.borrowLimit],
            counterPartyId: [this.counterParty.counterPartyId],
            createdOn: [this.counterParty.createdOn],
            bizEmail: [this.counterParty.bizEmail, [Validators.required, Validators.email]],
            opsEmail: [this.counterParty.opsEmail, [Validators.required, Validators.email]]
        });
    }

    compareEntity(entityId1: number, entityId2: number): boolean {
      return entityId1 === entityId2;
    }

    printForm() {
        //console.log(this.counterPartyForm.getRawValue());
    }

    /**
     * MODIFY EXISTING
     */
    saveCounterParty()
    {
        const data = this.counterPartyForm.getRawValue();
        this.counterPartyDetailService.saveCounterParty(data)
            .then(() => {

                // Trigger the subscription with new data
                this.counterPartyDetailService.onCounterPartyChanged.next(data);

                // Show the success message
                this.snackBar.open('Counterparty saved', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            });
    }

    /**
     * SAVE NEW
     */
    addCounterParty()
    {
        const data = this.counterPartyForm.getRawValue();
        this.counterPartyDetailService.addCounterParty(data)
            .then((updatedCounterParty) => {
                // //console.log('Updated Entity: ', updatedEntity);
                // Trigger the subscription with new data
                this.counterPartyDetailService.onCounterPartyChanged.next(updatedCounterParty);

                // Show the success message
                this.snackBar.open('Counterparty added', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });

                // Change the location with new one
                this.location.go('counterparties/' + this.counterParty.counterPartyId);
            });
    }

    addNewAccount(){
        const navTabItem = new NavTabItem('accounts', 'New Account', 'item', 'business_center',
            '/accounts/new', null, null, null,
            null,
            'New Account', null, {counterPartyId: this.counterParty.counterPartyId});

        this.navTabService.addTab(navTabItem);
    }

    checkTabIndex(event) {
        // Rseize the table columns when the tab is selected
        if (event.index === 1) {
            this.accountListComponent.gridApi.sizeColumnsToFit();
            this.accountListComponent.setFilter();
            this.mode = 'account';
        } else {
            this.mode = 'counterParty';
        }
    }

}
