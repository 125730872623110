import {Injectable} from '@angular/core';
import {FirestoreCollectionService} from '../../../../shared/firestore-collection.service';
import {HttpClient} from '@angular/common/http';
import {AngularFirestore} from 'angularfire2/firestore';
import {AngularFireAuth} from 'angularfire2/auth';
import {LcorContract} from './lcor-contract.model';
import {QuoteService} from '../../instruments/quotes/quote.service';
import {MatSnackBar} from '@angular/material';
import {SwitcherService} from '../../../../shared/switcher/switcher.service';
import {environment} from '../../../../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class LcorService extends FirestoreCollectionService<LcorContract> {

    collectionName = 'loanet-lcor';
    currentCollection = [];

    constructor(private httpClient: HttpClient,
                private firestoreClient: AngularFirestore,
                private afAuthClient: AngularFireAuth,
                private quoteService: QuoteService,
                public snackBar: MatSnackBar,
                public switcherService: SwitcherService) {
        super(httpClient, firestoreClient, afAuthClient);
        super.initializeService(this.collectionName, this.createContractCallback);

        this.currentCollection = this.collectionData;
    }

    public createContractCallback(newObj: any): LcorContract {
        return new LcorContract(newObj);
    }

    submitbatch(loanetId: string, contraLoanetId: string, minRebate: number, minQuantity: number, maxPrice: number, timeLimit: number,
                divRate: number, profitCenter: string, publicComment: string, batchDetail: any[]) {
        var self = this;
        const endpoint = environment.verticalApiEndpoint + 'lcor/submitbatch';

        const json = {
            loanetId: loanetId,
            contraLoanetId: contraLoanetId,
            minRebate: minRebate,
            minQuantity: minQuantity,
            maxPrice: maxPrice,
            timeLimit: timeLimit,
            divRate: divRate,
            profitCenter: profitCenter,
            publicComment: publicComment,
            batchDetail: batchDetail
        };

        self.httpClient
            .post(endpoint, JSON.stringify(json))
            .subscribe((resp) => {
                    this.snackBar.open('Submitted Successfully', 'OK', {
                        verticalPosition: 'bottom',
                        duration: 2000
                    });
                }, (error) => {
                    console.log('error: ', error);
                }
            );
    }

}
