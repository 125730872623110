import { Injectable } from '@angular/core';
import {Currency} from './currency.model';
import {HttpClient} from '@angular/common/http';
import {Status} from '../status/status.model';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {environment} from '../../../../../../environments/environment';

@Injectable()
export class CurrencyService {

    currencyList: Currency[] = undefined;
    endpoint = environment.verticalApiEndpoint + 'constants/currencyCodes'

    constructor(private http: HttpClient) { }

    getCurrencyList(): void {
        if (this.currencyList === undefined) {
            this.http.get(this.endpoint)
                .subscribe((currencies: any) => {
                    this.currencyList = [];
                    const currencyList = currencies.items;
                    for (const currency of currencyList) {
                        this.currencyList.push(new Currency(currency));
                    }
                    console.log('Currencies: ', this.currencyList);
                }, error => {
                    console.log('Error getting currencies: ', error);
                });
        }
    }

    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getCurrencyList()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }
}
