import { Injectable } from '@angular/core';

import {environment} from '../../../../../../../environments/environment';
import {UserDetailService} from '../user-detail.service';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {Role} from '../../../../../../auth/user/role';

@Injectable()
export class UserRolesService {

    readonly endpoint = environment.verticalApiEndpoint + 'user/';
    onUserRolesChanged: BehaviorSubject<any> = new BehaviorSubject({});

    private readonly _validRoleList: Role[] = [
        new Role({abbr: 'EA', description: 'Creates Entities', roleId: 100, name: 'Entity Admin'}),
        new Role({abbr: 'T', description: 'Can enter contracts', roleId: 101, name: 'createContract'}),
        new Role({abbr: 'T1', description: 'Can approve contracts', roleId: 102, name: 'approveContract'}),
        new Role({abbr: 'EC', description: 'Can edit contracts', roleId: 103, name: 'editContract'}),
        new Role({abbr: 'VU', description: 'Can view a list of users', roleId: 201, name: 'viewUsers'}),
        new Role({abbr: 'MU', description: 'Can create, update, destroy users', roleId: 202, name: 'manageUsers'}),
        new Role({abbr: 'IM', description: 'Can update instrument data', roleId: 301, name: 'manageInstruments'}),
        new Role({abbr: 'FPLV', description: 'Can view FPL Accounts and Positions', roleId: 302, name: 'fplView'}),
        new Role({abbr: 'FPLA', description: 'Can manage FPL Accounts', roleId: 303, name: 'fplAccounts'}),
        new Role({abbr: 'FPLT', description: 'Can trade from FPL Positions', roleId: 304, name: 'fplTrade'}),
        new Role({abbr: 'RC', description: 'Can review contracts', roleId: 305, name: 'reviewContract'}),
        new Role({abbr: 'LCOR', description: 'Can use LCOR functionality', roleId: 401, name: 'manageLcor'}),
        new Role({abbr: 'MSEG', description: 'Can use Memoseg functionality', roleId: 501, name: 'manageMemoseg'}),
        new Role({abbr: 'BETA', description: 'Reveal beta features on the website', roleId: 901, name: 'betaFeatures'})
    ];

  constructor(private userDetailService: UserDetailService,
              private http: HttpClient) {
  }

    get validRoleList(): Role[] {
        return this._validRoleList;
    }

    getRoleById(roleId: number): Role {
      for (const role of this._validRoleList) {
          if (role.roleId === roleId) {
              return role;
          }
      }
      return null;
    }

    saveUserRoles(userRoles, enabledRoles)
    {
        const json = {
            userUid: this.userDetailService.user.userUid,
            userRoles: userRoles,
            enabled: enabledRoles
        };
        //console.log('put USER ROLES: ', userRoles);
        return new Promise((resolve, reject) => {
            this.http.put(this.endpoint + this.userDetailService.user.loginId + '/roles', JSON.stringify(json))
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }
}
