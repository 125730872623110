import * as moment from 'moment-timezone';
import {forEach} from 'lodash';

export class Quote {
    closeDate: String;
    closePrice: number;
    cusip: string;
    description: string;
    modified: String;
    oldCusip: string;
    oldSymbol: string;
    exchange: string;
    status: string;
    symbol: string;
    updateSource: string;
    collateralPrice: number;
    volume: number;
    valid: boolean;
    upToDate: boolean;
    restrictions: string;
    restrictionsList: string[];
    blocked: boolean;

    constructor(quote) {
        this.closeDate = moment.utc(quote.closeDate).format('YYYY-MM-DD');
        // if(quote.closeDate){
        //     var day = quote.closeDate.dayOfMonth;
        //     var month = quote.closeDate.monthValue - 1; // Month is 0-indexed
        //     var year = quote.closeDate.year;
        //     var minutes = quote.closeDate.minute;
        //     var hours = quote.closeDate.hour;
        //     var seconds = quote.closeDate.second;
        //     this.closeDate = moment.utc(Date.UTC(year, month, day, hours, minutes, seconds)).format('YYYY-MM-DD');
        // }
        this.closePrice = quote.closePrice;
        this.cusip = quote.cusip;
        this.description = quote.description;
        this.modified = moment.utc(quote.modified).format('YYYY-MM-DD');
        this.oldCusip = quote.oldCusip;
        this.oldSymbol = quote.oldSymbol;
        this.exchange = quote.exchange;
        if (quote.status) {
            this.status = quote.status;
        } else {
            this.status = null;
        }
        this.symbol = quote.symbol;
        this.updateSource = quote.updateSource;
        this.volume = quote.volume;
        this.upToDate = quote.upToDate;

        // this.collateralPrice = quote.closePrice;
        // let collateralPrice: number = quote.closePrice * (1.02);
        // collateralPrice = Math.ceil(collateralPrice * (1 / 1)) / (1 / 1);
        // this.collateralPrice = collateralPrice;
        this.collateralPrice = Math.ceil(quote.closePrice * 1.02);

        // //console.log('full quote: ', quote);
        if (this.symbol && this.description && this.closePrice) {
            this.valid = true;
        } else {
            this.valid = false;
        }



        if(quote.restrictions) {
            this.restrictions = quote.restrictions.toString();
            this.restrictionsList = Array.from(this.restrictions);

           let i = 0;
           while(i< this.restrictionsList.length && !this.blocked){
               if(this.restrictionsList[i] === this.restrictionsList[i].toUpperCase()){
                   this.blocked = true;
               }
           }
        }else{
            this.restrictionsList = null;
        }
    }
}

