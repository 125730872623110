import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import {FplAccount} from '../fplAccount.model';
import {FplService} from '../fpl.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material';
import {CounterpartyService} from '../../counterparties/counterparty.service';
import {CounterPartyAccount} from '../../accounts/account.model';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
    selector: 'app-fpl-accounts-detail',
    templateUrl: './fpl-accounts-detail.component.html',
    styleUrls: ['./fpl-accounts-detail.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class FplAccountsDetailComponent implements OnInit {

    fplAccount: FplAccount;
    fplAccountForm: FormGroup;
    unassignedSlsAccounts: CounterPartyAccount[] = [];
    slsAccount: CounterPartyAccount;

    filteredSlsAccounts: Observable<CounterPartyAccount[]>;

    constructor(public fplService: FplService,
                private formBuilder: FormBuilder,
                public snackBar: MatSnackBar,
                private counterpartyService: CounterpartyService) {
        this.fplAccount = fplService.selectedFplAccount;
    }

    ngOnInit() {


        this.fplService.getFplSlsAccounts().then((unassignedSlsAccounts) => {
            this.unassignedSlsAccounts = unassignedSlsAccounts;
            if (this.fplAccount.accountId){
                this.slsAccount = new CounterPartyAccount(this.counterpartyService.getAccountByAccountId(this.fplAccount.accountId));
                this.unassignedSlsAccounts.push(this.slsAccount);
            }

            this.fplAccountForm = this.createFplAccountForm();

            this.filteredSlsAccounts = this.fplAccountForm.controls.accountId.valueChanges
                .pipe(
                    startWith(''),
                    map(value => typeof value === 'string' ? value : value.name),
                    map(name => name ? this._filter(name) : this.unassignedSlsAccounts.slice())
                );
        });
    }

    private _filter(name: string): CounterPartyAccount[] {
        const filterValue = name.toLowerCase();

        return this.unassignedSlsAccounts.filter(option => JSON.stringify(option).toLowerCase().indexOf(filterValue) > -1);
    }

    displayFn(account: CounterPartyAccount): string {
        return account && account.name ? account.name : '';
    }

    createFplAccountForm() {
        return this.formBuilder.group({
            acctType: [this.fplAccount.accountType],
            acctNo: ['' + this.fplAccount.accountNumber],
            corr: [this.fplAccount.correspondentCode],
            office: [this.fplAccount.office],
            status: [this.fplAccount.status],
            accountId: [this.slsAccount]
        });
    }

    saveFplAccount() {
        const account = this.fplAccountForm.getRawValue();
        this.slsAccount = account.accountId;
        account.accountId = this.slsAccount.accountId;
        const updatedFplAccount = new FplAccount(account);

        //console.log(updatedFplAccount);
        this.fplService.updateFplAccount(updatedFplAccount).then((res) => {
            this.snackBar.open('Account updated', 'Dismiss', {
                verticalPosition: 'bottom',
                duration: 2000
            });
        });
    }

    checkTabIndex(event) {
        // Resize the table columns when the tab is selected
        // if (event.index === 1) {
        //     this.accountListComponent.gridApi.sizeColumnsToFit();
        //     this.accountListComponent.setFilter();
        //     this.mode = 'account';
        // } else {
        //     this.mode = 'counterParty';
        // }
    }

}
