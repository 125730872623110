import {AfterViewInit, Component, ElementRef, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {UserService} from '../../../../../auth/user/user.service';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import {GridOptions, ColDef, ColumnApi, GridApi, RowNode} from 'ag-grid-community';
import {BulkImportService} from './bulk-import.service';
import {MatSnackBar} from '@angular/material';
import {ConstantsService} from '../../../../../shared/constants/constants.service';
import {SwitcherService} from '../../../../../shared/switcher/switcher.service';
import {QuoteService} from '../../../instruments/quotes/quote.service';
import {Quote} from '../../../instruments/quotes/quote.model';
import {ContractsService} from '../contracts.service';
import {formatCurrency, formatNumber} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';
import {RoundRulesService} from '../../../operations/constants/roundrules/round-rules.service';
import {Observable, Subscription} from 'rxjs';
import {CounterParty} from '../../../operations/counterparties/counterparty.model';
import {CounterPartyAccount} from '../../../operations/accounts/account.model';
import {map} from 'rxjs/operators';
import {_filter} from '../../trade-form/trade-form.component';
import {CounterpartyService} from '../../../operations/counterparties/counterparty.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LimitProfile} from '../../../operations/limitprofiles/limitprofile.model';
import {RoundRule} from '../../../operations/constants/roundrules/roundrule.model';
import {BulkRequest} from './bulkrequest.model';
import {AngularFireAuth} from 'angularfire2/auth';
import {CustomTooltip} from './toolTip';
import Split from 'split.js';
import {ContractDetailPreferencesService} from '../contract-detail-preferences/contract-detail-preferences.service';
import {Contract} from '../contract.model';
import {StatusSelectComponent} from '../contract-context-menu/status-select/status-select.component';
import {ApprovalComponent} from '../contract-context-menu/status-select/approval.component';
import * as moment from 'moment-timezone';
import {ContractDetailPreferences} from '../contract-detail-preferences/contract-detail-preferences';


@Component({
    selector: 'app-contract-import',
    templateUrl: './bulk-import.component.html',
    styleUrls: ['./bulk-import.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class BulkImportComponent implements OnInit, OnDestroy, AfterViewInit {

    gridOptions: GridOptions = <GridOptions>{};
    detailsGridOptions: GridOptions = <GridOptions>{};

    defaultColDef = {
        editable: true,
        enableCellChangeFlash: false,
        sortable: true,
        filter: true,
        resizable: true,
        tooltipComponent: 'customTooltip',

    };

    frameworkComponents = {customTooltip: CustomTooltip};

    detailsFrameworkComponents = {
        statusEditor: StatusSelectComponent,
        approvalComponent: ApprovalComponent
    };

    importGridColumnApi: ColumnApi;
    importGridApi: GridApi;
    completedGridColumnApi: ColumnApi;
    completedGridApi: GridApi;
    side: string;
    contra: string;
    profitCenter: string;
    pastedText: string;
    margin: number;
    divRate: number;
    rounding: number;
    specFlag: string;
    batchCode: string;
    lines = [];

    contraAccounts: Observable<CounterParty[]>;
    bulkImportForm: FormGroup;
    counterPartyLimit: LimitProfile;
    selectedRoundRule: RoundRule;

    onImportCollectionAdded: Subscription;
    onImportCollectionChanged: Subscription;
    onContractsAddedSubscription: Subscription;
    onContractsChangedSubscription: Subscription;
    onContractsRemovedSubscription: Subscription;
    onDepositoryNoSwitchedSubscription: Subscription;

    splitControl;

    today = (moment.utc(new Date(), 'X').tz('America/New_York').format('MM-DD-YY'));

    @ViewChild('counterParty') contraElement: ElementRef;

    constructor(public userService: UserService,
                public contractImportService: BulkImportService,
                public snackBar: MatSnackBar,
                public contractDetailPreferenceService: ContractDetailPreferencesService,
                public constantsService: ConstantsService,
                public switcherService: SwitcherService,
                public quoteService: QuoteService,
                public contractService: ContractsService,
                public roundRulesService: RoundRulesService,
                public counterpartiesService: CounterpartyService,
                public afAuthClient: AngularFireAuth,
                private formBuilder: FormBuilder,
                @Inject(LOCALE_ID) private locale: string,
                private http: HttpClient) {

        this.bulkImportForm = this.createBulkImportForm();

        this.bulkImportForm.get('divRate').setValue(100);
        this.bulkImportForm.get('margin').setValue(102);
        this.bulkImportForm.get('rounding').setValue(1);

        this.divRate = 100;
        this.margin = 102;
        this.rounding = 1;
        this.roundSelectUpdate(this.rounding);

        this.gridOptions = {
            columnDefs: this.getColumnDefs(),
            rowSelection: 'multiple',
            rowClassRules: {
                // 'invalid-prototype': (params) => {
                //     const prototype: ContractPrototype = params.data;
                //     if (prototype.quantity) {
                //         return (!(prototype.isValid()) && prototype.status !== '');
                //     } else {
                //         return isNaN(prototype.quantity);
                //     }
                // }
            },
            enableRangeSelection: true,
            floatingFilter: true,
            suppressRowClickSelection: true,
            processDataFromClipboard: (params) => {
                // //console.log(params);
                // this.addRowData(params.data.length - 1);
                return params.data;
            },
            onCellKeyDown: this.onCellKeyDown,
            context: this
        };
        this.tabToNextCell = this.tabToNextCell.bind(this);


        this.detailsGridOptions = {
            columnDefs: this.getDetailColDefs(),
            rowClassRules: {},
            floatingFilter: true,
            suppressRowClickSelection: true,
            context: this
        };
    }

    ngOnInit() {
    }

    createBulkImportForm() {
        return this.formBuilder.group({
            side: [null, Validators.required],
            contra: [],
            counterParty: [null, Validators.required],
            divRate: ['', Validators.required],
            profitCenter: [],
            margin: [102, Validators.compose([Validators.required, Validators.min(100), Validators.max(999)])],
            rounding: [1, Validators.required],
            specFlag: [],
            batchCode: [],
            pastedText: []
        });
    }

    getDetailColDefs(): ColDef[] {
        const columnDefs = [];
        columnDefs.push(
            {
                headerName: 'Symbol', field: 'symbol', width: 100, minWidth: 100,
                filterParams: {
                    newRowsAction: 'keep'
                },
                filter: 'agTextColumnFilter'
            },
            {
                headerName: 'Cusip',
                field: 'cusip',
                width: 100,
                minWidth: 100,
                filterParams: {newRowsAction: 'keep', defaultOption: 'equals'},
                filter: 'agTextColumnFilter'
            },
            {
                headerName: 'S', field: 'side', minWidth: 70, suppressSizeToFit: true, filter: 'agTextColumnFilter',
                filterParams: {newRowsAction: 'keep'}, width: 70,
                sortedAt: 1, sort: 'asc',
            },
            {headerName: 'Contra', field: 'counterPartyName', filter: 'agTextColumnFilter', hide: true, width: 120, filterParams: {newRowsAction: 'keep'}},
            {headerName: 'Broker', field: 'contraAccount', filter: 'agTextColumnFilter', hide: false, width: 120, filterParams: {newRowsAction: 'keep'}},
            {
                headerName: 'B Rate', field: 'borrowRate', minWidth: 75, width: 75, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                valueFormatter: (params) => {
                    if (params.value === 'B Avg') {
                        return params.value;
                    } else if (params.value == undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');
                    }
                }, filter: 'agNumberColumnFilter'
            },
            {
                headerName: 'L Rate', field: 'loanRate', minWidth: 75, width: 75, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                valueFormatter: (params) => {
                    if (params.value === 'L Avg') {
                        return params.value;
                    } else if (params.value == undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');
                    }
                }, filter: 'agNumberColumnFilter'
            },
            {
                headerName: 'B Qty', field: 'borrowQuantity', minWidth: 100, maxWidth: 100, width: 100, suppressSizeToFit: true,
                valueFormatter: (params) => {
                    if (params.value === null) {
                        return '';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                },
                pinnedRowCellRenderer: (params) => {
                    if (params.value === 'Borrow') {
                        return params.value;
                    } else if (params.value !== undefined) {
                        return formatNumber(params.value, this.locale);
                    }
                },
                type: 'numericColumn', filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}
            },
            {
                headerName: 'L Qty', field: 'loanQuantity', minWidth: 100, maxWidth: 100, width: 100, suppressSizeToFit: true,
                valueFormatter: (params) => {
                    if (params.value === null) {
                        return '';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                },
                pinnedRowCellRenderer: (params) => {
                    if (params.value === 'Loan') {
                        return params.value;
                    } else if (params.value !== undefined) {
                        return formatNumber(params.value, this.locale);
                    }
                },
                type: 'numericColumn', filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}
            },
            {
                headerName: 'B Amt', field: 'borrowAmount', type: 'numericColumn', filter: 'agNumberColumnFilter', minWidth: 100, width: 100,
                valueFormatter: (params) => {
                    if (params.value === null) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$', undefined, '1.0-0');
                    }
                },
                pinnedRowCellRenderer: (params) => {
                    if (params.value === 'Borrow $' ||
                        params.value === 'Blended Borrow' ||
                        params.value === 'Funding') {
                        return params.value;
                    } else if (params.value !== undefined) {
                        return params.data.amountFormat(params);
                    }
                },
                filterParams: {newRowsAction: 'keep'}
            },
            {
                headerName: 'L Amt', field: 'loanAmount', type: 'numericColumn', filter: 'agNumberColumnFilter', minWidth: 100, width: 100,
                valueFormatter: (params) => {
                    if (params.value === null) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$', undefined, '1.0-0');
                    }
                },
                pinnedRowCellRenderer: (params) => {
                    if (params.value === 'Loan $' ||
                        params.value === 'Blended Loan' ||
                        params.value === 'Match') {
                        return params.value;
                    } else if (params.value !== undefined) {
                        return params.data.amountFormat(params);
                    }
                },
                filterParams: {newRowsAction: 'keep'}
            }
        );
        columnDefs.push({
            headerName: 'Contract #',
            field: 'contractNo',
            suppressSizeToFit: true,
            minWidth: 120, maxWidth: 120, width: 120,
            filter: 'agNumberColumnFilter',
            filterParams: {newRowsAction: 'keep'},
            pinnedRowCellRenderer: (params) => {
                if (params.value === 'Net' ||
                    params.value === 'Net Spread' ||
                    params.value === 'Total P&L') {
                    return params.value;
                } else if (params.value !== undefined) {
                    return params.data.amountFormat(params);
                }
            },
        });
        if (this.userService.requirePermission('editContract')) {
            columnDefs.push(
                {
                    headerName: 'PC', field: 'profitCenter', minWidth: 55, maxWidth: 55, width: 55, suppressSizeToFit: true,
                    filter: 'agSetColumnFilter', filterParams: {newRowsAction: 'keep'},
                    editable: this.contractService.isEffectiveDateToday(), cellEditor: 'agLargeTextCellEditor',
                    cellEditorParams: {
                        maxLength: 1,
                        rows: 1,
                        cols: 10
                    }
                }
            );
        } else {
            columnDefs.push(
                {
                    headerName: 'PC', field: 'profitCenter', minWidth: 55, maxWidth: 55, width: 55, suppressSizeToFit: true,
                    filter: 'agSetColumnFilter', filterParams: {newRowsAction: 'keep'},
                    editable: false
                },
            );
        }
        columnDefs.push(
            {
                headerName: 'Term', field: 'expireOn', minWidth: 80, maxWidth: 80, width: 80, filterParams: {newRowsAction: 'keep'},
                filter: 'agSetColumnFilter',
            },
        );
        if (this.userService.requirePermission('approveContract')) {
            columnDefs.push(
                {
                    headerName: 'Status', field: 'contractStatus',
                    cellRenderer: 'approvalComponent',
                    filter: 'agTextColumnFilter',
                    filterParams: {newRowsAction: 'keep'},
                    minWidth: 90, maxWidth: 90, width: 90,
                    cellClassRules: {
                        'borrow-recall-return': function (params) {
                            return params.data.contractStatus === 'Closed';
                        },
                    },
                }
            );
        } else {
            columnDefs.push(
                {
                    headerName: 'Status', field: 'contractStatus',
                    minWidth: 80, maxWidth: 80, width: 80, suppressSizeToFit: true, filter: 'agTextColumnFilter', filterParams: {newRowsAction: 'keep'}
                }
            );
        }
        columnDefs.push(
            {headerName: 'Name', field: 'accountName', filter: 'agTextColumnFilter', hide: true, filterParams: {newRowsAction: 'keep'}},
            {headerName: 'Acct', field: 'depositoryNo', width: 75, suppressSizeToFit: true, hide: true, filter: 'agTextColumnFilter', filterParams: {newRowsAction: 'keep'}},
            {
                headerName: 'Rebate', field: 'dailyRebate', type: 'numericColumn', filter: 'agNumberColumnFilter',
                valueFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$');
                    }
                }, filterParams: {newRowsAction: 'keep'}, hide: true
            },
            {
                headerName: 'Rebate To Date', field: 'profitLoss', type: 'numericColumn', filter: 'agNumberColumnFilter',
                valueFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$');
                    }
                }, filterParams: {newRowsAction: 'keep'}, hide: true
            },
            {
                headerName: 'Daily Funding', field: 'dailyFundingCharge', type: 'numericColumn', filter: 'agNumberColumnFilter',
                valueFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$');
                    }
                }, filterParams: {newRowsAction: 'keep'}, hide: true,
            },
            {
                headerName: 'Funding To Date', field: 'fundingCharge', type: 'numericColumn', filter: 'agNumberColumnFilter',
                valueFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$');
                    }
                }, filterParams: {newRowsAction: 'keep'}, hide: true
            },
            {headerName: 'Comment', field: 'publicComment', filter: 'agTextColumnFilter', filterParams: {newRowsAction: 'keep'}, minWidth: 170, maxWidth: 170, width: 170},
            {headerName: 'Private Comment', field: 'privateComment', filter: 'agTextColumnFilter', filterParams: {newRowsAction: 'keep'}, hide: true},
            {headerName: 'Submitter', field: 'submitterNickname', filter: 'agTextColumnFilter', filterParams: {newRowsAction: 'keep'}, minWidth: 95, maxWidth: 95, width: 95},
            // {headerName: 'Reviewer', field: 'approverNickname', filter: 'agTextColumnFilter', filterParams: {newRowsAction: 'keep'}, minWidth: 95, maxWidth: 95, width: 95,},
            {
                headerName: 'Modified', field: 'modifiedOn', hide: false, filterParams: {newRowsAction: 'keep'},
                minWidth: 105, width: 105,
                comparator: this.dateComparator, valueFormatter: (params) => {
                    return moment(params.value).format('hh:mm a');
                }
            },
            {headerName: 'rateSort', field: 'rateSort', hide: true, filterParams: {newRowsAction: 'keep'}, sort: 'desc', sortedAt: 3}
        );
        return columnDefs;
    }

    dateComparator(date1, date2) {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        if (date1 && date2) {
            return momentDate1.diff(momentDate2);
        }
    }

    static statusColors(params) {
        if (params.value === 'X') {
            return {'background-color': '#ff9a9ade'};
        } else {
            return {'background-color': 'inherit'};
        }
    }

    private getColumnDefs(): ColDef[] {
        const colDefs = [];
        colDefs.push(
            {
                headerName: '',
                field: 'selected',
                headerCheckboxSelection: true,
                maxWidth: 40,
                minWidth: 40,
                filter: false,
                suppressMenu: true,
                checkboxSelection: true
            },
            {
                headerName: 'Side',
                field: 'side',
                maxWidth: 65,
                minWidth: 65,
                valueFormatter: params => {
                    if (params.value) {
                        return params.value.toUpperCase();
                    }
                },
            },
            {
                headerName: 'ContractId',
                field: 'contractId',
                hide: true
            },
            {
                headerName: 'Contra',
                field: 'contraLoanetId',
            },
            {
                headerName: 'Account',
                field: 'accountId',
                hide: true
            },
            {
                headerName: 'ContraAccount',
                field: 'contraAccountId',
                hide: true
            },
            {
                headerName: 'Symbol',
                field: 'symbol',
                filter: 'agTextColumnFilter',
            },
            {
                headerName: 'CUSIP',
                field: 'cusip',
                filter: 'agTextColumnFilter',
                minWidth: 120,
            },
            {
                headerName: 'Shares',
                field: 'quantity',
                filter: false,
                valueFormatter: this.numberFormatter,
                valueParser: this.numberParser
            },
            {
                headerName: 'Price',
                field: 'price',
                editable: false,
                filter: false,
            },
            {
                headerName: 'Round',
                field: 'roundRuleValue',
                filter: false,
                editable: false,
                hide: true
            },
            {
                headerName: 'Margin',
                field: 'margin',
                editable: false,
                hide: true
            },
            {
                headerName: 'DivRate',
                field: 'divRate',
                editable: false,
                hide: true
            },
            {
                headerName: 'Amount',
                field: 'amount',
                valueFormatter: (params) => {
                    if (params.value === null || params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$', undefined, '1.0-0');
                    }
                },
                editable: false,
                filter: false,
            },
            {
                headerName: 'Rate',
                field: 'rebateRate',
                filter: false,

            },
            {
                headerName: 'PRC',
                field: 'profitCenter',
                valueFormatter: params => {
                    if (params.value) {
                        return params.value.toUpperCase();
                    }
                },
            },
            {
                headerName: 'Comments',
                field: 'publicComment',
                filter: false
            },
            {
                headerName: 'BatchCode',
                field: 'batchCode',
                valueFormatter: params => {
                    if (params.value) {
                        return params.value.toUpperCase();
                    }
                },
            },
            {
                headerName: 'SpecFlag',
                field: 'specFlag',
                valueFormatter: params => {
                    if (params.value) {
                        return params.value.toUpperCase();
                    }
                },
            },
            {
                headerName: 'Status',
                field: 'bulkStatus',
                hide: false,
                editable: false,
                minWidth: 125,
                maxWidth: 125,
                width: 125,
                cellStyle: BulkImportComponent.statusColors,
                valueFormatter: (params) => {
                    switch (params.value) {
                        case 'U':
                            return 'Unsubmitted';
                            break;
                        case 'S':
                            return 'Submitted';
                            break;
                        case 'P':
                            return 'Processing';
                            break;
                        case'X':
                            return 'Error';
                            break;
                        default:
                            return params.value;
                    }
                }
            },
            {
                headerName: 'Error Message',
                field: 'errorMessage',
                tooltipField: 'errorMessage',
                filter: false,
                tooltipComponentParams: {color: '#ececec'},
                minWidth: 300,
                width: 300,
            },
        );
        return colDefs;
    }

    ngAfterViewInit(): void {
        this.contraAccounts = this.bulkImportForm.get('counterParty')!.valueChanges
            .pipe(
                map(value => this._filterGroup(value))
            );

        this.splitControl =
            Split(['#import', '#details'], {
                sizes: [40, 60],
                direction: 'vertical',
                gutterSize: 15,
            });
    }

    private _filterGroup(value: string): CounterParty[] {
        if (value) {
            return this.counterpartiesService.combinedCounterParties
                .map(contra => ({
                    counterPartyId: contra.counterPartyId,
                    entityId: contra.entityId,
                    name: contra.name,
                    hasLoanNet: contra.hasLoanNet,
                    canReturnOnRecall: contra.canReturnOnRecall,
                    canRecallPartial: contra.canRecallPartial,
                    occMemberId: contra.occMemberId,
                    bic: contra.bic,
                    billingReference: contra.billingReference,
                    complianceReference: contra.complianceReference,
                    currencyId: contra.currencyId,
                    createdOn: contra.createdOn,
                    modifiedOn: contra.modifiedOn,
                    counterPartyTypeId: contra.counterPartyTypeId,
                    counterPartyStatusId: contra.counterPartyStatusId,
                    defaultRoundRuleValue: contra.defaultRoundRuleValue,
                    defaultMargin: contra.defaultMargin,
                    accounts: _filter(contra.accounts, value),
                    shortCode: contra.shortCode,
                    lendLimit: contra.lendLimit,
                    borrowLimit: contra.borrowLimit,
                    bizEmail: contra.bizEmail,
                    opsEmail: contra.opsEmail,
                    internal: contra.internal
                }))
                .filter(contra => contra.accounts && contra.accounts.length > 0);
        }
        return this.counterpartiesService.combinedCounterParties;
    }

    verifyContraInput(): void {
        let dtcInput: string;
        let contraAccount: CounterPartyAccount;
        dtcInput = this.bulkImportForm.get('counterParty').value;
        this.contra = dtcInput;

        if (dtcInput) {
            contraAccount = this.counterpartiesService.getAccountByLoanetIdOrName(dtcInput);
        }

        if (contraAccount) {
            this.setContraAccount(contraAccount);
            this.bulkImportForm.patchValue({counterParty: contraAccount.loanetId + ' - ' + contraAccount.name});
        } else {
            // this.externalCounterPartyLimit = null;
            this.bulkImportForm.controls['counterParty'].setErrors({'invalid': true});
        }
    }

    refreshImportDataFilters() {
        this.importGridApi.setRowData([]);
        this.importGridApi.setRowData(this.contractImportService.collectionData.filter(s => ((s.bulkStatus != 'S' && s.bulkStatus != 'D') && s.enteredBy === this.userService.user.loginId)));
    }

    refreshCompletedDataFilters() {
        this.completedGridApi.setRowData([]);
        this.completedGridApi.setRowData(this.contractImportService.collectionData.filter(s => (s.bulkStatus == 'S')));
    }

    setupImportSubscriptions() {
        this.refreshImportDataFilters();
        this.onImportCollectionAdded = this.contractImportService.onCollectionAdded.subscribe(bulkRequests => {
            const newRows: BulkRequest[] = [];
            for (const bulkRequest of bulkRequests.filter(s => ((s.bulkStatus != 'S' && s.bulkStatus != 'D') && s.enteredBy === this.userService.user.loginId))) {
                const rowNode = this.importGridApi.getRowNode(bulkRequest.primaryKey);
                if (rowNode === undefined) {
                    newRows.push(bulkRequest);
                }
            }
            this.importGridApi.updateRowData({
                add: newRows
            });
        });
        this.onImportCollectionChanged = this.contractImportService.onCollectionChanged.subscribe(bulkImportRows => {
            this.importGridApi.updateRowData({update: bulkImportRows.filter(s => (this.importGridApi.getRowNode(s.primaryKey) != undefined))});
            this.importGridApi.updateRowData({remove: bulkImportRows.filter(s => ((s.bulkStatus === 'S' || s.bulkStatus === 'D' ||
                    s.enteredBy != this.userService.user.loginId)) && (this.importGridApi.getRowNode(s.primaryKey) != undefined))});
        });

    }

    depositoryFilter(contract: Contract): boolean {
        return (contract.depositoryNo === this.switcherService.getSelectedDepositoryNo() && contract.startOn === this.today);
    }

    contractAdded(contracts: Contract[]) {
        if (this.completedGridApi) {
            if (contracts.length > 0) {
                const newRows: RowNode[] = [];
                const newContracts: Contract[] = [];
                for (const contract of contracts.filter(c => this.depositoryFilter(c))) {
                    const rowNode = this.completedGridApi.getRowNode(contract.primaryKey);
                    if (rowNode === undefined) {
                        newContracts.push(contract);
                    }
                }
                this.completedGridApi.updateRowData({
                    add: newContracts
                });
                for (const contract of newContracts) {
                    const rowNode = this.completedGridApi.getRowNode(String(contract.primaryKey));
                    newRows.push(rowNode);
                }
                // this.completedGridApi.flashCells({
                //     rowNodes: newRows
                // });
            }
        }
    }

    contractChanged(contracts: Contract[]) {
        if (this.completedGridApi) {
            this.completedGridApi.updateRowData({
                update: contracts.filter(c => this.depositoryFilter(c))
            });
        }
    }

    contractRemoved(contracts: Contract[]) {
        if (this.completedGridApi) {
            this.completedGridApi.updateRowData({
                remove: contracts.filter(c => this.depositoryFilter(c))
            });
        }
    }

    setupCompletedSubscriptions() {
        this.completedGridApi.setRowData([]);
        this.completedGridApi.setRowData(this.contractService.currentCollection.filter((c => this.depositoryFilter(c))));

        this.onContractsAddedSubscription =
            this.contractService.onCollectionAdded.subscribe(contracts => this.contractAdded(contracts));

        this.onContractsChangedSubscription =
            this.contractService.onCollectionChanged.subscribe(contracts => this.contractChanged(contracts));

        this.onContractsRemovedSubscription =
            this.contractService.onCollectionRemoved.subscribe(contracts => this.contractRemoved(contracts));
    }

    setContraAccount(contraAccount: CounterPartyAccount): void {
        // if the acct belongs to unknown counterparty hten its not good
        if (contraAccount.counterPartyId === this.counterpartiesService.UNKNOWN_COUNTERPARTY_ID) {
            this.bulkImportForm.get('counterParty').setErrors({'invalid': true});
            return;
        }

        const contra: CounterParty = this.counterpartiesService.getCounterPartyById(contraAccount.counterPartyId);
        this.counterpartiesService.getCounterPartyLimitProfile(contraAccount.counterPartyId, false)
            .subscribe((limitProfile: any) => {
                //console.log('[LimitProfile] limit: ', limitProfile);
                // this.externalCounterPartyLimitLoading = false;
                this.counterPartyLimit = new LimitProfile(limitProfile);
            });

        this.counterpartiesService.getAccountLimitProfile(contraAccount.accountId, false)
            .subscribe((limitProfile: any) => {
                //console.log('[LimitProfile] limit: ', limitProfile);
                // this.externalAccountLimitLoading = false;
                this.counterPartyLimit = new LimitProfile(limitProfile);
            });
        if (contra) {
            this.bulkImportForm.get('margin').setValue(contra.defaultMargin * 100);
            this.bulkImportForm.get('rounding').setValue(contra.defaultRoundRuleValue);
            this.margin = contra.defaultMargin * 100;
            this.rounding = contra.defaultRoundRuleValue;

            this.roundSelectUpdate(contra.defaultRoundRuleValue);
            this.marginUpdate(contra.defaultMargin * 100);
        }
    }

    roundSelectUpdate(roundRule: number): void {
        // this.contract.roundRuleValue = roundRule;
        for (const roundRuleLoop of this.roundRulesService.roundRules) {
            if (roundRuleLoop.roundRuleValue === roundRule) {
                this.selectedRoundRule = roundRuleLoop;
                // this.rounding = roundRuleLoop;
                break;
            }
        }
        this.updateContractValue();
    }

    marginUpdate(margin: number): void {
        this.margin = margin;
        this.updateContractValue();
    }

    updateContractValue(): void {
        // need quote price, margin recallQty, rounding rule, quantity
        // //console.log('Calling update contract value!');
        // if (this.quote && this.selectedRoundRule && this.contract.quantity && this.margin) {
        //     let amount: number = this.quote.closePrice * (this.selectedMargin / 100);
        //     //console.log('amount pre-round: ', amount);
        //     if (this.selectedRoundRule.value !== 0) {
        //         amount = Math.ceil(amount * (1 / this.selectedRoundRule.value)) / (1 / this.selectedRoundRule.value);
        //     }
        //     //console.log('amount post-round: ', amount);
        //     this.contract.amount = amount * this.contract.quantity;
        //     // this.contractForm.get('benchmarkRate').setValue(this.contract.benchmarkId);
        //     this.contractForm.get('amount').setValue(this.contract.amount);
        //     this.contractForm.get('visibleAmount').setValue(formatCurrency(this.contract.amount, 'en-US', 'USD'));
        // } else {
        //     //console.log('Not ready to update value yet');
        // }
    }

    autoSizeAll(event) {
        this.importGridColumnApi = event.columnApi;
        this.importGridColumnApi.autoSizeColumns(this.importGridColumnApi.getAllColumns());
    }

    onImportGridReady(params) {
        this.importGridApi = params.api;
        this.importGridColumnApi = params.columnApi;
        this.gridOptions.api = params.api;
        this.autoSizeAll(params);
        this.setupImportSubscriptions();
    }

    onDetailsGridReady(params) {
        this.completedGridApi = params.api;
        this.completedGridColumnApi = params.columnApi;
        this.detailsGridOptions.api = params.api;
        this.setupCompletedSubscriptions();
        this.completedGridApi.setSortModel(this.getBulkImportDetailsPreferences().sort);
        this.onDepositoryNoSwitchedSubscription = this.switcherService.depositoryNoSwitched.subscribe(depositoryNo => {
            this.completedGridApi.setRowData([]);
            this.completedGridApi.setRowData(this.contractService.currentCollection.filter((c => this.depositoryFilter(c))));
        });
    }

    clearGrid() {
        const rows = this.importGridApi.getSelectedRows();
        this.importGridApi.deselectAll();
        this.contractImportService.deleteRows(rows);

    }

    submit() {
        const rows = this.importGridApi.getSelectedRows();
        this.importGridApi.deselectAll();
        this.http.post(environment.verticalApiEndpoint + 'bulk/requests/send', {items: rows.filter(r => r.bulkStatus === 'U')})
            .subscribe((response: any) => {
                this.snackBar.open('Successfully uploaded and being processed.', null, {
                    verticalPosition: 'top',
                    horizontalPosition: 'center',
                    panelClass: 'snackbar',
                    duration: 4000,
                });
            });
    }

    cellEditingStopped(event) {
        if (event.value !== '') {
            if (event.colDef.field === 'symbol' || event.colDef.field === 'cusip') {
                this.http.post(environment.verticalApiEndpoint + 'securityMaster/find/list', {items: [event.value]})
                    .subscribe((response: any) => {
                        for (let i in response.items) {
                            const quote = new Quote(response.items[i]);
                            event.data.symbol = quote.symbol.toUpperCase();
                            event.data.cusip = quote.cusip.toUpperCase();
                            event.data.price = quote.closePrice;
                            event.data.amount = this.calculatePrice(quote.closePrice, event.data.quantity);
                        }

                        let reqs: BulkRequest[] = [];
                        event.data.requestTime = null;
                        reqs.push(event.data);

                        this.http.post(environment.verticalApiEndpoint + 'bulk/requests/save', {items: reqs})
                            .subscribe((response: any) => {
                            });
                    });
            } else {
                if (event.colDef.field === 'quantity') {
                    event.data.amount = this.calculatePrice(event.data.price, event.data.quantity);
                    this.importGridApi.refreshCells();
                }

                let reqs: BulkRequest[] = [];
                event.data.requestTime = null;
                reqs.push(event.data);

                this.http.post(environment.verticalApiEndpoint + 'bulk/requests/save', {items: reqs})
                    .subscribe((response: any) => {
                    });

            }

        }
    }

    manipulateTextLines() {
        if (this.pastedText && this.pastedText != '') {
            this.pastedText = this.pastedText.trim().replace(/(^[ \t]*\n)/gm, '');
            this.lines = this.pastedText.split('\n');
        } else {
            this.lines = [];
        }
    }

    applyDefaults() {

        if (this.lines && this.lines.length > 0) {

            let newRows: BulkRequest[] = [];

            // this.pastedText = this.pastedText.trim().replace(/(^[ \t]*\n)/gm, '');
            // this.lines = this.pastedText.split('\n');

            for (let i = 0; i < this.lines.length; i++) {
                let row = this.contractImportService.generateBlankBulkImportRow();
                row.contraDepositoryNo = this.counterpartiesService.getAccountByLoanetIdOrName(this.contra).dtcId
                    ? this.counterpartiesService.getAccountByLoanetIdOrName(this.contra).dtcId : null;
                row.depositoryNo = this.switcherService.getSelectedDepositoryNo();
                row.contraLoanetId = this.contra ? this.contra : null;
                row.loanetId = this.switcherService.getSelectedDepositoryNo();
                row.accountId = this.counterpartiesService.getAccountByLoanetIdOrName(this.switcherService.getSelectedDepositoryNo()).accountId;
                row.contraAccountId = this.counterpartiesService.getAccountByLoanetIdOrName(this.contra).accountId;
                row.side = this.side ? this.side : null;
                row.profitCenter = this.profitCenter ? this.profitCenter : null;
                row.margin = this.margin ? this.margin : null;
                row.roundRuleValue = this.rounding ? this.rounding : null;
                row.divRate = this.divRate ? this.divRate : null;
                row.batchCode = this.batchCode ? this.batchCode : null;
                row.specFlag = this.specFlag ? this.specFlag : null;

                let values = this.lines[i].split(/\s+/g);
                if (values[0].length === 9) {
                    let cusip = values[0].toUpperCase();
                    row.cusip = cusip;
                    // symbolOrCusip.push(cusip);
                } else {
                    let symbol = values[0].toUpperCase();
                    row.symbol = symbol;
                    // symbolOrCusip.push(symbol);
                }

                row.quantity = Number(values[1]);
                row.rebateRate = Number(values[2]);

                newRows.push(row);
            }

            this.http.post(environment.verticalApiEndpoint + 'bulk/requests/save', {items: newRows})
                .subscribe((response: any) => {
                    this.bulkImportForm.controls.pastedText.setValue('');
                    this.lines = [];
                });

            // this.http.post(environment.verticalApiEndpoint + 'securityMaster/find/list', {items: symbolOrCusip})
            //     .subscribe((response: any) => {
            //         for (let i in response.items) {
            //             let quote = new Quote(response.items[i]);
            //             symbolquotes.set(quote.symbol.toUpperCase(), quote);
            //             cusipquotes.set(quote.cusip.toUpperCase(), quote);
            //         }
            //
            //         for (let i in newRows) {
            //             if (newRows[i].symbol) {
            //                 let q = symbolquotes.get(newRows[i].symbol);
            //                 if (q) {
            //                     //console.log(q);
            //                     newRows[i].cusip = q.cusip;
            //                     let restrictions = q.restrictions ? q.restrictions : null;
            //                     let blocked = q.blocked ? q.blocked : null;
            //                     if (q.upToDate) {
            //                         newRows[i].price = q.closePrice;
            //                         newRows[i].amount = this.calculatePrice(q.closePrice, newRows[i].quantity);
            //                         newRows[i].restrictions = restrictions;
            //                         newRows[i].blocked = blocked;
            //                     } else {
            //                         newRows[i].price = -999;
            //                         newRows[i].amount = null;
            //                         newRows[i].restrictions = restrictions;
            //                     }
            //                     // newRows[i].instrumentId = q.instrumentId;
            //                 } else {
            //                     newRows[i].cusip = 'UNKNOWN';
            //                     newRows[i].price = 0;
            //                     newRows[i].amount = 0;
            //                     newRows[i].restrictions = null;
            //                 }
            //             } else if (newRows[i].cusip) {
            //                 let q = cusipquotes.get(newRows[i].cusip);
            //                 if (q) {
            //                     newRows[i].symbol = q.symbol;
            //                     let restrictions = q.restrictions ? q.restrictions : null;
            //                     let blocked = q.blocked ? q.blocked : null;
            //                     if (q.upToDate) {
            //                         newRows[i].price = q.closePrice;
            //                         newRows[i].amount = this.calculatePrice(q.closePrice, newRows[i].quantity);
            //                         newRows[i].restrictions = restrictions;
            //                         newRows[i].blocked = blocked;
            //                     } else {
            //                         newRows[i].price = -999;
            //                         newRows[i].amount = null;
            //                         newRows[i].restrictions = restrictions;
            //                     }
            //                     // newRows[i].instrumentId = q.instrumentId;
            //                 } else {
            //                     newRows[i].symbol = 'UNKNOWN';
            //                     newRows[i].price = 0;
            //                     newRows[i].amount = 0;
            //                     newRows[i].restrictions = null;
            //                     // newRows[i].instrumentId = 0;
            //                 }
            //             }
            //         }
            //
            //         this.http.post(environment.verticalApiEndpoint + 'bulk/requests/save', {items: newRows})
            //             .subscribe((response: any) => {
            //                 this.bulkImportForm.controls.pastedText.setValue('');
            //             });
            //     });
        }
    }

    addBlankRow() {
        if (this.importGridApi.getDisplayedRowCount() == 0 || !this.isEmptyRow(<BulkRequest>this.importGridApi.getDisplayedRowAtIndex(this.importGridApi.getLastDisplayedRow()).data)) {
            this.contractImportService.addBlankRowsToFirebase(1).then(result => {
                this.importGridApi.setFocusedCell(this.importGridApi.getLastDisplayedRow(), 'side');
            });
        }
    }

    isEmptyRow(row: BulkRequest) {
        if (row.batchCode === null &&
            row.publicComment === null &&
            row.depositoryNo === null &&
            row.contraDepositoryNo === null &&
            row.cusip === null &&
            row.price === null &&
            row.quantity === null &&
            row.rebateRate === null &&
            row.side === null &&
            row.specFlag === null &&
            row.bulkStatus === null &&
            row.symbol === null &&
            row.amount === null) {
            return true;
        } else {
            return false;
        }
    }

    calculatePrice(price: number, shares: number): number {
        let value: number;
        let amount: number = price * (this.margin / this.divRate);
        if (price === -999) {
            return null;
        }
        if (this.rounding !== 0) {
            amount = Math.ceil(amount * (1 / this.selectedRoundRule.value)) / (1 / this.selectedRoundRule.value);
        }
        return value = amount * shares;
    }

    public getRowNodeId(data) {
        return data.primaryKey;
    }

    ngOnDestroy(): void {
        this.onImportCollectionAdded.unsubscribe();
        this.onImportCollectionChanged.unsubscribe();
        this.onContractsAddedSubscription.unsubscribe();
        this.onContractsRemovedSubscription.unsubscribe();
        this.onContractsChangedSubscription.unsubscribe();
    }

    numberFormatter(params) {
        if (params.value) {
            return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1');
        } else {
            return params.value;
        }
    }

    numberParser(params)         {
        return Number(params.newValue);
    }

    tabToNextCell(params) {
        var previousCell = params.previousCellPosition,
            lastRowIndex = previousCell.rowIndex,
            result;
        if (previousCell.column.getColId() === 'symbol' || previousCell.column.getColId() === 'cusip') {
            result = {
                rowIndex: lastRowIndex,
                column: this.importGridColumnApi.getColumn('quantity'),
                floating: previousCell.floating,
            };
        } else if (previousCell.column.getColId() === 'specFlag') {
            if (lastRowIndex === (this.importGridApi.getDisplayedRowCount() - 1)) {
                this.addBlankRow();
            }

            result = {
                rowIndex: lastRowIndex + 1,
                column: this.importGridColumnApi.getColumn('side'),
                floating: previousCell.floating,
            };
        } else if (previousCell.column.getColId() === 'status' && params.nextCellPosition === null) {
            this.addBlankRow();
            result = {
                rowIndex: lastRowIndex + 1,
                column: this.importGridColumnApi.getColumn('side'),
                floating: previousCell.floating,
            };
        } else {
            result = {
                rowIndex: lastRowIndex,
                column: params.nextCellPosition.column,
                floating: previousCell.floating,
            };
        }
        return result;
    }

    onCellKeyDown(event) {
        if (event.event.code === 'ArrowDown' && event.node.lastChild) {
            // @ts-ignore
            this.context.addBlankRow();
        }
    }

    cellValueChanged(event): void {
        // this.contractImportService.updatePrototype(event.data);
    }

    getBulkImportDetailsPreferences(): ContractDetailPreferences {

        return new ContractDetailPreferences({
            primaryKey: 'BULK_IMPORT_DETAILS',
            name: 'Bulk Import Details',
            sort: [
                {
                    colId: 'modifiedOn',
                    sort: 'desc'
                }
            ]
        });
    }

}





