import {Component, Inject, LOCALE_ID, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ColDef,GridApi} from 'ag-grid-community';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {DtcTransactionsService} from './dtc-transactions.service';
import {formatNumber} from '@angular/common';
import {Subscription} from 'rxjs';
import {SwitcherService} from '../../../../shared/switcher/switcher.service';
import {DtcSummary} from '../dtc-summary/dtc-summary';
import {DtcTransaction} from './dtc-transaction';

@Component({
    selector: 'app-dtc-transactions',
    templateUrl: './dtc-transactions.component.html',
    styleUrls: ['./dtc-transactions.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class DtcTransactionsComponent implements OnInit, OnDestroy {

    gridApi: GridApi;
    colDefs: ColDef[];
    defaultColDef: any;
    onDepositoryNoChanged: Subscription;
    onDTCTransactionAdded: Subscription;
    onDTCTransactionChanged: Subscription;
    onDTCTransactionRemoved: Subscription;

    constructor(public dtcTransactionService: DtcTransactionsService,
                @Inject(LOCALE_ID) private locale: string,
                public switcherService: SwitcherService
    ) {
        this.colDefs = [
            {
                headerName: 'Symbol',
                field: 'symbol',
                filter: 'agTextColumnFilter'
            },
            {
                headerName: 'CUSIP',
                field: 'cusip',
                filter: 'agTextColumnFilter'
            },
            {
                headerName: 'Quantity',
                field: 'quantity',
                cellRenderer: (params) => {
                    if (params.value === null) {
                        return '0';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                },
            },
            {
                headerName: 'Amount',
                field: 'amount'
            },
            {
                headerName: 'DTC ID',
                field: 'dtcId'
            },
            {
                headerName: 'Contra',
                field: 'contraDtcId'
            },
            {
                headerName: 'Occurred At',
                field: 'transactTime',
                sort: 'desc'
            },
            {
                headerName: 'Reason Code',
                field: 'reason'
            },
            {
                headerName: 'Status',
                field: 'status'
            },
            {
                headerName: 'Action',
                field: 'action'
            }
        ];
        this.defaultColDef = {
            enableCellChangeFlash: true,
            sortable: true,
            filter: true,
            resizable: true
        };
    }

    ngOnInit() {
    }


    onGridReady(params): void {
        this.gridApi = params.api;
        this.dtcTransactionService.initializeService(
            this.dtcTransactionService.collectionName,
            this.dtcTransactionService.createTransactionCallback
        );
        this.setupSubscriptions();
        this.onDepositoryNoChanged = this.switcherService.depositoryNoSwitched.subscribe(depositoryNo => {
            this.gridApi.setRowData([]);
            this.gridApi.setRowData(this.dtcTransactionService.collectionData.filter(dtcTransaction => this.depositoryFilter(dtcTransaction)));
        });
    }

    depositoryFilter(dtcTransaction: DtcTransaction): boolean {
        return dtcTransaction.dtcId == this.switcherService.getSelectedDepositoryNo();
    }


    getRowNodeId(data) {
        return data.dtcTranId;
    }

    ngOnDestroy(): void {
        this.dtcTransactionService.clearSubscriptions();
    }

    setupSubscriptions() {
        this.gridApi.setRowData([]);
        this.gridApi.setRowData(this.dtcTransactionService.collectionData.filter(dtcTransaction => this.depositoryFilter(dtcTransaction)));
        this.onDTCTransactionAdded = this.dtcTransactionService.onCollectionAdded.subscribe(dtcTransactions => {
            const newSummaries: DtcTransaction[] = [];
            for (const dtcTransaction of dtcTransactions.filter(t => this.depositoryFilter(t))) {
                const rowNode = this.gridApi.getRowNode(dtcTransaction.primaryKey);
                if (rowNode === undefined) {
                    newSummaries.push(dtcTransaction);
                }
            }
            this.gridApi.updateRowData({
                add: newSummaries
            });

        });
        this.onDTCTransactionChanged = this.dtcTransactionService.onCollectionChanged.subscribe(dtcTransactions => {
            this.gridApi.updateRowData({update: dtcTransactions.filter(t => this.depositoryFilter(t))});
        });
        this.onDTCTransactionRemoved = this.dtcTransactionService.onCollectionRemoved.subscribe(dtcTransactions => {
            this.gridApi.updateRowData({remove: dtcTransactions.filter(t => this.depositoryFilter(t))});
            this.gridApi.flashCells();
        });
    }
}
