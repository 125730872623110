import {AfterViewInit, Attribute, Component, ElementRef, Inject, Input, OnDestroy, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, ValidationErrors, Validators} from '@angular/forms';
import {ErrorStateMatcher, MAT_DIALOG_DATA, MatDialogRef, MatSnackBar} from '@angular/material';
import {Contract} from '../contracts/contract.model';
import {UserService} from '../../../../auth/user/user.service';
import {CounterParty} from '../../operations/counterparties/counterparty.model';
import {Observable, Subscription} from 'rxjs';
import {LimitProfile} from '../../operations/limitprofiles/limitprofile.model';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Quote} from '../../instruments/quotes/quote.model';
import {CounterPartyAccount} from '../../operations/accounts/account.model';
import {RoundRule} from '../../operations/constants/roundrules/roundrule.model';
import {Benchmark} from '../../operations/constants/benchmarks/benchmark.model';
import {formatCurrency} from '@angular/common';
import {ContractsService} from '../contracts/contracts.service';
import {CounterpartyService} from '../../operations/counterparties/counterparty.service';
import {RoundRulesService} from '../../operations/constants/roundrules/round-rules.service';
import {BenchmarksService} from '../../operations/constants/benchmarks/benchmarks.service';
import {QuoteService} from '../../instruments/quotes/quote.service';
import {Moment} from 'moment';
import {SwitcherService} from '../../../../shared/switcher/switcher.service';
import {FplPosition} from '../../operations/fpl/fplAccountPosition.model';
import {FplService} from '../../operations/fpl/fpl.service';


@Component({
    selector: 'fpl-trade-form',
    templateUrl: './fpl-trade-form.component.html',
    styleUrls: ['./fpl-trade-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class FplTradeFormComponent implements OnDestroy, AfterViewInit {
    contractForm: FormGroup;

    a: FormGroupDirective;
    action: string;
    contract: Contract;
    quote: Quote;
    loadingQuote: boolean;
    loadingSubmit: boolean;
    validSymbol: boolean;
    fplPosition: FplPosition;
    symbol: string;

    constructor(
        public userService: UserService,
        private formBuilder: FormBuilder,
        private httpClient: HttpClient,
        private contractService: ContractsService,
        public counterpartiesService: CounterpartyService,
        public quoteService: QuoteService,
        public fplService: FplService,
        private snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<FplTradeFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.loadingQuote = false;
        this.fplPosition = data.fplAccountPos;
        this.contract = new Contract({});
        this.contractForm = this.createContractForm();
    }

    ngOnDestroy() {
    }

    setSymbol(symbol: string): void {
        this.symbol = symbol;
        this.contractForm.get('symbol').setValue(symbol);
        if (symbol !== '') {
            this.quoteService.getQuote(this.symbol, this.contractService.effectiveDate)
                .subscribe((quote) => {
                    if (quote) {
                        this.quote = new Quote(quote);
                        this.updateContractValue();
                        if(!quote.upToDate){
                            this.contractForm.controls['symbol'].setErrors({'invalid': true});
                            this.contractForm.controls['symbol'].markAsTouched();
                            this.validSymbol = false;
                            this.loadingQuote = false;
                            return;
                        }
                    } else {
                        this.contractForm.controls['symbol'].setErrors({'invalid': true});
                        this.contractForm.controls['symbol'].markAsTouched();
                        this.quote = null;
                        this.validSymbol = false;
                        this.loadingQuote = false;
                    }
                }, (error) => {
                    this.updateContractValue();
                    this.contractForm.controls['symbol'].setErrors({'invalid': true});
                    this.contractForm.controls['symbol'].markAsTouched();
                    this.quote = null;
                    this.validSymbol = false;
                    this.loadingQuote = false;
                });
        }
    }

    updateContractValue(): void {
        this.contractForm.controls['quantity'].markAsPristine();
        if (this.quote && this.contract.quantity) {
            if (this.quote.closePrice === 0) {
                this.contractForm.controls['quantity'].setErrors({'invalid': true});
                this.contractForm.controls['quantity'].markAsTouched();
            }
            let amount: number = this.quote.closePrice * (102 / 100);
            amount = Math.ceil(amount * (1 / 1)) / (1 / 1);
            this.contract.amount = amount * this.contract.quantity;
            this.contractForm.get('amount').setValue(this.contract.amount);
            this.contractForm.get('visibleAmount').setValue(formatCurrency(this.contract.amount, 'en-US', 'USD'));
        } else {
            //console.log('Not ready to update value yet');
        }
    }

    parseNumberText(num: string, field: string): number {
        const newNum: string = num.replace(/\D/g,
            '');
        const parsed = parseInt(newNum, 10);

        if (field === 'quantity') {
            this.contract.quantity = parsed;
            this.updateContractValue();
        } else if (field === 'margin') {
            this.updateContractValue();
        }
        return parsed;
    }

    createContractForm() {
        return this.formBuilder.group({
            symbol: [],
            quantity: ['', [Validators.max(this.fplPosition.available), Validators.min(100)]],
            rate: [null, Validators.compose([Validators.min(-999), Validators.max(999)])],
            amount: [],
            visibleAmount: []
        });
    }

    submit(tradeForm: FormGroupDirective) {
        const contractData = this.contractForm.getRawValue();
        this.setSymbol(contractData.symbol);

        const contract = {
            accountId: this.counterpartiesService.getAccountByLoanetIdOrName('0294').accountId,
            amount: contractData.amount,
            batchCode: 'W',
            specFlag: 'P',
            contraAccountId: this.fplPosition.accountId,
            contraDepositoryNo: '0294',
            depositoryNo: '0294',
            divRate: '100',
            margin: '100',
            quantity: contractData.quantity,
            rate: contractData.rate,
            symbol: contractData.symbol,
            side: 'B',
            roundRuleValue: '1',
            fplPositionId: this.fplPosition.fplPositionId
        };

        // alert(this.fplAccountPosition.fplPositionId);
        // return;

        this.contractService.updateContract(contract)
            .then((resp) => {
                this.snackBar.open('Submitted Successfully', null, {
                    verticalPosition: 'bottom',
                    horizontalPosition: 'center',
                    panelClass: 'snackbar',
                    duration: 2000,
                });
                this.loadingSubmit = false;
                this.resetForm(tradeForm);
                this.dialogRef.close();
                // this.fplAccountPosition.amountToBorrow = contractData.quantity;
                // this.fplAccountPosition.borrowPrice = contractData.rate;
                // //console.log(this.fplAccountPosition);
                // this.fplService.borrowFromFplAccountPosition(this.fplAccountPosition).then(() => {
                //     this.snackBar.open('Submitted Successfully', null, {
                //         verticalPosition: 'bottom',
                //         horizontalPosition: 'center',
                //         panelClass: 'snackbar',
                //         duration: 2000,
                //     });
                //     this.loadingSubmit = false;
                //     this.resetForm(tradeForm);
                //     this.dialogRef.close();
                // });
            })
            .catch((error) => {
                this.loadingSubmit = false;
            });
        // this.fplAccountPosition.amountToReturn = contractData.quantity;
        // this.fplAccountPosition.borrowPrice = contractData.rate;
        // //console.log(this.fplAccountPosition);
        // this.fplService.returnToFplAccountPosition(this.fplAccountPosition).then();
        // this.loadingSubmit = false;
        // this.resetForm(tradeForm);
        // this.dialogRef.close();
    }


    private finalContractValidation(contract: any): boolean {
        let isValid = true;
        if (contract.amount < 100) {
            isValid = false;
            this.contractForm.controls['quantity'].setErrors({
                Reason: 'Contract amount below minimum'
            });
        }
        return isValid;
    }

    ngAfterViewInit(): void {
        this.setSymbol(this.fplPosition.symbol);
        this.contractForm.controls['quantity'].setValue(this.fplPosition.available);
        this.contract.quantity = this.fplPosition.available;
        this.updateContractValue();
    }

    clearAllValidationErrors() {
        for (let name in this.contractForm.controls) {
            if (name !== 'symbol') {
                this.contractForm.controls[name].setErrors(null);
            }
        }
    }

    resetForm(tradeForm: FormGroupDirective) {
        const result = [];
        Object.keys(this.contractForm.controls).forEach(key => {

            const controlErrors: ValidationErrors = this.contractForm.get(key).errors;
            if (controlErrors) {
                Object.keys(controlErrors).forEach(keyError => {
                    result.push({
                        'control': key,
                        'error': keyError,
                        'value': controlErrors[keyError]
                    });
                });
            }
        });

        Object.keys(this.contractForm.controls).forEach(key => {

            const controlErrors: ValidationErrors = this.contractForm.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    //console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });

        this.clearAllValidationErrors();
        tradeForm.resetForm();
        this.quote = null;
        this.setSymbol(this.fplPosition.symbol);
        this.contractForm.get('symbol').markAsTouched();
        this.contract = new Contract({});
    }
}
