import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../../auth/auth.guard';
import { DtcSummaryComponent } from './dtc-summary/dtc-summary.component';
import { DtcTransactionsComponent } from './dtc-transactions/dtc-transactions.component';
import {
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule
} from '@angular/material';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {AgGridModule} from 'ag-grid-angular';
import { CombinedDtcSummaryComponent } from './combined-dtc-summary/combined-dtc-summary.component';
import { DtcSummaryFilterComponent } from './dtc-summary-filter/dtc-summary-filter.component';
import { CombinedDtcTransactionsComponent } from './combined-dtc-transactions/combined-dtc-transactions.component';
import { DtcTransactionsFilterComponent } from './dtc-transactions-filter/dtc-transactions-filter.component';

const routes: Routes = [
    {
        path: 'dtc/combined-summary',
        component: CombinedDtcSummaryComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dtc/summary',
        component: DtcSummaryComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'dtc/transactions',
        component: DtcTransactionsComponent,
        canActivate: [AuthGuard]
    }
];


@NgModule({
    declarations: [
        DtcSummaryComponent,
        DtcTransactionsComponent,
        CombinedDtcSummaryComponent,
        DtcSummaryFilterComponent,
        CombinedDtcTransactionsComponent,
        DtcTransactionsFilterComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        MatIconModule,
        MatSidenavModule,
        FuseSharedModule,
        AgGridModule,
        MatFormFieldModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatInputModule,
        MatButtonModule,
        MatButtonToggleModule
    ]
})
export class DtcModule {
}
