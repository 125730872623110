export class LimitProfile {
    borrow: number;
    lend: number;
    borrowLimit: number;
    lendLimit: number;

   constructor(limitProfile) {
       this.borrow = limitProfile.borrow;
       this.lend = limitProfile.lend;
       this.borrowLimit = limitProfile.borrowLimit;
       this.lendLimit = limitProfile.lendLimit;
   }


}
