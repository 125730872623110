import {AfterViewInit, Component, Inject, Input, LOCALE_ID, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatDialog, MatDialogRef, MatSort} from '@angular/material';
import {Subscription} from 'rxjs';
import {fuseAnimations} from '@fuse/animations';
import {FuseConfirmDialogComponent} from '@fuse/components/confirm-dialog/confirm-dialog.component';
import {ContractsService, ProfitLoss} from '../contracts.service';
import {UserService} from '../../../../../auth/user/user.service';
import {formatCurrency} from '@angular/common';
import {StatusSelectComponent} from '../contract-context-menu/status-select/status-select.component';
import {FuseContractsContractFormDialogComponent} from '../contract-form/contract-form.component';
import {MatchContractFormComponent} from '../match-contract-form/match-contract-form.component';
import {environment} from '../../../../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {ContractHistory} from '../contract-history.model';
import {QuoteService} from '../../../instruments/quotes/quote.service';
import {HttpClient} from '@angular/common/http';
import {GetContextMenuItemsParams, MenuItemDef, RowNode} from 'ag-grid-community';
import {ContractDetailPreferencesService} from '../contract-detail-preferences/contract-detail-preferences.service';
import {Contract} from '../contract.model';
import {ApprovalComponent} from '../contract-context-menu/status-select/approval.component';
import {NavTabService} from '../../../../nav-tab/nav-tab.service';
import {ContractDetailPreferences} from '../contract-detail-preferences/contract-detail-preferences';
import {ContractFilterService} from '../../../../../contract-filter/contract-filter.service';
import {SwitcherService} from '../../../../../shared/switcher/switcher.service';
import {FuseContractsReRateFormDialogComponent} from '../rerate-form/rerate-form.component';
import {FormGroup} from '@angular/forms';
import {FuseContractsReturnFormDialogComponent} from '../return-form/return-form.component';
import {FuseContractsRecallFormDialogComponent} from '../recall-form/recall-form.component';
import {ConfirmationDialogComponent} from '../../../../../shared/confirmation-dialog/confirmation-dialog.component';
import {TradeFormComponent} from '../../trade-form/trade-form.component';
import {Quote} from '../../../instruments/quotes/quote.model';
import {TradeFormMode, TradeFormParams} from '../../trade-form/TradeFormParams';
import {SnapshotResponse} from '../../quick-symbol-summary/SnapshotResponse';
import {QuickSymbolSummaryService} from '../../quick-symbol-summary/quickSymbolSummaryService';
import * as moment from 'moment-timezone';
import {BuyInFormComponent} from '../buy-in-form/buy-in-form.component';

export interface QuantityAggregate {
    inventory: number;
    borrowQuantity: number;
    loanQuantity: number;
    pendingSideReturnQuantity: number;
    pendingSignRecallQuantity: number;
    loanRecallQuantity: number;
    loanReturnQuantity: number;
    borrowRecallQuantity: number;
    borrowReturnQuantity: number;
}

export interface AmountAggregate {
    borrowAmount: number;
    loanAmount: number;
    netAmount: number;
}

export interface RateAggregate {
    avgBorrowRate: number;
    avgLoanRate: number;
    netSpread: number;
}

export interface RebateAggregate {
    fundingToDate: number;
    fundingToday: number;
    rebateToday: number;
    rebateToDate: number;
    totalProfitLoss: number;
}

@Component({
    selector: 'fuse-contracts-contract-list',
    templateUrl: './contract-list.component.html',
    styleUrls: ['./contract-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})


export class FuseContractsContractListComponent implements OnInit, OnDestroy, AfterViewInit {


    @ViewChild('dialogContent') dialogContent: TemplateRef<any>;

    @ViewChild('borrowComponent') set borrowForm(content: FuseContractsContractFormDialogComponent) {
        // if (content) {
        //     this.borrowComponent = content;
        //     const filters = this.gridApi.getFilterModel();
        //     if (filters.symbol) {
        //         // this.borrowComponent.setSymbol(filters.symbol.filter.toUpperCase());
        //     }
        // }
    }

    @ViewChild('loanComponent') set loanForm(content: FuseContractsContractFormDialogComponent) {
        // if (content) {
        //     this.loanComponent = content;
        //     const filters = this.gridApi.getFilterModel();
        //     if (filters.symbol) {
        //         // this.loanComponent.setSymbol(filters.symbol.filter.toUpperCase());
        //     }
        // }
    }

    @ViewChild(MatchContractFormComponent) set matchForm(content: MatchContractFormComponent) {
        // if (content) {
        //     this.matchComponent = content;
        //     const filters = this.gridApi.getFilterModel();
        //     if (filters.symbol) {
        //         // this.matchComponent.setBorrowSymbol(filters.symbol.filter.toUpperCase());
        //     }
        // }
    }

    private borrowComponent: FuseContractsContractFormDialogComponent;
    private loanComponent: FuseContractsContractFormDialogComponent;
    private matchComponent: MatchContractFormComponent;
    private gridApi;
    private gridColumnApi;
    public postSort;
    public init = false;
    public collapse = false;
    public tabId: string;
    public summarySelectedSymbol: string;
    quote: Quote;
    snapshot: SnapshotResponse = undefined;

    // contracts: any;
    user: any;
    selectedContracts: any[];
    checkboxes: {};

    quantityAggregates: QuantityAggregate[];
    quantityAggregateColumns;

    amountAggregates: AmountAggregate[];
    amountAggregateColumns;

    rateAggregates: RateAggregate[];
    rateAggregateColumns;

    rebateAggregates: RebateAggregate[];
    rebateAggregateColumns;

    getRowStyle;

    @ViewChild(MatSort) sort: MatSort;
    @Input() showTradeButton;

    onContractsChangedSubscription: Subscription;
    onContractsAddedSubscription: Subscription;
    onSelectedContractsChangedSubscription: Subscription;
    onUserDataChangedSubscription: Subscription;
    onContractsRemovedSubscription: Subscription;
    onDepositoryNoSwitchedSubscription: Subscription;
    onSelectedSummarySymbolChangedSubscription: Subscription;
    onFilterChangedSubscription: Subscription;
    onEffectiveDateChanged: Subscription;
    dialogRef: any;
    rowClassRules: any;
    toolbarLocation: string = 'none';
    tradeFormComponent: TradeFormComponent;

    gridOptions = {
        context: this
    };

    defaultcoldefs = {};
    quoteSearchString = '';

    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;


    sideBar = {
        toolPanels: ['columns'],
        defaultToolPanel: ''
    };

    // BEGIN TEST DATA
    columnDefs: any[] = [];

    detailCellRendererParams;

    contractComponents = {
        statusEditor: StatusSelectComponent,
        approvalComponent: ApprovalComponent
    };

    pinnedBottomRowData;

    private symbolFilter = null;
    private counterPartyName = null;

    constructor(
        public contractsService: ContractsService,
        public userService: UserService,
        public dialog: MatDialog,
        private httpClient: HttpClient,
        @Inject(LOCALE_ID) private locale: string,
        public quoteService: QuoteService,
        public contractDetailPreferenceService: ContractDetailPreferencesService,
        public router: Router,
        public navTabService: NavTabService,
        public filterService: ContractFilterService,
        public switcherService: SwitcherService,
        public preferenceService: ContractDetailPreferencesService,
        public quickSymbolService: QuickSymbolSummaryService,
        public activatedRoute: ActivatedRoute
    ) {

        this.rowClassRules = {
            'contract-warning': function (params) {
                const status = params.data.contractStatus;
                return status === 'Warning';
            },
            // 'internal-recall': function(params){
            //     if (params.data.contraDepositoryNo === '0294' &&
            //         params.data.side === 'B' && params.data.pendingRecall > 0) {
            //         return true;
            //     }
            // }
        };


        this.detailCellRendererParams = {
            detailGridOptions: {
                columnDefs: [
                    {field: 'eventName', minWidth: 140, maxWidth: 140, width: 140},
                    {field: 'occuredOn', minWidth: 150, maxWidth: 150, width: 150},
                    {field: 'description', minWidth: 500, maxWidth: 500, width: 500},
                    {field: 'status', filter: 'agSetColumnFilter', filterParams: {newRowsAction: 'keep'}}
                ],
                onGridReady: function (params) {
                    // //console.log('Grid ready!');
                    params.api.sizeColumnsToFit();
                },
                onFirstDataRendered: function (params) {
                    // //console.log('First data rendered!');
                    // const statusFilterComponent = params.api.getFilterInstance('status');
                    // statusFilterComponent.setModel({
                    //     type: 'set',
                    //     values: ['M']
                    // });
                    // params.api.onFilterChanged();
                    const statusFilterComponent = params.api.getFilterInstance('eventName');
                    statusFilterComponent.setModel({
                        type: 'set',
                        values: ['RevReturn',
                            'RevRecall',
                            'New',
                            'Recall',
                            'ReturnOnRecall',
                            'Return',
                            'BuyIn',
                            'Rerate',
                            'AmountChange',
                            'ProfitCenterChange',
                            'Update',
                            'Reviewed',
                            'Adjustment',
                            'Closed']
                    });
                    params.api.onFilterChanged();
                },
                enableColResize: true,
                enableFilter: true,
                floatingFilter: false
            },
            getDetailRowData: (params: any) => {
                //console.log('Opening detail view! ', params);
                const httpOptions = {
                    params: {
                        date: this.contractsService.effectiveDate.format()
                    }
                };
                const endpoint = environment.verticalApiEndpoint + 'contracts/' + params.data.contractId + '/history';
                this.httpClient.get(endpoint, httpOptions)
                    .subscribe((details: any) => {
                        // //console.log('details: ', details);
                        const contractDetails: ContractHistory[] = [];
                        for (const contractDetail of details.items) {
                            const contractDetailObj = new ContractHistory(contractDetail);
                            contractDetails.push(contractDetailObj);
                        }
                        params.successCallback(contractDetails);
                    }, (error) => {
                        // //console.log('Couldnt get details: ', error);
                    });

            }
        };

        this.postSort = (rowNodes) => {
            if (this.init) {
                this.addModifiedSort();
            }
        };

        this.getRowStyle = (params) => {
            if (params.node.rowPinned) {
                return {'font-weight': 'bold'};
            }
        };

    }

    rerateContracts() {
        this.dialogRef = this.dialog.open(FuseContractsReRateFormDialogComponent, {
            panelClass: 'rerate-form-dialog',
            data: {
                action: 'ReRate Contract',
                contract: this.contractsService.getSelected().length > 1 ? null : this.contractsService.getSelected()[0]
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: FormGroup) => {
                if (!response) {
                    return;
                }
                const rate = response[1].controls.rate.value;

                //console.log('rate: ', rate);
                this.contractsService.reRateSelected(rate);
                this.contractsService.deselectContracts();
            });
    }

    returnContract() {
        this.dialogRef = this.dialog.open(FuseContractsReturnFormDialogComponent, {
            panelClass: 'return-form-dialog',
            data: {
                action: 'Add Return',
                contract: this.contractsService.selectedContracts[0]
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: FormGroup) => {
                if (!response) {
                    return;
                }
                // const returnQty = response[1].controls.returnQty.value;
                const formGroup = response[1].getRawValue();
                // //console.log('form group: ', formGroup);
                // //console.log('returnQty: ', returnQty);
                this.contractsService.return(formGroup.contractId, formGroup.returnQty, formGroup.specFlag, formGroup.batchCode, null);
                this.contractsService.deselectContracts();
            });
    }

    recallContract() {
        this.dialogRef = this.dialog.open(FuseContractsRecallFormDialogComponent, {
            panelClass: 'recall-form-dialog',
            data: {
                action: 'Add Recall',
                contract: this.contractsService.selectedContracts[0]
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: FormGroup) => {
                if (!response) {
                    return;
                }
                const recallQty = response[1].controls.recallQty.value;

                //console.log('recallQty: ', recallQty);
                this.contractsService.recall(recallQty);
                this.contractsService.deselectContracts();
            });
    }

    toggleTradeWindow() {
        this.contractsService.toggleTradeWindow();
    }


    disable(minLength: number, maxLength: number, onlyLoans: boolean, onlyBorrows: boolean, reqApproval: boolean): boolean {
        const selected = this.contractsService.getSelected();
        for (const contract of selected) {
            if (contract.contractStatus !== 'Open') {
                return true;
            }

            if (reqApproval && contract.contractStatusId === 0) {
                return true;
            }

            if (onlyLoans && contract.side === 'B') {
                return true;
            } else if (onlyBorrows && contract.side === 'L') {
                return true;
            }
        }
        if (maxLength === null) {
            return !(selected.length >= minLength);
        } else {
            return !(selected.length >= minLength && selected.length <= maxLength);
        }
    }

    disableApproveOrDeny(): boolean {
        const selected = this.contractsService.getSelected();
        for (const contract of selected) {
            if (contract.contractStatus !== 'Submitted') {
                return true;
            }
        }

        if (selected.length < 1) {
            return true;
        }
    }

    bulkApproveOrDeny(approve: boolean) {
        const action = approve ? 'Approve' : 'Deny';
        this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            width: '350px',
            data: {
                dialogTitle: action + ' contracts?',
                dialogText: 'Are you sure to ' + action + ' ' + this.contractsService.getSelected().length + ' contract(s)?'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: boolean) => {
                if (response) {
                    this.contractsService.approveContracts(approve);
                    this.contractsService.gridApi.deselectAll();
                }
            });
    }


    ngOnInit() {
        this.onDepositoryNoSwitchedSubscription = this.switcherService.depositoryNoSwitched.subscribe(sub => {
            this.setFilters();
        });

        this.onSelectedSummarySymbolChangedSubscription = this.contractsService.symbolSelected.subscribe(symbol => {
            this.summarySelectedSymbol = symbol;
            this.navTabService.saveSelectedSymbol(this.tabId, symbol);
            if (this.gridApi) {
                this.setFilters();
            }
        });

        this.onEffectiveDateChanged = this.contractsService.onNewContractArray.subscribe(contracts => {
            if (this.onContractsAddedSubscription) {
                this.onContractsAddedSubscription.unsubscribe();
            }
            if (this.onContractsChangedSubscription) {
                this.onContractsChangedSubscription.unsubscribe();
            }
            if (this.onContractsRemovedSubscription) {
                this.onContractsRemovedSubscription.unsubscribe();
            }
            if (this.onUserDataChangedSubscription) {
                this.onContractsAddedSubscription.unsubscribe();
            }

            if (this.contractsService.isEffectiveDateToday()) {
                this.setupContractSubscriptions();
            } else {
                this.gridApi.setRowData(contracts.filter((c => this.depositoryFilter(c))));
                this.gridApi.setFilterModel(this.filterService.currentFilter);
            }

            this.gridApi.setRowData(contracts.filter((c => this.depositoryFilter(c))));
            this.getQuote(this.summarySelectedSymbol);
        });

        this.activatedRoute.params.subscribe(params => {
            this.tabId = params['id'];
            this.summarySelectedSymbol = params['selectedSymbol'];
            if (this.gridApi) {
                this.setFilters();
            } else {
                if (this.summarySelectedSymbol && this.summarySelectedSymbol != 'none' && this.summarySelectedSymbol != 'Total') {
                    this.getQuote(this.summarySelectedSymbol);
                } else {
                    this.quote = undefined;
                }
            }
        });
    }

    ngAfterViewInit(): void {
    }

    getQuote(symbol: string) {
        this.quoteService.getQuote(symbol, this.contractsService.effectiveDate)
            .subscribe((quote) => {
                if (quote) {
                    this.quote = new Quote(quote);
                } else {
                    this.quote = undefined;
                }
            });
        this.quickSymbolService.getQuickSymbolSummary(this.summarySelectedSymbol).then(response => {
            this.snapshot = response;
        });
    }

    setFilters() {
        this.gridApi.setRowData([]);
        this.snapshot = undefined;
        this.summarySelectedSymbol = this.navTabService.getSelectedSymbol(this.tabId);
        this.gridApi.setRowData(this.contractsService.currentCollection.filter((c => this.depositoryFilter(c))));
        if (this.summarySelectedSymbol && this.summarySelectedSymbol != 'none' && this.summarySelectedSymbol != 'Total') {
            this.getQuote(this.summarySelectedSymbol);
        } else {
            this.quote = undefined;
        }
        this.gridApi.setFilterModel(this.navTabService.getFilter(this.tabId));
    }

    addModifiedSort() {
        const sort = this.gridApi.getSortModel();
        let sideSort = false;
        const modifiedSort = false;
        let rateSort = false;
        for (let i = 0; i < sort.length; i++) {

            const item = sort[i];
            if (item.colId === 'side') {
                sideSort = true;
            }
            if (item.colId === 'rateSort') {
                rateSort = true;
            }
            // if (item.colId === 'modifiedOn') {
            //     modifiedSort = true;
            // }
        }
        if (sideSort && !rateSort) {
            sort.push({colId: 'rateSort', sort: 'asc'});
            //console.log('Setting sort model: ', sort);
            this.gridApi.setSortModel(sort);
            return;
        }
        if (!modifiedSort) {
            sort.push({colId: 'modifiedOn', sort: 'desc'});
            //console.log('Setting sort model: ', sort);
            this.gridApi.setSortModel(sort);
        }

    }

    autoSizeAll(event) {
        if (event.columnApi) {
            this.gridColumnApi = event.columnApi;
            const allColumnIds = [];
            this.gridColumnApi.getAllColumns().forEach(function (column) {
                allColumnIds.push(column.colId);
            });
            this.gridColumnApi.autoSizeColumns(allColumnIds);
        }
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.contractsService.init = true;
        this.init = true;
        this.setupContractSubscriptions();

        this.onFilterChangedSubscription = this.filterService.filterChangedEvent.subscribe(filter => {
            if (filter.symbol.values && filter.symbol.values[0]) {
                this.summarySelectedSymbol = filter.symbol.values[0].toUpperCase();
            } else if (filter.cusip.values && filter.cusip.values[0]) {
                this.summarySelectedSymbol = filter.cusip.values[0].toUpperCase();
            } else {
                this.summarySelectedSymbol = 'none';
            }
            this.navTabService.saveSelectedSymbol(this.tabId, this.summarySelectedSymbol);
            this.navTabService.saveFilter(this.tabId, filter);
            this.setFilters();
        });
    }


    depositoryFilter(contract: Contract): boolean {
        if (this.summarySelectedSymbol) {
            return (contract.depositoryNo == this.switcherService.getSelectedDepositoryNo() &&
                (contract.symbol === this.summarySelectedSymbol || contract.cusip === this.summarySelectedSymbol));
        } else {
            return false;
        }
    }

    contractAdded(contracts: Contract[]) {
        if (this.gridApi) {
            if (contracts.length > 0) {
                const newRows: RowNode[] = [];
                const newContracts: Contract[] = [];
                for (const contract of contracts.filter(c => this.depositoryFilter(c))) {
                    const rowNode = this.gridApi.getRowNode(contract.primaryKey);
                    if (rowNode === undefined) {
                        newContracts.push(contract);
                    }
                }
                this.gridApi.updateRowData({
                    add: newContracts
                });
                for (const contract of newContracts) {
                    const rowNode = this.gridApi.getRowNode(String(contract.primaryKey));
                    newRows.push(rowNode);
                }
                this.gridApi.flashCells({
                    rowNodes: newRows
                });
                this.selectedContracts = [];
                this.contractsService.setSelected([]);
                this.aggregateData();
            }
        }
        this.checkboxes = {};
        contracts.map(contract => {
            this.checkboxes[contract.primaryKey] = false;
        });
    }

    contractChanged(contracts: Contract[]) {
        if (this.gridApi) {
            this.gridApi.updateRowData({
                update: contracts.filter(c => this.depositoryFilter(c))
            });
            this.aggregateData();
        }
    }

    contractRemoved(contracts: Contract[]) {
        if (this.gridApi) {
            this.gridApi.updateRowData({
                remove: contracts.filter(c => this.depositoryFilter(c))
            });
            this.aggregateData();
        }
    }

    setupContractSubscriptions() {
        this.setFilters();

        this.onContractsAddedSubscription =
            this.contractsService.onCollectionAdded.subscribe(contracts => this.contractAdded(contracts));

        this.onContractsChangedSubscription =
            this.contractsService.onCollectionChanged.subscribe(contracts => this.contractChanged(contracts));

        this.onContractsRemovedSubscription =
            this.contractsService.onCollectionRemoved.subscribe(contracts => this.contractRemoved(contracts));

        this.onSelectedContractsChangedSubscription =
            this.contractsService.onSelectedContractsChanged.subscribe((selectedcontracts) => {
                for (const id in this.checkboxes) {
                    if (!this.checkboxes.hasOwnProperty(id)) {
                        continue;
                    }

                    this.checkboxes[id] = selectedcontracts.includes(id);
                }
                this.selectedContracts = selectedcontracts;
            });

        this.onUserDataChangedSubscription =
            this.contractsService.onUserDataChanged.subscribe(user => {
                this.user = user;
            });


        let prefs = this.navTabService.getPreferences(this.tabId);
        this.setState(prefs);
    }

    onSelectionChanged(event) {
        this.contractsService.setSelected(this.gridApi.getSelectedRows());
    }

    saveTabPreferences(preferences: ContractDetailPreferences) {
        // this.navTabService.savePreferences(this.tabId, preferences);
        // this.navTabService.setTitle(this.tab)
    }

    onFilterChanged(event) {
        const filters = this.gridApi.getFilterModel();
        if (filters.symbol) {
            const sort = [];
            sort.push({colId: 'side', sort: 'asc'});
            sort.push({colId: 'rateSort', sort: 'asc'});
            this.gridApi.setSortModel(sort);
            // if there's already a quote check if i need to update it
            if (this.quoteService.quote) {
                if (this.quoteService.quote.cusip !== filters.symbol.filter) {
                    this.contractsService.onFilterChanged.next(filters);
                }
            } else {
                this.contractsService.onFilterChanged.next(filters);
            }
        } else if (filters.counterPartyName) {
            const sort = [];
            sort.push({colId: 'side', sort: 'asc'});
            sort.push({colId: 'rateSort', sort: 'asc'});
            this.gridApi.setSortModel(sort);
        } else {
            this.quoteService.quote = null;
        }
        this.aggregateData();
        this.localPreferenceChange();
    }

    onSortChanged($event: any) {
        this.localPreferenceChange();
    }

    localPreferenceChange() {
        const name = this.tabId;
        const state = this.gridColumnApi.getColumnState();
        const sort = this.gridApi.getSortModel();
        const filter = this.gridApi.getFilterModel();
        const prefs: ContractDetailPreferences = new ContractDetailPreferences({
            name: name,
            state: state,
            sort: sort,
            filter: filter
        });
        this.navTabService.savePreferences(this.tabId, prefs);
    }

    onRowDataChanged(event) {
        if (this.gridApi) {
            this.aggregateData();
        }
    }

    ngOnDestroy() {
        this.onContractsAddedSubscription.unsubscribe();
        this.onContractsChangedSubscription.unsubscribe();
        this.onSelectedContractsChangedSubscription.unsubscribe();
        this.onUserDataChangedSubscription.unsubscribe();
        this.onContractsRemovedSubscription.unsubscribe();
        this.contractsService.showTradeForm = false;
        this.quoteService.quote = null;
        this.gridApi.setFilterModel({});
        this.gridApi.destroy();
        this.onDepositoryNoSwitchedSubscription.unsubscribe();
        this.onSelectedSummarySymbolChangedSubscription.unsubscribe();
        this.onFilterChangedSubscription.unsubscribe();
    }

    aggregateData() {
        let borrowQuantityTotal = 0;
        let loanQuantityTotal = 0;
        let borrowAmountTotal = 0;
        let loanAmountTotal = 0;
        let borrowRebateTotal = 0;
        let loanRebateTotal = 0;
        let borrowRate = 0;
        let loanRate = 0;
        let dailyFundingTotal = 0;
        let fundingToDateTotal = 0;
        let rebateToDate = 0;
        let rebateToday = 0;
        let pendingSideReturnTotal = 0;
        let pendingSignRecallTotal = 0;
        let loanRecallTotal = 0;
        let loanReturnTotal = 0;
        let borrowRecallTotal = 0;
        let borrowReturnTotal = 0;
        // let numRows: number;
        this.gridApi.forEachNodeAfterFilter((rowNode: RowNode) => {
            if (rowNode.data.contractStatus === 'Open' || rowNode.data.contractStatus === 'Warning') {
                // numRows = index;
                borrowQuantityTotal += rowNode.data.borrowQuantity;
                loanQuantityTotal += rowNode.data.loanQuantity;
                borrowAmountTotal += rowNode.data.borrowAmount;
                loanAmountTotal += rowNode.data.loanAmount;
                dailyFundingTotal += rowNode.data.dailyFundingCharge;
                fundingToDateTotal += rowNode.data.fundingCharge;
                pendingSideReturnTotal += rowNode.data.pendingSideReturn;
                pendingSignRecallTotal += rowNode.data.pendingSignRecall;
                loanRecallTotal += rowNode.data.loanRecall;
                loanReturnTotal += rowNode.data.loanReturn;
                borrowRecallTotal += rowNode.data.borrowRecall;
                borrowReturnTotal += rowNode.data.borrowReturn;
                if (rowNode.data.side === 'B') {
                    borrowRate += (rowNode.data.borrowQuantity * rowNode.data.rate);
                    borrowRebateTotal += rowNode.data.dailyRebate + rowNode.data.profitLoss;
                } else {
                    loanRate += (rowNode.data.loanQuantity * rowNode.data.rate);
                    loanRebateTotal += rowNode.data.dailyRebate + rowNode.data.profitLoss;
                }
                rebateToDate += rowNode.data.profitLoss;
                rebateToday += rowNode.data.dailyRebate;
            }
        });
        if (borrowQuantityTotal === 0) {
            borrowRate = 0;
        } else {
            borrowRate = borrowRate / (borrowQuantityTotal);
        }

        if (loanQuantityTotal === 0) {
            loanRate = 0;
        } else {
            loanRate = loanRate / (loanQuantityTotal);
        }
        // //console.log('Borrow rate: ', borrowRate);

        // //console.log('Loan rate: ', loanRate);
        const quantityAggregates = [];
        const amountAggregates = [];
        const rateAggregates = [];
        const rebateAggregates = [];

        quantityAggregates.push({
            inventory: (borrowQuantityTotal - loanQuantityTotal),
            borrowQuantity: borrowQuantityTotal,
            loanQuantity: loanQuantityTotal,
            pendingSideReturnQuantity: pendingSideReturnTotal,
            pendingSignRecallQuantity: pendingSignRecallTotal,
            borrowRecallQuantity: borrowRecallTotal,
            loanRecallQuantity: loanRecallTotal,
            borrowReturnQuantity: borrowReturnTotal,
            loanReturnQuantity: loanReturnTotal

        });

        amountAggregates.push({
            borrowAmount: borrowAmountTotal,
            loanAmount: loanAmountTotal,
            netAmount: (loanAmountTotal - borrowAmountTotal)
        });

        rateAggregates.push({
            avgBorrowRate: borrowRate,
            avgLoanRate: loanRate,
            netSpread: Math.abs(loanRate - borrowRate)
        });
        const profitLoss: ProfitLoss = this.contractsService.calculateProfitLoss(
            borrowQuantityTotal, loanQuantityTotal,
            borrowAmountTotal, loanAmountTotal,
            borrowRate, loanRate
        );
        rebateAggregates.push({
            fundingToday: profitLoss.funding,
            rebateToday: profitLoss.rebate,
            totalProfitLoss: (profitLoss.rebate + profitLoss.funding)
        });

        this.quantityAggregates = quantityAggregates;
        this.quantityAggregateColumns = ['borrowQuantity', 'loanQuantity', 'inventory', 'borrowRecall', 'loanRecall', 'borrowReturn', 'loanReturn'];

        this.amountAggregates = amountAggregates;
        this.amountAggregateColumns = ['borrowAmount', 'loanAmount', 'netAmount'];

        this.rateAggregates = rateAggregates;
        this.rateAggregateColumns = ['avgBorrowRate', 'avgLoanRate', 'netSpread'];

        this.rebateAggregates = rebateAggregates;
        this.rebateAggregateColumns = ['fundingToday', 'fundingToDate', 'rebateToday', 'rebateToDate', 'totalProfitLoss'];

        this.updatePinnedRows();

    }

    showTradeWindow() {
        return this.contractsService.showTradeForm;
    }

    getRowNodeId(data) {
        return data.primaryKey;
    }

    filterPendingRecalls(enable: boolean) {
        if (enable) {
            const pendingRecall = this.gridApi.getFilterInstance('pendingRecall');
            const pendingReturn = this.gridApi.getFilterInstance('pendingReturn');
            pendingRecall.setModel({
                type: 'notEqual',
                filter: 0,
                newRowsAction: 'keep'
            });
            pendingReturn.setModel({
                type: 'notEqual',
                filter: null,
                newRowsAction: 'keep'
            });
            this.gridApi.onFilterChanged();
        } else {
            const pendingRecall = this.gridApi.getFilterInstance('pendingRecall');
            const pendingReturn = this.gridApi.getFilterInstance('pendingReturn');
            pendingRecall.setModel({
                type: 'notEqual',
                filter: null,
                newRowsAction: 'keep'
            });
            pendingReturn.setModel({
                type: 'notEqual',
                filter: null,
                newRowsAction: 'keep'
            });
            this.gridApi.onFilterChanged();
        }

    }

    filterPendingReturns(enable: boolean) {
        if (enable) {
            const pendingRecall = this.gridApi.getFilterInstance('pendingRecall');
            const pendingReturn = this.gridApi.getFilterInstance('pendingReturn');
            pendingRecall.setModel({
                type: 'notEqual',
                filter: null,
                newRowsAction: 'keep'
            });
            pendingReturn.setModel({
                type: 'notEqual',
                filter: 0,
                newRowsAction: 'keep'
            });
            this.gridApi.onFilterChanged();
        } else {
            const pendingRecall = this.gridApi.getFilterInstance('pendingRecall');
            const pendingReturn = this.gridApi.getFilterInstance('pendingReturn');
            pendingRecall.setModel({
                type: 'notEqual',
                filter: null,
                newRowsAction: 'keep'
            });
            pendingReturn.setModel({
                type: 'notEqual',
                filter: null,
                newRowsAction: 'keep'
            });
            this.gridApi.onFilterChanged();
        }

    }

    cellEditingStopped(event) {
        //console.log('cell editing stopped: ', event);
        if (event.colDef.headerName === 'PC') {
            if (event.value === '') {
                this.contractsService.profitCenterAdjust(event.data, ' ');
            }
            if (event.value.length === 1 && this.contractsService.isEffectiveDateToday()) {
                this.contractsService.profitCenterAdjust(event.data, event.value);
            }
        }
    }

    getContextMenuItems(params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
        let today = (moment.utc(new Date(), 'X').tz('America/New_York').format('MM-DD-YY'));
        var result: (string | MenuItemDef)[] = [];
        if (params.node.data.buyInDate <= today) {
            result.push({
                // custom item
                name: 'Modify Buy In Quantity for ' + params.node.data.contractNo,
                action: () => {
                    params.context.dialogRef = params.context.dialog.open(BuyInFormComponent, {
                        panelClass: 'buy-in-form-dialog',
                        data: {
                            action: 'Modify Buy In Quantiy',
                            contract: params.node.data
                        }
                    });

                    params.context.dialogRef.afterClosed()
                        .subscribe((response: FormGroup) => {
                            if (!response) {
                                return;
                            }
                            const quantity = response[1].controls.quantity.value;
                            params.context.contractsService.markForBuyIn(params.node.data.contractId, quantity);
                        });

                },
                //cssClasses: ['red', 'bold'],
                //icon: 'mode_heat',
            });
            result.push('separator');
        }
        //     {
        //         // custom item
        //         name: 'Checked',
        //         checked: true,
        //         action: () => {
        //             console.log('Checked Selected');
        //         },
        //         icon:
        //             '<img src="https://www.ag-grid.com/example-assets/skills/mac.png"/>',
        //     },
        result.push('copy', 'copyWithHeaders', 'separator', 'export');
        //     'copy',
        //     'copyWithHeaders',
        //     'separator',
        //     'export',
        // ];
        return result;
    }

    updatePinnedRows(): void {
        const rows = [];
        rows.push({
            symbol: 'Inventory',
            borrowQuantity: 'Borrow',
            loanQuantity: 'Loan',
            borrowAmount: 'Borrow $',
            loanAmount: 'Loan $',
            contractNo: 'Net',
            pendingSignRecall: 'Recall',
            pendingSideReturn: 'Return',
            borrowRate: 'B Avg',
            loanRate: 'L Avg',
        });
        rows.push({
            symbol: this.quantityAggregates[0].inventory,
            borrowQuantity: this.quantityAggregates[0].borrowQuantity,
            loanQuantity: this.quantityAggregates[0].loanQuantity,
            borrowAmount: this.amountAggregates[0].borrowAmount,
            loanAmount: this.amountAggregates[0].loanAmount,
            contractNo: this.amountAggregates[0].netAmount,
            amountFormat: (params) => formatCurrency(params.value, this.locale, '$'),
            pendingSignRecall: this.quantityAggregates[0].pendingSignRecallQuantity,
            pendingSideReturn: this.quantityAggregates[0].pendingSideReturnQuantity,
            borrowRecall: this.quantityAggregates[0].borrowRecallQuantity,
            loanRecall: this.quantityAggregates[0].loanRecallQuantity,
            borrowReturn: this.quantityAggregates[0].borrowReturnQuantity,
            loanReturn: this.quantityAggregates[0].loanReturnQuantity,
            borrowRate: this.rateAggregates[0].avgBorrowRate,
            loanRate: this.rateAggregates[0].avgLoanRate,

        });
        // rows.push({
        //     borrowAmount: 'Blended Borrow',
        //     loanAmount: 'Blended Loan',
        //     contractNo: 'Net Spread',
        // });
        // rows.push({
        //     borrowAmount: this.rateAggregates[0].avgBorrowRate,
        //     loanAmount: this.rateAggregates[0].avgLoanRate,
        //     contractNo: this.rateAggregates[0].netSpread,
        //     amountFormat: (params) => formatCurrency(params.value, this.locale, '')
        // });
        rows.push({
            borrowAmount: 'Funding',
            loanAmount: 'Match',
            contractNo: 'Total P&L'
        });
        rows.push({
            borrowAmount: this.rebateAggregates[0].fundingToday,
            loanAmount: this.rebateAggregates[0].rebateToday,
            contractNo: this.rebateAggregates[0].totalProfitLoss,
            amountFormat: (params) => formatCurrency(params.value, this.locale, '$')
        });
        this.pinnedBottomRowData = rows;
        this.gridApi.setPinnedBottomRowData(this.pinnedBottomRowData);
    }

    setState(prefs: ContractDetailPreferences): void {
        if (this.gridColumnApi && this.gridApi) {
            if (prefs) {
                if (prefs.state) {
                    this.gridColumnApi.setColumnState(prefs.state);
                } else {
                    this.gridColumnApi.setColumnState(this.contractDetailPreferenceService.defaultPreferences.state);
                }

                if (prefs.sort) {
                    this.gridApi.setSortModel(prefs.sort);
                } else {
                    this.gridApi.setSortModel(this.contractDetailPreferenceService.defaultPreferences.sort);
                }

                // if (prefs.filter) {
                //     this.gridApi.setFilterModel(prefs.filter);
                // } else {
                //     this.gridApi.setFilterModel(this.contractDetailPreferenceService.defaultPreferences.filter);
                // }

                this.gridApi.onSortChanged();
                this.gridApi.onFilterChanged();
            }
        }
    }
}
