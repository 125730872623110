import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../../../auth/auth.guard';
import {TransactionHistoryComponent} from './transaction-history.component';
import {FuseSharedModule} from '../../../../../@fuse/shared.module';
import {FuseContractsModule} from '../../trading/contracts/contracts.module';
import {
    MatButtonModule,
    MatCardModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule
} from '@angular/material';
import {TransactionGridComponent} from './transaction-grid/transaction-grid.component';
import {AgGridModule} from 'ag-grid-angular';
import {TransactionQueryComponent} from './transaction-query/transaction-query.component';

const routes: Routes = [
    {
        path: 'transaction-history',
        component: TransactionHistoryComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [
        TransactionHistoryComponent,
        TransactionGridComponent,
        TransactionQueryComponent
    ],
    imports: [
        CommonModule,
        FuseSharedModule,
        RouterModule.forChild(routes),
        FuseContractsModule,
        MatSidenavModule,
        AgGridModule,
        MatExpansionModule,
        MatCardModule,
        MatSelectModule,
        MatDatepickerModule,
        MatInputModule,
        MatButtonModule,
        MatRadioModule,
        MatProgressSpinnerModule,
    ]
})
export class TransactionHistoryModule {
}
