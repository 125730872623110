import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import {Router} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Subscription} from 'rxjs';
import {RecallDetailService} from './recall-detail.service';

@Component({
  selector: 'app-return-allocation',
  templateUrl: './recall-allocation.component.html',
  styleUrls: ['./recall-allocation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class RecallAllocationComponent implements OnInit, OnDestroy {

    // 'symbol', 'quantity', 'rate'
    displayedColumns = ['counterPartyName', 'quantity', 'rate', 'returnQty', 'batchCode', 'specFlag'];
    totalReturns = 0;
    form: FormGroup;
    init = false;
    initListenerCount = 0;
    initListenerSub: Subscription;

    constructor(private formBuilder: FormBuilder,
                public recallDetailService: RecallDetailService,
                public router: Router,
                public snackBar: MatSnackBar) {

        if (this.recallDetailService.errorState) {
            this.router.navigateByUrl('/returns')
                .then( () => {
                    this.snackBar.open(this.recallDetailService.error.error.error.errors[0].message, 'Dismiss');
                });
        } else {
            this.initListenerSub = this.recallDetailService.onInitListener
                .subscribe(
                    init => {
                        this.initListenerCount++;
                        // //console.log('value: ', this.returnDetailService.onInitListener.value + ' init: ', init);
                        if (init) {
                            this.form = this.formBuilder.group({
                                returns: this.createFormArray()
                            });
                            this.init = true;
                            // //console.log('this is where I should set init to true because init = ' + init + ' (index #' + this.initListenerCount + ')');
                        }
                    }
                );
        }
    }

    createFormArray(): FormArray {
        return new FormArray(this.recallDetailService.loanContracts.map(item => new FormGroup({
            contractId: new FormControl(item.contractId),
            returnQty: new FormControl('', Validators.max(item.quantity)),
            batchCode: new FormControl('E', Validators.required),
            specFlag: new FormControl('0', Validators.required),
            parentReturnId: new FormControl(this.recallDetailService.recall.contractHistoryId)
        })));
    }

    /** Gets the total cost of all transactions. */
    getTotalReturns() {
        let total = 0;
        const formArray: FormArray = this.form.get('returns') as FormArray;
        // //console.log('what the fuck is this object: ', formArray);
        for (const formElement of formArray.controls) {
            const casted: FormGroup = formElement as FormGroup;
            total += +casted.controls.returnQty.value;
            // //console.log('value: ', casted.controls.returnQty.value);
        }
        this.totalReturns = total;
        if (this.totalReturns > (this.recallDetailService.recall.quantity - this.recallDetailService.recall.allocated)) {
            this.form.setErrors({ 'invalid': true });
        }
    }

    submitReturns() {
        // //console.log('form: ', this.form.getRawValue());
        const rawValue = this.form.getRawValue();
        for (const returnToSubmit of rawValue.returns) {
            // //console.log('return: ', returnToSubmit);
            this.recallDetailService.submitReturn(returnToSubmit);
        }
        this.router.navigate(['/contracts'], {
            replaceUrl: true,
            queryParams: {
                cusip: this.recallDetailService.recall.cusip
            }
        }).then( () => {
            this.snackBar.open('Recalls submitted.', 'Dismiss');
        });
    }


  ngOnInit() {
  }

    ngOnDestroy(): void {
        if (this.recallDetailService.errorState === false) {
            // this.returnDetailService.clearInUseBy();
            this.initListenerSub.unsubscribe();
        }
    }

}
