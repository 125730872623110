import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from '../../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {InternalUser} from '../../../../../auth/user/internal-user';

@Injectable()
export class UserDetailService {

    routeParams: any;
    user: InternalUser;
    onUserChanged: BehaviorSubject<any> = new BehaviorSubject({});
    readonly endpoint = environment.verticalApiEndpoint + 'user/';
    readonly personEndpoint = environment.verticalApiEndpoint + 'person/';

    constructor(
        private http: HttpClient
    )
    {
    }

    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {

        this.routeParams = route.params;
        //console.log('route params for detail: ', this.routeParams);
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getUser()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getUser(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            if ( this.routeParams.userUid === 'new' )
            {
                this.onUserChanged.next(false);
                resolve(false);
            }
            else
            {
                this.http.get(this.endpoint + this.routeParams.userUid)
                    .subscribe((response: any) => {
                        this.user = response;
                        this.onUserChanged.next(this.user);
                        resolve(response);
                    }, reject);
            }
        });
    }

    saveUser(user)
    {
        //console.log('put USER: ', user);
        return new Promise((resolve, reject) => {
            this.http.put(this.personEndpoint + this.user.personId, user)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    addUser(user)
    {
        return new Promise((resolve, reject) => {
            this.http.post(this.endpoint, user)
                .subscribe((response: any) => {
                    //console.log('ADDED: ', response);
                    resolve(response);
                }, reject);
        });
    }

}
