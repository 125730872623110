import {Injectable} from '@angular/core';
import {FirestoreCollectionService} from '../../../../shared/firestore-collection.service';
import {SegMemoSeg} from './seg-memoseg.model';
import {HttpClient} from '@angular/common/http';
import {AngularFirestore} from 'angularfire2/firestore';
import {AngularFireAuth} from 'angularfire2/auth';
import {QuoteService} from '../../instruments/quotes/quote.service';
import {MatSnackBar} from '@angular/material';
import {SwitcherService} from '../../../../shared/switcher/switcher.service';
import {environment} from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SegMemosegService extends FirestoreCollectionService<SegMemoSeg> {

    collectionName = 'dtc-memoseg';
    currentCollection = [];

    constructor(private httpClient: HttpClient,
                private firestoreClient: AngularFirestore,
                private afAuthClient: AngularFireAuth,
                private quoteService: QuoteService,
                public snackBar: MatSnackBar,
                public switcherService: SwitcherService) {
        super(httpClient, firestoreClient, afAuthClient);
        super.initializeService(this.collectionName, this.createSegMemosegCallback);

        this.currentCollection = this.collectionData;
    }

    public createSegMemosegCallback(newObj: any): SegMemoSeg {
        return new SegMemoSeg(newObj);
    }

    seg(dtcid: string, batch: any[]) {
        var self = this;
        const endpoint = environment.verticalApiEndpoint + 'memoseg/seg';

        const json = {
            dtcId: dtcid,
            batch: batch,
        };

        //console.log(json);

        self.httpClient
            .post(endpoint, JSON.stringify(json))
            .subscribe((resp) => {
                    this.snackBar.open('Segged Successfully', 'OK', {
                        verticalPosition: 'bottom',
                        duration: 2000
                    });
                }, (error) => {
                    //console.log('error: ', error);
                }
            );
    }

    unseg(dtcid: string, batch: any[]) {
        var self = this;
        const endpoint = environment.verticalApiEndpoint + 'memoseg/unseg';

        const json = {
            dtcId: this.switcherService.getSelectedDepositoryNo(),
            batch: batch,
        };

        self.httpClient
            .post(endpoint, JSON.stringify(json))
            .subscribe((resp) => {
                    // this.snackBar.open('Segged Successfully', 'OK', {
                    //     verticalPosition: 'bottom',
                    //     duration: 2000
                    // });
                }, (error) => {
                    //console.log('error: ', error);
                }
            );
    }

}




