import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {MatDialog} from '@angular/material';
import {FplPosition} from './fplAccountPosition.model';
import {FplTradeFormComponent} from '../../trading/trade-form/fpl-trade-form.component';
import {ContractsService} from '../../trading/contracts/contracts.service';
import {TradeFormMode, TradeFormParams} from '../../trading/trade-form/TradeFormParams';
import {UserService} from '../../../../auth/user/user.service';


@Component({
    selector: 'child-cell',
    template: `
        <span *ngIf="available > 0 && accountId > 0">
            <button mat-raised-button [disabled]="!this.userService.requirePermission('fplTrade')" (click)="showTradeWindow($event)" class="btn">Trade</button>
        </span>
    `,
    styles: [
            `.btn {
            height: 20px;
            line-height: 0.5;
            width: 75px;
        }
        `
    ]
})
export class BorrowButtonRenderer implements ICellRendererAngularComp {
    public params: any;
    public available: number = 0;
    public accountId: number = 0;
    public borrowed: number = 0;
    public fplAccountPosition: FplPosition;
    dialogRef: any;

    constructor(public dialog: MatDialog, public contractService: ContractsService, public userService: UserService) {
    }

    agInit(params: any): void {
        this.params = params;
        this.fplAccountPosition = params.data;
        if (this.fplAccountPosition) {
            this.available = this.fplAccountPosition.available;
            this.borrowed = this.fplAccountPosition.borrowQuantity;
            this.accountId = this.fplAccountPosition.accountId;
        }
    }

    showTradeWindow($events) {
        let params = new TradeFormParams(TradeFormMode.Fpl);
        params.fplPosition = this.fplAccountPosition;
        this.contractService.toggleTradeWindow(params);
        // this.dialogRef = this.dialog.open(FplTradeFormComponent, {
        //     width: '300px',
        //     height: '400px',
        //     data: {
        //         fplAccountPos: this.fplAccountPosition,
        //     }
        // });
    }

    public invokeParentMethod() {
        this.params.context.componentParent.methodFromParent(
            this.fplAccountPosition
        );
    }

    refresh(): boolean {
        return false;
    }
}
