import {Component, Inject, Input, LOCALE_ID, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import {CounterpartyService} from '../counterparty.service';
import {Router} from '@angular/router';
import {formatCurrency} from '@angular/common';

@Component({
  selector: 'app-counterparty-list',
  templateUrl: './counterparty-list.component.html',
  styleUrls: ['./counterparty-list.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class CounterpartyListComponent implements OnInit {

    columnDefs: any[];
    gridApi: any;
    @Input() entityId;

    constructor(public counterPartyService: CounterpartyService,
                @Inject(LOCALE_ID) private locale: string,
                private router: Router) {
    }

    ngOnInit() {
        this.columnDefs = [
            {field: 'entityId', hide: true, filter: 'agNumberColumnFilter', sort: 'asc'},
            {headerName: 'Name', field: 'name', filter: 'agTextColumnFilter', sort: 'asc'},
            {headerName: 'Borrow Limit', field: 'borrowLimit',
                cellRenderer: (params) => {
                    if (params.value === null) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$');
                    }
                }, filter: 'agNumberColumnFilter',
            },
            {headerName: 'Lend Limit', field: 'lendLimit',
                cellRenderer: (params) => {
                    if (params.value === null) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$');
                    }
                }, filter: 'agNumberColumnFilter',
            }
        ];

    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
        //console.log('entity input: ', this.entityId);
        if (this.entityId) {
            //console.log('filtering');
           const filter = {
               entityId: {
                   filter: this.entityId,
                   filterType: 'number',
                   type: 'equals'
               }
           };
           this.gridApi.setFilterModel(filter);
        }
    }

    getRowNodeId(data) {
        return data.counterPartyId;
    }


    onRowClicked(event) {
        const counterpartyId = this.getRowNodeId(event.node.data);
        this.router.navigate(['counterparties/' + counterpartyId], {
            replaceUrl: true,
        });
    }
}
