import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {FirestoreCollectionService} from '../../../../../shared/firestore-collection.service';
import {ContractDetailPreferences} from './contract-detail-preferences';
import {HttpClient} from '@angular/common/http';
import {AngularFirestore} from 'angularfire2/firestore';
import {AngularFireAuth} from 'angularfire2/auth';
import {UserService} from '../../../../../auth/user/user.service';
import {formatCurrency, formatNumber} from '@angular/common';
import {ContractsService} from '../contracts.service';
import * as moment from 'moment';
import {BehaviorSubject} from 'rxjs';
import {UserPreferencesService} from '../../../operations/user-preferences/user-preferences.service';

@Injectable({
    providedIn: 'root'
})
export class ContractDetailPreferencesService extends FirestoreCollectionService<ContractDetailPreferences> {

    public currentPreferences: ContractDetailPreferences;
    public defaultPreferences: ContractDetailPreferences;

    private statusBar;

    public defaultColDefs = {};

    public columnDefaults = {
        enableCellChangeFlash: false,
        sortable: true,
        filter: false,
        resizable: true,

        cellStyle: ContractDetailPreferencesService.styleZero,
        floatingFilter: false,
        floatingFilterComponentParams: {
            suppressFilterButton: true
        },
        menuTabs: ['filterMenuTab']

    };
    // public defaultColDefs: { enableCellChangeFlash: boolean; sortable: boolean; filter: boolean; resizable: boolean;
    // cellStyle: (params) => ({ color: string } | { color: string });
    // floatingFilterComponentParams: { suppressFilterButton: boolean; color: string } };

    /**
     * Whenever an action is clicked, emit the event through here
     */
    public requestPreferenceAction: BehaviorSubject<any> = new BehaviorSubject('');

    /**
     * Emit change in preferences here
     */
    public preferenceStateEmitter: BehaviorSubject<any> = new BehaviorSubject('');

    constructor(private httpClient: HttpClient,
                private firestoreClient: AngularFirestore,
                private afAuthClient: AngularFireAuth,
                private userService: UserService,
                private contractsService: ContractsService,
                private accountsPreferencesService: UserPreferencesService,
                @Inject(LOCALE_ID) private locale: string) {
        super(httpClient, firestoreClient, afAuthClient);
        const collectionName = 'users/' + this.userService.user.userUid + '/details-preferences';
        super.initializeService(collectionName, this.createReturnCallback);


        this.setDefaultColDef();
        this.defaultPreferences = this.getDefaultPreferences();
        this.currentPreferences = this.defaultPreferences;
    }

    public createReturnCallback(newObj: any): ContractDetailPreferences {
        return new ContractDetailPreferences(newObj);
    }

    public defaultSelected(): boolean {
        if (this.currentPreferences) {
            if (this.currentPreferences.name === 'Default') {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    public savePreferences(name, state: any[], sort: any[], filter: any[]) {
        const uid = this.afAuthClient.auth.currentUser.uid;
        const prefs: ContractDetailPreferences = new ContractDetailPreferences({
            name: name,
            state: state,
            sort: sort,
            filter: filter
        });

        this.firestoreClient.collection('users')
            .doc(uid)
            .collection('details-preferences').doc(name).set({
            name: name,
            state: state,
            sort: sort,
            filter: filter
        })
            .then(
                (docRef) => {
                    this.setPreferences(prefs);
                }
            );

        // this.firestoreClient.collection('users')
        //     .doc(uid)
        //     .collection('details-preferences')
        //     .add({
        //         primaryKey: name,
        //         name: name,
        //         state: state,
        //         sort: sort,
        //         filter: filter
        //     })
        //     .then(
        //         (docRef) => {
        //             //console.log('success save: ', docRef);
        //             prefs.primaryKey = docRef.id;
        //             //console.log('prefs now: ', prefs);
        //             this.setPreferences(prefs);
        //         }
        //     );
    }

    public renamePreferences(newName: string): void {
        const uid = this.afAuthClient.auth.currentUser.uid;
        this.firestoreClient.collection('users')
            .doc(uid)
            .collection('details-preferences')
            .doc(this.currentPreferences.name)
            .update({
                name: newName
            }).then(
            () => {
                this.currentPreferences.name = newName;
            }
        );
    }

    public deletePreferences(): void {
        const uid = this.afAuthClient.auth.currentUser.uid;
        this.firestoreClient.collection('users')
            .doc(uid)
            .collection('details-preferences')
            .doc(this.currentPreferences.primaryKey)
            .delete()
            .then(
                () => {
                    this.setDefaultPreferences();
                }
            );
    }

    setDefaultColDef(): void {
        const columnDefs = [];
        columnDefs.push(
            {
                headerName: 'Symbol', field: 'symbol', width: 150, minWidth: 150, maxWidth: 150,
                filterParams: {
                    newRowsAction: 'keep'
                },
                filter: 'agSetColumnFilter', lockPinned: true,
                pinnedRowCellRenderer: (params) => {
                    if (params.value === 'Inventory') {
                        return params.value;
                    } else if (params.value !== undefined) {
                        return formatNumber(params.value, this.locale);
                    }
                },
                checkboxSelection: true, cellRenderer: 'agGroupCellRenderer',
                headerCheckboxSelectionFilteredOnly: true,
                headerCheckboxSelection: true,
            },
            {
                headerName: 'S', field: 'side', minWidth: 60, suppressSizeToFit: true, filter: 'agSetColumnFilter',
                filterParams: {newRowsAction: 'keep'}, width: 60,
                maxWidth: 60, sortedAt: 1, sort: 'asc',
            },
            {
                headerName: 'Cusip',
                field: 'cusip',
                width: 100,
                minWidth: 100,
                maxWidth: 100,
                filterParams: {newRowsAction: 'keep', defaultOption: 'equals'},
                filter: 'agSetColumnFilter',
                lockPinned: true,
                hide: true
            },
            {headerName: 'Contra', field: 'counterPartyName', filter: 'agSetColumnFilter', hide: true, width: 120, filterParams: {newRowsAction: 'keep'}},
            {headerName: 'Broker', field: 'contraAccount', filter: 'agSetColumnFilter', hide: false, width: 120, filterParams: {newRowsAction: 'keep'}},
            {
                headerName: 'DTC', field: 'contraDepositoryNo', width: 65, maxWidth: 65, suppressSizeToFit: true, hide: false, filter: 'agSetColumnFilter',
                filterParams: {newRowsAction: 'keep'},
                cellStyle: params => this.styleDtc(params)
            },
            {
                headerName: 'LoanetId', field: 'contraLoanetId', width: 90, maxWidth: 90, suppressSizeToFit: true, hide: false, filter: 'agSetColumnFilter',
                filterParams: {newRowsAction: 'keep'}
            },
            {
                headerName: 'Rate', field: 'rate', width: 70, suppressSizeToFit: true, type: 'numericColumn',
                sortedAt: 2, sort: 'desc',
                cellStyle: {color: 'inherit'},
                valueFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');
                    }
                }, filter: 'agSetColumnFilter', hide: true
            },
            {
                headerName: 'B Rate', field: 'borrowRate', minWidth: 85, maxWidth: 85, width: 85, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                valueFormatter: (params) => {
                    if (params.value === 'B Avg') {
                        return params.value;
                    } else if (params.value == undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');
                    }
                }, filter: 'agSetColumnFilter'
            },
            {
                headerName: 'L Rate', field: 'loanRate', minWidth: 85, maxWidth: 85, width: 85, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                valueFormatter: (params) => {
                    if (params.value === 'L Avg') {
                        return params.value;
                    } else if (params.value == undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');
                    }
                }, filter: 'agSetColumnFilter'
            }, {
                headerName: 'FxRate', field: 'contraFxRate', minWidth: 85, maxWidth: 85, width: 85, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'}, filter: 'agSetColumnFilter', hide:true
            },
            {
                headerName: 'CSA %', field: 'fee', minWidth: 85, maxWidth: 85, width: 85, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'}, filter: 'agSetColumnFilter', hide:true
            },
            // {
            //     headerName: 'Fee 2 %', field: 'fee2', minWidth: 85, maxWidth: 85, width: 85, suppressSizeToFit: true, type: 'numericColumn',
            //     cellStyle: {color: 'inherit'}, filter: 'agSetColumnFilter', hide:true
            // },
            {
                headerName: 'B Qty', field: 'borrowQuantity', minWidth: 100, maxWidth: 100, width: 100, suppressSizeToFit: true,
                valueFormatter: (params) => {
                    if (params.value === null) {
                        return '';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                },
                pinnedRowCellRenderer: (params) => {
                    if (params.value === 'Borrow') {
                        return params.value;
                    } else if (params.value !== undefined) {
                        return formatNumber(params.value, this.locale);
                    }
                },
                type: 'numericColumn', filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}
            },
            {
                headerName: 'L Qty', field: 'loanQuantity', minWidth: 100, maxWidth: 100, width: 100, suppressSizeToFit: true,
                valueFormatter: (params) => {
                    if (params.value === null) {
                        return '';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                },
                pinnedRowCellRenderer: (params) => {
                    if (params.value === 'Loan') {
                        return params.value;
                    } else if (params.value !== undefined) {
                        return formatNumber(params.value, this.locale);
                    }
                },
                type: 'numericColumn', filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}
            },
            {
                headerName: 'B Amt', field: 'borrowAmount', type: 'numericColumn', filter: 'agNumberColumnFilter', minWidth: 130, width: 130, maxWidth: 130,
                valueFormatter: (params) => {
                    if (params.value === null) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$', undefined, '1.0-0');
                    }
                },
                pinnedRowCellRenderer: (params) => {
                    if (params.value === 'Borrow $' ||
                        params.value === 'Blended Borrow' ||
                        params.value === 'Funding') {
                        return params.value;
                    } else if (params.value !== undefined) {
                        return params.data.amountFormat(params);
                    }
                },
                filterParams: {newRowsAction: 'keep'}
            },{
                headerName: 'Fx B Amt', field: 'fxBorrowAmount', type: 'numericColumn', filter: 'agNumberColumnFilter', minWidth: 130, width: 130, maxWidth: 130,
                hide: true,
                valueFormatter: (params) => {
                    if (params.value === null) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$', undefined, '1.0-0');
                    }
                },
                pinnedRowCellRenderer: (params) => {
                    if (params.value === 'Fx Borrow $') {
                        return params.value;
                    } else if (params.value !== undefined) {
                        return params.data.amountFormat(params);
                    }
                },
                filterParams: {newRowsAction: 'keep'}
            },
            {
                headerName: 'L Amt', field: 'loanAmount', type: 'numericColumn', filter: 'agNumberColumnFilter', minWidth: 130, width: 130, maxWidth: 130,
                valueFormatter: (params) => {
                    if (params.value === null) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$', undefined, '1.0-0');
                    }
                },
                pinnedRowCellRenderer: (params) => {
                    if (params.value === 'Loan $' ||
                        params.value === 'Blended Loan' ||
                        params.value === 'Match') {
                        return params.value;
                    } else if (params.value !== undefined) {
                        return params.data.amountFormat(params);
                    }
                },
                filterParams: {newRowsAction: 'keep'}
            },
            {
                headerName: 'Fx L Amt', field: 'fxLoanAmount', type: 'numericColumn', filter: 'agNumberColumnFilter', minWidth: 130, width: 130, maxWidth: 130,
                hide: true,
                valueFormatter: (params) => {
                    if (params.value === null) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$', undefined, '1.0-0');
                    }
                },
                pinnedRowCellRenderer: (params) => {
                    if (params.value === 'Fx Loan $') {
                        return params.value;
                    } else if (params.value !== undefined) {
                        return params.data.amountFormat(params);
                    }
                },
                filterParams: {newRowsAction: 'keep'}
            },
            {
                headerName: 'Rebate', field: 'dailyRebate', type: 'numericColumn', filter: 'agNumberColumnFilter',
                minWidth: 110, maxWidth: 110, width: 110, hide: true,
                valueFormatter: (params) => {
                    if (params.value === 'Rebate') {
                        return params.value;
                    } else {
                        return formatCurrency(params.value, this.locale, '$');
                    }
                }, filterParams: {newRowsAction: 'keep'}
            },
            {
                headerName: 'FxRebate', field: 'fxDailyRebate', type: 'numericColumn', filter: 'agNumberColumnFilter',
                minWidth: 110, maxWidth: 110, width: 110, hide: true,
                valueFormatter: (params) => {
                    if (params.value === 'FxRebate') {
                        return params.value;
                    } else {
                        return formatCurrency(params.value, this.locale, '$');
                    }
                }, filterParams: {newRowsAction: 'keep'}
            },
            {
                headerName: 'CSA $' , field: 'dailyRebateFee', type: 'numericColumn', filter: 'agNumberColumnFilter',
                minWidth: 110, maxWidth: 110, width: 110, hide:true,
                valueFormatter: (params) => {
                    if (params.value === 'CSA $') {
                        return params.value;
                    } else {
                        return formatCurrency(params.value, this.locale, '$');
                    }
                }, filterParams: {newRowsAction: 'keep'}
            },
            {
                headerName: 'FxCSA $' , field: 'fxDailyRebateFee', type: 'numericColumn', filter: 'agNumberColumnFilter',
                minWidth: 110, maxWidth: 110, width: 110, hide:true,
                valueFormatter: (params) => {
                    if (params.value === 'FxCSA $') {
                        return params.value;
                    } else {
                        return formatCurrency(params.value, this.locale, '$');
                    }
                }, filterParams: {newRowsAction: 'keep'}
            },
            // {
            //     headerName: 'Rebate Fee 2' , field: 'dailyRebateFee2', type: 'numericColumn', filter: 'agNumberColumnFilter',
            //     minWidth: 110, maxWidth: 110, width: 110, hide:true,
            //     valueFormatter: (params) => {
            //         if (params.value === 'Rebate Fee 2') {
            //             return params.value;
            //         } else {
            //             return formatCurrency(params.value, this.locale, '$');
            //         }
            //     }, filterParams: {newRowsAction: 'keep'}
            // }
        );
        columnDefs.push({
            headerName: 'Contract #',
            field: 'contractNo',
            suppressSizeToFit: true,
            minWidth: 120, maxWidth: 120, width: 120,
            filter: 'agSetColumnFilter',
            filterParams: {newRowsAction: 'keep'},
            pinnedRowCellRenderer: (params) => {
                if (params.value === 'Net' ||
                    params.value === 'Net Spread' ||
                    params.value === 'Total P&L') {
                    return params.value;
                } else if (params.value !== undefined) {
                    return params.data.amountFormat(params);
                }
            },
        });
        columnDefs.push(
            {
                headerName: 'ContraCurrencyId', field: 'contraCurrencyId', minWidth: 55, maxWidth: 55, width: 55, suppressSizeToFit: true,
                filter: 'agSetColumnFilter', filterParams: {newRowsAction: 'keep'},
                editable: false, hide: true
            },
        );
        if (this.userService.requirePermission('editContract')) {
            columnDefs.push(
                {
                    headerName: 'PC', field: 'profitCenter', minWidth: 55, maxWidth: 55, width: 55, suppressSizeToFit: true,
                    filter: 'agSetColumnFilter', filterParams: {newRowsAction: 'keep'},
                    editable: this.contractsService.isEffectiveDateToday(), cellEditor: 'agLargeTextCellEditor',
                    cellEditorParams: {
                        maxLength: 1,
                        rows: 1,
                        cols: 10
                    }
                }
            );
        } else {
            columnDefs.push(
                {
                    headerName: 'PC', field: 'profitCenter', minWidth: 55, maxWidth: 55, width: 55, suppressSizeToFit: true,
                    filter: 'agSetColumnFilter', filterParams: {newRowsAction: 'keep'},
                    editable: false
                },
            );
        }
        columnDefs.push(
            {
                headerName: 'Return', field: 'pendingSideReturn', filter: 'agNumberColumnFilter', type: 'numericColumn', minWidth: 80, maxWidth: 80, width: 80,
                valueFormatter: (params) => {
                    if (params.value === null) {
                        return '0';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                },
                pinnedRowCellRenderer: (params) => {
                    if (params.value === 'Return') {
                        return params.value;
                    } else if (params.value !== undefined) {
                        return formatNumber(params.value, this.locale);
                    }
                },
                filterParams: {
                    defaultOption: 'greaterThan', newRowsAction: 'keep'
                }
            },
            {
                headerName: 'Recall', field: 'pendingSignRecall', filter: 'agNumberColumnFilter', type: 'numericColumn', minWidth: 80, maxWidth: 80, width: 80,
                valueFormatter: (params) => {
                    if (params.value === null) {
                        return '0';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                },
                pinnedRowCellRenderer: (params) => {
                    if (params.value === 'Recall') {
                        return params.value;
                    } else if (params.value !== undefined) {
                        return formatNumber(params.value, this.locale);
                    }
                },
                filterParams: {
                    defaultOption: 'greaterThan',
                    newRowsAction: 'keep'
                }
            },
            {
                headerName: 'Buy In', field: 'buyInDate', minWidth: 80, maxWidth: 80, width: 80, filterParams: {newRowsAction: 'keep'},
                filter: 'agSetColumnFilter',comparator: this.dateComparator
            },
            // {
            //     headerName: 'B Recall', field: 'borrowRecall', filter: 'agNumberColumnFilter', type: 'numericColumn',
            //     valueFormatter: (params) => {
            //         if (params.value === null) {
            //             return '0';
            //         } else {
            //             return formatNumber(params.value, this.locale);
            //         }
            //     },
            //     filterParams: {
            //         defaultOption: 'greaterThan',
            //         newRowsAction: 'keep'
            //     }, pinnedRowCellRenderer: (params) => {
            //         if (params.value !== undefined) {
            //             return formatNumber(params.value, this.locale);
            //         }
            //     }, cellStyle: ContractDetailPreferencesService.styleZero
            // },
            // {
            //     headerName: 'L Recall', field: 'loanRecall', filter: 'agNumberColumnFilter', type: 'numericColumn',
            //     valueFormatter: (params) => {
            //         if (params.value === null) {
            //             return '0';
            //         } else {
            //             return formatNumber(params.value, this.locale);
            //         }
            //     },
            //     filterParams: {
            //         defaultOption: 'greaterThan',
            //         newRowsAction: 'keep'
            //     }, pinnedRowCellRenderer: (params) => {
            //         if (params.value !== undefined) {
            //             return formatNumber(params.value, this.locale);
            //         }
            //     }, cellStyle: ContractDetailPreferencesService.styleZero,
            // },
            // {
            //     headerName: 'B Return', field: 'borrowReturn', filter: 'agNumberColumnFilter', type: 'numericColumn',
            //     valueFormatter: (params) => {
            //         if (params.value === null) {
            //             return '0';
            //         } else {
            //             return formatNumber(params.value, this.locale);
            //         }
            //     },
            //     filterParams: {
            //         defaultOption: 'greaterThan',
            //         newRowsAction: 'keep'
            //     }, pinnedRowCellRenderer: (params) => {
            //         if (params.value !== undefined) {
            //             return formatNumber(params.value, this.locale);
            //         }
            //     }, cellStyle: ContractDetailPreferencesService.styleZero,
            // },
            // {
            //     headerName: 'L Return', field: 'loanReturn', filter: 'agNumberColumnFilter', type: 'numericColumn',
            //     valueFormatter: (params) => {
            //         if (params.value === null) {
            //             return '0';
            //         } else {
            //             return formatNumber(params.value, this.locale);
            //         }
            //     },
            //     filterParams: {
            //         defaultOption: 'greaterThan',
            //         newRowsAction: 'keep'
            //     }, pinnedRowCellRenderer: (params) => {
            //         if (params.value !== undefined) {
            //             return formatNumber(params.value, this.locale);
            //         }
            //     }, cellStyle: ContractDetailPreferencesService.styleZero,
            // },

            {
                headerName: 'Start', field: 'startOn', minWidth: 80, maxWidth: 80, width: 80,
                //suppressMenu: true,
                filter: 'agSetColumnFilter',
                comparator: this.dateComparator,
                // filterParams: {
                //     textCustomComparator: (type, value, filter) => {
                //         filter = JSON.parse(filter);
                //
                //         if (filter.starton && filter.starton !== 'off') {
                //             const fromDate = new Date(filter.fromdate);
                //             const toDate = new Date(filter.todate);
                //             const startOn = new Date(value);
                //
                //             if (startOn >= fromDate && startOn <= toDate) {
                //                 return true;
                //             } else {
                //                 return false;
                //             }
                //         }
                //         return true;
                //     }
                // }
            },
            {
                headerName: 'Term', field: 'expireOn', minWidth: 80, maxWidth: 80, width: 80, filterParams: {newRowsAction: 'keep'},
                filter: 'agSetColumnFilter', comparator: this.dateComparator,
            },
        );
        if (this.userService.requirePermission('approveContract')) {
            columnDefs.push(
                {
                    headerName: 'Status', field: 'contractStatus',
                    cellRenderer: 'approvalComponent',
                    filter: 'agTextColumnFilter',
                    filterParams: {newRowsAction: 'keep'},
                    minWidth: 90, maxWidth: 90, width: 90,
                    cellClassRules: {
                        'borrow-recall-return': function (params) {
                            return params.data.contractStatus === 'Closed';
                        },
                    },
                }
            );
        } else {
            columnDefs.push(
                {
                    headerName: 'Status', field: 'contractStatus',
                    minWidth: 80, maxWidth: 80, width: 80, suppressSizeToFit: true, filter: 'agTextColumnFilter', filterParams: {newRowsAction: 'keep'}
                }
            );
        }
        columnDefs.push(
            {headerName: 'Name', field: 'accountName', filter: 'agTextColumnFilter', hide: true, filterParams: {newRowsAction: 'keep'}},
            {headerName: 'Acct', field: 'depositoryNo', width: 75, suppressSizeToFit: true, hide: true, filter: 'agTextColumnFilter', filterParams: {newRowsAction: 'keep'}},
            {
                headerName: 'Rebate', field: 'dailyRebate', type: 'numericColumn', filter: 'agNumberColumnFilter',
                valueFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$');
                    }
                }, filterParams: {newRowsAction: 'keep'}, hide: true
            },
            {
                headerName: 'Rebate To Date', field: 'profitLoss', type: 'numericColumn', filter: 'agNumberColumnFilter',
                valueFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$');
                    }
                }, filterParams: {newRowsAction: 'keep'}, hide: true
            },
            {
                headerName: 'Daily Funding', field: 'dailyFundingCharge', type: 'numericColumn', filter: 'agNumberColumnFilter',
                valueFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$');
                    }
                }, filterParams: {newRowsAction: 'keep'}, hide: true,
            },
            {
                headerName: 'Funding To Date', field: 'fundingCharge', type: 'numericColumn', filter: 'agNumberColumnFilter',
                valueFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$');
                    }
                }, filterParams: {newRowsAction: 'keep'}, hide: true
            },
            {headerName: 'Comment', field: 'publicComment', filter: 'agTextColumnFilter', filterParams: {newRowsAction: 'keep'}, minWidth: 170, maxWidth: 170, width: 170},
            {headerName: 'Private Comment', field: 'privateComment', filter: 'agTextColumnFilter', filterParams: {newRowsAction: 'keep'}, hide: true},
            {headerName: 'Submitter', field: 'submitterNickname', filter: 'agTextColumnFilter', filterParams: {newRowsAction: 'keep'}, minWidth: 95, maxWidth: 95, width: 95},
            {headerName: 'Reviewer', field: 'approverNickname', filter: 'agTextColumnFilter', filterParams: {newRowsAction: 'keep'}, minWidth: 95, maxWidth: 95, width: 95,},
            {
                headerName: 'Modified', field: 'modifiedOn', hide: false, filterParams: {newRowsAction: 'keep'},
                filter: 'agDateColumnFilter',
                suppressMenu: true,
                //comparator: this.dateComparator
            },
            {headerName: 'rateSort', field: 'rateSort', hide: true, filterParams: {newRowsAction: 'keep'}, sort: 'desc', sortedAt: 3}
        );
        this.defaultColDefs = columnDefs;
    }

    getDefaultPreferences(): ContractDetailPreferences {
        const colState = [
            // {colId: 'symbol', hide: false, aggFunc: null, width: 150, minWidth: 150, maxWidth: 150, pivotIndex: null},
            // {colId: 'side', hide: false, aggFunc: null, width: 60, minWidth: 60, maxWidth: 60, pivotIndex: null, sortedAt: 1, sort: 'asc',},
            // {colId: 'counterPartyName', hide: false, aggFunc: null, filter: 'agSetColumnFilter', width: 120, filterParams: {newRowsAction: 'keep'}},
            // {colId: 'contraAccount', hide: false, aggFunc: null, width: 120, pivotIndex: null},
            // {colId: 'contraDepositoryNo', hide: false, aggFunc: null, width: 70, pivotIndex: null},
            // {colId: 'contraLoanetId', hide: false, aggFunc: null, width: 90, maxWidth: 90, pivotIndex: null},
            // {colId: 'rate', hide: true, aggFunc: null, width: 70, pivotIndex: null},
            // {colId: 'borrowRate', hide: false, aggFunc: null,  width: 85, minWidth: 85, maxWidth: 85, pivotIndex: null},
            // {colId: 'loanRate', hide: false, aggFunc: null, width: 85, minWidth: 85, maxWidth: 85, pivotIndex: null},
            // {colId: 'borrowQuantity', hide: false, aggFunc: null, width: 90, pivotIndex: null},
            // {colId: 'loanQuantity', hide: false, aggFunc: null, width: 90, pivotIndex: null},
            // {colId: 'borrowAmount', hide: false, aggFunc: null, width: 125, minWidth: 125, pivotIndex: null},
            // {colId: 'loanAmount', hide: false, aggFunc: null, width: 125, minWidth: 125, pivotIndex: null},
            // {colId: 'contractNo', hide: false, aggFunc: null, width: 90, pivotIndex: null},
            // {colId: 'profitCenter', hide: false, aggFunc: null, width: 80, pivotIndex: null},
            // {colId: 'pendingSignRecall', hide: false, aggFunc: null, width: 96, pivotIndex: null},
            // {colId: 'pendingSideReturn', hide: false, aggFunc: null, width: 99, pivotIndex: null},
            // {colId: 'startOn', hide: false, aggFunc: null, width: 120, pivotIndex: null},
            // {colId: 'expireOn', hide: false, aggFunc: null, width: 115, pivotIndex: null},
            // {colId: 'contractStatus', hide: false, aggFunc: null, width: 97, pivotIndex: null},
            // {colId: 'accountName', hide: true, aggFunc: null, width: 100, pivotIndex: null},
            // {colId: 'depositoryNo', hide: true, aggFunc: null, width: 75, pivotIndex: null},
            // {colId: 'dailyRebate', hide: true, aggFunc: null, width: 200, pivotIndex: null},
            // {colId: 'profitLoss', hide: true, aggFunc: null, width: 200, pivotIndex: null},
            // {colId: 'dailyFundingCharge', hide: true, aggFunc: null, width: 200, pivotIndex: null},
            // {colId: 'fundingCharge', hide: true, aggFunc: null, width: 200, pivotIndex: null},
            // {colId: 'buyInDate', hide: false, aggFunc: null, width: 120, pivotIndex: null},
            // {colId: 'publicComment', hide: false, aggFunc: null, width: 200, pivotIndex: null},
            // {colId: 'privateComment', hide: false, aggFunc: null, width: 200, pivotIndex: null},
            // {colId: 'modifiedOn', hide: false, aggFunc: null, width: 200, pivotIndex: null},
            // {colId: 'submitterNickname', hide: false, aggFunc: null, width: 200, pivotIndex: null},
            // {colId: 'approverNickname', hide: false, aggFunc: null, width: 200, pivotIndex: null},
            // {colId: 'rateSort', hide: true, aggFunc: null, width: 200, pivotIndex: null}
            {colId: 'symbol', hide: false},
            {colId: 'side', hide: false},
            {colId: 'counterPartyName', hide: true},
            {colId: 'contraAccount', hide: false},
            {colId: 'contraDepositoryNo', hide: false},
            {colId: 'contraLoanetId', hide: false},
            {colId: 'rate', hide: true},
            {colId: 'borrowRate', hide: false},
            {colId: 'loanRate', hide: false},
            {colId: 'borrowQuantity', hide: false},
            {colId: 'loanQuantity', hide: false},
            {colId: 'borrowAmount', hide: false},
            {colId: 'loanAmount', hide: false},
            {colId: 'contractNo', hide: false},
            {colId: 'profitCenter', hide: false},
            {colId: 'pendingSignRecall', hide: false},
            {colId: 'pendingSideReturn', hide: false},
            {colId: 'buyInDate', hide: false},
            {colId: 'startOn', hide: false},
            {colId: 'expireOn', hide: false},
            {colId: 'contractStatus', hide: false},
            {colId: 'accountName', hide: true},
            {colId: 'depositoryNo', hide: true},
            {colId: 'dailyRebate', hide: true},
            {colId: 'profitLoss', hide: true},
            {colId: 'dailyFundingCharge', hide: true},
            {colId: 'fundingCharge', hide: true},
            {colId: 'publicComment', hide: false},
            {colId: 'privateComment', hide: true},
            {colId: 'submitterNickname', hide: false},
            {colId: 'approverNickname', hide: false},
            {colId: 'modifiedOn', hide: false},
            {colId: 'rateSort', hide: true}
        ];

        return new ContractDetailPreferences({
            primaryKey: 'DEFAULT',
            name: 'Default',
            state: colState,
            sort: [
                {
                    colId: 'side',
                    sort: 'asc'
                },
                {
                    colId: 'rate',
                    sort: 'desc'
                },
                {
                    colId: 'rateSort',
                    sort: 'desc'
                }
            ],
            filter: {
                // contractStatus: {filterType: 'set', values: ['Open', 'Submitted', 'Warning', 'Closed']}
            }
        });
    }

    getFxContractPreferences(): ContractDetailPreferences {
        const colState = [
            {colId: 'symbol', hide: false},
            {colId: 'side', hide: false},
            {colId: 'counterPartyName', hide: true},
            {colId: 'contraAccount', hide: false},
            {colId: 'contraDepositoryNo', hide: false},
            {colId: 'contraLoanetId', hide: false},
            {colId: 'rate', hide: true},
            {colId: 'borrowRate', hide: false},
            {colId: 'loanRate', hide: false},
            {colId: 'contraFxRate', hide:false},
            {colId: 'fee', hide: false},
            {colId: 'borrowQuantity', hide: false},
            {colId: 'loanQuantity', hide: false},
            {colId: 'borrowAmount', hide: false},
            {colId: 'fxBorrowAmount', hide: false},
            {colId: 'loanAmount', hide: false},
            {colId: 'fxLoanAmount', hide: false},
            {colId: 'dailyRebate', hide: false},
            {colId: 'fxDailyRebate', hide: false},
            {colId: 'dailyRebateFee', hide: false},
            {colId: 'fxDailyRebateFee', hide: false},
            {colId: 'contractNo', hide: false},
            {colId: 'profitCenter', hide: false},
            {colId: 'pendingSignRecall', hide: false},
            {colId: 'pendingSideReturn', hide: false},
            {colId: 'startOn', hide: false},
            {colId: 'expireOn', hide: false},
            {colId: 'contractStatus', hide: false},
            {colId: 'accountName', hide: true},
            {colId: 'depositoryNo', hide: true},
            {colId: 'profitLoss', hide: true},
            {colId: 'dailyFundingCharge', hide: true},
            {colId: 'fundingCharge', hide: true},
            {colId: 'buyInDate', hide: false},
            {colId: 'publicComment', hide: false},
            {colId: 'privateComment', hide: true},
            {colId: 'submitterNickname', hide: false},
            {colId: 'approverNickname', hide: false},
            {colId: 'modifiedOn', hide: false},
            {colId: 'rateSort', hide: true}
        ];

        return new ContractDetailPreferences({
            primaryKey: 'DEFAULT',
            name: 'Default',
            state: colState,
            sort: [
                {
                    colId: 'side',
                    sort: 'asc'
                },
                {
                    colId: 'rate',
                    sort: 'desc'
                },
                {
                    colId: 'rateSort',
                    sort: 'desc'
                }
            ],
            filter: {
                // contractStatus: {filterType: 'set', values: ['Open', 'Submitted', 'Warning', 'Closed']}
            }
        });
    }

    getPendingApprovalPreferences(): ContractDetailPreferences {
        const colState = [
            {colId: 'symbol', hide: false, aggFunc: null, width: 155, pivotIndex: null},
            {colId: 'side', hide: false, aggFunc: null, width: 106, pivotIndex: null},
            {colId: 'cusip', hide: false, aggFunc: null, width: 210, pivotIndex: null},
            {colId: 'contraDepositoryNo', hide: false, aggFunc: null, width: 86, pivotIndex: null},
            {colId: 'borrowQuantity', hide: false, aggFunc: null, width: 94, pivotIndex: null},
            {colId: 'borrowAmount', hide: false, aggFunc: null, width: 133, pivotIndex: null},
            {colId: 'rate', hide: false, aggFunc: null, width: 87, pivotIndex: null},
            {colId: 'loanQuantity', hide: false, aggFunc: null, width: 90, pivotIndex: null},
            {colId: 'loanAmount', hide: false, aggFunc: null, width: 121, pivotIndex: null},
            {colId: 'profitCenter', hide: false, aggFunc: null, width: 87, pivotIndex: null},
            {colId: 'depositoryNo', hide: false, aggFunc: null, width: 84, pivotIndex: null},
            {colId: 'publicComment', hide: false, aggFunc: null, width: 200, pivotIndex: null},
            {colId: 'submitterNickname', hide: false, aggFunc: null, width: 200, pivotIndex: null},
            {colId: 'contractStatus', hide: false, aggFunc: null, width: 97, pivotIndex: null}
        ];

        return new ContractDetailPreferences({
            primaryKey: 'PENDING_APPROVALS',
            name: 'Pending Approvals',
            state: colState,
            sort: [
                {
                    colId: 'rate',
                    sort: 'desc'
                }
            ],
            filter: {
                contractStatus: {
                    filterType: 'text', filter: 'Submitted', type: 'equals'
                }
            }
        });
    }

    dateComparator(date1, date2) {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        if (date1 && date2) {
            return momentDate1.diff(momentDate2);
        }
    }

    styleDtc(params) {
        if (this.accountsPreferencesService.accountsPreferencesDictionary &&
            this.accountsPreferencesService.accountsPreferencesDictionary[params.data.contraAccountId] &&
            this.accountsPreferencesService.accountsPreferencesDictionary[params.data.contraAccountId] !== '#ffffff') {
            return {'background-color': this.accountsPreferencesService.accountsPreferencesDictionary[params.data.contraAccountId]};
        } else {
            return null;
        }
    }

    static styleZero(params) {
        if (params.value === 0) {
            return {'color': '#bfbfbf'};
        } else {
            return {'color': 'inherit'};
        }
    }



    static styleNegZero(params) {
        if (params.value === 0) {
            return {'color': '#bfbfbf'};
        } else if (params.value < 0) {
            return {'color': 'red'};
        } else if (params.value > 0) {
            return {'color': 'inherit'};
        }
    }

    public setDefaultPreferences(): void {
        this.currentPreferences = this.getDefaultPreferences();
        this.preferenceStateEmitter.next(this.currentPreferences);
    }

    public setPendingApprovalPreferences(): void {
        this.currentPreferences = this.getPendingApprovalPreferences();
        this.preferenceStateEmitter.next(this.currentPreferences);
    }

    public setPreferences(preference: ContractDetailPreferences): void {
        if (preference) {
            this.currentPreferences = preference;
            this.preferenceStateEmitter.next(this.currentPreferences);
        }
    }

    public setFxContractPreferences(): void {
        this.currentPreferences = this.getFxContractPreferences();
        this.preferenceStateEmitter.next(this.currentPreferences);
    }

}
