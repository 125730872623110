import {Component, HostBinding, Input, Output} from '@angular/core';
import {NavTabService} from '../../../../../app/main/nav-tab/nav-tab.service';
import {UserService} from '../../../../../app/auth/user/user.service';

@Component({
    selector   : 'fuse-nav-vertical-item',
    templateUrl: './nav-vertical-item.component.html',
    styleUrls  : ['./nav-vertical-item.component.scss']
})
export class FuseNavVerticalItemComponent
{
    @HostBinding('class') classes = 'nav-item';
    @Input() item: any;

    constructor(public navTabService: NavTabService, public userService: UserService)
    {
    }

    addLink(item){
        this.navTabService.addLink(item);
    }
}
