import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

import { ContractsService } from '../contracts.service';

@Component({
    selector   : 'fuse-selected-bar',
    templateUrl: './selected-bar.component.html',
    styleUrls  : ['./selected-bar.component.scss']
})
export class FuseContractsSelectedBarComponent
{
    selectedContracts: string[];
    hasSelectedContracts: boolean;
    isIndeterminate: boolean;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;

    constructor(
        private contractsService: ContractsService,
        public dialog: MatDialog
    )
    {
        this.contractsService.onSelectedContractsChanged
            .subscribe(selectedContracts => {
                this.selectedContracts = selectedContracts;
                setTimeout(() => {
                    this.hasSelectedContracts = selectedContracts.length > 0;
                    this.isIndeterminate = (selectedContracts.length !== this.contractsService.currentCollection.length && selectedContracts.length > 0);
                }, 0);
            });

    }

    selectAll()
    {
        this.contractsService.selectContracts();
    }

    deselectAll()
    {
        this.contractsService.deselectContracts();
    }

}
