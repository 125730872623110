import {EventEmitter, Injectable, Output} from '@angular/core';
import {environment} from '../../../../../environments/environment';

import {HttpClient} from '@angular/common/http';

import {Transaction} from './transaction.model';
import {ContractEvent} from '../../../../shared/constants/contractevent.model';
import {ProfitCenter} from '../../../../shared/constants/profitcenter.model';

@Injectable({
    providedIn: 'root'
})
export class TransactionHistoryService {

    @Output() queryCompleted = new EventEmitter();
    contractEventsList: ContractEvent[] = undefined;
    profitCenterList: ProfitCenter[] = undefined;
    transactionList: Transaction[] = [];
    isQuerying = false;

    endpoint = environment.verticalApiEndpoint + 'reports/shortLoanInterest';
    // endpoint = environment.verticalApiEndpoint + 'contract/transactions';
    contractEventEndpoint = environment.verticalApiEndpoint + 'constants/contractEvents'
    profitCenterEndpoint = environment.verticalApiEndpoint + 'constants/profitCenters'

    constructor(private http: HttpClient) {
    }

    getTransactionHistory(params: any): Promise<any> {
        this.isQuerying = true;
        this.transactionList = [];
        return new Promise((resolve, reject) => {
            this.http.post(this.endpoint, params)
                .subscribe((response: any) => {
                    for (const transaction of response.items) {
                        //console.log(transaction);
                        //this.transactionList.push(new Transaction(transaction));
                    }
                    this.emitQueryCompletedEvent();
                    this.isQuerying = false;
                    resolve(response);
                }, reject);
        });
    }

    // getContractEventList(): void {
    //     if (this.contractEventsList === undefined) {
    //         this.http.get(this.contractEventEndpoint)
    //             .subscribe((contractEvents: any) => {
    //                 this.contractEventsList = [];
    //                 const contractEventsList = contractEvents.items;
    //                 for (const contractEvent of contractEventsList) {
    //                     const newContractEvent: ContractEvent = new ContractEvent(contractEvent);
    //                     this.contractEventsList.push(newContractEvent);
    //                 }
    //                 //console.log('ContractEventList: ', this.contractEventsList);
    //             }, error => {
    //                 //console.log('Error getting ContractEventList: ', error);
    //             });
    //     }
    // }
    //
    // getProfitCenterList(): void {
    //     if (this.profitCenterList === undefined) {
    //         this.http.get(this.profitCenterEndpoint)
    //             .subscribe((profitCenters: any) => {
    //                 this.profitCenterList = [];
    //                 const profitCentersList = profitCenters.items;
    //                 for (const profitCenter of profitCentersList) {
    //                     const newProfitCenter: ProfitCenter = new ProfitCenter(profitCenter);
    //                     this.profitCenterList.push(newProfitCenter);
    //                 }
    //                 //console.log('ProfitCenterList: ', this.profitCenterList);
    //             }, error => {
    //                 //console.log('Error getting ProfitCenterList: ', error);
    //             });
    //     }
    // }

    emitQueryCompletedEvent() {
        this.queryCompleted.emit();
    }

    getQueryCompleteEmitter() {
        return this.queryCompleted;
    }
}
