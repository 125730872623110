export class Address {
    addressId: number;
    addressTypeId: number;
    address1: string;
    address2: string;
    city: string;
    state: string;
    postal: string;
    countryCode: string;
    addressType: string;

    constructor(address?) {
        address = address || {};
        this.addressId = address.addressId || 0;
        this.addressTypeId = address.addressTypeId || -1;
        this.address1 = address.address1 || '';
        this.address2 = address.address2 || '';
        this.city = address.city || '';
        this.state = address.state || '';
        this.postal = address.postal || '';
        this.countryCode = address.countryCode || '';
        if (address.addressTypeId === 0) {
            this.addressType = 'Business Address';
        } else if (address.addressTypeId === 1) {
            this.addressType = 'Billing  Address';
        } else {
            this.addressType = 'New Address';
        }
    }
}
