import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {CounterPartyAccount} from './account.model';

@Injectable()
export class AccountService implements Resolve<any> {

    accounts: CounterPartyAccount[] = [];
    onAccountsLoaded: BehaviorSubject<any> = new BehaviorSubject([]);
    endpoint = environment.verticalApiEndpoint + 'account';

    constructor(private httpClient: HttpClient) {
    }

    getRemoteAccounts(): void {
        this.httpClient.get(this.endpoint)
            .subscribe((resp: any) => {
                const accountArray: CounterPartyAccount[] = [];
                for (const account of resp.items) {
                    accountArray.push(new CounterPartyAccount(account));
                }
                this.accounts = accountArray;
                this.onAccountsLoaded.next(this.accounts);
            }, error => {
                console.log('Error getting accounts: ', error);
            });
    }

    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getRemoteAccounts()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    disableAccount(accountId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.httpClient.put(this.endpoint + '/' + accountId + '/delete', {})
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

}
