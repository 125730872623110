import {Identifiable} from '../../../../shared/firestore-collection.service';
import {Moment} from 'moment';
import * as moment from 'moment-timezone';

export class DtcTransaction implements Identifiable{
    primaryKey: any;

    dtcTranId: number;
    dtcId: string;
    contraDtcId: string;
    transactTime: Moment;
    // instrumentId: number;
    cusip: string;
    symbol: string;
    quantity: number;
    amount: number;
    status: string;
    action: string;
    activity: number;
    repend: boolean;
    pendDropReason: string;
    reason: string;
    journal: string;
    isSPO: boolean;
    hasDueBill: boolean;
    isADR: boolean;
    isOCC: boolean;
    isPartial: boolean;
    originalAmount: number;
    originalQuantity: number;
    comments: string;

    constructor(dtcTransaction) {
        this.primaryKey = dtcTransaction.primaryKey;
        this.action = dtcTransaction.action;
        this.activity = dtcTransaction.activity;
        this.amount = dtcTransaction.amount;
        this.comments = dtcTransaction.comments;
        this.contraDtcId = dtcTransaction.contraDTCId;
        this.cusip = dtcTransaction.cusip;
        this.dtcId = dtcTransaction.dtcid;
        this.dtcTranId = dtcTransaction.dtctranId;
        this.hasDueBill = dtcTransaction.hasDueBill;
        // this.instrumentId = dtcTransaction.instrumentId;
        this.isADR = dtcTransaction.isADR;
        this.isOCC = dtcTransaction.isOCC;
        this.isPartial = dtcTransaction.isPartial;
        this.isSPO = dtcTransaction.isSPO;
        this.journal = dtcTransaction.journal;
        this.originalAmount = dtcTransaction.originalAmount;
        this.originalQuantity = dtcTransaction.originalQuantity;
        this.pendDropReason = dtcTransaction.pendDropReason;
        this.quantity = dtcTransaction.quantity;
        this.reason = dtcTransaction.reason;
        this.repend = dtcTransaction.repend;
        this.status = dtcTransaction.status;
        this.symbol = dtcTransaction.symbol;
        this.transactTime = moment.utc(dtcTransaction.transactTime, 'X').tz('America/New_York');
    }
}
