import {AfterViewInit, Component, ViewChild, ViewContainerRef} from '@angular/core';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {environment} from '../../../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'radio-cell',
    template: `
    <mat-card>
        <div class="container" #group tabindex="0" (keydown)="onKeyDown($event)">
        <mat-form-field>
            <mat-select [(ngModel)]="currentStatus" [disabled]="disableEdit()" #statusSelect (valueChange)="setStatus(statusSelect.value)">
                <mat-option *ngFor="let status of statuses" [value]="status" [disabled]="disableSet(status)">
                    {{ status }}
                </mat-option>
            </mat-select>
            </mat-form-field>
        </div>
    </mat-card>
`,
    styles: [
        `
            .container {
                width: 190px;
                height: 48px;
            }

            .container:focus {
                outline: none;
            }
        `
    ]
})
export class StatusSelectComponent implements ICellEditorAngularComp, AfterViewInit {
    private params: any;
    public statuses: string[];
    public currentStatus: string;
    public selectedIndex: number;

    @ViewChild("group", { read: ViewContainerRef })
    public group;

    constructor(private httpClient: HttpClient) {

    }

    agInit(params: any): void {
        this.params = params;
        // this.gridOptions =
        this.currentStatus = this.params.value;
        this.statuses = this.params.statuses;

        this.selectedIndex = this.statuses.findIndex(item => {
            return item === this.params.value;
        });
    }

    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {
        setTimeout(() => {
            this.group.element.nativeElement.focus();
        });
        this.selectStatusFromIndex();
    }

    private selectStatusFromIndex() {
        this.currentStatus = this.statuses[this.selectedIndex];
    }

    getValue() {
        return this.currentStatus;
    }

    isPopup(): boolean {
        return true;
    }

    setStatus(value) {
        const contractId = this.params.node.data.contractId;
        if (value === 'Approved') {
            this.approveContract(contractId, true);
        } else if (value === 'Denied') {
            this.approveContract(contractId, false);
        } else if (value === 'Open') {
            this.clearWarning(contractId);
        }
    }

    /*
     * A little over complicated for what it is, but the idea is to illustrate how you might navigate through the radio
     * buttons with up & down keys (instead of finishing editing)
     */
    onKeyDown(event): void {
        const key = event.which || event.keyCode;
        if (key === 38 || key === 40) {
            this.preventDefaultAndPropagation(event);

            if (key === 38) {
                // up
                this.selectedIndex = this.selectedIndex === 0 ? this.statuses.length - 1 : this.selectedIndex - 1;
            } else if (key === 40) {
                // down
                this.selectedIndex = this.selectedIndex === this.statuses.length - 1 ? 0 : this.selectedIndex + 1;
            }
            this.selectStatusFromIndex();
        }
    }

    approveContract(contractId, approved: boolean) {
        //console.log('Submitting ' + contractId + ' for approval!');
        const endpoint = environment.verticalApiEndpoint + 'contracts/' + contractId + '/approval';
        //console.log('post to: ', endpoint);
        const jsonApproved = {'approved': approved};
        this.httpClient
            .post(endpoint, JSON.stringify(jsonApproved))
            .subscribe((resp) => {
                    //console.log('response to post: ', resp);
                }, (error) => {
                    //console.log('error: ', error);
                }
            );
    }

    clearWarning(contractId) {
        //console.log('Submitting ' + contractId + ' for approval!');
        const endpoint = environment.verticalApiEndpoint + 'contracts/' + contractId + '/warning';
        //console.log('post to: ', endpoint);
        const jsonApproved = {'approved': true};
        this.httpClient
            .post(endpoint, JSON.stringify(jsonApproved))
            .subscribe((resp) => {
                    //console.log('response to post: ', resp);
                }, (error) => {
                    //console.log('error: ', error);
                }
            );
    }

    private preventDefaultAndPropagation(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    /**
     *
     {status: 'New', canEdit: true, canSet: false},
     {status: 'Approved', canEdit: false, canSet: true},
     {status: 'Denied', canEdit: false, canSet: true},
     {status: 'Error', canEdit: false, canSet: true},
     {status: 'Open', canEdit: false, canSet: true},
     {status: 'Rejected', canEdit: false, canSet: false},
     {status: 'Enqueued', canEdit: false, canSet: false},
     {status: 'Warning', canEdit: true, canSet: false}
     *
     */

    disableEdit() {
        if (this.currentStatus === 'Submitted' || this.currentStatus === 'Warning') {
            return false;
        }
        return true;
    }

    disableSet(status: string) {
        if (!this.disableEdit()) {
            // New contracts can only be approved or Denied
            if (this.currentStatus === 'Submitted') {
                if (status === 'Approved' || status === 'Denied') {
                    return false;
                } else {
                    return true;
                }
            }
            // Warning contracts can only be acknowledged as being in warning
            else if (this.currentStatus === 'Warning') {
                if (status === 'Open') {
                    return false;
                } else {
                    return true;
                }
            }
        } else {
            return true;
        }
    }
}
