import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {environment} from '../../../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ConfirmationDialogComponent} from '../../../../../../shared/confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ContractsService} from '../../contracts.service';


@Component({
    selector: 'child-cell',
    template: `
        <span *ngIf="status==='Submitted'">
            <button mat-icon-button (click)="approveContract(true)" class="btn approve"><mat-icon>check</mat-icon></button>
            <button mat-icon-button (click)="approveContract(false)" class="btn deny"><mat-icon>close</mat-icon></button>
        </span>

        <span *ngIf="status!=='Submitted'">{{status}}</span>
    `,
    styles: [
        `.btn {
            height: 20px;
            line-height: 0.5;
        }

        .btn.approve {
            color: green;
        }

        .btn.deny {
            color: red;
        }

        .mat-icon-button {
            width: 30px;
        }
        `
    ]
})
export class ApprovalComponent implements ICellRendererAngularComp {
    public params: any;
    public status: string;
    dialogRef: any;

    constructor(public dialog: MatDialog, private contractsService: ContractsService) {
    }

    agInit(params: any): void {
        this.params = params;
        this.status = this.params.node.data.contractStatus;
    }

    approveContract(approved: boolean) {
        const contractIds: number[] = [];
        contractIds.push(this.params.data.contractId);
        this.contractsService.approveContractsByIds(approved, contractIds);
        this.params.api.deselectAll();
    }

    refresh(): boolean {
        return false;
    }
}
