import {Component, Inject, Input, LOCALE_ID, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import {FplService} from '../fpl.service';
import {Router} from '@angular/router';
import {FplSlsAccountDropDownComponent} from '../fplSlsAccountDropDown.component';
import {FplSlsAccountRendererComponent} from '../fplSlsAccountDropDown.component';
import {formatNumber} from '@angular/common';
import {FplAccount} from '../fplAccount.model';
import {MatSnackBar} from '@angular/material';
import {UserService} from '../../../../../auth/user/user.service';

@Component({
    selector: 'app-fpl-accounts-list',
    templateUrl: './fpl-accounts-list.component.html',
    styleUrls: ['./fpl-accounts-list.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})

export class FplAccountsListComponent implements OnInit {

    columnDefs: any[];
    gridApi: any;
    frameworkComponents = {
        accountCellEditor: FplSlsAccountDropDownComponent,
        accountCellRenderer: FplSlsAccountRendererComponent
    };
    map;

    autoGroupColumnDef = {
        headerName: 'Account Number',
        minWidth: 140,
        width: 140,
        resizable: false,
        pinned: true,
        filter: 'agTextColumnFilter',
        field: 'accountNumber',
        cellRendererParams: {
            suppressCount: true,
            suppressDoubleClickExpand: false,
            suppressEnterExpand: false
        },
    };

    getRowStyle = (params) => {
        if (params.node.group) {
            return {'font-weight': 'bold'};
        }
    };

    constructor(public fplService: FplService,
                public snackBar: MatSnackBar,
                @Inject(LOCALE_ID) private locale: string,
                private router: Router,
                public userService: UserService) {
    }

    ngOnInit() {
        this.fplService.getFplSlsAccounts().then(accounts => {
            this.map = new Map<string, FplAccount>();
            this.columnDefs = [

                {field: 'accountNumber', hide: true, rowGroup: true},
                {field: 'accountNumber', hide: true},
                // {headerName: 'Intelliclear Account', field: 'uniqueKey', filter: 'agTextColumnFilter'},
                {headerName: 'Office', field: 'office', filter: 'agSetColumnFilter', width: 100, minWidth: 100},
                {headerName: 'Corr', field: 'correspondentCode', filter: 'agSetColumnFilter', width: 100, minWidth: 100},
                {headerName: 'Type', field: 'accountType', filter: 'agSetColumnFilter', width: 75, minWidth: 75},
                {headerName: 'Status', field: 'status', filter: 'agSetColumnFilter', width: 75, minWidth: 75},
                {
                    headerName: 'Split',
                    field: 'revSplit',
                    filter: 'agSetColumnFilter',
                    editable: this.userService.requirePermission('fplAccounts'),
                    width: 100,
                    minWidth: 100,
                    valueFormatter: (params) => {
                        if (params.value === 0) {
                            return '';
                        }
                    }
                },
                {
                    headerName: 'SLS Account', field: 'name', filter: 'agTextColumnFilter', editable: this.userService.requirePermission('fplAccounts'), width: 125, minWidth: 125,
                    cellEditor: 'accountCellEditor', cellRenderer: 'accountCellRenderer'
                },
                // {
                //     headerName: 'Linked SLS Account', field: 'accountId', filter: 'agTextColumnFilter', editable: true,
                //     cellEditor: 'accountCellEditor'
                // },
            ]
            ;
        });

    }

    onGridReady(params) {
        this.gridApi = params.api;

        // setTimeout(() => {
        //     this.gridApi.forEachNode(function(node) {
        //         node.expanded = true;
        //     });
        //     this.gridApi.onGroupExpandedOrCollapsed();
        // }, 1000);


        // this.gridApi.sizeColumnsToFit();
    }

    getRowNodeId(data) {
        return data.fplAccountId;
    }

    onDoubleRowClicked(event) {
        this.fplService.selectedFplAccount = event.node.data;
        const fplAccountId = this.getRowNodeId(event.node.data);

        this.router.navigate(['fpl/accounts/' + fplAccountId], {
            replaceUrl: true,
        });

        this.gridApi.get;
    }

    cellEditingStopped(event) {
        if (event.value !== '') {
            this.fplService.updateIndividualFplAccount(event.data).then(res => {
                // this.snackBar.open('Saved', 'OK', {
                //     verticalPosition: 'top',
                //     duration        : 2000
                // });

                this.snackBar.open('Account Updated', null, {
                    verticalPosition: 'bottom',
                    horizontalPosition: 'center',
                    panelClass: 'snackbar',
                    duration: 2000,
                });
            });
        }
        // this.map.set(event.data.uniqueKey, event.data);
        // //console.log(this.map);
    }
}
