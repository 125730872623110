import {CounterPartyAccount} from '../accounts/account.model';

export class CounterParty {
    counterPartyId: number;
    entityId: number;
    name: string;
    hasLoanNet: boolean;
    canReturnOnRecall: boolean;
    canRecallPartial: boolean;
    occMemberId: number;
    bic: string;
    billingReference: string;
    complianceReference: string;
    currencyId: string;
    createdOn: Date;
    modifiedOn: Date;
    counterPartyTypeId: number;
    counterPartyStatusId: number;
    defaultRoundRuleValue: number;
    defaultMargin: number;
    accounts: CounterPartyAccount[];
    shortCode: string;
    lendLimit: number;
    borrowLimit: number;

    bizEmail: string;
    opsEmail: string;

    internal: boolean;

   constructor(counterParty?) {
       counterParty = counterParty || {};
       this.accounts = counterParty.accounts || [];
       this.bic = counterParty.bic || '';
       this.billingReference = counterParty.billingReference || '';
       this.canReturnOnRecall = counterParty.canReturnOnRecall || true;
       this.complianceReference = counterParty.complianceReference || '';
       this.counterPartyId = Number(counterParty.counterPartyId) || 0;
       this.counterPartyStatusId = counterParty.counterPartyStatusId || 1;
       this.counterPartyTypeId = counterParty.counterPartyTypeId || 1;
       this.createdOn = counterParty.createdOn || undefined;
       this.currencyId = counterParty.currencyId || 'USD';
       this.defaultMargin = counterParty.defaultMargin || 1.02;
       this.defaultRoundRuleValue = counterParty.defaultRoundRuleValue || 0;
       this.entityId = counterParty.entityId || 0;
       this.hasLoanNet = counterParty.hasLoanNet || true;
       this.modifiedOn = counterParty.modifiedOn || undefined;
       this.name = counterParty.name || '';
       this.occMemberId = counterParty.occMemberId || '';
       this.canRecallPartial = counterParty.canRecallPartial || true;
       this.shortCode = counterParty.shortCode || '';
       this.lendLimit = counterParty.lendLimit || 0;
       this.borrowLimit = counterParty.borrowLimit || 0;
       this.bizEmail = counterParty.bizEmail || '';
       this.opsEmail = counterParty.opsEmail || '';
       this.internal = counterParty.internal || false;
   }


}
