import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../../environments/environment';
import {FirestoreCollectionService} from '../../../../../../shared/firestore-collection.service';
import {ContractPrototype} from './contract-prototype';
import {AngularFirestore} from 'angularfire2/firestore';
import {AngularFireAuth} from 'angularfire2/auth';
import {Observable} from 'rxjs';

export interface ContractPrototypeContainer {
    contractPrototypes: ContractPrototype[];
}

@Injectable({
    providedIn: 'root'
})
export class ContractImportService extends FirestoreCollectionService<ContractPrototype>{

    private import_endpoint = environment.verticalApiEndpoint + 'contracts/import';

    private collectionName: string;

    constructor(private httpClient: HttpClient,
                private firestoreClient: AngularFirestore,
                private afAuthClient: AngularFireAuth) {
        super(httpClient, firestoreClient, afAuthClient);
        const collectionName = 'contract-import';
        this.collectionName = collectionName;
        super.initializeService(this.collectionName, this.createContractPrototypeCallback);
    }

    public createContractPrototypeCallback(newObj: any): ContractPrototype {
        return new ContractPrototype(newObj);
    }

    public importPrototypes(prototypes: ContractPrototype[]): Observable<void> {
        const container: ContractPrototypeContainer = {
            contractPrototypes: prototypes
        };
        return this.httpClient.post<void>(this.import_endpoint, JSON.stringify(container));
    }

    public addBlankData(numRows: number): void {
        for (let i = 0; i < numRows; i++) {
            const prototype = this.generateBlankPrototype();
            this.firestoreClient.collection(this.collectionName)
                .add(Object.assign({}, prototype)).then();
        }
    }

    private generateBlankPrototype(): ContractPrototype {
        return new ContractPrototype({
            symbol: '         ',
            cusip: '         ',
            quantity: null,
            pcPrice: null,
            price: null,
            value: null,
            conduit: '',
            borrowRate: null,
            borrowContra: '    ',
            loanRate: null,
            loanContra: '    ',
            profitCenter: ' ',
            comments: '    ',
            status: '',
            primaryKey: null,
            loanSpecFlag: '',
            loanBatchCode: '',
        });
    }

    public updatePrototype(prototype: ContractPrototype): void {
        this.firestoreClient.collection(this.collectionName)
            .doc<ContractPrototype>(prototype.primaryKey)
            .update(Object.assign({}, prototype));
    }

    public clearPrototypes(prototypes: ContractPrototype[]): void {
        const batch = this.firestoreClient.firestore.batch();
        for (const prototype of prototypes) {
            const updateRef = this.firestoreClient.firestore.collection(this.collectionName).doc(prototype.primaryKey);
            const blankPrototype = Object.assign({}, this.generateBlankPrototype());
            blankPrototype.primaryKey = prototype.primaryKey;
            batch.update(updateRef, blankPrototype);
        }
        batch.commit();
    }

    public updatePrototypeBatch(prototypes: ContractPrototype[]): void {
        const batch = this.firestoreClient.firestore.batch();
        for (const prototype of prototypes) {
            const document = this.firestoreClient.firestore.collection(this.collectionName).doc(prototype.primaryKey);
            batch.update(document, Object.assign({}, prototype));
        }
        batch.commit();
    }
}
