import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {UserListService} from './user-list.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-users',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class UserListComponent implements OnInit {

    columnDefs: any[];
    gridApi: any;

    getContextMenuItems;

  constructor(public userService: UserListService,
              private router: Router) { }

  ngOnInit() {
      this.columnDefs = [
          {headerName: 'First', field: 'first', filter: 'agTextColumnFilter'},
          {headerName: 'Last', field: 'last', filter: 'agTextColumnFilter'},
          {headerName: 'Disabled', field: 'disabled', filter: 'agTextColumnFilter'}
      ];


      this.getContextMenuItems = function getContextMenuItems(params) {
          const result = [
              {
                  name: params.node.data.disabled ? 'Enable User' : 'Disable User',
                  action: function() {
                      // window.alert('Alerting about ' + params.value);
                      //console.log('params: ', params);
                      params.api.userService.toggleUserDisabled(params.node.data)
                          .then((resp) => {
                              params.api.updateRowData({update: [resp]});
                          })
                          .catch((error) => {
                              //console.log('err: ', error);
                          });
                  }
              },
          ];
          return result;
      };
  }

  onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
      this.gridApi.userService = this.userService;
  }

    getRowNodeId(data) {
        return data.userUid;
    }


    onRowClicked(event) {
        const userUid = this.getRowNodeId(event.node.data);
        // //console.log('Grouping: ', this.gridApi.getRenderedNodes());
        this.router.navigate(['users/' + userUid], {
            replaceUrl: true,
        });
    }
}
