import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material';

import {Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';

import {fuseAnimations} from '@fuse/animations';

import {FuseContractsContractFormDialogComponent} from './contract-form/contract-form.component';
import {ContractsService} from './contracts.service';
import {FuseContractsReRateFormDialogComponent} from './rerate-form/rerate-form.component';
import {UserService} from '../../../../auth/user/user.service';
import {FuseContractsReturnFormDialogComponent} from './return-form/return-form.component';
import {FuseContractsRecallFormDialogComponent} from './recall-form/recall-form.component';
import {MatchContractFormComponent} from './match-contract-form/match-contract-form.component';
import {FuseContractsContractListComponent} from './contract-list/contract-list.component';
import {QuoteService} from '../../instruments/quotes/quote.service';
import {Quote} from '../../instruments/quotes/quote.model';
import {AngularFirestore} from 'angularfire2/firestore';
import {AngularFireAuth} from 'angularfire2/auth';
import {ContractDetailPreferencesService} from './contract-detail-preferences/contract-detail-preferences.service';
import {ContractDetailsPreferenceRenameComponent} from './contract-detail-preferences/rename-form/rename-form.component';
import {ConfirmationDialogComponent} from '../../../../shared/confirmation-dialog/confirmation-dialog.component';
import {ActivatedRoute} from '@angular/router';
import {assertLessThan} from '@angular/core/src/render3/assert';
import {NavTabService} from '../../../nav-tab/nav-tab.service';
import {ContractFilterService} from '../../../../contract-filter/contract-filter.service';
import {NavTabItem} from '../../../nav-tab/nav-tab-item';

@Component({
    selector: 'fuse-contracts',
    templateUrl: './contracts.component.html',
    styleUrls: ['./contracts.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class FuseContractsComponent implements OnInit, OnDestroy {


    id: string;
    hasSelectedContracts: boolean;
    searchInput: FormControl;
    dialogRef: any;
    onSelectedContractsChangedSubscription: Subscription;
    onFilterChangedSubscription: Subscription;
    tabId: string;
    showTradeWindow = false;
    loadingQuote = false;

    pendingRecallsFilter = false;
    pendingReturnsFilter = false;

    onPreferenceActionRequestSubscription: Subscription;
    onPreferenceChangeSubscription: Subscription;
    selectedSymbol;
    onContractFilterChanged: Subscription;

    @ViewChild(FuseContractsContractListComponent) listComponent: FuseContractsContractListComponent;

    constructor(
        public contractsService: ContractsService,
        public userService: UserService,
        public dialog: MatDialog,
        public quoteService: QuoteService,
        public firestore: AngularFirestore,
        public afAuth: AngularFireAuth,
        public preferenceService: ContractDetailPreferencesService,
        public activatedRoute: ActivatedRoute,
        public navTabService: NavTabService,
        public contractFilterService: ContractFilterService
    ) {

        this.quoteService.quote = null;
        this.searchInput = new FormControl('');
        // this.onFilterChangedSubscription =
        //     this.contractsService.onFilterChanged
        //         .subscribe((filters) => {
        //             if (filters.symbol) {
        //                 const symbol = filters.symbol.filter;
        //                 alert('!');
        //                 this.quoteService.updateQuote(symbol, this.contractsService.effectiveDate);
        //             } else {
        //                 this.quoteService.quote = null;
        //             }
        //         });

        this.onPreferenceActionRequestSubscription = this.preferenceService.requestPreferenceAction
            .subscribe(
                (action) => {
                    if (action) {
                        if (action === 'save') {
                            this.savePreferences();
                        } else if (action === 'rename') {
                            this.renamePreferences();
                        } else if (action === 'delete') {
                            this.deletePreferences();
                        }
                    }
                }, (err) => {

                }
            );

        this.onPreferenceChangeSubscription = this.preferenceService.preferenceStateEmitter
            .subscribe(
                (prefs) => {
                    if (prefs) {
                        if (this.listComponent) {
                            this.listComponent.setState(prefs);
                            this.listComponent.autoSizeAll({
                                columnApi: this.contractsService.gridColumnApi
                            });
                            this.listComponent.saveTabPreferences(prefs);
                        }
                    }
                }, (err) => {

                }
            );
    }

    deletePreferences() {
        this.preferenceService.deletePreferences();
    }

    renamePreferences() {
        this.dialogRef = this.dialog.open(ContractDetailsPreferenceRenameComponent, {
            panelClass: 'rename-form-dialog',
            data: {
                action: 'new',
                name: this.preferenceService.currentPreferences.name
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: FormGroup) => {
                if (!response) {
                    return;
                }
                const newName = response[1].getRawValue().name;
                this.preferenceService.renamePreferences(newName);
            });
    }

    savePreferences() {
        this.dialogRef = this.dialog.open(ContractDetailsPreferenceRenameComponent, {
            panelClass: 'rename-form-dialog',
            data: {
                action: 'new'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: FormGroup) => {
                if (!response) {
                    return;
                }
                const name = response[1].getRawValue().name;
                const state = this.contractsService.gridColumnApi.getColumnState();
                const sort = this.contractsService.gridApi.getSortModel();
                const filter = this.contractsService.gridApi.getFilterModel();
                this.preferenceService.savePreferences(name, state, sort, filter);

            });

    }

    cloneContract() {
        this.dialogRef = this.dialog.open(FuseContractsContractFormDialogComponent, {
            panelClass: 'contract-form-dialog',
            data: {
                action: 'clone',
                contract: this.contractsService.getSelected()[0]
            }
        });

        this.dialogRef.afterClosed()
            .subscribe((response: FormGroup) => {
                if (!response) {
                    return;
                }

                this.contractsService.updateContract(response.getRawValue());
            });

    }

    // rerateContracts() {
    //     this.dialogRef = this.dialog.open(FuseContractsReRateFormDialogComponent, {
    //         panelClass: 'rerate-form-dialog',
    //         data: {
    //             action: 'ReRate Contract',
    //             contract: this.contractsService.getSelected().length > 1 ? null : this.contractsService.getSelected()[0]
    //         }
    //     });
    //
    //     this.dialogRef.afterClosed()
    //         .subscribe((response: FormGroup) => {
    //             if (!response) {
    //                 return;
    //             }
    //             const rate = response[1].controls.rate.value;
    //
    //             //console.log('rate: ', rate);
    //             this.contractsService.reRateSelected(rate);
    //             this.contractsService.deselectContracts();
    //         });
    // }
    //
    // returnContract() {
    //     this.dialogRef = this.dialog.open(FuseContractsReturnFormDialogComponent, {
    //         panelClass: 'return-form-dialog',
    //         data: {
    //             action: 'Add Return',
    //             contract: this.contractsService.selectedContracts[0]
    //         }
    //     });
    //
    //     this.dialogRef.afterClosed()
    //         .subscribe((response: FormGroup) => {
    //             if (!response) {
    //                 return;
    //             }
    //             // const returnQty = response[1].controls.returnQty.value;
    //             const formGroup = response[1].getRawValue();
    //             // //console.log('form group: ', formGroup);
    //             // //console.log('returnQty: ', returnQty);
    //             this.contractsService.return(formGroup.contractId, formGroup.returnQty, formGroup.specFlag, formGroup.batchCode, null);
    //             this.contractsService.deselectContracts();
    //         });
    // }
    //
    // recallContract() {
    //     this.dialogRef = this.dialog.open(FuseContractsRecallFormDialogComponent, {
    //         panelClass: 'recall-form-dialog',
    //         data: {
    //             action: 'Add Recall',
    //             contract: this.contractsService.selectedContracts[0]
    //         }
    //     });
    //
    //     this.dialogRef.afterClosed()
    //         .subscribe((response: FormGroup) => {
    //             if (!response) {
    //                 return;
    //             }
    //             const recallQty = response[1].controls.recallQty.value;
    //
    //             //console.log('recallQty: ', recallQty);
    //             this.contractsService.recall(recallQty);
    //             this.contractsService.deselectContracts();
    //         });
    // }
    //
    // toggleTradeWindow() {
    //     this.contractsService.toggleTradeWindow(); // showTradeForm = !this.contractsService.showTradeForm;
    // }

    ngOnInit() {

        this.onSelectedContractsChangedSubscription =
            this.contractsService.onSelectedContractsChanged
                .subscribe(selectedContracts => {
                    this.hasSelectedContracts = selectedContracts.length > 0;
                });


        this.activatedRoute.params.subscribe(params => {
            this.tabId = params['id'];
            this.selectedSymbol = params['selectedSymbol'];
        });
    }

    ngOnDestroy() {
        this.onSelectedContractsChangedSubscription.unsubscribe();
        // this.onFilterChangedSubscription.unsubscribe();
        this.quoteService.quote = null;
        this.quoteService.quoteObs = null;
        this.onPreferenceActionRequestSubscription.unsubscribe();
        this.preferenceService.requestPreferenceAction.next('');
        this.onPreferenceChangeSubscription.unsubscribe();
        this.preferenceService.setDefaultPreferences();
        this.listComponent=null;
       // this.onContractFilterChanged.unsubscribe();

    }

    //
    // disable(minLength: number, maxLength: number, onlyLoans: boolean, onlyBorrows: boolean, reqApproval: boolean): boolean {
    //     const selected = this.contractsService.getSelected();
    //     for (const contract of selected) {
    //         if (contract.contractStatus !== 'Open') {
    //             return true;
    //         }
    //
    //         if (reqApproval && contract.contractStatusId === 0) {
    //             return true;
    //         }
    //
    //         if (onlyLoans && contract.side === 'B') {
    //             return true;
    //         } else if (onlyBorrows && contract.side === 'L') {
    //             return true;
    //         }
    //     }
    //     if (maxLength === null) {
    //         return !(selected.length >= minLength);
    //     } else {
    //         return !(selected.length >= minLength && selected.length <= maxLength);
    //     }
    // }
    //
    // disableApproveOrDeny(): boolean {
    //     const selected = this.contractsService.getSelected();
    //     for (const contract of selected) {
    //         if (contract.contractStatus !== 'Submitted') {
    //             return true;
    //         }
    //     }
    //
    //     if (selected.length < 1) {
    //         return true;
    //     }
    // }
    //
    // bulkApproveOrDeny(approve: boolean) {
    //     const action = approve ? 'Approve' : 'Deny';
    //     this.dialogRef = this.dialog.open(ConfirmationDialogComponent, {
    //         width: '350px',
    //         data: {
    //             dialogTitle: action + ' contracts?',
    //             dialogText: 'Are you sure to ' + action + ' ' + this.contractsService.getSelected().length + ' contract(s)?'
    //         }
    //     });
    //
    //     this.dialogRef.afterClosed()
    //         .subscribe((response: boolean) => {
    //             if (response) {
    //                 this.contractsService.approveContracts(approve);
    //                 this.contractsService.gridApi.deselectAll();
    //             }
    //         });
    // }

    applyFilter($event) {
        this.contractsService.gridApi.setFilterModel($event);
        this.contractsService.gridApi.onFilterChanged();
    }
}
