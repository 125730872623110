import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ReleaseNotesComponent} from './release_notes.component';
import {AuthGuard} from '../../../auth/auth.guard';
import {CommonModule} from '@angular/common';
import {FuseSharedModule} from '../../../../@fuse/shared.module';

const routes: Routes = [
    {
        path     : 'release_notes',
        component: ReleaseNotesComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [
        ReleaseNotesComponent
    ],
    imports: [
        CommonModule,
        FuseSharedModule,
        RouterModule.forChild(routes),
    ]
})
export class ReleaseNotesModule {
}
