import * as moment from 'moment-timezone';

export class ContractHistory {
    eventName: string;
    occuredOn: string;
    modifiedOn: string;
    description: string;
    status: string;

    constructor(contractHistory) {
        this.eventName = contractHistory.eventName;
        this.occuredOn = moment.utc(contractHistory.occuredOn).format('YY-MM-DD  HH:mm:ss');
        // if (this.eventName === 'Recall' || this.eventName === 'RevRecall') {
        //     this.occuredOn = moment.utc(contractHistory.occuredOn).format('lll');
        // } else {
        //     this.occuredOn = moment.utc(contractHistory.occuredOn).tz('America/New_York').format('lll');
        // }

        this.modifiedOn = moment.utc(contractHistory.modifiedOn).tz('America/New_York').format('lll');
        this.description = contractHistory.description;
        this.status = contractHistory.status;
    }
}
