import {EventEmitter, Injectable, Output} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {FplAccount} from './fplAccount.model';
import {CounterPartyAccount} from '../accounts/account.model';
import {FplPosition} from './fplAccountPosition.model';
import {FirestoreCollectionService} from '../../../../shared/firestore-collection.service';
import {AngularFirestore} from 'angularfire2/firestore';
import {AngularFireAuth} from 'angularfire2/auth';

@Injectable()
export class FplService extends FirestoreCollectionService<FplPosition> implements Resolve<any> {

    unassignedFplAccounts: FplAccount[];
    fplAccounts: FplAccount[] = [];
    fplAccountPositions: FplPosition[] = [];
    unassignedSlsAccounts: CounterPartyAccount[] = [];
    endpoint = environment.verticalApiEndpoint;
    collectionName = 'fpl-positions';
    @Output() fplAccountPositionChanged = new EventEmitter();
    private _selectedFplAccount: FplAccount;

    constructor(private httpClient: HttpClient,
                private firestoreClient: AngularFirestore,
                private afAuthClient: AngularFireAuth) {
        super(httpClient, firestoreClient, afAuthClient);
    }

    public createTransactionCallback(newObj): FplPosition {
        return new FplPosition(newObj);
    }

    set selectedFplAccount(value: FplAccount) {
        this._selectedFplAccount = value;
    }

    get selectedFplAccount(): FplAccount {
        return this._selectedFplAccount;
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getFplAccounts()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }


    getFplSlsAccounts(): Promise<CounterPartyAccount[]> {
        return new Promise((resolve, reject) => {
            this.httpClient.get(this.endpoint + 'fpl/accounts/getFplSlsAccounts')
                .subscribe((response: any) => {
                    this.unassignedSlsAccounts = [];
                    this.unassignedSlsAccounts.push(new CounterPartyAccount());
                    for (const account of response.items) {
                        this.unassignedSlsAccounts.push(new CounterPartyAccount(account));
                    }
                    resolve(this.unassignedSlsAccounts);
                }, reject);
        });
    }

    getUnassignedFplAccounts(): Promise<FplAccount[]> {
        return new Promise((resolve, reject) => {
            this.httpClient.get(this.endpoint + 'fpl/accounts/getUnassignedFplAccounts')
                .subscribe((response: any) => {
                    this.unassignedFplAccounts = [];
                    for (const account of response.items) {
                        this.unassignedFplAccounts.push(new FplAccount(account));
                    }
                    resolve(this.unassignedFplAccounts);
                }, reject);
        });
    }


    getFplAccounts(): Promise<FplAccount[]> {
        return new Promise((resolve, reject) => {
            this.httpClient.get(this.endpoint + 'fpl/accounts')
                .subscribe((response: any) => {
                    this.fplAccounts = [];
                    for (const account of response.items) {
                        this.fplAccounts.push(new FplAccount(account));
                    }
                    resolve(this.fplAccounts);
                }, reject);
        });
    }

    getFplAccountPositions(): Promise<FplPosition[]> {
        return new Promise((resolve, reject) => {
            let url = this.endpoint + 'fpl/accounts/positions/';
            this.httpClient.get(url)
            // this.http.get(this.endpoint + 'com/sls/common/fpl/accountPositions')
                .subscribe((response: any) => {
                    this.fplAccountPositions = [];
                    for (const position of response.items) {
                        this.fplAccountPositions.push(new FplPosition(position));
                    }
                    resolve(this.fplAccountPositions);
                }, reject);
        });
    }


    updateFplAccount(fplAccount: FplAccount): Promise<any> {
        this.fplAccounts = [];
        return new Promise((resolve, reject) => {
            let body = {
                fplAccountId: fplAccount.fplAccountId,
                accountId: fplAccount.accountId,
                revSplit: fplAccount.revSplit,
            };
            this.httpClient.post(this.endpoint + 'fpl/accounts/update', body).subscribe((response: any) => {
                for (const item of response.items) {
                    this.fplAccounts.push(new FplAccount(item));
                }
                resolve(this.fplAccounts);
            }, reject);
        });
    }

    movePositiontoFpl(fplPosition: FplPosition): Promise<any> {
        return new Promise((resolve, reject) => {
            let body = {
                fplPositionId: fplPosition.fplPositionId,
                symbol: fplPosition.symbol,
                accountId: fplPosition.accountId,
                revSplit: fplPosition.revSplit,
                acctNo: fplPosition.accountNumber,
                corr: fplPosition.correspondentCode,
                acctType: fplPosition.accountType,
                office: fplPosition.office,
                status: fplPosition.status,
                amountToBorrow: fplPosition.amountToBorrow,
                tclose: fplPosition.quantity
            };
            this.httpClient.post(this.endpoint + 'fpl/positions/movePositionToFpl', body).subscribe((response: any) => {
                let pos: FplPosition = new FplPosition(response);
                resolve(pos);
            }, reject);
        });
    }

    updateIndividualFplAccount(fplAccount: FplAccount): Promise<any> {
        return new Promise((resolve, reject) => {
            let body = {
                fplAccountId: fplAccount.fplAccountId,
                accountId: fplAccount.accountId,
                revSplit: fplAccount.revSplit,
            };
            this.httpClient.post(this.endpoint + 'fpl/accounts/update', body).subscribe((response: any) => {
                // for (const item of response.items) {
                //     this.fplAccounts.push(new FplAccount(item));
                // }
                resolve(this.fplAccounts);
            }, reject);
        });
    }

    borrowFromFplAccountPosition(fplAccountPosition: FplPosition): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let body = {
                fplAccountPositionId: fplAccountPosition.fplPositionId,
                symbol: fplAccountPosition.symbol,
                corr: fplAccountPosition.correspondentCode,
                office: fplAccountPosition.office,
                acctNo: fplAccountPosition.accountNumber,
                acctType: fplAccountPosition.accountType,
                amountToBorrow: fplAccountPosition.amountToBorrow,
                borrowPrice: fplAccountPosition.borrowPrice,
            };
            this.httpClient.post(this.endpoint + 'fpl/accountPositions/borrow', body).subscribe((response: any) => {
                resolve(true);
            }, reject);
        });
    }


    returnToFplAccountPosition(fplAccountPosition: FplPosition): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let body = {
                fplAccountPositionId: fplAccountPosition.fplPositionId,
                symbol: fplAccountPosition.symbol,
                corr: fplAccountPosition.correspondentCode,
                office: fplAccountPosition.office,
                acctNo: fplAccountPosition.accountNumber,
                acctType: fplAccountPosition.accountType,
                amountToReturn: fplAccountPosition.amountToReturn,
                borrowPrice: fplAccountPosition.borrowPrice,
            };
            //console.log(body);
            this.httpClient.post(this.endpoint + 'fpl/accountPositions/return', body).subscribe((response: any) => {
                resolve(true);
            }, reject);
        });
    }

    getFplAccountBySlsAccountId(accountId: number): Promise<FplAccount> {
        return new Promise((resolve, reject) => {
            this.httpClient.get(this.endpoint + 'fpl/accountBySlsAccountId/' + accountId)
                .subscribe((response: any) => {
                    let fplAccount: FplAccount = new FplAccount(response);
                    resolve(fplAccount);
                }, reject);
        });
    }
}
