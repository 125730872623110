import {EventEmitter, Injectable, Output} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import moment from 'moment';
import {ContractReview} from './contract-review.model';


@Injectable({
    providedIn: 'root'
})
export class ContractReviewService {

    @Output() queryCompleted = new EventEmitter();
    reviewList: ContractReview[] = [];
    unReviewedList = [];
    isQuerying = false;

    private endpoint = environment.verticalApiEndpoint + 'contract/review/list';
    private unReviewedEndpoint = environment.verticalApiEndpoint + 'contract/review/count';
    private submitReviewEndpoint = environment.verticalApiEndpoint + 'contract/review/update';

    constructor(private http: HttpClient) {
    }

    getReviewList(params: any): Promise<any> {
        this.reviewList = [];
        return new Promise((resolve, reject) => {
            this.http.post(this.endpoint, params)
                .subscribe((response: any) => {
                    for (const reviewItem of response.items) {
                        this.reviewList.push(new ContractReview(reviewItem));
                    }
                    resolve(this.reviewList);
                }, reject);
        });
    }


    getUnReviewedList(): Promise<any> {
        this.unReviewedList = [];
        return new Promise((resolve, reject) => {
            this.http.get(this.unReviewedEndpoint)
                .subscribe((response: any) => {
                    for (const unReviewedItem of response.items) {
                        let date = new Date(unReviewedItem.starton);
                        let formatted = moment.utc(date, 'X').format('MM/DD/YY');
                        unReviewedItem.tradedate = formatted;
                        this.unReviewedList.push(formatted);
                    }
                    resolve(this.unReviewedList);
                }, reject);
        });
    }

    submitReview(params: any): Promise<any> {
        this.reviewList = [];
        return new Promise((resolve, reject) => {
            this.http.post(this.submitReviewEndpoint, params)
                .subscribe((response: any) => {
                    for (const reviewItem of response.items) {
                        this.reviewList.push(new ContractReview(reviewItem));
                    }
                    resolve(this.reviewList);
                }, reject);
        });
    }


    emitQueryCompletedEvent() {
        this.queryCompleted.emit();
    }

    getQueryCompleteEmitter() {
        return this.queryCompleted;
    }
}
