import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../../@fuse/animations';

@Component({
    selector: 'release-notes',
    templateUrl: './release_notes.component.html',
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class ReleaseNotesComponent implements OnInit, OnDestroy {

    constructor() {
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {

    }
}
