import {FuseUtils} from '@fuse/utils';
import {RoundRule} from '../../operations/constants/roundrules/roundrule.model';
import * as moment from 'moment-timezone';
import {Identifiable} from '../../../../shared/firestore-collection.service';
import {ConfigSettingsService} from '../../../../shared/config-settings.service';
import {CounterpartyService} from '../../operations/counterparties/counterparty.service';

export class Contract implements Identifiable {
    contractId: number;
    //instrumentId: number;
    symbol: string;
    quantity: number;
    amount: number;
    rate: number;
    margin: number;
    roundRuleValue: number;
    contractNo: string;
    rebateDate: Date;
    benchmarkRate: number;
    side: string;
    accountId: number;


    benchmarkId: number;
    contraAccountId: number;
    depositoryNo: string;
    contraDepositoryNo: number;


    expireOn: Date;
    createdOn: Date;
    modifiedOn: Date;
    originalQuantity: number;
    contractStatusId: number;
    profitCenter: string;
    publicComment: string;
    privateComment: string;
    sourceLoginId: number;
    approveLoginId: number;
    submitterNickname: string;
    approverNickname: string;
    loading: boolean;
    counterPartyName: string;
    contraAccount: string;
    contractStatus: string;
    divRate: number;
    pendingRecall: number;
    pendingSignRecall: number;
    pendingReturn: number;
    pendingSideReturn: number;
    dailyRebate: number;
    fxDailyRebate: number;
    accountName: string;
    borrowQuantity: number;
    loanQuantity: number;
    borrowAmount: number;
    loanAmount: number;
    fxBorrowAmount: number;
    fxLoanAmount: number;
    loanRecall: number;
    loanReturn: number;
    borrowRecall: number;
    borrowReturn: number;
    rateSort: number;
    detailRecords: any[];
    startOn: Date;
    cusip: string;
    profitLoss: number;
    collateralCode: string;
    fplPositionId: number;
    contraLoanetId: string;
    contractMade: boolean;
    rebateAvg: number;

    // START aggregate fields
    borrowRate: number;
    loanRate: number;
    spread: number;
    imbalance: number;
    cash: number;

    // END aggregate fields
    roundRule: RoundRule;

    dailyFundingCharge: number;
    fundingCharge: number;
    buyInDate: Date;

    dtcStatus: string;

    contraFxRate: number;

    contraCurrencyId: string;

    fee: number;
    fxAmount: number;

    dailyRebateFee: number;
    fxDailyRebateFee: number;
    buyInQuantity: number;

    primaryKey: any;

    constructor(contract) {
        this.contractId = contract.contractId || FuseUtils.generateGUID();
        this.contractNo = contract.contractNo;
        //this.instrumentId = contract.instrumentId;
        this.symbol = contract.symbol;
        this.quantity = contract.quantity;
        this.amount = contract.amount;
        this.rate = contract.rate;
        this.margin = contract.margin;
        this.submitterNickname = contract.submitterNickname;
        this.approverNickname = contract.approverNickname;
        this.roundRuleValue = contract.roundRuleValue;
        this.rebateDate = contract.rebateDate;
        this.benchmarkRate = contract.benchmarkRate;
        this.side = contract.side;
        this.accountId = contract.accountId;
        this.benchmarkId = contract.benchmarkId;
        this.contraAccountId = contract.contraAccountId;
        this.contraAccount = contract.contraAccount;
        this.depositoryNo = contract.depositoryNo;
        this.contraDepositoryNo = contract.contraDepositoryNo;
        this.collateralCode = contract.collateralCode;
        this.fplPositionId = contract.fplPositionId;
        this.contraLoanetId = contract.contraLoanetId;
        if (contract.createdOn) {
            this.createdOn = new Date(contract.createdOn.seconds * 1000);
        }
        if (contract.modifiedOn) {
            this.modifiedOn = new Date(contract.modifiedOn.seconds * 1000);
            this.modifiedOn = moment.utc(this.modifiedOn, 'X').tz('America/New_York').format('MM-DD-YY  HH:mm:ss');
        }
        this.originalQuantity = contract.originalQuantity;
        this.contractStatusId = contract.contractStatusId;
        this.contractStatus = contract.contractStatus;
        // this.profitCenter = contract.profitCenter || ;
        if (contract.profitCenter) {
            this.profitCenter = contract.profitCenter.trim();
        } else {
            this.profitCenter = '';
        }
        this.approveLoginId = contract.approveLoginId;
        this.sourceLoginId = contract.sourceLoginId;
        this.loading = false;
        this.pendingRecall = contract.pendingRecall;
        this.pendingReturn = contract.pendingReturn;
        this.pendingSignRecall = (this.side === 'B' ? 1 : -1) * contract.pendingRecall;
        this.pendingSideReturn = (this.side === 'B' ? 1 : -1) * contract.pendingReturn;
        this.counterPartyName = contract.counterPartyName;
        this.accountName = contract.accountName;
        this.dailyRebate = contract.dailyRebate || 0;
        this.fxDailyRebate = contract.fxDailyRebate || 0;
        this.buyInQuantity = contract.buyInQuantity;

        // if (contract.dailyRebate) {
        //
        //     // this.dailyRebate = ((this.rate / 100) * this.amount) / 360 * ((this.side === 'L') ? -1 : 1);
        // } else {
        //     this.dailyRebate = ((this.rate / 100) * this.amount) / 360 * ((this.side === 'L') ? -1 : 1);
        // }
        this.publicComment = contract.publicComment;
        this.privateComment = contract.privateComment;
        this.rebateAvg = contract.rebateAvg;

        this.contraCurrencyId = contract.contraCurrencyId;
        this.contraFxRate = contract.contraFxRate;
        this.fee = contract.fee;
        this.fxAmount = contract.fxAmount;
        this.dailyRebateFee = contract.dailyRebateFee;
        this.fxDailyRebateFee= contract.fxDailyRebateFee;

        if (this.side === 'L') {
            this.borrowAmount = null;
            this.fxBorrowAmount = null;
            this.borrowQuantity = null;
            this.loanAmount = this.amount;
            this.fxLoanAmount = this.fxAmount;
            this.loanQuantity = this.quantity;
            this.rateSort = this.rate * 10000;
            this.loanRate = contract.rate;
            this.loanRecall = this.pendingSignRecall;
            this.loanReturn = this.pendingSideReturn;
            this.borrowRecall = null;
            this.borrowReturn = null;
        } else {
            this.borrowAmount = this.amount;
            this.fxBorrowAmount = this.fxAmount;
            this.borrowQuantity = this.quantity;
            this.loanAmount = null;
            this.loanQuantity = null;
            this.fxLoanAmount = null;
            this.rateSort = 100 - this.rate;
            this.borrowRate = contract.rate;
            this.loanRecall = null;
            this.loanReturn = null;
            this.borrowRecall = this.pendingSignRecall;
            this.borrowReturn = this.pendingSideReturn;
        }

        // this.detailRecords = [
        //     {
        //         'eventName': 'New Contract',
        //         'date': formatDate(this.createdOn, 'shortDate', 'en-US')
        //     }
        // ];

        if (this.approveLoginId) {
            this.detailRecords.push({
                'event': 'Approved',
                'date': this.modifiedOn
            });
        }

        // this.startOn = moment_tz.tz(contract.startOn, 'America/New_York').format('lll');
        // this.startOn = contract.startOn || null;

        if (contract.startOn) {
            this.startOn = new Date(contract.startOn.seconds * 1000);
            //this.startOn.setHours(this.startOn.getHours() + 5);
            this.startOn = moment.utc(this.startOn, 'X').format('MM-DD-YY');
        }
        if (contract.expireOn) {
            this.expireOn = new Date(contract.expireOn.seconds * 1000);
            this.expireOn = moment.utc(this.expireOn, 'X').format('MM-DD-YY');

        }

        this.imbalance = 0;
        this.cash = 0;

        this.cusip = contract.cusip || '';
        this.profitLoss = contract.profitLoss || 0;
        this.dailyFundingCharge = contract.dailyFundingCharge || 0;
        this.fundingCharge = contract.fundingCharge || 0;
        if (contract.buyInDate) {
            this.buyInDate = new Date(contract.buyInDate.seconds * 1000);
            this.buyInDate = moment.utc(this.buyInDate, 'X').format('MM-DD-YY');
        }
        this.primaryKey = contract.primaryKey;

        this.dtcStatus = contract.dtcStatus;

        if (this.dtcStatus === 'M') {
            this.contractMade = true;
        } else {
            this.contractMade = false;
        }
    }

    setRoundRule(value: RoundRule) {
        this.roundRule = value;
    }

    toString(): string {
        return JSON.stringify(this);
    }

    humanString(): string {
        return this.side + ' ' + this.quantity + ' ' + this.symbol + ' (' + this.cusip + ') ' + '@ ' + this.rate + (this.side === 'B' ? ' from ' : ' to ') + this.counterPartyName;
    }

    public isFpl(): boolean {
        return this.fplPositionId > 0;
    }
}
