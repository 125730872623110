import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserPreferencesComponent } from './user-preferences.component';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../../../auth/auth.guard';
import {FuseSharedModule} from '../../../../../@fuse/shared.module';
import {MatButtonModule, MatIconModule, MatSidenavModule, MatTabsModule} from '@angular/material';
import { AccountsPreferencesComponent } from './accounts-preferences/accounts-preferences.component';
import {AgGridModule} from 'ag-grid-angular';
import { ColorPickerModule } from 'ngx-color-picker';
import {ColorPickerRendererComponent} from './accounts-preferences/color-picker.component';
import {ColorPickerDialogueComponent} from './accounts-preferences/color-picker-dialogue.component';
import { ChangePasswordComponent } from 'app/change-password/change-password.component';

const routes: Routes = [
    {
        path: 'preferences',
        component: UserPreferencesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'change-password',
        component: ChangePasswordComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
  declarations: [UserPreferencesComponent, AccountsPreferencesComponent, ColorPickerRendererComponent, ColorPickerDialogueComponent],
    imports: [
        CommonModule,
        FuseSharedModule,
        RouterModule.forChild(routes),
        MatIconModule,
        MatTabsModule,
        AgGridModule.withComponents([ColorPickerRendererComponent, ColorPickerDialogueComponent]),
        ColorPickerModule,
        MatButtonModule,
        MatSidenavModule
    ]
})
export class UserPreferencesModule { }
