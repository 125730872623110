import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import {Subscription} from 'rxjs';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {Location, TitleCasePipe} from '@angular/common';
import {UserDetailService} from './user-detail.service';
import {UserRolesComponent} from './user-roles/user-roles.component';
import {UserRolesService} from './user-roles/user-roles.service';
import {InternalUser} from '../../../../../auth/user/internal-user';
import {Role} from '../../../../../auth/user/role';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class UserDetailComponent implements OnInit, OnDestroy, AfterViewInit {

    user: InternalUser = new InternalUser();
    onUserChanged: Subscription;
    pageType: string;
    userForm: FormGroup;

    @ViewChild(UserRolesComponent) roleComponent: UserRolesComponent;

    personSave = true;

    constructor(
        private userDetailService: UserDetailService,
        private formBuilder: FormBuilder,
        public snackBar: MatSnackBar,
        private location: Location,
        private userRoleService: UserRolesService,
        private titlePipe: TitleCasePipe
    )
    {
    }

    ngOnInit()
    {
        // Subscribe to update entity on changes
        this.onUserChanged =
            this.userDetailService.onUserChanged
                .subscribe(user => {

                    if ( user )
                    {
                        this.user = new InternalUser(user);
                        this.userDetailService.user = this.user;
                        this.userRoleService.onUserRolesChanged.next(this.user.roles);
                        //console.log('User: ', this.user);
                        this.pageType = 'edit';
                    }
                    else
                    {
                        this.pageType = 'new';
                        this.user = new InternalUser();
                    }

                    this.userForm = this.createUserForm();
                });
    }

    ngOnDestroy()
    {
        this.onUserChanged.unsubscribe();
    }

    createUserForm()
    {
        return this.formBuilder.group({
            first: [this.user.first],
            last: [this.user.last],
            title: [this.user.title],
            salutation: [this.user.salutation],
            nickName: [this.user.nickName],
            email: [this.user.email, [Validators.required, Validators.email]],
            userUid: [this.user.userUid]
        });
    }

    /**
     * MODIFIES EXISTING USERS
     */
    saveUser()
    {
        const data = this.userForm.getRawValue();
        this.userDetailService.saveUser(data)
            .then(() => {

                // Trigger the subscription with new data
                this.userDetailService.onUserChanged.next(data);

                // Show the success message
                this.snackBar.open('User saved', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            })
            .catch((error) => {
                //console.log('error saving user: ', error);
                // Show the fail message
                let message: string = error.error.error.message;
                message = this.titlePipe.transform(message);
                message = message.replace(/-/g, ' ');
                this.snackBar.open('User save failed: ' + this.titlePipe.transform(message), 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            });
    }

    saveRoles() {
        const data = this.roleComponent.roleForm.getRawValue();
        const newRoles: number[] = [];
        const newRolesEnabled: boolean[] = [];
        for (const {enabled, index} of data.roles.map((enabled, index) => ({ enabled, index}))) {
            newRoles.push(this.userRoleService.validRoleList[index].roleId);
            newRolesEnabled.push(enabled);
        }
        //console.log('save roles: ', newRoles);
        this.userRoleService.saveUserRoles(newRoles, newRolesEnabled)
            .then((resp) => {
                //console.log('saved users: ', resp);
                // Trigger the subscription with new data

                // Show the success message
                this.snackBar.open('Roles saved', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
                const newRoleList: Role[] = [];
                newRoles.forEach((item, index) => {
                    if (newRolesEnabled[index] === true) {
                        const newRole = this.userRoleService.getRoleById(item);
                        if (newRole) {
                            newRoleList.push(newRole);
                        }
                    }
                });
                //console.log('setting new role list to: ', newRoleList);
                this.userDetailService.user.roles = newRoleList;
                this.userRoleService.onUserRolesChanged.next(newRoleList);
            })
            .catch( (err) => {
                //console.log('err sav: ', err);
            });
    }

    /**
     * CREATES NEW USERS
     */
    addUser()
    {
        const data = this.userForm.getRawValue();
        this.userDetailService.addUser(data)
            .then((updatedUser) => {
                // //console.log('Updated Entity: ', updatedEntity);
                // Trigger the subscription with new data
                this.userDetailService.onUserChanged.next(updatedUser);
                // this.userDetailService.roleService.onUserRolesChanged.next(updatedUser.roles);

                // Show the success message
                this.snackBar.open('User added', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });

                // Change the location with new one
                this.location.go('users/' + this.user.userUid);
            })
            .catch((error) => {
                //console.log('error saving user: ', error);
                // Show the fail message
                let message: string = error.error.error.message;
                message = this.titlePipe.transform(message);
                message = message.replace(/-/g, ' ');
                this.snackBar.open('User save failed: ' + message, 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            });
    }

    tabChange(event: any) {
        // //console.log('tab change: ', eventName);
        this.personSave = !this.personSave;
    }

    ngAfterViewInit(): void {
    }

}
