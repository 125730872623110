export class Role {
    abbr: string;
    description: string;
    name: string;
    roleId: number;
    loginId: number;

    private _enabled: boolean;

    constructor(role) {
        this.abbr = role.abbr;
        this.description = role.description;
        this.name = role.name;
        this.roleId = role.roleId;
    }


    set enabled(value: boolean) {
        this._enabled = value;
    }
}
