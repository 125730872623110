import {AfterViewChecked, Component, Inject, ViewChild, ViewEncapsulation} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Contract} from '../contract.model';

// @ViewChild('focusThis') focusThis;

@Component({
    selector     : 'buy-in-form-dialog',
    templateUrl  : './buy-in-form.component.html',
    styleUrls    : ['./buy-in-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class BuyInFormComponent implements AfterViewChecked
{
    dialogTitle: string;
    buyInFormGroup: FormGroup;
    quantity: number;
    contract: Contract;

    constructor(public dialogRef: MatDialogRef<BuyInFormComponent>,
                @Inject(MAT_DIALOG_DATA) private data: any,
                private formBuilder: FormBuilder) {
        this.contract = data.contract;
        this.buyInFormGroup = this.createBuyInForm();
    }

    createBuyInForm()
    {
        return this.formBuilder.group({
            quantity: [this.quantity,
                Validators.compose([Validators.max(this.contract.quantity)])]
        });
    }

    ngAfterViewChecked() {
    }
}
