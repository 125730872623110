import {Identifiable} from '../../../../shared/firestore-collection.service';
import * as moment from 'moment-timezone';

export class SegMemoSeg implements Identifiable {


    primaryKey: any;

    actionCode: string;
    activityType: string;
    comments: string;
    cusip: string;
    dtcId: string;
    errorMessage: any;
    memoSegDate: any;
    memoSegId: number;
    quantity: number;
    responseTime: any;
    seqNumber: string;
    status: string;
    symbol: string;
    unSeg: number;


    constructor(memoseg) {
        this.actionCode = memoseg.actionCode;
        this.cusip = memoseg.cusip;
        this.activityType = memoseg.activityType;
        this.comments = memoseg.comments;
        this.dtcId = memoseg.dtcId;
        this.status = memoseg.status;
        this.errorMessage = memoseg.errorMessage;
        this.responseTime = this.getTime(memoseg.responseTime);
        this.quantity = memoseg.quantity;
        this.seqNumber = memoseg.seqNumber;
        this.symbol = memoseg.symbol;
        this.primaryKey = memoseg.primaryKey;
    }

    getTime(dateObject) {
        const date = new Date(dateObject.year + '-' + dateObject.month + '-' + dateObject.dayOfMonth + ' '
            + dateObject.hour + ':' + dateObject.minute + ':' + dateObject.second + '.000+00:00');

        return date;
    }
}
