import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {EntityService} from '../entity.service';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import {Router} from '@angular/router';

@Component({
  selector: 'app-entity-list',
  templateUrl: './entity-list.component.html',
  styleUrls: ['./entity-list.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class EntityListComponent implements OnInit {

    displayedColumns: string[] = ['entityId', 'name', 'internal', 'createdOn', 'modifiedOn'];
    columnDefs: any[];

    gridApi: any;

  constructor(public entityService: EntityService,
              private router: Router) {
      this.columnDefs = [
          {field: 'entityId', hide: true, filter: 'agNumberColumnFilter'},
          {field: 'internal', hide: true, filter: 'agTextColumnFilter', sort: 'desc'},
          {field: 'name', filter: 'agTextColumnFilter', sort: 'asc'},
      ];
  }

  ngOnInit() {
  }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    getRowNodeId(data) {
        return data.entityId;
    }


    onRowClicked(event) {
        const entityId = this.getRowNodeId(event.node.data);
        this.router.navigate(['entity/' + entityId], {
            replaceUrl: true,
        });
    }
}
