import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Return} from './return.model';
import {FirestoreCollectionService} from '../../../../shared/firestore-collection.service';
import {AngularFirestore} from 'angularfire2/firestore';
import {AngularFireAuth} from 'angularfire2/auth';

@Injectable()
export class ReturnHoldingService extends FirestoreCollectionService<Return> {

    private collectionName = 'returns';

  constructor(private httpClient: HttpClient,
              private firestoreClient: AngularFirestore,
              private afAuthClient: AngularFireAuth) {
      super(httpClient, firestoreClient, afAuthClient);
      super.initializeService(this.collectionName, this.createReturnCallback);
  }

  public createReturnCallback(newObj: any): Return {
      return new Return(newObj);
  }

  public getReturnByContractHistoryId(contractHistoryId: number): Return {
      for (const returnObj of this.collectionData) {
          if (returnObj.contractHistoryId === contractHistoryId) {
              return returnObj;
          } else {
          }
      }
      return null;
  }

}
