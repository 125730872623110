import {AfterViewChecked, Component, Inject, ViewChild, ViewEncapsulation} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Contract} from '../contract.model';

// @ViewChild('focusThis') focusThis;

@Component({
    selector     : 'fuse-contracts-rerate-form-dialog',
    templateUrl  : './rerate-form.component.html',
    styleUrls    : ['./rerate-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class FuseContractsReRateFormDialogComponent implements AfterViewChecked
{
    dialogTitle: string;
    reRateForm: FormGroup;
    rate: number;
    contract: Contract;

    constructor(public dialogRef: MatDialogRef<FuseContractsReRateFormDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private data: any,
                private formBuilder: FormBuilder) {
        this.contract = data.contract;
        this.reRateForm = this.createRerateForm();
    }

    createRerateForm()
    {
        return this.formBuilder.group({
            rate: [this.rate,
                Validators.compose([Validators.max(999), Validators.maxLength(6)])]
        });
    }

    ngAfterViewChecked() {
        // this.reRateForm.get('rate').
    }
}
