import {AfterViewInit, Component, ElementRef, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {UserService} from '../../../../auth/user/user.service';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {GridOptions, ColDef, ColumnApi, GridApi, RowNode} from 'ag-grid-community';
import {MatSnackBar} from '@angular/material';
import {ConstantsService} from '../../../../shared/constants/constants.service';
import {SwitcherService} from '../../../../shared/switcher/switcher.service';
import {QuoteService} from '../../instruments/quotes/quote.service';
import {ContractsService} from '../../trading/contracts/contracts.service';
import {formatCurrency, formatNumber} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Observable, Subscription} from 'rxjs';
import {CounterParty} from '../counterparties/counterparty.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AngularFireAuth} from 'angularfire2/auth';
import {CustomTooltip} from '../../trading/contracts/bulk/toolTip';
import Split from 'split.js';
import {Contract} from '../../trading/contracts/contract.model';
import {StatusSelectComponent} from '../../trading/contracts/contract-context-menu/status-select/status-select.component';
import {ApprovalComponent} from '../../trading/contracts/contract-context-menu/status-select/approval.component';
import * as moment from 'moment-timezone';
import {ContractDetailPreferences} from '../../trading/contracts/contract-detail-preferences/contract-detail-preferences';
import {CheckboxRenderer} from './checkbox-renderer.component';
import {remove} from 'lodash';


@Component({
    selector: 'app-contract-import',
    templateUrl: './contract-management.component.html',
    styleUrls: ['./contract-management.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})

export class ContractManagementComponent implements OnInit, OnDestroy, AfterViewInit {

    gridOptions: GridOptions = <GridOptions>{};
    completedGridOptions: GridOptions = <GridOptions>{};

    currentMadeFilter = ['M', 'P'];

    defaultColDef = {
        editable: false,
        enableCellChangeFlash: true,
        sortable: true,
        filter: true,
        resizable: true,
        tooltipComponent: 'customTooltip',
    };

    cellSelect = (params) => {
        return 'cell-select';
    };

    frameworkComponents = {customTooltip: CustomTooltip, checkboxRenderer: CheckboxRenderer};

    detailsFrameworkComponents = {
        statusEditor: StatusSelectComponent,
        approvalComponent: ApprovalComponent,
        checkboxRenderer: CheckboxRenderer
    };

    gridColumnApi: ColumnApi;
    completedGridColumnApi: ColumnApi;
    gridApi: GridApi;
    completedGridApi: GridApi;
    side: string;
    contra: string;
    profitCenter: string;
    margin: number;
    divRate: number;
    rounding: number;
    specFlag: string;
    batchCode: string;

    contraAccounts: Observable<CounterParty[]>;
    onContractsAddedSubscription: Subscription;
    onContractsChangedSubscription: Subscription;
    onContractsRemovedSubscription: Subscription;
    onDepositoryNoSwitchedSubscription: Subscription;

    onCompletedContractsAddedSubscription: Subscription;
    onCompletedContractsChangedSubscription: Subscription;
    onCompletedContractsRemovedSubscription: Subscription;
    onCompletedDepositoryNoSwitchedSubscription: Subscription;

    splitControl;

    today = (moment.utc(new Date(), 'X').tz('America/New_York').format('MM-DD-YY'));

    @ViewChild('counterParty') contraElement: ElementRef;

    constructor(public userService: UserService,
                public snackBar: MatSnackBar,
                public constantsService: ConstantsService,
                public switcherService: SwitcherService,
                public quoteService: QuoteService,
                public contractService: ContractsService,
                public afAuthClient: AngularFireAuth,
                private formBuilder: FormBuilder,
                @Inject(LOCALE_ID) private locale: string,
                private http: HttpClient) {

        this.gridOptions = {
            columnDefs: this.getGridColDefs(),
            rowClassRules: {},
            floatingFilter: true,
            rowSelection: 'multiple',
            rowMultiSelectWithClick: true,
            suppressRowClickSelection: true,
            isRowSelectable: function (rowData) {
                return rowData.data.contractStatus === 'Submitted';
            },
            context: this
        };

        this.completedGridOptions = {
            columnDefs: this.getDetailColDefs(),
            rowClassRules: {},
            floatingFilter: true,
            suppressRowClickSelection: true,
            context: this
        };
    }

    ngOnInit() {
    }

    getGridColDefs(): ColDef[] {
        const columnDefs = [];
        if (this.userService.requirePermission('approveContract')) {
            columnDefs.push({
                    minWidth: 30, maxWidth: 30, width: 30, field: 'checkbox', pinned: true,
                    checkboxSelection: true, headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true,
                    suppressMenu: true, filter: false, cellStyle: {
                        border: 'none',
                    }, headerClass: 'select-header',
                    resizable: false
                },
            );
        }
        columnDefs.push(
            {
                headerName: 'Symbol', field: 'symbol', width: 100, minWidth: 100,
                filterParams: {
                    newRowsAction: 'keep'
                },
                filter: 'agTextColumnFilter',
            },
            {
                headerName: 'Cusip',
                field: 'cusip',
                width: 100,
                minWidth: 100,
                filterParams: {newRowsAction: 'keep', defaultOption: 'equals'},
                filter: 'agTextColumnFilter'
            },
            {
                headerName: 'S', field: 'side', minWidth: 70, suppressSizeToFit: true, filter: 'agTextColumnFilter',
                filterParams: {newRowsAction: 'keep'}, width: 70,
                sortedAt: 1, sort: 'asc',
            },
            {
                headerName: 'LoanetId',
                field: 'contraDepositoryNo',
                minWidth: 100,
                maxWidth: 100,
                width: 100,
                filter: 'agTextColumnFilter',
                hide: false,
                filterParams: {newRowsAction: 'keep'}
            },
            {headerName: 'Contra', field: 'counterPartyName', filter: 'agTextColumnFilter', hide: true, width: 120, filterParams: {newRowsAction: 'keep'}},
            {headerName: 'Broker', field: 'contraAccount', filter: 'agTextColumnFilter', hide: true, width: 120, filterParams: {newRowsAction: 'keep'}},
            {
                headerName: 'Rate', field: 'rate', minWidth: 75, width: 75, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                valueFormatter: (params) => {

                    return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');

                }, filter: 'agNumberColumnFilter'
            },
            {
                headerName: 'Qty', field: 'quantity', minWidth: 100, maxWidth: 100, width: 100, suppressSizeToFit: true,
                valueFormatter: (params) => {
                    if (params.value === null) {
                        return formatNumber(params.value, this.locale);
                    }
                },
                type: 'numericColumn', filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}
            },
            {
                headerName: 'Amt', field: 'amount', type: 'numericColumn', filter: 'agNumberColumnFilter', minWidth: 100, width: 100,
                valueFormatter: (params) => {
                    return formatCurrency(params.value, this.locale, '$', undefined, '1.0-0');
                },
                filterParams: {newRowsAction: 'keep'}
            },
        );
        columnDefs.push({
            headerName: 'Contract #',
            field: 'contractNo',
            suppressSizeToFit: true,
            minWidth: 120, maxWidth: 120, width: 120,
            filter: 'agNumberColumnFilter',
            filterParams: {newRowsAction: 'keep'},
            pinnedRowCellRenderer: (params) => {
                if (params.value === 'Net' ||
                    params.value === 'Net Spread' ||
                    params.value === 'Total P&L') {
                    return params.value;
                } else if (params.value !== undefined) {
                    return params.data.amountFormat(params);
                }
            },
        });
        if (this.userService.requirePermission('editContract')) {
            columnDefs.push(
                {
                    headerName: 'PC', field: 'profitCenter', minWidth: 55, maxWidth: 55, width: 55, suppressSizeToFit: true,
                    filter: 'agSetColumnFilter', filterParams: {newRowsAction: 'keep'},
                    editable: false
                }
            );
        } else {
            columnDefs.push(
                {
                    headerName: 'PC', field: 'profitCenter', minWidth: 55, maxWidth: 55, width: 55, suppressSizeToFit: true,
                    filter: 'agSetColumnFilter', filterParams: {newRowsAction: 'keep'},
                    editable: false
                },
            );
        }
        columnDefs.push(
            {
                headerName: 'Term', field: 'expireOn', minWidth: 80, maxWidth: 80, width: 80, filterParams: {newRowsAction: 'keep'},
                filter: 'agSetColumnFilter',
            },
        );
        columnDefs.push(
            {headerName: 'StatusId', field: 'contractStatusId', filter: 'agSetColumnFilter', hide: true, filterParams: {newRowsAction: 'keep'}},
            {headerName: 'Name', field: 'accountName', filter: 'agTextColumnFilter', hide: true, filterParams: {newRowsAction: 'keep'}},
            {headerName: 'Acct', field: 'depositoryNo', width: 75, suppressSizeToFit: true, hide: true, filter: 'agTextColumnFilter', filterParams: {newRowsAction: 'keep'}},
            {
                headerName: 'Rebate', field: 'dailyRebate', type: 'numericColumn', filter: 'agNumberColumnFilter',
                valueFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$');
                    }
                }, filterParams: {newRowsAction: 'keep'}, hide: true
            },
            {
                headerName: 'Rebate To Date', field: 'profitLoss', type: 'numericColumn', filter: 'agNumberColumnFilter',
                valueFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$');
                    }
                }, filterParams: {newRowsAction: 'keep'}, hide: true
            },
            {
                headerName: 'Daily Funding', field: 'dailyFundingCharge', type: 'numericColumn', filter: 'agNumberColumnFilter',
                valueFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$');
                    }
                }, filterParams: {newRowsAction: 'keep'}, hide: true,
            },
            {
                headerName: 'Funding To Date', field: 'fundingCharge', type: 'numericColumn', filter: 'agNumberColumnFilter',
                valueFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$');
                    }
                }, filterParams: {newRowsAction: 'keep'}, hide: true
            },
            {headerName: 'Comment', field: 'publicComment', filter: 'agTextColumnFilter', filterParams: {newRowsAction: 'keep'}, minWidth: 170, maxWidth: 170, width: 170},
            {headerName: 'Submitter', field: 'submitterNickname', filter: 'agTextColumnFilter', filterParams: {newRowsAction: 'keep'}, minWidth: 95, maxWidth: 95, width: 95},
            {headerName: 'Approver', field: 'approverNickname', filter: 'agTextColumnFilter', filterParams: {newRowsAction: 'keep'}, minWidth: 95, maxWidth: 95, width: 95,},
            {
                headerName: 'Modified', field: 'modifiedOn', hide: false, filterParams: {newRowsAction: 'keep'},
                minWidth: 105, width: 105,
                comparator: this.dateComparator, valueFormatter: (params) => {
                    return moment(params.value).format('hh:mm a');
                },
            },
            {
                headerName: 'Notes', field: 'privateComment', filter: 'agTextColumnFilter', filterParams: {newRowsAction: 'keep'}, hide: false,
                editable: this.userService.requirePermission('approveContract')
            },
            {headerName: 'rateSort', field: 'rateSort', hide: true, filterParams: {newRowsAction: 'keep'}, sort: 'desc', sortedAt: 3},
            {headerName: 'dtcStatus', field: 'dtcStatus', hide: true, filterParams: {newRowsAction: 'keep'}});
        if (this.userService.requirePermission('approveContract')) {
            columnDefs.push(
                {
                    headerName: 'Status', field: 'contractStatus',
                    cellRenderer: 'approvalComponent',
                    filter: 'agSetColumnFilter',
                    filterParams: {newRowsAction: 'keep'},
                    minWidth: 90, maxWidth: 90, width: 90,
                    cellClassRules: {
                        'borrow-recall-return': function (params) {
                            return params.data.contractStatus === 'Closed';
                        },
                    },
                }
            );
        } else {
            columnDefs.push(
                {
                    headerName: 'Status', field: 'contractStatus',
                    minWidth: 80, maxWidth: 80, width: 80, suppressSizeToFit: true, filter: 'agSetColumnFilter', filterParams: {newRowsAction: 'keep'}
                }
            );
        }
        columnDefs.push(
            {
                headerName: 'Made',
                field: 'contractMade',
                filter: false,
                suppressMenu: true,
                cellRenderer: 'checkboxRenderer',
                editable: false,
                minWidth: 70,
                maxWidth: 70,
                width: 70,
            },
        );
        return columnDefs;
    }

    getDetailColDefs(): ColDef[] {
        const columnDefs = [];
        columnDefs.push(
            {
                headerName: 'Symbol', field: 'symbol', width: 100, minWidth: 100,
                filterParams: {
                    newRowsAction: 'keep'
                },
                filter: 'agTextColumnFilter',
            },
            {
                headerName: 'Cusip',
                field: 'cusip',
                width: 100,
                minWidth: 100,
                filterParams: {newRowsAction: 'keep', defaultOption: 'equals'},
                filter: 'agTextColumnFilter'
            },
            {
                headerName: 'S', field: 'side', minWidth: 70, suppressSizeToFit: true, filter: 'agTextColumnFilter',
                filterParams: {newRowsAction: 'keep'}, width: 70,
                sortedAt: 1, sort: 'asc',
            },
            {
                headerName: 'LoanetId',
                field: 'contraDepositoryNo',
                minWidth: 100,
                maxWidth: 100,
                width: 100,
                filter: 'agTextColumnFilter',
                hide: false,
                filterParams: {newRowsAction: 'keep'}
            },
            {headerName: 'Contra', field: 'counterPartyName', filter: 'agTextColumnFilter', hide: true, width: 120, filterParams: {newRowsAction: 'keep'}},
            {headerName: 'Broker', field: 'contraAccount', filter: 'agTextColumnFilter', hide: true, width: 120, filterParams: {newRowsAction: 'keep'}},
            {
                headerName: 'Rate', field: 'rate', minWidth: 75, width: 75, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                valueFormatter: (params) => {

                    return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');

                }, filter: 'agNumberColumnFilter'
            },
            {
                headerName: 'Qty', field: 'quantity', minWidth: 100, maxWidth: 100, width: 100, suppressSizeToFit: true,
                valueFormatter: (params) => {
                    if (params.value === null) {
                        return formatNumber(params.value, this.locale);
                    }
                },
                type: 'numericColumn', filter: 'agNumberColumnFilter', filterParams: {newRowsAction: 'keep'}
            },
            {
                headerName: 'Amt', field: 'amount', type: 'numericColumn', filter: 'agNumberColumnFilter', minWidth: 100, width: 100,
                valueFormatter: (params) => {
                    return formatCurrency(params.value, this.locale, '$', undefined, '1.0-0');
                },
                filterParams: {newRowsAction: 'keep'}
            },
        );
        columnDefs.push({
            headerName: 'Contract #',
            field: 'contractNo',
            suppressSizeToFit: true,
            minWidth: 120, maxWidth: 120, width: 120,
            filter: 'agNumberColumnFilter',
            filterParams: {newRowsAction: 'keep'},
            pinnedRowCellRenderer: (params) => {
                if (params.value === 'Net' ||
                    params.value === 'Net Spread' ||
                    params.value === 'Total P&L') {
                    return params.value;
                } else if (params.value !== undefined) {
                    return params.data.amountFormat(params);
                }
            },
        });
        if (this.userService.requirePermission('editContract')) {
            columnDefs.push(
                {
                    headerName: 'PC', field: 'profitCenter', minWidth: 55, maxWidth: 55, width: 55, suppressSizeToFit: true,
                    filter: 'agSetColumnFilter', filterParams: {newRowsAction: 'keep'},
                    editable: false
                }
            );
        } else {
            columnDefs.push(
                {
                    headerName: 'PC', field: 'profitCenter', minWidth: 55, maxWidth: 55, width: 55, suppressSizeToFit: true,
                    filter: 'agSetColumnFilter', filterParams: {newRowsAction: 'keep'},
                    editable: false
                },
            );
        }
        columnDefs.push(
            {
                headerName: 'Term', field: 'expireOn', minWidth: 80, maxWidth: 80, width: 80, filterParams: {newRowsAction: 'keep'},
                filter: 'agSetColumnFilter',
            },
        );
        columnDefs.push(
            {headerName: 'Name', field: 'accountName', filter: 'agTextColumnFilter', hide: true, filterParams: {newRowsAction: 'keep'}},
            {headerName: 'Acct', field: 'depositoryNo', width: 75, suppressSizeToFit: true, hide: true, filter: 'agTextColumnFilter', filterParams: {newRowsAction: 'keep'}},
            {
                headerName: 'Rebate', field: 'dailyRebate', type: 'numericColumn', filter: 'agNumberColumnFilter',
                valueFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$');
                    }
                }, filterParams: {newRowsAction: 'keep'}, hide: true
            },
            {
                headerName: 'Rebate To Date', field: 'profitLoss', type: 'numericColumn', filter: 'agNumberColumnFilter',
                valueFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$');
                    }
                }, filterParams: {newRowsAction: 'keep'}, hide: true
            },
            {
                headerName: 'Daily Funding', field: 'dailyFundingCharge', type: 'numericColumn', filter: 'agNumberColumnFilter',
                valueFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$');
                    }
                }, filterParams: {newRowsAction: 'keep'}, hide: true,
            },
            {
                headerName: 'Funding To Date', field: 'fundingCharge', type: 'numericColumn', filter: 'agNumberColumnFilter',
                valueFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$');
                    }
                }, filterParams: {newRowsAction: 'keep'}, hide: true
            },
            {headerName: 'Comment', field: 'publicComment', filter: 'agTextColumnFilter', filterParams: {newRowsAction: 'keep'}, minWidth: 170, maxWidth: 170, width: 170},
            {headerName: 'Submitter', field: 'submitterNickname', filter: 'agTextColumnFilter', filterParams: {newRowsAction: 'keep'}, minWidth: 95, maxWidth: 95, width: 95},
            {headerName: 'Approver', field: 'approverNickname', filter: 'agTextColumnFilter', filterParams: {newRowsAction: 'keep'}, minWidth: 95, maxWidth: 95, width: 95,},
            {
                headerName: 'Modified', field: 'modifiedOn', hide: false, filterParams: {newRowsAction: 'keep'},
                minWidth: 105, width: 105,
                comparator: this.dateComparator, valueFormatter: (params) => {
                    return moment(params.value).format('hh:mm a');
                },
            },
            {headerName: 'rateSort', field: 'rateSort', hide: true, filterParams: {newRowsAction: 'keep'}, sort: 'desc', sortedAt: 3},
            {
                headerName: 'Made',
                field: 'contractMade',
                filter: false,
                suppressMenu: true,
                cellRenderer: 'checkboxRenderer',
                editable: false,
                minWidth: 70,
                maxWidth: 70,
                width: 70,
            },
        );
        return columnDefs;
    }

    dateComparator(date1, date2) {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        if (date1 && date2) {
            return momentDate1.diff(momentDate2);
        }
    }

    protected static statusColors(params) {
        if (params.value === 'X') {
            return {'background-color': '#ff9a9ade'};
        } else {
            return {'background-color': 'inherit'};
        }
    }

    ngAfterViewInit(): void {
        // this.splitControl =
        //     Split(['#top', '#bottom'], {
        //         sizes: [30, 70],
        //         direction: 'vertical',
        //         gutterSize: 15,
        //     });
    }

    depositoryFilter(contract: Contract): boolean {
        return (contract.depositoryNo === this.switcherService.getSelectedDepositoryNo() && contract.startOn === this.today);
    }

    topFilter(contract: Contract): boolean {
        return this.depositoryFilter(contract) && !contract.contractMade;
    }

    bottomFilter(contract: Contract): boolean {
        return (this.depositoryFilter(contract) && contract.contractStatusId == 7 && contract.contractMade);
    }

    contractAdded(contracts: Contract[]) {
        if (this.gridApi) {
            if (contracts.length > 0) {
                const newRows: RowNode[] = [];
                const newContracts: Contract[] = [];

                for (const contract of contracts.filter(c => this.depositoryFilter(c))) {
                    const rowNode = this.gridApi.getRowNode(contract.primaryKey);
                    if (rowNode === undefined) {
                        newContracts.push(contract);
                    }
                }
                this.gridApi.updateRowData({
                    add: newContracts
                });

                for (const contract of newContracts) {
                    const rowNode = this.gridApi.getRowNode(String(contract.primaryKey));
                    newRows.push(rowNode);
                }
                this.gridApi.flashCells({
                    rowNodes: newRows
                });
            }
        }
    }

    contractChanged(contracts: Contract[]) {
        if (this.gridApi) {
            const changedContracts: Contract[] = [];
            const newContracts: Contract[] = [];

            for (const contract of contracts.filter(c => this.depositoryFilter(c))) {
                const rowNode = this.gridApi.getRowNode(contract.primaryKey);
                if (rowNode === undefined) {
                    newContracts.push(contract);
                } else {
                    changedContracts.push(contract);
                }
            }

            this.gridApi.updateRowData({
                add: newContracts,
                update: changedContracts,
            });

            this.gridApi.refreshCells();
            this.gridOptions.api.onFilterChanged();
        }
    }

    contractRemoved(contracts: Contract[]) {
        if (this.gridApi) {
            this.gridApi.updateRowData({
                remove: contracts.filter(c => this.depositoryFilter(c))
            });
        }
    }


    // contractAdded(contracts: Contract[]) {
    //     if (this.gridApi) {
    //         if (contracts.length > 0) {
    //             const newRows: RowNode[] = [];
    //             const newContracts: Contract[] = [];
    //
    //             for (const contract of contracts) {
    //                 if (this.topFilter(contract)) {
    //                     const rowNode = this.gridApi.getRowNode(contract.primaryKey);
    //                     if (rowNode === undefined) {
    //                         newContracts.push(contract);
    //                     }
    //                 }
    //             }
    //             this.gridApi.updateRowData({
    //                 add: newContracts
    //             });
    //         }
    //     }
    // }
    //
    // contractChanged(contracts: Contract[]) {
    //     if (this.gridApi) {
    //         const changedContracts: Contract[] = [];
    //         const removeContracts: Contract[] = [];
    //         const newContracts: Contract[] = [];
    //
    //         for (const contract of contracts) {
    //             if (this.topFilter(contract)) {
    //                 const rowNode = this.gridApi.getRowNode(contract.primaryKey);
    //                 if (rowNode === undefined) {
    //                     newContracts.push(contract);
    //                 } else {
    //                     changedContracts.push(contract);
    //                 }
    //             } else if (this.bottomFilter(contract)) {
    //                 const rowNode = this.gridApi.getRowNode(contract.primaryKey);
    //                 if (rowNode != undefined) {
    //                     removeContracts.push(contract);
    //                 }
    //             }
    //         }
    //
    //         this.gridApi.updateRowData({
    //             add: newContracts,
    //             update: changedContracts,
    //             remove: removeContracts
    //
    //         });
    //
    //         this.gridApi.refreshCells();
    //     }
    // }
    //
    // contractRemoved(contracts: Contract[]) {
    //     if (this.gridApi) {
    //         this.gridApi.updateRowData({
    //             remove: contracts.filter(c => this.topFilter(c))
    //         });
    //     }
    // }

    completedContractAdded(contracts: Contract[]) {
        if (this.completedGridApi) {
            if (contracts.length > 0) {
                const newRows: RowNode[] = [];
                const newContracts: Contract[] = [];
                for (const contract of contracts.filter(c => this.bottomFilter(c))) {
                    const rowNode = this.completedGridApi.getRowNode(contract.primaryKey);
                    if (rowNode === undefined) {
                        newContracts.push(contract);
                    }
                }
                this.completedGridApi.updateRowData({
                    add: newContracts
                });
                // for (const contract of newContracts) {
                //     const rowNode = this.completedGridApi.getRowNode(String(contract.primaryKey));
                //     newRows.push(rowNode);
                // }
                // this.completedGridApi.flashCells({
                //     rowNodes: newRows
                // });
            }
        }
    }

    completedContractChanged(contracts: Contract[]) {
        if (this.completedGridApi) {
            const changedContracts: Contract[] = [];
            const removeContracts: Contract[] = [];
            const newContracts: Contract[] = [];

            for (const contract of contracts) {
                if (this.bottomFilter(contract)) {
                    const rowNode = this.completedGridApi.getRowNode(contract.primaryKey);
                    if (rowNode === undefined) {
                        newContracts.push(contract);
                    } else {
                        changedContracts.push(contract);
                    }
                } else if (this.topFilter(contract)) {
                    const rowNode = this.completedGridApi.getRowNode(contract.primaryKey);
                    if (rowNode) {
                        removeContracts.push(contract);
                    }
                }
            }

            this.completedGridApi.updateRowData({
                add: newContracts,
                update: changedContracts,
                remove: removeContracts

            });

            this.completedGridApi.refreshCells();
        }
    }

    completedContractRemoved(contracts: Contract[]) {
        if (this.completedGridApi) {
            this.completedGridApi.updateRowData({
                remove: contracts.filter(c => this.bottomFilter(c))
            });
        }
    }

    // setupCompletedSubscriptions() {
    //
    //     this.completedGridApi.setRowData([]);
    //     this.completedGridApi.setRowData(this.contractService.currentCollection.filter(c => this.bottomFilter(c)));
    //
    //     this.onCompletedContractsAddedSubscription =
    //         this.contractService.onCollectionAdded.subscribe(contracts => this.completedContractAdded(contracts));
    //
    //     this.onCompletedContractsChangedSubscription =
    //         this.contractService.onCollectionChanged.subscribe(contracts => this.completedContractChanged(contracts));
    //
    //     this.onCompletedContractsRemovedSubscription =
    //         this.contractService.onCollectionRemoved.subscribe(contracts => this.completedContractRemoved(contracts));
    //
    //     this.onCompletedDepositoryNoSwitchedSubscription = this.switcherService.depositoryNoSwitched.subscribe(depositoryNo => {
    //         this.completedGridApi.setRowData([]);
    //         this.completedGridApi.setRowData(this.contractService.currentCollection.filter(c => this.bottomFilter(c)));
    //     });
    // }

    setupSubscriptions() {

        this.onContractsAddedSubscription =
            this.contractService.onCollectionAdded.subscribe(contracts => this.contractAdded(contracts));

        this.onContractsChangedSubscription =
            this.contractService.onCollectionChanged.subscribe(contracts => this.contractChanged(contracts));

        this.onContractsRemovedSubscription =
            this.contractService.onCollectionRemoved.subscribe(contracts => this.contractRemoved(contracts));

        this.onDepositoryNoSwitchedSubscription = this.switcherService.depositoryNoSwitched.subscribe(depositoryNo => {
            this.gridApi.setRowData([]);
            this.gridApi.setRowData(this.contractService.currentCollection.filter(c => this.depositoryFilter(c)));
            this.applyMadeFilter();
        });
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridOptions.api = params.api;
        this.setupSubscriptions();
        this.gridApi.setSortModel(this.getBulkImportDetailsPreferences().sort);

        this.gridApi.setRowData([]);
        this.gridApi.setRowData(this.contractService.currentCollection.filter(c => this.depositoryFilter(c)));

        this.applyMadeFilter();
    }

    onCompletedGridReady(params) {
        this.completedGridApi = params.api;
        this.completedGridColumnApi = params.columnApi;
        this.completedGridOptions.api = params.api;
        //this.setupCompletedSubscriptions();
        this.completedGridApi.setSortModel(this.getBulkImportDetailsPreferences().sort);

    }

    public getRowNodeId(data) {
        return data.primaryKey;
    }

    public getCompletedRowNodeId(data) {
        return data.primaryKey;
    }

    ngOnDestroy(): void {
        this.onContractsAddedSubscription.unsubscribe();
        this.onContractsRemovedSubscription.unsubscribe();
        this.onContractsChangedSubscription.unsubscribe();
        this.onDepositoryNoSwitchedSubscription.unsubscribe();
        // this.onCompletedContractsAddedSubscription.unsubscribe();
        // this.onCompletedContractsRemovedSubscription.unsubscribe();
        // this.onCompletedContractsChangedSubscription.unsubscribe();
        // this.onCompletedDepositoryNoSwitchedSubscription.unsubscribe();
    }

    getBulkImportDetailsPreferences(): ContractDetailPreferences {

        return new ContractDetailPreferences({
            primaryKey: 'BULK_IMPORT_DETAILS',
            name: 'Bulk Import Details',
            sort: [
                {
                    colId: 'modifiedOn',
                    sort: 'desc'
                }
            ]
        });
    }

    onSelectionChanged($event) {
        this.contractService.setSelected(this.gridApi.getSelectedRows());
    }

    areRowsSelected() {
        if (this.gridApi && this.gridApi.getSelectedRows().length > 0) {
            return false;
        } else {
            return true;
        }
    }

    approveContracts(approved: boolean) {
        var contractIds = [];
        this.gridApi.getSelectedRows().forEach(function (contract) {
            contractIds.push(contract.contractId);
        });

        this.contractService.approveContractsByIds(approved, contractIds);
        this.gridApi.deselectAll();
    }

    cellEditingStopped(event) {
        if (event.colDef.headerName === 'Notes') {
            this.contractService.updatePrivateComment(event.data, event.value);
        }
    }

    onMadeToggleChange(filter) {
        this.currentMadeFilter = [];
        switch (filter) {
            case 'all':
                this.currentMadeFilter = ['M', 'P'];
                break;
            case 'made':
                this.currentMadeFilter = ['M'];
                break;
            case 'pend':
                this.currentMadeFilter = ['P'];
        }
        this.applyMadeFilter();
    }

    applyMadeFilter() {
        const dtcStatusFilterComponent = this.gridOptions.api.getFilterInstance('dtcStatus');
        const model = dtcStatusFilterComponent.getModel();
        dtcStatusFilterComponent.setModel({values: this.currentMadeFilter});
        this.gridOptions.api.onFilterChanged();
    }
}

