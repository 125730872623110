import { Injectable } from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Recall} from './recall.model';

@Injectable()
export class RecallHoldingService implements Resolve<any> {

    readonly endpoint = environment.verticalApiEndpoint + 'recalls';
    onRecallsLoaded: BehaviorSubject<any> = new BehaviorSubject([]);
    // onReturnsChanged: BehaviorSubject<any> = new BehaviorSubject([]);

    selectedRecall: Recall;
    holdingRecall: Recall[];

  constructor(private httpClient: HttpClient) {
      this.holdingRecall = [];
  }

    getRemoteRecallHolding(): void {
        this.httpClient.get(this.endpoint)
            .subscribe((recalls: any) => {
                const recallsArr = recalls.items;
                this.holdingRecall = [];
                for (const recallObj of recallsArr) {
                    this.holdingRecall.push(new Recall(recallObj));
                }
                this.onRecallsLoaded.next(this.holdingRecall);
                //console.log('Recall: ', this.holdingRecall);
            }, error => {
                //console.log('Error getting returns: ', error);
            });
    }

    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                // this.getRemoteRecallHolding()
                this.holdingRecall.push(new Recall({
                    cusip: 'TEST',
                    symbol: 'TEST',
                    shortCode: 'FC Stone',
                    rate: -10,
                    contractQty: 1000,
                    quantity: 100,
                    allocated: 0,
                    comments: ''
                }))
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }
}
