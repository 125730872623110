import {EventEmitter, Injectable, OnInit, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {SwitcherService} from '../../../../shared/switcher/switcher.service';
import {Subscription} from 'rxjs';
import {ContractsService} from '../contracts/contracts.service';
import {SnapshotResponse} from './SnapshotResponse';

@Injectable({
    providedIn: 'root'
})
export class QuickSymbolSummaryService implements OnInit {

    endpoint = environment.verticalApiEndpoint;
    switcherServiceSubscription: Subscription;
    snapShot: SnapshotResponse;
    currentSymbol: String = '';
    showQuickSymbolSummary = false;

    @Output() snapshotReceived = new EventEmitter();

    constructor(private httpClient: HttpClient, private switcherService: SwitcherService) {
        // this.switcherServiceSubscription = this.switcherService.depositoryNoSwitched.subscribe(params => {
        //     if (this.showQuickSymbolSummary) {
        //         this.getQuickSymbolSummary(this.currentSymbol).then(response => {
        //             this.snapshotReceived.emit(response);
        //         });
        //     }
        // });
    }


    getQuickSymbolSummary(symbol: String): Promise<SnapshotResponse> {
        this.currentSymbol = symbol;
        return new Promise((resolve, reject) => {
            let body = {
                sym: symbol,
                depositoryNo: this.switcherService.getSelectedDepositoryNo()
            };

            this.httpClient.post(this.endpoint + 'sls/snapshot', body).subscribe((response: any) => {
                this.snapShot = <SnapshotResponse>response;
                resolve(this.snapShot);
                this.snapshotReceived.emit(this.snapShot);
            }, reject);
        });
    }

    ngOnInit(): void {
    }
}
