import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../../../@fuse/animations';

@Component({
    selector: 'app-transaction-history',
    templateUrl: './transaction-history.component.html',
    styleUrls: ['./transaction-history.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None

})
export class TransactionHistoryComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }
}
