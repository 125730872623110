import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BenchmarksService} from './benchmarks/benchmarks.service';
import {CurrencyService} from './currency/currency.service';
import {RoundRulesService} from './roundrules/round-rules.service';
import {StatusService} from './status/status.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
    providers: [
        BenchmarksService,
        CurrencyService,
        RoundRulesService,
        StatusService
    ]
})
export class ConstantsModule { }
