import {Component, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AccountService} from '../../accounts/account.service';
import {TransactionHistoryService} from '../transaction-history.service';
import {ConstantsService} from '../../../../../shared/constants/constants.service';
import {SwitcherService} from '../../../../../shared/switcher/switcher.service';
import {Subscription} from 'rxjs';


@Component({
    selector: 'app-transaction-query',
    templateUrl: './transaction-query.component.html',
    styleUrls: ['./transaction-query.component.scss']
})

export class TransactionQueryComponent implements OnInit, OnDestroy {


    constructor(private fb: FormBuilder,
                public accountService: AccountService,
                public transactionHistoryService: TransactionHistoryService,
                public constantsService: ConstantsService,
                public switcherService: SwitcherService) {
        accountService.getRemoteAccounts();
    }

    datePreset;
    maxDate = new Date();
    startOfDay = new Date();
    public fromDate;
    public toDate;
    public symbol = new FormControl();
    public contraAccountIds = new FormControl();
    public contractEventId = new FormControl();
    public profitCenter = new FormControl();
    public contractNo = new FormControl();
    public accountId;
    onAccountSwitched: Subscription;

    ngOnInit() {
        this.datePreset = new FormControl('today');
        this.startOfDay.setHours(0, 0, 0, 0);
        this.maxDate.setHours(23, 59, 59, 999);
        this.fromDate = new FormControl(this.startOfDay);
        this.toDate = new FormControl(this.maxDate);
        this.onAccountSwitched = this.switcherService.depositoryNoSwitched.subscribe(() => {
            if(this.transactionHistoryService.transactionList.length>0) {
                this.generateQueryParameters()
            }
        });
    }

    ngOnDestroy(): void {
        this.onAccountSwitched.unsubscribe();
    }

    generateQueryParameters() {
        var symbolCusipArray = (this.symbol.value !== null && this.symbol.value.length > 0) ? this.symbol.value.trim().toUpperCase().split(',', 10) : null;
        var contractNoArray = this.contractNo.value !== null ? this.contractNo.value.trim().toUpperCase().split(',', 10) : null;
        let from = new Date(this.fromDate.value);
        from.setHours(0,0,0,1);
        let to = new Date(this.toDate.value);
        to.setHours(23,59,59,999);
        var queryParams = {
            fromDate: from,
            toDate: to,
            symbols: symbolCusipArray,
            account: this.switcherService.getSelectedDepositoryNo(),
            contraAccounts: this.contraAccountIds.value,
            contractEventIds: this.contractEventId.value,
            contractNos: contractNoArray,
            profitCenters: this.profitCenter.value
        };
        //console.log(queryParams);
        this.transactionHistoryService.getTransactionHistory(queryParams).then(() => {
        }).catch((response) => {
            //console.log(response);
        });
    }


    firstDayOfWeek(weekOffset: number): Date {
        var curr = new Date(); // get current date
        var daysBack = weekOffset * 7;
        var first = curr.getDate() - daysBack - curr.getDay() + 1; // First day is the day of the month - the day of the week
        var firstDay = new Date(curr.setDate(first));
        firstDay.setHours(0, 0, 0, 0);
        return firstDay;
    }

    firstDayOfMonth(monthOffset: number): Date {
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        if (m === 0 && monthOffset > 0) {  //If we are in January and User selects previous month
            m = 12;
            y--;
        }
        var firstDay = new Date(y, m - monthOffset, 1);
        firstDay.setHours(0, 0, 0, 0);
        return firstDay;
    }

    datePresetChanged($event) {
        this.toDate.setValue(this.maxDate);
        switch ($event.value) {
            case 'week':
                this.fromDate.setValue(this.firstDayOfWeek(0));
                break;
            case 'month':
                this.fromDate.setValue(this.firstDayOfMonth(0));
                break;
            case 'lastweek' :
                this.fromDate.setValue(this.firstDayOfWeek(1));
                break;
            case 'lastmonth' :
                this.fromDate.setValue(this.firstDayOfMonth(1));
                break;
            default:
                this.fromDate.setValue(this.startOfDay);
                break;
        }
    }
}
