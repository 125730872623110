import {Component, OnInit} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';

@Component({
    selector: 'app-lcor-pinned-row-renderer',
    templateUrl: './lcor-pinned-row-renderer.component.html',
    styleUrls: ['./lcor-pinned-row-renderer.component.scss']
})

export class LcorPinnedRowRendererComponent implements ICellRendererAngularComp {

    public params: any;
    public style: string;

    agInit(params: any): void {
        this.params = params;
        this.style = this.params.style;
    }

    refresh(): boolean {
        return false;
    }

}
