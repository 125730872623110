import {Component, Inject, Input, LOCALE_ID, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {FplService} from './fpl.service';


@Component({
    selector: 'app-fpl-accounts',
    templateUrl: './fplAccounts.component.html',
    styleUrls: ['./fplAccounts.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class FplAccountsComponent implements OnInit {

    @Input() entityId;


    constructor(public fplService: FplService,
                @Inject(LOCALE_ID) private locale: string,
                private router: Router) {
    }

    ngOnInit() {
    }
}
