import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InstrumentsComponent} from './instruments.component';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../../../auth/auth.guard';
import {MatButtonModule, MatDividerModule, MatIconModule, MatInputModule, MatSidenavModule, MatDatepickerModule, MatSlideToggleModule} from '@angular/material';
import {FuseSharedModule} from '../../../../../@fuse/shared.module';

const routes: Routes = [
    {
        path     : 'instruments',
        component: InstrumentsComponent,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    declarations: [
        InstrumentsComponent
    ],
    imports: [
        CommonModule,
        FuseSharedModule,
        RouterModule.forChild(routes),
        MatSidenavModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        MatDividerModule,
        MatDatepickerModule,
        MatSlideToggleModule
    ]
})
export class InstrumentsModule {
}
