import {AfterViewInit, Component, ElementRef, Inject, LOCALE_ID, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ColumnApi, GridApi, GridOptions, RowNode} from 'ag-grid-community';
import {Subscription} from 'rxjs';
import {SegMemosegService} from '../seg-memoseg/seg-memoseg.service';
import {SwitcherService} from '../../../../shared/switcher/switcher.service';
import {UserService} from '../../../../auth/user/user.service';
import {formatCurrency, formatNumber} from '@angular/common';
import {SegMemoSeg} from '../seg-memoseg/seg-memoseg.model';
import Split from 'split.js';
import moment from 'moment';

@Component({
    selector: 'app-seg-memoseg',
    templateUrl: './seg-memoseg.component.html',
    styleUrls: ['./seg-memoseg.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SegMemosegComponent implements OnInit, AfterViewInit, OnDestroy {

    segUnsegForm: FormGroup;
    summaryGridOptions: GridOptions = <GridOptions>{};
    detailGridOptions: GridOptions = <GridOptions>{};
    summaryGridColumnApi: ColumnApi;
    detailGridColumnApi: ColumnApi;
    summaryGridApi: GridApi;
    detailGridApi: GridApi;
    summaryColumnDefs: any[] = [];
    detailsColumnDefs: any[] = [];
    detailsAddedSubscription: Subscription;
    detailsChangedSubscription: Subscription;
    onDepositoryNoSwitchedSubscription: Subscription;
    today = moment.utc(new Date(), 'X').format('MM-DD-YY');
    defaultQuantity = 10000000;

    @ViewChild('symQtyRef') symQtyRef: ElementRef;
    @ViewChild('formDiv') formDiv: ElementRef;

    autoGroupColumnDef = {
        headerName: 'Symbol',
        minWidth: 115,
        width: 115,
        maxWidth: 115,
        resizable: false,
        hide: false,
        filter: 'agTextColumnFilter',
        field: 'symbol',
        editable: false,
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
            suppressCount: true,
            suppressDoubleClickExpand: true,
            suppressEnterExpand: true
        },
    };

    summaryDefaultColDef = {
        editable: true,
        enableCellChangeFlash: true,
        sortable: true,
        filter: true,
        resizable: true,
        suppressMovable: true,
    };

    detailsDefaultColDef = {
        editable: false,
        enableCellChangeFlash: true,
        sortable: true,
        filter: true,
        resizable: true,
        suppressMovable: true,
    };

    splitControl;
    summaryRowData: { symbol: string; cusip: string; seg: number; unSeg: string; };
    summaryGridRecords: [{}];

    constructor(public segMemosegService: SegMemosegService,
                private formBuilder: FormBuilder,
                @Inject(LOCALE_ID) private locale: string,
                private switcherService: SwitcherService,
                public userService: UserService) {
        this.segUnsegForm = this.createsegUnsegForm();

        this.summaryGridOptions = { // upper grid
            enableGroupEdit: true
        };

        this.detailGridOptions = { // lower grid
        };

        this.switcherService.depositoryNoSwitched.subscribe((value) => {
            this.segUnsegForm.controls.loanetId.setValue(value);
        });

        this.defineColumns();
    }

    dateComparator(date1, date2) {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        if (date1 && date2) {
            return momentDate1.diff(momentDate2);
        }
    }

    ngOnInit() {
        this.onDepositoryNoSwitchedSubscription = this.switcherService.depositoryNoSwitched.subscribe(sub => {
            this.resetGridData();
        });
    }

    ngAfterViewInit(): void {
        this.splitControl =
            Split(['#summary', '#details'], {
                sizes: [40, 60],
                direction: 'vertical',
                gutterSize: 15,
            });
    }

    createsegUnsegForm() {
        return this.formBuilder.group({
            loanetId: [this.switcherService.getSelectedDepositoryNo()],
            symbolAndQuantity: ['', Validators.required],
            defaultQuantity: [10000000, Validators.required]
        });
    }

    unseg() {
        let dtcId = '';
        let batch = [];
        this.summaryGridApi.forEachNode(node => {
                if (node.group && node.aggData.unSeg > 0) {
                    let instance = {'symCusip': '', 'quantity': 0};
                    instance.symCusip = node.aggData.cusip;
                    instance.quantity = node.aggData.unSeg;
                    batch.push(JSON.parse(JSON.stringify(instance)));
                }
            }
        );
        this.summaryGridApi.forEachNode(node => {
                if (node.group && node.aggData.unSeg > 0) {
                    node.aggData.unSeg = '';
                }
            }
        );
        this.summaryGridApi.refreshCells();
        this.segMemosegService.unseg(dtcId, batch);
    }

    resetAfterSubmission() {
        this.segUnsegForm.get('symbolAndQuantity').markAsPristine();
        // this.symQtyRef.nativeElement.markAsPristine();
        this.symQtyRef.nativeElement.value = '';
        this.symQtyRef.nativeElement.focus();
    }


    submitSeg() {
        const segBatch = this.segUnsegForm.getRawValue();

        if (segBatch.symbolAndQuantity && segBatch.symbolAndQuantity !== '') {
            const symbolAndQuantityTrimmed = segBatch.symbolAndQuantity.trim().replace(/(^[ \t]*\n)/gm, '');
            const symbolAndQuantityLines = symbolAndQuantityTrimmed.split('\n').map((val) =>
                ({
                    symCusip: val.split(' ')[0].toLocaleUpperCase(),
                    quantity: val.split(' ')[1] == null ? this.defaultQuantity : val.split(' ')[1]
                }));

            this.segMemosegService.seg(segBatch.loanetId, symbolAndQuantityLines);

            this.resetAfterSubmission();
        }
    }


    defineColumns() {
        this.summaryColumnDefs = [
            {
                headerName: 'Symbol', field: 'symbol',
                minWidth: 100, maxWidth: 160, valueFormatter: '',
                rowGroup: true, showRowGroup: 'symbol',
                hide: true, editable: false,
                filter: 'agTextColumnFilter',
            },
            {
                headerName: 'Cusip', field: 'cusip',
                minWidth: 100, maxWidth: 160, valueFormatter: '', editable: false,
                filter: 'agTextColumnFilter', type: 'numericColumn',
                aggFunc: 'first', // built in sum function
            },
            {
                headerName: 'Seg', field: 'quantity',
                minWidth: 100, maxWidth: 160, editable: false,
                filter: 'agNumberColumnFilter',
                aggFunc: 'sum',
                type: 'numericColumn',
                cellStyle: {'color': 'red'},
                valueFormatter: (params) => {
                    if (typeof params.value === 'string') {
                        return params.value;
                    }

                    if (params.value === null || params.value === undefined) {
                        return '';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                }
            },
            {
                headerName: 'Un-Seg', field: 'unSeg',
                minWidth: 100, maxWidth: 160,
                type: 'numericColumn',
                editable: true,
                valueFormatter: (params) => {
                    if (params.value === null || params.value === undefined || params.value < 1) {
                        return '';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                }

            }
        ];

        this.detailsColumnDefs = [ //lower grid
            {
                headerName: 'Time', field: 'responseTime',
                sort: 'desc', minWidth: 140, maxWidth: 180, resizable: true,
                filter: 'agTextColumnFilter',
                comparator: this.dateComparator, valueFormatter: (params) => {
                    return moment(params.value).format('hh:mm:ss');
                }
            },
            {
                headerName: 'Symbol', field: 'symbol', suppressMovable: true,
                minWidth: 140, maxWidth: 180, resizable: true, valueFormatter: '',
                filter: 'agTextColumnFilter',
            },
            {
                headerName: 'Cusip', field: 'cusip', suppressMovable: true,
                minWidth: 140, maxWidth: 180, resizable: true, valueFormatter: '',
                filter: 'agTextColumnFilter', type: 'numericColumn',
            },
            {
                headerName: 'Quantity', field: 'quantity', suppressMovable: true,
                minWidth: 140, maxWidth: 180, resizable: true,
                filter: 'agNumberColumnFilter', type: 'numericColumn',
                cellStyle: {'color': 'red'},

                valueFormatter: (params) => {
                    if (typeof params.value === 'string') {
                        return params.value;
                    }

                    if (params.value === null) {
                        return '';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                }
            },
            {
                headerName: 'Status', field: 'status', suppressMovable: true,
                minWidth: 140, maxWidth: 180, resizable: true, valueFormatter: '',
                filter: 'agTextColumnFilter'
            },
            {
                headerName: 'Type', field: 'activityType', suppressMovable: true,
                minWidth: 140, maxWidth: 180, resizable: true, valueFormatter: '',
                filter: 'agTextColumnFilter'
            }
        ];

    }


    depositoryFilter(contract: SegMemoSeg): boolean {
        return (contract.dtcId === this.switcherService.getSelectedDepositoryNo());
    }

    todayFilter(memoSeg: SegMemoSeg): boolean {
        return moment.utc(memoSeg.responseTime, 'X').format('MM-DD-YY') == this.today;
    }

    onSummaryGridReady(params) {
        this.summaryGridApi = params.api;
        this.summaryGridColumnApi = params.columnApi;
        this.summaryGridOptions.api = params.api;
        this.autoSizeAllSummary(params);
    }

    onDetailGridReady(params) {
        this.detailGridApi = params.api;
        this.detailGridColumnApi = params.columnApi;
        this.detailGridOptions.api = params.api;
        this.autoSizeAllDetails(params);
        this.setupSegMemosegSubscriptions();
    }


    autoSizeAllSummary(event) {
        this.summaryGridColumnApi = event.columnApi;
        this.summaryGridColumnApi.autoSizeColumns(this.summaryGridColumnApi.getAllColumns());
    }

    autoSizeAllDetails(event) {
        this.detailGridColumnApi = event.columnApi;
        this.detailGridColumnApi.autoSizeColumns(this.detailGridColumnApi.getAllColumns());
    }

    setupSegMemosegSubscriptions() {
        this.detailGridApi.setRowData([]);
        this.resetGridData();
        this.detailsAddedSubscription = this.segMemosegService.onCollectionAdded.subscribe(memosegs => this.qtySegged(memosegs));
        this.detailsChangedSubscription = this.segMemosegService.onCollectionChanged.subscribe(memosegs => this.qtySegged(memosegs));
        // this.summaryGridApi.setFilterModel({
        //     quantity: {type: 'notEqual', filter: '0'},
        // });
        this.summaryGridApi.onFilterChanged();
    }

    resetGridData() {
        this.summaryGridApi.setRowData(this.segMemosegService.currentCollection.filter((c => this.depositoryFilter(c))).filter((c => this.todayFilter(c))));
        this.detailGridApi.setRowData(this.segMemosegService.currentCollection.filter((c => this.depositoryFilter(c))).filter((c => this.todayFilter(c))));
        // this.summaryGridApi.setFilterModel({
        //     quantity: {type: 'notEqual', filter: '0'},
        // });
        this.summaryGridApi.onFilterChanged();
    }

    qtySegged(memoSegs: SegMemoSeg[]) {
        if (this.detailGridApi) {
            const newMemoSegs: SegMemoSeg[] = [];
            const updateMemoSegs: SegMemoSeg[] = [];
            for (const memoSeg of memoSegs.filter(c => this.depositoryFilter(c)).filter((c => this.todayFilter(c)))) {
                const rowNode = this.detailGridApi.getRowNode(memoSeg.primaryKey);
                if (rowNode === undefined) {
                    newMemoSegs.push(memoSeg);
                } else {
                    updateMemoSegs.push(memoSeg);
                }
            }
            this.summaryGridApi.updateRowData({
                add: newMemoSegs
            });

            this.summaryGridApi.updateRowData({
                update: updateMemoSegs
            });

            this.detailGridApi.updateRowData({
                add: newMemoSegs,
                update: updateMemoSegs
            });

        }

        this.summaryGridApi.forEachNode(node => {
                if (node.group) {
                    node.data = node.aggData;
                }
            }
        );
    }

    onGridSizeChanged(params) {
        setTimeout(() => {
            // get the current grids width
            const gridWidth = document.getElementById('seg-memoseg-grid').offsetWidth;

            // keep track of which columns to hide/show
            const columnsToShow = [];
            const columnsToHide = [];

            // iterate over all columns (visible or not) and work out
            // now many columns can fit (based on their minWidth)
            let totalColsWidth = 0;
            const allColumns = params.columnApi.getAllColumns();
            for (let i = 0; i < allColumns.length; i++) {
                const column = allColumns[i];
                totalColsWidth += column.getMinWidth();
                if (totalColsWidth > gridWidth) {
                    columnsToHide.push(column.colId);
                } else {
                    columnsToShow.push(column.colId);
                }
            }

            params.api.sizeColumnsToFit();
        });
    }

    ngOnDestroy() {
        this.detailsAddedSubscription.unsubscribe();
        this.onDepositoryNoSwitchedSubscription.unsubscribe();
        this.summaryGridApi.destroy();
    }

    cellEditingStarted(event) {
    }

    getRowNodeId(data) {
        return data.primaryKey;
    }

    groupAgg(nodes) {
        const result = {
            quantity: 0,
            cusip: '',
            unSeg: ''
        };

        nodes.forEach(function (node) {
            const data = node.group ? node.aggData : node.data;
            result.cusip = data.cusip;
            switch (data.status) {
                case 'A':
                    result.quantity += data.quantity;
                    break;
                case 'S':
                    result.quantity -= data.quantity;
                    break;
                case 'O':
                    result.quantity = data.quantity;
                    break;
            }
            result.unSeg = data.unSeg;
        });

        return result;
    }

}
