import {v4 as uuidv4} from 'uuid';
import {ContractDetailPreferences} from '../content/trading/contracts/contract-detail-preferences/contract-detail-preferences';

export class NavTabItem {
    public id: string;
    public title: string;
    public type: string;
    public icon: string;
    public url: string;
    public requiresSwitcher: boolean;
    public preferences?: ContractDetailPreferences;
    public routeParams?;
    public filter?;
    public tabPreferences?;
    public selectedSymbol?: string;
    private readonly uuid?: string;
    public queryParams;

    constructor(id: string, title: string, type: string, icon: string, url: string, requiresSwitcher: boolean, uuid?: string,
                preferences?, routeParams?, filter?, selectedSymbol?, tabPreferences?, queryParams?) {
        this.id = id;
        this.title = title;
        this.type = type;
        this.icon = icon;
        this.url = url;
        this.requiresSwitcher = requiresSwitcher;
        this.routeParams = routeParams;
        this.preferences = preferences;
        this.filter = filter;
        this.selectedSymbol = selectedSymbol;
        this.tabPreferences = tabPreferences;
        this.uuid = uuid ? uuid : uuidv4();
        this.queryParams = queryParams;
    }

    getUUID() {
        return this.uuid;
    }
}

