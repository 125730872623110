import { Component, OnInit } from '@angular/core';
import {ContractDetailPreferencesService} from './contract-detail-preferences.service';
import {ContractDetailPreferences} from './contract-detail-preferences';

@Component({
  selector: 'app-contract-detail-preferences',
  templateUrl: './contract-detail-preferences.component.html',
  styleUrls: ['./contract-detail-preferences.component.scss']
})
export class ContractDetailPreferencesComponent implements OnInit {

    constructor(public preferencesService: ContractDetailPreferencesService) {

    }

    ngOnInit() {
    }

    save(): void {
        this.preferencesService.requestPreferenceAction.next('save');
    }

    rename(): void {
        this.preferencesService.requestPreferenceAction.next('rename');
    }

    deletePrefs(): void {
        this.preferencesService.requestPreferenceAction.next('delete');
    }

    setDefault(event): void {
        // //console.log('menu clicked: ', event);
        this.preferencesService.setDefaultPreferences();
    }

    setPendingApprovals(event): void {
        // //console.log('menu clicked: ', event);
        this.preferencesService.setPendingApprovalPreferences();
    }

    setFxContractPreferences(event): void {
        // //console.log('menu clicked: ', event);
        this.preferencesService.setFxContractPreferences();
    }
    setPreferences(preference: ContractDetailPreferences): void {
        //console.log('set prefs to: ', preference);
        this.preferencesService.setPreferences(preference);
    }
}
