import {Role} from './role';

export class InternalUser {
    loginId: number;
    userUid: string;
    personId: number;
    first: string;
    last: string;
    roles: Role[];
    title: string;
    nickName: string;
    salutation: string;
    email: string;
    disabled: boolean;

    constructor(user?) {
        user = user || {};
        this.loginId = user.loginId || 0;
        this.userUid = user.userUid || '';
        this.personId = user.personId || 0;
        this.first = user.first || '';
        this.last = user.last || '';
        this.roles = user.roles || [];
        this.title = user.title || '';
        this.nickName = user.nickName || '';
        this.salutation = user.salutation || '';
        this.email = user.email || '';
        this.disabled = user.disabled || false;
    }
}
