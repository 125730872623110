import {EventEmitter, Injectable, Output} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DtcTransactionsFilterService {

    @Output() filterChangedEvent = new EventEmitter();
    @Output() setFilterControlsEvent = new EventEmitter();
    @Output() clearFilterControlsEvent = new EventEmitter();
    @Output() symbolSelected = new EventEmitter();
    public currentFilter;

    constructor() {
    }

    public filterChanged(filter: any): void {
        this.currentFilter = filter;
        this.filterChangedEvent.emit(filter);
    }

    public setFilterControls(filter: any): void{
        this.setFilterControlsEvent.emit(filter);
    }

    public clearFilterControls(applyFilter: boolean){
        this.clearFilterControlsEvent.emit(applyFilter);
    }

}
