import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../../../@fuse/animations';

@Component({
  selector: 'app-user-preferences',
  templateUrl: './user-preferences.component.html',
  styleUrls: ['./user-preferences.component.scss'],
  animations   : fuseAnimations,
  encapsulation: ViewEncapsulation.None,
})
export class UserPreferencesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

    checkTabIndex(event) {
        // Rseize the table columns when the tab is selected
        if (event.index === 1) {
            // this.accountListComponent.gridApi.sizeColumnsToFit();
            // this.accountListComponent.setFilter();
            // this.mode = 'account';
        } else {
            // this.mode = 'counterParty';
        }
    }

}
