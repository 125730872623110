import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';
import { Location } from '@angular/common';
import {Entity} from '../entity.model';
import {EntityDetailService} from './entity-detail.service';
import {FuseContractsContractListComponent} from '../../../trading/contracts/contract-list/contract-list.component';
import {CounterpartyListComponent} from '../../counterparties/counterparty-list/counterparty-list.component';

@Component({
    selector     : 'app-entity-detail',
    templateUrl  : './entity-detail.component.html',
    styleUrls    : ['./entity-detail.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class EntityDetailComponent implements OnInit, OnDestroy
{
    validStatus = [
        {statusId: 1, name: 'Active'},
        {statusId: 2, name: 'Inactive'},
        {statusId: 3, name: 'Deleted'}
    ];
    entity = new Entity();
    onEntityChanged: Subscription;
    pageType: string;
    entityForm: FormGroup;

    addressesLoaded = false;

    @ViewChild(CounterpartyListComponent) counterPartyListComponent: CounterpartyListComponent;

    loadAddresses() {
        if (!this.addressesLoaded) {
            this.addressesLoaded = true;
            this.entityDetailService.getEntityAddress(this.entity);
        }
    }

    constructor(
        private entityDetailService: EntityDetailService,
        private formBuilder: FormBuilder,
        public snackBar: MatSnackBar,
        private location: Location
    )
    {
    }

    ngOnInit()
    {
        // Subscribe to update entity on changes
        this.onEntityChanged =
            this.entityDetailService.onEntityChanged
                .subscribe(entity => {
                    //console.log('entity: ', entity);
                    if ( entity )
                    {
                        this.entity = new Entity(entity);
                        this.pageType = 'edit';
                    }
                    else
                    {
                        this.pageType = 'new';
                        this.entity = new Entity();
                    }

                    this.entityForm = this.createEntityForm();

                });
    }

    ngOnDestroy()
    {
        this.onEntityChanged.unsubscribe();
    }

    createEntityForm()
    {
        return this.formBuilder.group({
            entityId: [this.entity.entityId],
            name: [this.entity.name],
            internal: [this.entity.internal],
            entityStatusId: [this.entity.entityStatusId],
            createdOn: [this.entity.createdOn]
        });
    }

    /**
     * MODIFY EXISTING
     */
    saveEntity()
    {
        const data = this.entityForm.getRawValue();
        this.entityDetailService.saveEntity(data)
            .then(() => {

                // Trigger the subscription with new data
                this.entityDetailService.onEntityChanged.next(data);

                // Show the success message
                this.snackBar.open('Entity saved', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });
            });
    }

    /**
     * SAVE NEW
     */
    addEntity()
    {
        const data = this.entityForm.getRawValue();
        this.entityDetailService.addEntity(data)
            .then((updatedEntity) => {
                // //console.log('Updated Entity: ', updatedEntity);
                // Trigger the subscription with new data
                this.entityDetailService.onEntityChanged.next(updatedEntity);

                // Show the success message
                this.snackBar.open('Entity added', 'OK', {
                    verticalPosition: 'top',
                    duration        : 2000
                });

                // Change the location with new one
                this.location.go('entity/' + this.entity.entityId);
            });
    }

    checkTabIndex(event) {
        // Rseize the table columns when the tab is selected
        if (event.index === 2) {
            this.counterPartyListComponent.gridApi.sizeColumnsToFit();
        }
    }
}
