export class ProfitCenter {

    profitCenterId :number;
    name : string;
    description : string;
    abbr : string;

    constructor(profitCenter?) {

        this.profitCenterId = profitCenter.profitCenterId;
        this.name = profitCenter.name;
        this.description = profitCenter.description;
        this.abbr = profitCenter.abbr;
    }
}
