import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LcorComponent } from './lcor.component';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../../../../auth/auth.guard';
import {AgGridModule} from 'ag-grid-angular';
import {ReactiveFormsModule} from '@angular/forms';
import {
    MatAutocompleteModule, MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule, MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule, MatProgressBarModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule, MatSlideToggleModule, MatSnackBarModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule
} from '@angular/material';
import {CdkTableModule} from '@angular/cdk/table';
import {FuseSharedModule} from '../../../../../@fuse/shared.module';
import {FuseConfirmDialogModule} from '../../../../../@fuse/components';
import {SharedModule} from '../../../../shared';
import {ContractContextMenuComponent} from '../../trading/contracts/contract-context-menu/contract-context-menu.component';
import {StatusSelectComponent} from '../../trading/contracts/contract-context-menu/status-select/status-select.component';
import {ApprovalComponent} from '../../trading/contracts/contract-context-menu/status-select/approval.component';
import { LcorPinnedRowRendererComponent } from './lcor-pinned-row-renderer/lcor-pinned-row-renderer.component';

const routes: Routes = [
    {
        path     : 'lcor',
        component: LcorComponent,
        canActivate: [AuthGuard]
    }
];

@NgModule({
  declarations: [LcorComponent, LcorPinnedRowRendererComponent],
    imports: [
        RouterModule.forChild(routes),
        CdkTableModule,
        MatCardModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatRippleModule,
        MatSidenavModule,
        MatTableModule,
        MatToolbarModule,
        MatSelectModule,
        MatRadioModule,
        MatTabsModule,
        MatStepperModule,
        MatExpansionModule,
        MatSnackBarModule,
        FuseSharedModule,
        FuseConfirmDialogModule,
        SharedModule,
        AgGridModule.withComponents([ContractContextMenuComponent, StatusSelectComponent, ApprovalComponent]),
        MatDividerModule,
        MatProgressBarModule,
        MatAutocompleteModule,
        MatBottomSheetModule,
        MatSlideToggleModule,
        MatButtonToggleModule,
        MatTooltipModule
    ],
    entryComponents: [LcorPinnedRowRendererComponent]
})

export class LcorModule { }
