import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {NavTabItem} from './nav-tab-item';
import {NavigationCancel, NavigationStart, Router} from '@angular/router';
import {ContractDetailPreferences} from '../content/trading/contracts/contract-detail-preferences/contract-detail-preferences';
import {HttpClient} from '@angular/common/http';
import {AngularFirestore} from 'angularfire2/firestore';
import {AngularFireAuth} from 'angularfire2/auth';
import {filter} from 'rxjs/operators';
import {UserPreferencesService} from '../content/operations/user-preferences/user-preferences.service';

@Injectable({
    providedIn: 'root'
})
export class NavTabService implements OnDestroy {
    items: NavTabItem[] = [];
    savedTabs: NavTabItem[] = [];
    activeLink = 0;
    homeTab = new NavTabItem('combined-contracts', 'Contract Summary', 'item', 'horizontal_split', '/combined-contracts', true, null, null, null, null, null, null);
    onFilterChanged: Subscription;
    onDepositoryNoSwitched: Subscription;
    currentTab: NavTabItem = this.homeTab;

    constructor(public router: Router,
                private httpClient: HttpClient,
                private firestoreClient: AngularFirestore,
                public afAuthClient: AngularFireAuth,
                private userPreferencesService: UserPreferencesService
    ) {
        // this.router.events
        //     .pipe(filter(event => event instanceof NavigationStart))
        //     .subscribe(e => {
        //         if(e.url === '/combined-contracts'){
        //             this.addHomeTab();
        //         }
        //     });
    }


    ngOnDestroy(): void {
        this.onDepositoryNoSwitched.unsubscribe();
    }

    public addLink(item) {
        let navTabItem = new NavTabItem(item.id, item.title, item.type, item.icon, item.url, item.requiresSwitcher, item.preferences);
        this.addTab(navTabItem);
    }

    updatecurrentTab(tab: NavTabItem){
        this.currentTab = tab;
    }

    addTab(item: NavTabItem) {
        if (item) {
            //console.log(item);
            this.items.push(item);
            this.savePreferences(item.id, item.preferences);
            let i = this.items.length - 1;
            // this.router.navigate(this.buildRoute(item));
            const route = this.buildRoute(item);
            if (item.queryParams) {
                this.router.navigate(route, {queryParams: item.queryParams});
            } else {
                this.router.navigate(route);
            }

            this.activeLink = i;
            this.saveLayout(this.items);
            this.updatecurrentTab(item);
        }
    }

    clearTabs() {
        this.items = [];
        this. homeTab = new NavTabItem('combined-contracts', 'Contract Summary', 'item', 'horizontal_split', '/combined-contracts', true, null, null, null, null, null, null);
    }

    getSavedTabs() {
        // const uid = this.afAuthClient.auth.currentUser.uid;
        // this.firestoreClient.collection('users')
        //     .doc(uid)
        //     .collection('tabs').ref.get()
        //     .then(
        //         (docRef) => {
        //             docRef.docs.forEach(doc =>this.savedTabs.push(doc));
        //         }
        //     );
    }

    public removeTab(i) {
        if (this.items.length > 1) {
            this.router.navigate(this.buildRoute(this.items[i - 1]));
            this.updatecurrentTab(this.items[i - 1]);
        }

        this.items.splice(i, 1);

        if (this.activeLink >= i) {
            if (this.activeLink === i) {
                this.activeLink = i - 1;
            } else if (this.activeLink > i) {
                this.activeLink--;
            }
        } else {
            this.activeLink = i;
        }


        if (this.items.length === 0) {
            this.addHomeTab();
        }
        this.saveLayout(this.items);
    }

    addHomeTab() {
        this.items.push(this.homeTab);
        this.router.navigate(this.buildRoute(this.items[0]));
        this.activeLink = 0;
        this.updatecurrentTab(this.homeTab);
    }

    removeHomeTab() {
        this.items.splice(0, 1);
    }


    buildRoute(navBarItem: NavTabItem): any[] {
        let route: any[] = [];
        route.push(navBarItem.url);
        route.push({id: navBarItem.getUUID()});
        for (let key in navBarItem.routeParams) {
            route[1][key] = navBarItem.routeParams[key];
        }
        return route;
    }

    getPreferences(tabId: string): ContractDetailPreferences {
        for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].getUUID() === tabId) {
                return this.items[i].preferences;
            }
        }
        return null;
    }

    getTabPreferences(tabId: string): any {
        for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].getUUID() === tabId) {
                return this.items[i].tabPreferences;
            }
        }
        return null;
    }

    getFilter(tabId: string): any {
        for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].getUUID() === tabId) {

                if (this.items[i].routeParams && this.items[i].routeParams.filter){
                    return this.items[i].filter;
                }
                else{
                    if (this.userPreferencesService.tabsPreferences &&
                        this.userPreferencesService.tabsPreferences[this.items[i].id]) {
                        return this.userPreferencesService.tabsPreferences[this.items[i].id];
                    } else {
                        return this.items[i].filter;
                    }
                }
            }
        }
        return null;
    }

    getSelectedSymbol(tabId: string): string {
        for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].getUUID() === tabId) {
                return this.items[i].selectedSymbol;
            }
        }
        return null;
    }

    savePreferences(tabId: string, preferences: ContractDetailPreferences) {
        this.items.forEach(item => {
            if (item.getUUID() === tabId) {
                // this.filterService.setFilterControls(preferences.filter);
                return item.preferences = preferences;
            }
        });
    }

    saveTabPreferences(tabId: string, preferences: any) {
        this.items.forEach(item => {
            if (item.getUUID() === tabId) {
                // this.filterService.setFilterControls(preferences.filter);
                return item.tabPreferences = preferences;
            }
        });
    }

    saveFilter(tabId: string, filter: any[]) {
        this.items.forEach(item => {
            if (item.getUUID() === tabId) {
                if (!item.routeParams || !item.routeParams.filter){
                    this.userPreferencesService.addTabPreferences(item.id, filter);
                }
                return item.filter = filter;
            }
        });
    }

    saveSelectedSymbol(tabId: string, selectedSymbol: string) {
        this.items.forEach(item => {
            if (item.getUUID() === tabId) {
                return item.selectedSymbol = selectedSymbol;
            }
        });
    }

    setTitle(tabId: string, title: string) {
        this.items.forEach(item => {
            if (item.getUUID() === tabId) {
                return item.title = title;
            }
        });
    }

    replaceCurrentTab(tab: NavTabItem) {
        if (tab) {
            this.items[this.activeLink] = tab;
            this.savePreferences(tab.id, tab.preferences);
            this.router.navigate([this.items[this.activeLink].url]);
            this.saveLayout(this.items);
        }
    }

    onNavTabClick(index: number) {
        this.activeLink = index;
        this.router.navigate(this.buildRoute(this.items[index]));
        this.saveLayout(this.items);
        this.updatecurrentTab(this.items[index]);
    }

    public saveLayout(tabLayout: any) {
        // let layoutArray = [];
        //
        // tabLayout.forEach(function (tab) {
        //     layoutArray.push(
        //         {
        //             uuid: tab.uuid,
        //             title: tab.title,
        //             icon: tab.icon,
        //             url: tab.url,
        //             gridPreferences: {
        //                 filter: tab.preferences ? tab.preferences.filter : null,
        //                 state: tab.preferences ? tab.preferences.state : null,
        //                 sort: tab.preferences ? tab.preferences.sort : null,
        //             }
        //         }
        //     );
        // });
        //
        //
        // const uid = this.afAuthClient.auth.currentUser.uid;
        //
        // this.firestoreClient.collection('users')
        //     .doc(uid)
        //     .collection('workspaces').doc('recent').set({activeTab: this.activeLink, tabs: layoutArray})
        //     .then(
        //         (docRef) => {
        //         }
        //     );
    }

    public saveTab(tab: NavTabItem) {

        const uid = this.afAuthClient.auth.currentUser.uid;

        this.firestoreClient.collection('users')
            .doc(uid)
            .collection('tabs').doc(tab.title).set(
            {
                uuid: tab.getUUID(),
                title: tab.title,
                icon: tab.icon,
                url: tab.url,
                gridPreferences: {
                    filter: tab.preferences ? tab.preferences.filter : null,
                    state: tab.preferences ? tab.preferences.state : null,
                    sort: tab.preferences ? tab.preferences.sort : null,
                }
            }
        )
            .then(
                (docRef) => {
                    this.savedTabs.push(tab);
                }
            );
    }


    public getLayout(key: string) {
        const uid = this.afAuthClient.auth.currentUser.uid;
        this.firestoreClient.collection('users')
            .doc(uid)
            .collection('workspaces').doc(key).ref.get()
            .then(
                (docRef) => {
                    this.reloadWorkspace(docRef.data());
                }
            );
    }

    private reloadWorkspace(recentLayout: any) {
        let self = this;
        if (recentLayout) {
            recentLayout.tabs.forEach(function (tab) {
                let navTabItem = new NavTabItem(tab.id, tab.title, tab.type, tab.icon, tab.url, tab.requiresSwitcher, tab.uuid, new ContractDetailPreferences(tab.gridPreferences));
                self.items.push(navTabItem);
            });
            this.router.navigate([this.items[recentLayout.activeTab].url]);
            this.activeLink = recentLayout.activeTab;
        } else {
            this.addHomeTab();
        }
    }

    public loadTab(tab: any) {
        this.addTab(new NavTabItem(tab.id, tab.title, tab.type, tab.icon, tab.url, null, tab.preferences));
    }
}

