import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs';
import {ContractsService} from '../contracts/contracts.service';
import {SwitcherService} from '../../../../shared/switcher/switcher.service';
import {QuickSymbolSummaryService} from './quickSymbolSummaryService';
import {SnapshotResponse} from './SnapshotResponse';
import {SnapshotCashRates} from './SnapshotCashRates';


@Component({
    selector: 'app-quick-symbol-summary',
    templateUrl: './quick-symbol-summary.component.html',
    styleUrls: ['./quick-symbol-summary.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuickSymbolSummaryComponent {
    ss: SnapshotResponse;
    asOf: Date;

    // lpitColumns: string[] = ['lpit', 'avg', 'low', 'high'];
    // lpitData;
    //
    // slsColumns: string[] = ['sls', 'borrow', 'loan', 'net'];
    // slsData;
    //
    // dtcColumns: string[] = ['dtc', 'borrow', 'loan', 'diff'];
    // dtcData = [
    //     {dtc: 'New', borrow: 56100, loan: 5100, diff: 5000},
    //     {dtc: 'Return', borrow: 9999, loan: 8888, diff: 1111},
    //     {dtc: 'Pend', borrow: 9999, loan: 8888, diff: 1111}
    // ];


    constructor(private contractService: ContractsService, private switcherService: SwitcherService, private qs: QuickSymbolSummaryService) {
        this.setData(this.qs.snapShot);
        this.asOf = new Date();

        this.qs.snapshotReceived.subscribe(snapShot => {
            this.setData(snapShot);
            this.asOf = new Date();
        });
    }

    setData(snapShot: SnapshotResponse) {
        this.ss = snapShot;
        // this.lpitData = [
        //     {lpit: '30-Day', avg: this.ss.lendingPit.rebateAvg, low: this.ss.lendingPit.rebateLow, high: this.ss.lendingPit.rebateHigh},
        //     {lpit: 'New', avg: this.ss.lendingPit.newRebateAvg, low: this.ss.lendingPit.newRebateLow, high: this.ss.lendingPit.newRebateHigh}
        // ];
        //
        // this.slsData = [
        //     {sls: 'Avg', borrow: this.ss.sls.vbavgRate, loan: this.ss.sls.vlavgRate, net: this.ss.sls.vlavgRate},
        //     {sls: 'Qty', borrow: 123000, loan: 118000, net: 5000},
        //     {sls: 'Amount', borrow: 3567000, loan: 3422000, net: -145000},
        //     {sls: 'Recall', borrow: 9999, loan: 33200, net: 33200},
        //     {sls: 'Return', borrow: 9999, loan: 9999, net: 999}
        // ];
    }

    absolute(value:number){
        return Math.abs(value);
    }

}
