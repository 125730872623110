import {Component, Inject, LOCALE_ID, OnDestroy, OnInit} from '@angular/core';
import {ReportsService, ShortLoanInterest} from '../reports.service';
import {ColDef, GridApi} from 'ag-grid-community';
import {Subscription} from 'rxjs';
import * as moment from 'moment';
import {formatCurrency, formatNumber} from '@angular/common';

@Component({
    selector: 'app-stock-loan-interest-report-v2',
    templateUrl: './stock-loan-interest-report-v2.html',
    styleUrls: ['./stock-loan-interest-report-v2.component.scss']
})
export class StockLoanInterestReportV2Component implements OnInit, OnDestroy {


    public defaultColDefs = {};
    public autoGroupColumnDef : ColDef = {
        minWidth: 150,
        maxWidth: 150,
        width: 150
    };
    public columnDefaults = {
        enableCellChangeFlash: true,
        sortable: true,
        filter: true,
        resizable: true,

        floatingFilterComponentParams: {
            suppressFilterButton: true
        }
    };

    public gridOptions = {
        enableRangeSelection: true
    };

    public gridApi: GridApi;
    public shortLoanData: ShortLoanInterest[];
    private filterSubscription: Subscription;
    private groupingSubscription: Subscription;
    private pinnedBottomRowData;
    getRowStyle;

    dateComparator(date1, date2) {
        const momentDate1 = moment(date1);
        const momentDate2 = moment(date2);
        if (date1 && date2) {
            return momentDate1.diff(momentDate2);
        }
    }

    constructor(
        private reportsService: ReportsService,
        @Inject(LOCALE_ID) private locale: string
    ) {
        this.setDefaultColDef();

        this.getRowStyle = (params) => {
            if (params.node.rowPinned) {
                return {'font-weight': 'bold'};
            }
        };
    }

    ngOnInit() {
        this.filterSubscription = this.reportsService.shortLoanInterestChangeFilter.subscribe((val) => {
            this.reportsService.getShortLoanInterestNewReportData(val.fromDate, val.toDate).then((response) => {
                this.shortLoanData = response;
                this.aggregateData();
            });
        });

        this.groupingSubscription = this.reportsService.reportGroupChanged.subscribe((val) => {
            this.toggleGroup(val);
        });


        this.reportsService.getShortLoanInterestNewReportData(
            this.reportsService.getShortLoanInterestFilter().fromDate,
            this.reportsService.getShortLoanInterestFilter().toDate).then((response) => {
            this.shortLoanData = response;
            this.aggregateData();
        });

    }

    onGridReady(params) {
        this.gridApi = params.api;
    }


    // tslint:disable-next-line:member-ordering
    isOffice: boolean;
    // tslint:disable-next-line:member-ordering
    isAccNo: boolean;
    // tslint:disable-next-line:member-ordering
    isSymbol: boolean;

    public toggleOfficeSymbol() {
        this.isOffice = true;
        this.isAccNo = false;
        this.isSymbol = true;
        this.setDefaultColDef();
    }

    public toggleOfficeAccNo() {
        this.isOffice = true;
        this.isAccNo = true;
        this.isSymbol = false;
        this.setDefaultColDef();
    }

    public toggleNoGroup() {
        this.isOffice = false;
        this.isAccNo = false;
        this.isSymbol = false;
        this.setDefaultColDef();
    }


    toggleGroup(value) {
        switch (value) {
            case 'No Grouping':
                this.toggleNoGroup();
                break;
            case 'Office and Symbol':
                this.toggleOfficeSymbol();
                break;
            case 'Office and AccountNo':
                this.toggleOfficeAccNo();
                break;
        }
    }


    setDefaultColDef(): void {
        const columnDefs = [];
        this.defaultColDefs = columnDefs;

        columnDefs.push(
            {
                headerName: 'Date', field: 'date',
                sort: 'desc', width: 95, minWidth: 95, maxWidth: 95, resizable: true,
                filter: 'agTextColumnFilter',
                comparator: this.dateComparator,
                valueFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return moment(params.value).format('MM/DD/YYYY');
                    }
                },
                pinnedRowCellRenderer: (params) => {
                    return 'Total';
                }
            },
            {
                headerName: 'Office', field: 'office', width: 70, minWidth: 70, maxWidth: 70,
                filterParams: {
                    defaultOption: 'equals', newRowsAction: 'keep'
                },
                filter: 'agSetColumnFilter', lockPinned: true, rowGroup: this.isOffice,

                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                headerName: 'AcctNo', field: 'acctNo', width: 100, minWidth: 100, maxWidth: 100,
                filterParams: {
                    defaultOption: 'equals', newRowsAction: 'keep'
                },
                filter: 'agTextColumnFilter', lockPinned: true, rowGroup: this.isAccNo,

                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                headerName: 'Corr', field: 'corr', width: 75, minWidth: 75, maxWidth: 75,
                filterParams: {
                    defaultOption: 'equals', newRowsAction: 'keep'
                },
                filter: 'agTextColumnFilter', lockPinned: true,

                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                headerName: 'Symbol', field: 'symbol', width: 80, minWidth: 80, maxWidth: 80,
                filterParams: {
                    defaultOption: 'equals', newRowsAction: 'keep'
                },
                filter: 'agTextColumnFilter', lockPinned: true, rowGroup: this.isSymbol,

                floatingFilterComponentParams: {
                    suppressFilterButton: true
                }
            },
            {
                headerName: 'Shares', field: 'sclose', width: 120, minWidth: 120, maxWidth: 120, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                pinnedRowCellRenderer: (params) => {
                    if (params.value === undefined) {
                        return '';
                    }
                },
                cellFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.0-0');
                    }
                }, filter: 'agNumberColumnFilter', aggFunc: 'sum'
            },
            {
                headerName: 'Price', field: 'price', width: 100, minWidth: 100, maxWidth: 100, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                cellFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$', 'USD', '1.2-2');
                    }
                }, filter: 'agNumberColumnFilter',
                pinnedRowCellRenderer: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$', 'USD', '1.2-2');
                    }
                }
            },
            {
                headerName: 'Amount', field: 'smv', width: 145, minWidth: 145, maxWidth: 145, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                cellFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$', undefined, '1.2-2');
                    }
                }, filter: 'agNumberColumnFilter', aggFunc: 'sum',
                pinnedRowCellRenderer: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$', undefined, '1.2-2');
                    }
                }
            },
            {
                headerName: 'Rate', field: 'stockloanInterestRate', width: 90, minWidth: 90, maxWidth: 90, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                cellFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');
                    }
                }, filter: 'agNumberColumnFilter',
                pinnedRowCellRenderer: (params) => {
                    return '';
                }
            }, {
                headerName: 'FplRate', field: 'fplRate', width: 90, minWidth: 90, maxWidth: 90, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                cellFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.3-3');
                    }
                }, filter: 'agNumberColumnFilter',
                pinnedRowCellRenderer: (params) => {
                    return '';
                }
            },
            {
                headerName: 'Qty7359', field: 'qtyFrom7359', width: 125, minWidth: 125, maxWidth: 125, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                cellFormatter: (params) => {
                    if (params.value === undefined || params.value === null) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.0-0');
                    }

                }, filter: 'agNumberColumnFilter', aggFunc: 'sum'
            },
            {
                headerName: 'Cost7359', field: 'cost7359', width: 125, minWidth: 125, maxWidth: 125, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                cellFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$', undefined, '1.2-2');
                    }
                }, filter: 'agNumberColumnFilter', aggFunc: 'sum',
            },
            {
                headerName: 'QtyFpl', field: 'qtyFromFpl', width: 125, minWidth: 125, maxWidth: 125, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                cellFormatter: (params) => {
                    if (params.value === undefined || params.value === null) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '', undefined, '1.0-0');
                    }

                }, filter: 'agNumberColumnFilter', aggFunc: 'sum', //valueFormatter: (params) => formatNumber(params.value, this.locale)
            },
            {
                headerName: 'CostFpl', field: 'costFromFpl', width: 125, minWidth: 125, maxWidth: 125, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                cellFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$', undefined, '1.2-2');
                    }
                }, filter: 'agNumberColumnFilter', aggFunc: 'sum',
            },
            {
                headerName: 'Charge', field: 'charge', width: 130, minWidth: 130, maxWidth: 130, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                cellFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$', undefined, '1.2-2');
                    }
                }, filter: 'agNumberColumnFilter', aggFunc: 'sum',
            },
            {
                headerName: 'ProfitLoss', field: 'profitLoss', width: 130, minWidth: 130, maxWidth: 130, suppressSizeToFit: true, type: 'numericColumn',
                cellStyle: {color: 'inherit'},
                cellFormatter: (params) => {
                    if (params.value === undefined) {
                        return '';
                    } else {
                        return formatCurrency(params.value, this.locale, '$', undefined, '1.2-2');
                    }
                }, filter: 'agNumberColumnFilter', aggFunc: 'sum',
            },
        );
    }

    onRowDataChanged(event) {
        if (this.gridApi) {
            this.aggregateData();
        }
    }

    aggregateData() {
        const result = {
            smv: 0,
            charge: 0,
            qtyFrom7359: 0,
            cost7359: 0,
            costFromFpl: 0,
            qtyFromFpl: 0,
            profitLoss: 0,
        };


        this.gridApi.forEachNodeAfterFilter(function (node, index) {
            // const data = node.group ? node.aggData : node.data;
            if (node.group) {
                return;
            }
            const data = node.data;

            result.smv += data.smv;
            result.charge += data.charge;
            result.qtyFrom7359 += data.qtyFrom7359;
            result.cost7359 += data.cost7359;
            result.qtyFromFpl += data.qtyFromFpl;
            result.costFromFpl += data.costFromFpl;
            result.profitLoss += data.profitLoss;
        });

        this.pinnedBottomRowData = result;
        this.updatePinnedRows();
    }

    updatePinnedRows() {
        const rows = [];
        rows.push({
            date: '',
            symbol: '',
            shares: '',
            //price: '', //this.pinnedBottomRowData.price,
            smv: this.pinnedBottomRowData.smv,
            rate: '',
            charge: this.pinnedBottomRowData.charge,
            qtyFrom7359: this.pinnedBottomRowData.qtyFrom7359,
            rateFrom7359: '',
            cost7359: this.pinnedBottomRowData.cost7359,
            qtyFromFpl: this.pinnedBottomRowData.qtyFromFpl,
            fplPrice: '',
            fplAmount: '',
            fplRate: '',
            costFromFpl: this.pinnedBottomRowData.costFromFpl,
            profitLoss: this.pinnedBottomRowData.profitLoss,
        });

        this.gridApi.setPinnedBottomRowData(rows);
    }

    ngOnDestroy() {
        this.filterSubscription.unsubscribe();
        this.groupingSubscription.unsubscribe();
    }

    onFilterChanged($event) {
        this.aggregateData();
    }


}


