import { Injectable } from '@angular/core';
import {RoundRule} from './roundrule.model';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';

@Injectable()
export class RoundRulesService implements Resolve<any>{

    private _roundRules: RoundRule[] = undefined;
    private _roundRulesLoading: boolean;
    onRoundRulesLoaded: BehaviorSubject<any> = new BehaviorSubject([]);

    endpoint = environment.verticalApiEndpoint + 'constants/roundrules';

  constructor(private httpClient: HttpClient) {
  }


    get roundRules(): RoundRule[] {
        return this._roundRules;
    }

    get roundRulesLoading(): boolean {
        return this._roundRulesLoading;
    }

    updateRoundRules(): void {
      if (this._roundRules === undefined) {
          this._roundRulesLoading = true;
          this.getRoundRules().subscribe((roundRules: any) => {
              this._roundRulesLoading = false;
              this._roundRules = roundRules.items;
              this.onRoundRulesLoaded.next(this.roundRules);
          });
      }
    }

    private getRoundRules(): Observable<RoundRule[]> {

        return this.httpClient
            .get(this.endpoint)
            .pipe(
                map((body: any) => body),
                catchError(() => of('Error, could not load round rules.'))
            );
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.updateRoundRules()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }
}
