import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';

export class CounterPartyType {
    counterPartyTypeId: number;
    name: string;
    description: string;
    abbr: string;

    constructor(counterPartyType?) {
        counterPartyType = counterPartyType || {};
        this.counterPartyTypeId = counterPartyType.counterPartyTypeId  || 1;
        this.name = counterPartyType.name || '';
        this.description = counterPartyType.description || '';
        this.abbr = counterPartyType.abbr || '';
    }
}

@Injectable()
export class CounterPartyTypeService {

    counterPartyTypes: CounterPartyType[];
    endpoint = environment.verticalApiEndpoint + 'constants/counterpartytypes'

  constructor(private http: HttpClient) { }

    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getCounterPartyTypeList()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getCounterPartyTypeList(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this.http.get(this.endpoint)
                .subscribe((response: any) => {
                    //console.log('resp (counterpartytypes?): ', response);
                    const counterPartyTypeArray: CounterPartyType[] = [];
                    for (const newCounterPartyType of response.items) {
                        counterPartyTypeArray.push(new CounterPartyType(newCounterPartyType));
                    }
                    this.counterPartyTypes = counterPartyTypeArray;
                    resolve(response);
                }, reject);
        });
    }
}
