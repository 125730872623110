import {Identifiable} from '../../../../shared/firestore-collection.service';
import {Moment} from 'moment';
import * as moment from 'moment-timezone';

export class DtcSummary implements Identifiable{
    primaryKey: any;

    created: Moment;
    crediting: number;
    cusip: string;
    madeNewBorrow: number;
    madeNewLoan: number;
    madeReturnBorrow: number;
    madeReturnLoan: number;
    pendReturnBorrow: number;
    pendReturnLoan: number;
    dtcId: string;
    // instrumentId: number;
    modified: Moment;
    quantity: number;
    startQty: number;
    symbol: string;
    tradeDate: Moment;
    tranCount: number;

    constructor(summary) {
        this.primaryKey = summary.primaryKey;
        this.created = moment.utc(summary.created, 'X').tz('America/New_York');
        this.crediting = summary.crediting;
        this.cusip = summary.cusip;
        this.madeNewBorrow = summary.madeNewBorrow;
        this.madeNewLoan = summary.madeNewLoan;
        this.madeReturnBorrow = summary.madeReturnBorrow;
        this.madeReturnLoan = summary.madeReturnLoan;
        this.pendReturnBorrow = summary.pendReturnBorrow;
        this.pendReturnLoan = summary.pendReturnLoan;
        this.dtcId = summary.dtcId;
        // this.instrumentId = summary.instrumentId;
        this.modified = moment.utc(summary.modified, 'X').tz('America/New_York');
        this.quantity = summary.quantity;
        this.startQty = summary.startQty;
        this.symbol = summary.symbol;
        this.tradeDate = summary.tradeDate;
        this.tranCount = summary.tranCount;
    }
}
