import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {ReturnHoldingService} from './returnholding.service';
import {ContractsService} from '../contracts/contracts.service';
import {FuseContractsRecallFormDialogComponent} from '../contracts/recall-form/recall-form.component';
import {FormGroup} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {ReturnAllocationComponent} from './return-allocation/return-allocation.component';
import {Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-returns',
  templateUrl: './returns.component.html',
  styleUrls: ['./returns.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ReturnsComponent implements OnInit, OnDestroy {

    columnDefs;
    gridApi;
    public init = false;
    dialogRef: any;

    private collectionAddedSub: Subscription;
    private collectionChangedSub: Subscription;
    private collectionRemovedSub: Subscription;

  constructor(public returnHoldingService: ReturnHoldingService,
              public contractsService: ContractsService,
              public router: Router) {

      this.columnDefs = [
          {field: 'cusip'},
          {field: 'symbol', sort: 'asc'},
          {field: 'shortCode', headerName: 'Contra'},
          {field: 'rate'},
          {field: 'contractQty', headerName: 'Contract Qty'},
          {field: 'quantity', headerName: 'Return Qty'},
          {field: 'qtyLeft', type: 'numericColumn', filter: 'agNumberColumnFilter'},
          {field: 'comments'}
      ];

  }

  ngOnInit() {
  }

  getSelectedSymbol() {
      return this.gridApi.getSelectedRows()[0].symbol;
  }

  allocateSelectedReturn() {
      // this.dialogRef = this.dialog.open(ReturnAllocationComponent, {
      //     panelClass: 'allocation-form-dialog',
      //     data: {
      //         action: 'Allocate Returns',
      //         return: this.gridApi.getSelectedRows()[0],
      //         borrows: this.contractsService.getBorrowContractsWithSymbol(this.getSelectedSymbol())
      //     }
      // });
      //
      // this.dialogRef.afterClosed()
      //     .subscribe((response: FormGroup) => {
      //         if ( !response ) {
      //             return;
      //         }
      //         // const recallQty = response[1].controls.recallQty.value;
      //
      //
      //
      //     });
  }

  onGridReady(params) {
      this.gridApi = params.api;
      this.init = true;
      this.setupFirestoreSubscriptions();
  }

  onRowClicked(event) {
      const returnHoldingId = this.getRowNodeId(event.node.data);
      //console.log('return id: ', returnHoldingId);
      this.router.navigate(['returns/' + returnHoldingId + '/allocation'], {
          replaceUrl: true,
      });
  }

    getRowNodeId(data) {
        return data.contractHistoryId;
    }

    ngOnDestroy(): void {
      this.collectionRemovedSub.unsubscribe();
      this.collectionChangedSub.unsubscribe();
      this.collectionAddedSub.unsubscribe();
    }

    setupFirestoreSubscriptions() {
        this.collectionAddedSub =
            this.returnHoldingService.onCollectionAdded.subscribe(returns => {
                if (this.gridApi) {
                    this.gridApi.setRowData(this.returnHoldingService.collectionData);
                }
            });

        this.collectionChangedSub =
            this.returnHoldingService.onCollectionChanged.subscribe(returns => {
                if (this.gridApi) {
                    //console.log('returns changed: ', returns);
                    if (returns.length > 0) {
                        //console.log(returns[0] === this.returnHoldingService.collectionData[0]);
                        this.gridApi.updateRowData({update: returns});
                        const flashRows = [];
                        for (const returnObj of returns) {
                            const rowNode = this.gridApi.getRowNode(returnObj.contractHistoryId);
                            //console.log(typeof(returnObj.contractHistoryId));
                            //console.log('rowNode: ' + rowNode);
                            flashRows.push(rowNode);
                        }
                        this.gridApi.flashCells({
                            rowNodes: flashRows
                        });
                    }
                }
            });

        this.collectionRemovedSub =
            this.returnHoldingService.onCollectionRemoved.subscribe((contracts) => {
                if (this.gridApi) {
                    if (contracts.length > 0) {
                        this.gridApi.updateRowData({remove: contracts});
                    }
                }
            });
    }

    hideClosedContracts(): void {
        const qtyLeft = this.gridApi.getFilterInstance('qtyLeft');
        qtyLeft.setModel({
            type: 'notEqual',
            filter: 0,
            newRowsAction: 'keep',
            apply: true
        });
        this.gridApi.onFilterChanged();
    }

    firstDataRendered(params): void {
        this.gridApi = params.api;
        this.hideClosedContracts();
    }

}
