import {EventEmitter, Injectable, Output} from '@angular/core';
import {UserPreferencesService} from '../main/content/operations/user-preferences/user-preferences.service';
import {take} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class ContractFilterService {

    @Output() filterChangedEvent = new EventEmitter();
    @Output() setFilterControlsEvent = new EventEmitter();
    @Output() clearFilterControlsEvent = new EventEmitter();
    @Output() showDetailsPanelEvent = new EventEmitter();
    @Output() setDetailToggleEvent = new EventEmitter();
    public currentFilter;

    constructor(private userPreferencesService: UserPreferencesService) {
        this.userPreferencesService.contractsFilterChanged.pipe(
            take(1),
        ).subscribe(filter => {
            this.currentFilter = filter;
            this.setFilterControls(filter);
            this.filterChangedEvent.emit(filter);
        });
    }

    public filterChanged(filter: any): void {
        this.currentFilter = filter;
        this.filterChangedEvent.emit(filter);
    }

    public setFilterControls(filter:any):void{
        this.setFilterControlsEvent.emit(filter);
    }

    public clearFilterControls(applyFilter:boolean){
        this.clearFilterControlsEvent.emit(applyFilter);
    }

    public showDetails(showDetails:boolean){
        this.showDetailsPanelEvent.emit(showDetails);
    }

    public setDetailToggle(showDetails:boolean){
        this.setDetailToggleEvent.emit(showDetails);
    }
}
