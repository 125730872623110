export class Currency {
    currencyId: string;
    name: string;
    description: string;

    constructor(currency?) {
        currency = currency || {};
        this.currencyId = currency.currencyId || '';
        this.name = currency.name || '';
        this.description = currency.description || '';
    }
}
