import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {CounterParty} from '../counterparty.model';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';

@Injectable()
export class CounterpartyDetailService implements Resolve<any> {

    routeParams: any;
    counterParty: CounterParty;
    onCounterPartyChanged: BehaviorSubject<any> = new BehaviorSubject({});
    endpoint = environment.verticalApiEndpoint + 'counterparty/';

  constructor(private http: HttpClient) { }

    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getCounterParty()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getCounterParty(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            if ( this.routeParams.counterPartyId === 'new' )
            {
                this.onCounterPartyChanged.next(false);
                resolve(false);
            }
            else
            {
                this.http.get(this.endpoint + this.routeParams.counterPartyId)
                    .subscribe((response: any) => {
                        this.counterParty = response;
                        this.onCounterPartyChanged.next(this.counterParty);
                        resolve(response);
                    }, reject);
            }
        });
    }

    /**
     * MODIFY EXISTING
     * @param entity
     */
    saveCounterParty(counterParty)
    {
        //console.log('put COUNTERPARTY: ', counterParty);
        return new Promise((resolve, reject) => {
            this.http.put(this.endpoint + counterParty.counterPartyId, counterParty)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }


    /**
     * SAVE NEW
     * @param entity
     */
    addCounterParty(counterParty)
    {
        return new Promise((resolve, reject) => {
            this.http.post(this.endpoint, counterParty)
                .subscribe((response: any) => {
                    //console.log('ADDED: ', response);
                    resolve(response);
                }, reject);
        });
    }
}
