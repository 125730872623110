import {AfterViewChecked, Component, Inject, ViewChild, ViewEncapsulation} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Contract} from '../contract.model';

// @ViewChild('focusThis') focusThis;

@Component({
    selector     : 'fuse-contracts-recall-form-dialog',
    templateUrl  : './recall-form.component.html',
    styleUrls    : ['./recall-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class FuseContractsRecallFormDialogComponent implements AfterViewChecked
{
    dialogTitle: string;
    recallForm: FormGroup;
    recallQty: number;
    contract: Contract;

    constructor(public dialogRef: MatDialogRef<FuseContractsRecallFormDialogComponent>,
                @Inject(MAT_DIALOG_DATA) private data: any,
                private formBuilder: FormBuilder) {
        this.contract = data.contract;
        this.recallForm = this.createRecallForm();
    }

    createRecallForm()
    {
        return this.formBuilder.group({
            recallQty: [this.recallQty,
                Validators.compose([Validators.min(0),
                    Validators.max(this.contract.quantity)])]
        });
    }

    ngAfterViewChecked() {
        // this.recallForm.get('recallQty').
    }
}
