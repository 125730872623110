import { Component } from '@angular/core';

@Component({
    selector   : 'fuse-demo-sidenav',
    templateUrl: './demo-sidenav.component.html',
    styleUrls  : ['./demo-sidenav.component.scss']
})
export class FuseDemoSidenavComponent
{
    constructor()
    {
    }
}
