import {Attribute, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UserRolesService} from './user-roles.service';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {UserDetailService} from '../user-detail.service';
import {Role} from '../../../../../../auth/user/role';

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss']
})
export class UserRolesComponent implements OnInit, OnDestroy {

   @Input('roleList') roleList: Role[];
    onUserRolesChanged: Subscription;

   roleForm: FormGroup;

  constructor(public roleService: UserRolesService,
              private userDetailService: UserDetailService,
              private formBuilder: FormBuilder) {
  }

    createUserRoleForm()
    {
        const controls = this.roleService.validRoleList.map(c => new FormControl(this.checkRole(c)));
        return this.formBuilder.group({
            roles: new FormArray(controls)
        });
    }

  ngOnInit() {
      this.onUserRolesChanged =
          this.roleService.onUserRolesChanged
              .subscribe(roleList => {
                  if (roleList) {
                      this.roleList = this.userDetailService.user.roles;
                      this.roleForm = this.createUserRoleForm();
                  }
              });
  }
    /**
     * Given a role description, check if it exists in the roleList
     */
    checkRole(role: Role): boolean {
        // If the user doesn't have any roles, return false for everything
        if (this.roleList === undefined) {
            return false;
        }

        if (this.roleList === null) {
            return false;
        }

        if (this.roleList.length === 0) {
            return false;
        }

        for (const userRole of this.roleList) {
            if (role.roleId === userRole.roleId) {
                return true;
            }
        }
        return false;
    }

    ngOnDestroy(): void {

        this.onUserRolesChanged.unsubscribe();
    }
}
