import {Identifiable} from '../../../../../shared/firestore-collection.service';

export class BulkRequest implements Identifiable {
    bulkId: number;
    contractId: number;
    depositoryNo: string;
    contraDepositoryNo: string;
    accountId: number;
    contraAccountId: number;
    loanetId: string;
    contraLoanetId: string;
    side: string;
    cusip: string;
    contractNo: string;
    // instrumentId: number;
    symbol: string;
    price: number;
    quantity: number;
    amount: number;
    margin: number;
    divRate: number;
    roundRuleValue: number;
    batchCode: string;
    profitCenter: string;
    rebateRate: number;
    bulkStatus: string;
    fplPositionId: number;
    specFlag: string;
    publicComment: string;
    collateralCode: string;
    enteredBy: number;
    approvedBy: number;
    errorMessage: string;
    primaryKey: string;
    restrictions: string;
    blocked: boolean;

    constructor(bulkRequest) {
        this.bulkId = bulkRequest.bulkId;
        this.contractId = bulkRequest.contractId;
        this.depositoryNo = bulkRequest.depositoryNo;
        this.contraDepositoryNo = bulkRequest.contraDepositoryNo;
        this.loanetId = bulkRequest.loanetId;
        this.contraLoanetId = bulkRequest.contraLoanetId;
        this.accountId = bulkRequest.accountId;
        this.contraAccountId = bulkRequest.contraAccountId;
        this.side = bulkRequest.side;
        this.cusip = bulkRequest.cusip;
        this.contractNo = bulkRequest.contractNo;
        // this.instrumentId = bulkRequest.instrumentId;
        this.symbol = bulkRequest.symbol;
        this.quantity = bulkRequest.quantity;
        this.amount = bulkRequest.amount;
        this.price = bulkRequest.price;
        this.margin = bulkRequest.margin;
        this.divRate = bulkRequest.divRate;
        this.roundRuleValue = bulkRequest.roundRuleValue;
        this.batchCode = bulkRequest.batchCode;
        this.profitCenter = bulkRequest.profitCenter;
        this.rebateRate = bulkRequest.rebateRate;
        this.bulkStatus = bulkRequest.bulkStatus;
        this.fplPositionId = bulkRequest.fplPositionId;
        this.specFlag = bulkRequest.specFlag;
        this.publicComment = bulkRequest.publicComment;
        this.collateralCode = bulkRequest.collateralCode;
        this.enteredBy = bulkRequest.enteredBy;
        this.approvedBy = bulkRequest.approvedBy;
        this.errorMessage = bulkRequest.errorMessage;
        this.primaryKey = bulkRequest.bulkId;
        this.restrictions = bulkRequest.restrictions;
        this.blocked = bulkRequest.blocked;
    }
}
