import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {ContractsService} from '../contracts/contracts.service';
import {Router} from '@angular/router';
import {RecallHoldingService} from './recallholding.service';

@Component({
  selector: 'app-returns',
  templateUrl: './recalls.component.html',
  styleUrls: ['./recalls.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class RecallsComponent implements OnInit {

    columnDefs;
    gridApi;
    public init = false;
    dialogRef: any;

  constructor(public recallHoldingService: RecallHoldingService,
              public contractsService: ContractsService,
              public router: Router) {

      this.columnDefs = [
          {field: 'cusip'},
          {field: 'symbol'},
          {field: 'shortCode', headerName: 'Contra'},
          {field: 'rate'},
          {field: 'contractQty', headerName: 'Contract Qty'},
          {field: 'quantity', headerName: 'Recall Qty'},
          {field: 'allocated'},
          {field: 'comments'}
      ];

  }

  ngOnInit() {
  }

  getSelectedSymbol() {
      return this.gridApi.getSelectedRows()[0].symbol;
  }

  allocateSelectedReturn() {
      // this.dialogRef = this.dialog.open(ReturnAllocationComponent, {
      //     panelClass: 'allocation-form-dialog',
      //     data: {
      //         action: 'Allocate Returns',
      //         return: this.gridApi.getSelectedRows()[0],
      //         borrows: this.contractsService.getBorrowContractsWithSymbol(this.getSelectedSymbol())
      //     }
      // });
      //
      // this.dialogRef.afterClosed()
      //     .subscribe((response: FormGroup) => {
      //         if ( !response ) {
      //             return;
      //         }
      //         // const recallQty = response[1].controls.recallQty.value;
      //
      //
      //
      //     });
  }

  onGridReady(params) {
      this.gridApi = params.api;
      this.init = true;
      //console.log('this.init = ', this.init);
  }

  onRowClicked(event) {
      // const recallHoldingId = this.getRowNodeId(event.node.data);
      // //console.log('return id: ', recallHoldingId);
      // this.router.navigate(['recalls/' + recallHoldingId + '/allocation'], {
      //     replaceUrl: true,
      // });
  }

    getRowNodeId(data) {
        return data.contractHistoryId;
    }

}
