import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';


import { LoaderComponent } from './loader/loader.component';
import {MatProgressSpinnerModule} from '@angular/material';
import {ConfigSettingsService} from './config-settings.service';

@NgModule({
  imports: [
    FlexLayoutModule,
    MatProgressSpinnerModule,
    CommonModule
  ],
  declarations: [
    LoaderComponent
  ],
  exports: [
    LoaderComponent
  ],
    providers: [ConfigSettingsService]
})
export class SharedModule { }
