import {EventEmitter, Injectable, Output} from '@angular/core';
import {FirestoreCollectionService} from '../../../../shared/firestore-collection.service';
import {HighlightColor} from './accounts-preferences/highlight.color';
import {HttpClient} from '@angular/common/http';
import {AngularFirestore} from 'angularfire2/firestore';
import {AngularFireAuth} from 'angularfire2/auth';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserPreferencesService extends FirestoreCollectionService<any> {
    collectionName = 'users';

    private _accountsPreferences: HighlightColor[];
    private _tabsPreferences: any;

    private _accountsPreferencesDictionary: any;
    onAccountPreferenceChange: BehaviorSubject<HighlightColor[]> = new BehaviorSubject(null);

    @Output() contractsFilterChanged = new EventEmitter();

    constructor(private httpClient: HttpClient,
                private firestoreClient: AngularFirestore,
                private afAuthClient: AngularFireAuth) {
        super(httpClient, firestoreClient, afAuthClient);
    }

    get accountsPreferences(): HighlightColor[] {
        return this._accountsPreferences;
    }

    get tabsPreferences(): any {
        return this._tabsPreferences;
    }

    get accountsPreferencesDictionary(): any {
        return this._accountsPreferencesDictionary;
    }

    public bindUserPreferencesUpdates() {
        const uid = this.afAuthClient.auth.currentUser.uid;

        this.firestoreClient.collection(this.collectionName).doc(uid)
            .snapshotChanges().subscribe((data) => {
            if (data.payload.exists) {
                this._accountsPreferences = data.payload.get('accountsPreferences');
                // this._tabsPreferences = data.payload.get('tabsPreferences');

                if (this._accountsPreferences) {
                    this.transformAccountsPreferences();
                    this.onAccountPreferenceChange.next(this._accountsPreferences);
                }

                if (this._tabsPreferences){
                    // this.emitCombinedContractsPreferences();
                }
            }
        });
    }

    public transformAccountsPreferences() {
        this._accountsPreferencesDictionary = this._accountsPreferences.reduce((a, b) => (
            {...a, [b['accountId']]: b['highlightColor']}), {}
        );
    }

    public emitCombinedContractsPreferences(){
        if (this._tabsPreferences['combined-contracts']){
            this.contractsFilterChanged.emit(this._tabsPreferences['combined-contracts']);
        }
    }

    public addAccountPreferences(newHighlightColor: HighlightColor) {
        const uid = this.afAuthClient.auth.currentUser.uid;


        if (this._accountsPreferences) {
            const index = this._accountsPreferences.findIndex((e) => e.accountId === newHighlightColor.accountId);

            if (index === -1) {
                this._accountsPreferences.push(newHighlightColor);
            } else {
                this._accountsPreferences[index].highlightColor = newHighlightColor.highlightColor;
            }

            this.firestoreClient.collection(this.collectionName)
                .doc(uid)
                .update({
                    accountsPreferences: this._accountsPreferences.map((obj) => Object.assign({}, obj))
                })
                .then(
                    (docRef) => {
                    }
                );
        } else {
            const setValue = {};

            setValue['accountsPreferences'] = [{accountId: newHighlightColor.accountId, highlightColor: newHighlightColor.highlightColor}];
            if (this._tabsPreferences){
                setValue['tabsPreferences'] = this._tabsPreferences;
            }

            this.firestoreClient.collection(this.collectionName)
                .doc(uid)
                .set(setValue)
                .then(
                    (docRef) => {
                    }
                );
        }
    }

    public addTabPreferences(tabId: string, filter: any[]) {
        const uid = this.afAuthClient.auth.currentUser.uid;


        if (this._tabsPreferences) {

            this._tabsPreferences[tabId] = filter;

            // this.firestoreClient.collection(this.collectionName)
            //     .doc(uid)
            //     .update({
            //         tabsPreferences: this._tabsPreferences
            //     })
            //     .then(
            //         (docRef) => {
            //             //console.log('success save: ', docRef);
            //         }
            //     );
        } else {
            this._tabsPreferences = {};
            this._tabsPreferences[tabId] = filter;

            // const setValue = {};
            //
            // setValue['tabsPreferences'] = this._tabsPreferences;
            // if (this._accountsPreferences){
            //     setValue['accountsPreferences'] = this._accountsPreferences;
            // }
            //
            // this.firestoreClient.collection(this.collectionName)
            //     .doc(uid)
            //     .set(setValue)
            //     .then(
            //         (docRef) => {
            //             //console.log('success save: ', docRef);
            //         }
            //     );
        }
    }

    public resetTabPreferences() {
            const uid = this.afAuthClient.auth.currentUser.uid;
            this._tabsPreferences = {};

            const setValue = {};
            setValue['tabsPreferences'] = this._tabsPreferences;
            if (this._accountsPreferences){
                setValue['accountsPreferences'] = this._accountsPreferences;
            }

            this.firestoreClient.collection(this.collectionName)
                .doc(uid)
                .set(setValue)
                .then(
                    (docRef) => {
                    }
                );
    }

}
