import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {fuseAnimations} from '../../../../../../@fuse/animations';
import { MatSnackBar } from '@angular/material/snack-bar';
import {StatusService} from '../../constants/status/status.service';
import {Location} from '@angular/common';
import {AccountDetailService} from './account-detail.service';
import {CounterPartyAccount} from '../account.model';
import {CounterpartyService} from '../../counterparties/counterparty.service';
import {AccountCategoryService} from '../account-category.service';
import {ActivatedRoute} from '@angular/router';
import {FplAccount} from '../../fpl/fplAccount.model';
import {FplService} from '../../fpl/fpl.service';
import {map, startWith} from 'rxjs/operators';

@Component({
    selector: 'app-account-detail',
    templateUrl: './account-detail.component.html',
    styleUrls: ['./account-detail.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None,
})
export class AccountDetailComponent implements OnInit, OnDestroy {

    account: CounterPartyAccount = new CounterPartyAccount();
    onAccountChanged: Subscription;
    pageType: string;
    accountForm: FormGroup;
    unassignedIntelliclearAccounts: FplAccount[] = [];
    isIClearAccount = false;

    // filteredIntelliclearAccounts: Observable<FplAccount[]>;

    constructor(
        private accountDetailService: AccountDetailService,
        public counterPartyService: CounterpartyService,
        private formBuilder: FormBuilder,
        public snackBar: MatSnackBar,
        private location: Location,
        public statusService: StatusService,
        public accountCategoryService: AccountCategoryService,
        private route: ActivatedRoute,
        private fplService: FplService
    ) {
    }

    ngOnInit() {
        this.onAccountChanged =
            this.accountDetailService.onAccountChanged
                .subscribe(account => {

                    if (account) {
                        this.account = new CounterPartyAccount(account);
                        this.accountDetailService.account = this.account;
                        this.pageType = 'edit';

                        //console.log('account is now: ', this.account);
                    } else {
                        this.pageType = 'new';
                        this.account = new CounterPartyAccount();
                        this.route.queryParamMap
                            .subscribe((queryParams) => {
                                const counterPartyId = queryParams.get('counterPartyId');
                                this.account.counterPartyId = parseInt(counterPartyId, 10);
                            });
                    }

                    this.accountForm = this.createAccountForm();

                    this.isIClearAccount = false;
                    if (account.accountCategoryId === 2) {
                        this.isIClearAccount = true;
                        // this.getUnassignedFplAccounts();
                    }
                    // const setId = '' + this.counterParty.entityId;
                    // this.counterPartyForm.controls['entityId'].setValue(setId);
                });

        // this.filteredIntelliclearAccounts = this.accountForm.controls.linkedIclearAccount.valueChanges
        //     .pipe(
        //         startWith(''),
        //         map(value => typeof value === 'string' ? value : value.name),
        //         map(name => name ? this._filter(name) : this.unassignedIntelliclearAccounts.slice())
        //     );

    }

    private _filter(name: string): FplAccount[] {
        const filterValue = name.toLowerCase();

        return this.unassignedIntelliclearAccounts.filter(option => JSON.stringify(option).toLowerCase().indexOf(filterValue) > -1);
    }

    createAccountForm() {
        return this.formBuilder.group({
            accountId: [this.account.accountId],
            name: [this.account.name],
            dtcId: [this.account.dtcId],
            loanetId: [this.account.loanetId],
            counterPartyId: [this.account.counterPartyId],
            accountStatusId: [this.account.accountStatusId],
            accountCategoryId: [this.account.accountCategoryId],
            stopOrderEntry: [this.account.stopOrderEntry],
            clearingDestinationId: [this.account.clearingDestinationId],
            startOn: [this.account.startOn],
            endOn: [this.account.endOn],
            createdOn: [this.account.createdOn],
            modifiedOn: [this.account.modifiedOn],
            lendLimit: [this.account.lendLimit],
            borrowLimit: [this.account.borrowLimit],
            // linkedIclearAccount: [this.account.intelliclearAccount]
        });
    }

    onCategoryChange(event) {
        if (event.value === 2) {
            this.isIClearAccount = true;
            // this.getUnassignedFplAccounts();
        } else {
            this.isIClearAccount = false;
        }
        // this.updateIclearAccountField();
    }

    // updateIclearAccountField(): void {
    //     if (this.isIClearAccount === true) {
    //         this.accountForm.controls.linkedIclearAccount.setValidators([Validators.required]);
    //         this.accountForm.controls.linkedIclearAccount.updateValueAndValidity();
    //     } else if (this.isIClearAccount === false) {
    //         this.accountForm.controls.linkedIclearAccount.setValidators([]);
    //         this.accountForm.controls.linkedIclearAccount.updateValueAndValidity();
    //     }
    // }

    // getUnassignedFplAccounts() {
    //     this.fplService.getUnassignedFplAccounts().then((unassignedIntelliclearAccounts) => {
    //         this.unassignedIntelliclearAccounts = unassignedIntelliclearAccounts;
    //         if (this.account.accountId) {
    //             this.fplService.getFplAccountBySlsAccountId(this.account.accountId)
    //                 .then((fplAccount: FplAccount) => {
    //                     this.unassignedIntelliclearAccounts.push(fplAccount);
    //                     this.account.intelliclearAccount = fplAccount;
    //
    //                     this.accountForm.patchValue({
    //                         linkedIclearAccount: fplAccount
    //                     });
    //                     this.accountForm.updateValueAndValidity();
    //                 });
    //         }
    //     });
    // }

    compareFn(c1, c2): boolean {
        return c1 && c2 ? c1.uniqueKey === c2.uniqueKey : c1 === c2;
    }


    /**
     * MODIFY EXISTING
     */
    saveAccount() {
        const data = this.accountForm.getRawValue();
        const iclearAccount: FplAccount = data.linkedIclearAccount;

        this.accountDetailService.saveAccount(data)
            .then((saveAccountRes: any) => {

                // link to Intelliclear account if isIClearAccount is true.
                if (this.isIClearAccount) {
                    iclearAccount.accountId = parseInt(saveAccountRes.accountId, 10);
                    this.fplService.updateFplAccount(iclearAccount).then((res) => {
                        this.unassignedIntelliclearAccounts = res;
                        this.unassignedIntelliclearAccounts.push(iclearAccount);
                    });
                }

                // Trigger the subscription with new data
                this.accountDetailService.onAccountChanged.next(data);

                // Show the success message
                this.snackBar.open('Account saved', 'OK', {
                    verticalPosition: 'top',
                    duration: 2000
                });
            });
    }

    /**
     * SAVE NEW
     */
    addAccount() {
        const data = this.accountForm.getRawValue();
        const iclearAccount: FplAccount = data.linkedIclearAccount;
        // delete data.linkedIclearAccount;

        this.accountDetailService.addAccount(data)
            .then((updatedAccount: CounterPartyAccount) => {
                // //console.log('Updated Entity: ', updatedEntity);
                // Trigger the subscription with new data
                this.accountDetailService.onAccountChanged.next(updatedAccount);

                // link to Intelliclear account if isIClearAccount is true.
                if (this.isIClearAccount) {
                    iclearAccount.accountId = updatedAccount.accountId;
                    this.fplService.updateFplAccount(iclearAccount).then((res) => {
                        this.unassignedIntelliclearAccounts = res;
                        this.unassignedIntelliclearAccounts.push(iclearAccount);
                    });
                }

                // Show the success message
                this.snackBar.open('Account added', 'OK', {
                    verticalPosition: 'top',
                    duration: 2000
                });

                // Change the location with new one
                this.location.go('accounts/' + this.account.accountId);
            });
    }

    ngOnDestroy(): void {
        this.onAccountChanged.unsubscribe();
    }

    // checkTabIndex(event) {
    //     // Rseize the table columns when the tab is selected
    //     if (event.index === 1) {
    //         this.accountListComponent.gridApi.sizeColumnsToFit();
    //     }
    // }

}
