import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ContractEvent} from './contractevent.model';
import {ProfitCenter} from './profitcenter.model';

@Injectable({
    providedIn: 'root'
})
export class ConstantsService {

    contractEventsList: ContractEvent[] = undefined;
    profitCenterList: ProfitCenter[] = undefined;

    contractEventEndpoint = environment.verticalApiEndpoint + 'constants/contractEvents'
    profitCenterEndpoint = environment.verticalApiEndpoint + 'constants/profitCenters'

    constructor(private http: HttpClient) {
        this.getContractEventList();
        this.getProfitCenterList();
    }


    getContractEventList(): void {
        if (this.contractEventsList === undefined) {
            this.http.get(this.contractEventEndpoint)
                .subscribe((contractEvents: any) => {
                    this.contractEventsList = [];
                    const contractEventsList = contractEvents.items;
                    for (const contractEvent of contractEventsList) {
                        const newContractEvent: ContractEvent = new ContractEvent(contractEvent);
                        this.contractEventsList.push(newContractEvent);
                    }
                }, error => {
                    //console.log('Error getting ContractEventList: ', error);
                });
        }
    }

    getProfitCenterList(): void {
        if (this.profitCenterList === undefined) {
            this.http.get(this.profitCenterEndpoint)
                .subscribe((profitCenters: any) => {
                    this.profitCenterList = [];
                    const profitCentersList = profitCenters.items;

                    // this.profitCenterList.push({
                    //     profitCenterId:null,
                    //     name:'Blank',
                    //     description:'Blank',
                    //     abbr:' '
                    // })

                    for (const profitCenter of profitCentersList) {
                        const newProfitCenter: ProfitCenter = new ProfitCenter(profitCenter);
                        this.profitCenterList.push(newProfitCenter);
                    }

                }, error => {
                    //console.log('Error getting ProfitCenterList: ', error);
                });
        }
    }
}
