import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Status} from './status.model';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../../environments/environment';

@Injectable()
export class StatusService implements Resolve<any>{

    statusList: Status[] = undefined;
    endpoint = environment.verticalApiEndpoint + 'constants/statuscodes'

  constructor(private http: HttpClient) { }

    getStatusList(): void {
        if (this.statusList === undefined) {
            this.http.get(this.endpoint)
                .subscribe((statuses: any) => {
                    this.statusList = [];
                    const statusList = statuses.items;
                    for (const status of statusList) {
                        const newStatus: Status = new Status(status);
                        this.statusList.push(newStatus);
                    }
                    console.log('Statuses: ', this.statusList);
                }, error => {
                    console.log('Error getting statuses: ', error);
                });
        }
    }

    /**
     * Resolve
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getStatusList()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }
}
