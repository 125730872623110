import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';

@Component({
    selector: 'contract-context-menu',
    templateUrl: './contract-context-menu.component.html'
})
export class ContractContextMenuComponent implements ICellRendererAngularComp {
    public params: any;

    agInit(params: any): void {
        this.params = params;
    }

    public invokeParentMethod() {
        //console.log('hello world');
    }

    refresh(): boolean {
        return false;
    }
}
