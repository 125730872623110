export class ShortRate {
    symbol: string;
    cusip: string;
    rate: number;
    source: string;
    date: Date;

    constructor(shortRate?) {
        this.symbol = shortRate.symbol;
        this.cusip = shortRate.cusip;
        this.rate = shortRate.rate;
        this.source = shortRate.source;
        this.date = shortRate.date;
    }
}
