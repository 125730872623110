import {Contract} from '../contracts/contract.model';
import {FplPosition} from '../../operations/fpl/fplAccountPosition.model';

export class TradeFormParams {
    tradeFormMode;
    fplPosition: FplPosition;

    constructor(mode: TradeFormMode) {
        this.tradeFormMode = mode;
    }
}


export enum TradeFormMode {
    Normal,
    Fpl
}
