import {EventEmitter, Injectable, Output} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CounterpartyLimitsFilterService {

    @Output() filterChangedEvent = new EventEmitter();
    @Output() setFilterControlsEvent = new EventEmitter();
    @Output() clearFilterControlsEvent = new EventEmitter();
    @Output() showDetailsPanelEvent = new EventEmitter();
    @Output() setDetailToggleEvent = new EventEmitter();
    public currentFilter;

    constructor() {
    }

    public filterChanged(filter: any): void {
        this.currentFilter = filter;
        this.filterChangedEvent.emit(filter);
    }

    public setFilterControls(filter: any): void{
        this.setFilterControlsEvent.emit(filter);
    }

    public clearFilterControls(applyFilter: boolean){
        this.clearFilterControlsEvent.emit(applyFilter);
    }

    public showDetails(showDetails: boolean){
        this.showDetailsPanelEvent.emit(showDetails);
    }

    public setDetailToggle(showDetails: boolean){
        this.setDetailToggleEvent.emit(showDetails);
    }
}
