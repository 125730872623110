import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {fromEvent} from 'rxjs';
import {EntityService} from './entity.service';

@Component({
  selector: 'app-entities',
  templateUrl: './entities.component.html',
  styleUrls: ['./entities.component.scss'],
    animations   : fuseAnimations,
})
export class EntitiesComponent implements OnInit {

  constructor(public entityService: EntityService) { }

  ngOnInit() {
  }

}
