import {Component, Inject, LOCALE_ID, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {DtcSummaryService} from './dtc-summary.service';
import {ColDef, GridApi} from 'ag-grid-community';
import {formatNumber} from '@angular/common';
import {SwitcherService} from '../../../../shared/switcher/switcher.service';
import {Subscription} from 'rxjs';
import {Contract} from '../../trading/contracts/contract.model';
import {DtcSummary} from './dtc-summary';

@Component({
    selector: 'app-dtc-summary',
    templateUrl: './dtc-summary.component.html',
    styleUrls: ['./dtc-summary.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class DtcSummaryComponent implements OnInit, OnDestroy {

    gridApi;
    colDefs: ColDef[];
    defaultColDef: any;
    onDepositoryNoChanged: Subscription;
    onDTCSummaryAdded: Subscription;
    onDTCSummaryChanged: Subscription;
    onDTCSummaryRemoved: Subscription;

    constructor(protected dtcSummaryService: DtcSummaryService,
                @Inject(LOCALE_ID) private locale: string,
                public switcherService: SwitcherService) {
        this.colDefs = [
            {
                headerName: 'Symbol',
                field: 'symbol',
                filter: 'agTextColumnFilter',
                sort: 'asc'
            },
            {
                headerName: 'CUSIP',
                field: 'cusip',
                filter: 'agTextColumnFilter',
            },
            {
                headerName: 'Quantity',
                field: 'quantity',
                cellRenderer: (params) => {
                    if (params.value === null) {
                        return '0';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                },
                filter: 'agNumberColumnFilter'
            },
            {
                headerName: 'Made New Borrow',
                field: 'madeNewBorrow',
                cellRenderer: (params) => {
                    if (params.value === null) {
                        return '0';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                }, filter: 'agNumberColumnFilter'
            },
            {
                headerName: 'Made New Loan',
                field: 'madeNewLoan',
                cellRenderer: (params) => {
                    if (params.value === null) {
                        return '0';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                }, filter: 'agNumberColumnFilter'
            },
            {
                headerName: 'Made Return Borrow',
                field: 'madeReturnBorrow',
                cellRenderer: (params) => {
                    if (params.value === null) {
                        return '0';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                }, filter: 'agNumberColumnFilter'
            },
            {
                headerName: 'Made Return Loan',
                field: 'madeReturnLoan',
                cellRenderer: (params) => {
                    if (params.value === null) {
                        return '0';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                }, filter: 'agNumberColumnFilter'
            },
            {
                headerName: 'Pending New Borrow',
                field: 'pendNewBorrow',
                cellRenderer: (params) => {
                    if (params.value === null) {
                        return '0';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                }, filter: 'agNumberColumnFilter', hide: true
            },
            {
                headerName: 'Pending New Loan',
                field: 'pendNewLoan',
                cellRenderer: (params) => {
                    if (params.value === null) {
                        return '0';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                }, filter: 'agNumberColumnFilter', hide: true
            },
            {
                headerName: 'Pending Return Borrow',
                field: 'pendReturnBorrow',
                cellRenderer: (params) => {
                    if (params.value === null) {
                        return '0';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                }, filter: 'agNumberColumnFilter', hide: true
            },
            {
                headerName: 'Pending Return Loan',
                field: 'pendReturnLoan',
                cellRenderer: (params) => {
                    if (params.value === null) {
                        return '0';
                    } else {
                        return formatNumber(params.value, this.locale);
                    }
                }, filter: 'agNumberColumnFilter', hide: false
            },
            {
                headerName: 'DTC ID',
                field: 'dtcId',
                filter: 'agTextColumnFilter'
            }
        ];
        this.defaultColDef = {
            enableCellChangeFlash: true,
            sortable: true,
            filter: true,
            resizable: true
        };
    }

    ngOnInit() {

    }

    ngOnDestroy(): void {
        this.dtcSummaryService.clearSubscriptions();
    }

    onGridReady(params) {
        this.gridApi = params.api;
        // this.dtcSummaryService.initializeService(
        //     this.dtcSummaryService.collectionName,
        //     this.dtcSummaryService.createSummaryCallback
        // );
        this.onDepositoryNoChanged = this.switcherService.depositoryNoSwitched.subscribe(depositoryNo => {
            this.gridApi.setRowData([]);
            this.gridApi.setRowData(this.dtcSummaryService.collectionData.filter(dtcSummary => this.depositoryFilter(dtcSummary)));
        });
        this.setupSubscriptions();

    }

    getRowNodeId(data) {
        return data.primaryKey;
    }

    depositoryFilter(dtcSummary: DtcSummary): boolean {
        return dtcSummary.dtcId == this.switcherService.getSelectedDepositoryNo();
    }

    setupSubscriptions() {
        // this.gridApi.setRowData([]);
        // this.gridApi.setRowData(this.dtcSummaryService.collectionData.filter(dtcSummary => this.depositoryFilter(dtcSummary)));
        this.onDTCSummaryAdded = this.dtcSummaryService.onCollectionAdded.subscribe(dtcSummaries => {
            const newSummaries: DtcSummary[] = [];
            for (const dtcSummary of dtcSummaries.filter(s => this.depositoryFilter(s))) {
                const rowNode = this.gridApi.getRowNode(dtcSummary.primaryKey);
                if (rowNode === undefined) {
                    newSummaries.push(dtcSummary);
                }
            }
            this.gridApi.updateRowData({
                add: newSummaries
            });

        });
        this.onDTCSummaryChanged = this.dtcSummaryService.onCollectionChanged.subscribe(dtcSummaries => {
            this.gridApi.updateRowData({update: dtcSummaries.filter(s => this.depositoryFilter(s))});
        });
        this.onDTCSummaryRemoved = this.dtcSummaryService.onCollectionRemoved.subscribe(dtcSummaries => {
            this.gridApi.updateRowData({remove: dtcSummaries.filter(s => this.depositoryFilter(s))});
            this.gridApi.flashCells();
        });
    }
}
