import {EventEmitter, Injectable, Output} from '@angular/core';
import {ConfigSettingsService} from '../config-settings.service';

@Injectable({
    providedIn: 'root'
})
export class SwitcherService {
    @Output() depositoryNoSwitched = new EventEmitter();
    private selectedDepositoryNo = '';

    constructor(
        public configSettingsService: ConfigSettingsService) {
        this.configSettingsService.onSwitcherDepositoryNumbersChange.subscribe((depositoryNumbers: string[]) => {
            if (depositoryNumbers){
                this.selectedDepositoryNo = depositoryNumbers[0];
                this.depositoryNoSwitched.emit(this.selectedDepositoryNo);
            }
        });
    }

    getSelectedDepositoryNo(): string {
        return this.selectedDepositoryNo;
    }

    public switchAccount(depositoryNo: string) {
        this.selectedDepositoryNo = depositoryNo;
        this.depositoryNoSwitched.emit(depositoryNo);
    }
}
