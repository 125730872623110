import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    Resolve,
    Router,
    RouterStateSnapshot,
} from "@angular/router";
import { from, Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { AngularFireAuth } from "angularfire2/auth";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserService } from "app/auth/user/user.service";

@Injectable({
    providedIn: "root",
})
export class ChangePasswordService {
    public count = 0;
    constructor(
        private afAuth: AngularFireAuth,
        // private http: HttpClient,
        // private router: Router,
        private snackBar: MatSnackBar,
        public userService: UserService,
    ) {}

    updateCurrentPass(newPassword: string, currentPassword: string) {
        // console.log("UPDATING CURRENT PASSWORD");

        this.afAuth.auth
            .signInWithEmailAndPassword(
                this.userService.getUserEmail(),
                currentPassword,
            )

            .then((credentials) => {
                this.count = 0;
                // console.log("correctCurrentPAssword");
                this.afAuth.auth.currentUser
                    .updatePassword(newPassword)
                    .then(() => {
                        this.snackBar.open(
                            "Your password has been successfully updated.",
                            "Dismiss",
                            {
                                duration: 8000,
                            },
                        );

                        setTimeout(() => {
                            window.location.reload();
                        }, 4500);
                    })
                    .catch((error) => {
                        this.snackBar.open(
                            "An error occurred while updating your password. Please try again.",
                            "Dismiss",
                            {
                                duration: 8000,
                            },
                        );
                    });
            })
            .catch((error) => {
                this.count++;
                if (this.count == 1) {
                    this.snackBar.open(
                        "You have entered your current password incorrectly. Please try again.",
                        "Dismiss",
                        {
                            duration: 10000,
                        },
                    );
                } else if (this.count == 2) {
                    this.snackBar.open(
                        "You have entered the wrong password again. Please try once more.",
                        "Dismiss",
                        {
                            duration: 10000,
                        },
                    );
                } else if (this.count == 3) {
                    this.snackBar.open(
                        "You are being logged out because you entered the wrong password three times.",
                        "Dismiss",
                        {
                            duration: 10000,
                        },
                    );
                    this.count = 0;
                    this.userService.logout();
                }
            });

        // this.afAuth.auth.currentUser
        //     .updatePassword(newPassword)
        //     .then(() => {
        //         console.log("UPDATED PASSWOORD IS", newPassword);
        //     })
        //     .catch((error) => {
        //         // An error ocurred
        //         // ...
        //     });
    }
}
