import {Component, OnDestroy} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {ContractsService} from '../../trading/contracts/contracts.service';
import {UserService} from '../../../../auth/user/user.service';


@Component({
    selector: 'checkbox-renderer',
    template: `<input type="checkbox"
                      (click)="checkedHandler($event)"
                      [checked]="params.value"
                      [disabled]="(!this.userService.requirePermission('approveContract'))"/>`,
})
export class CheckboxRenderer implements ICellRendererAngularComp, OnDestroy {
    public params: any;

    constructor(public contractService: ContractsService, public userService: UserService) {
    }

    agInit(params: any): void {
        this.params = params;
    }

    checkedHandler(event) {
        let ids: number[] = [];
        ids.push(this.params.data.contractId);
        this.contractService.updateDTCStatus(ids, !this.params.data.contractMade);
    }

    ngOnDestroy(): void {
    }

    refresh(params: any): boolean {
        return false;
    }
}
