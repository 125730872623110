export class Recall {
    contractHistoryId: number;
    contractId: number;
    quantity: number;
    allocated: number;
    amount: number;
    batchCode: string;
    specFlag: string;
    trader: string;
    replyOn: string;
    parentReturnId: number;
    comments: string;

    cusip: string;
    symbol: string;
    shortCode: string;

    rate: number;
    contractQty: number;

    constructor(returnObj) {
        this.contractHistoryId = returnObj.contractHistoryId;
        this.quantity = returnObj.quantity;
        this.allocated = returnObj.allocated;
        this.contractId = returnObj.contractId;
        this.specFlag = returnObj.specFlag;
        this.trader = returnObj.trader;
        this.replyOn = returnObj.replyOn;
        this.parentReturnId = returnObj.parentReturnId;
        this.comments = returnObj.comments;
        this.cusip = returnObj.cusip;
        this.symbol = returnObj.symbol;
        this.shortCode = returnObj.shortCode;
        this.rate = returnObj.rate;
        this.contractQty = returnObj.contractQty;
    }
}
