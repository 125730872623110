import {Component, OnInit} from '@angular/core';
import {NavTabService} from './nav-tab.service';
import {FormControl} from '@angular/forms';
import {NavTabItem} from './nav-tab-item';
import {AngularFireAuth} from 'angularfire2/auth';


@Component({
    selector: 'app-nav-tab',
    templateUrl: './nav-tab.component.html',
    styleUrls: ['./nav-tab.component.scss']
})

export class NavTabComponent implements  OnInit{

    titleInput;

    constructor(public navTabService: NavTabService,
                public afAuthClient: AngularFireAuth) {

    }

    ngOnInit(): void {
        this.navTabService.addHomeTab();
        this.afAuthClient.auth.onAuthStateChanged((user) => {
            if (user){
                //this.getLayout('recent');
                // this.getSavedTabs();
            }else{
                this.navTabService.clearTabs();
            }
        });
    }

    stopPropagation(event) {
        event.stopPropagation();
    }

    saveTab(tab: NavTabItem, title: string) {
        this.navTabService.setTitle(tab.getUUID(), title);
        this.navTabService.saveTab(tab);
    }

    alert(tab: NavTabItem) {
       this.navTabService.loadTab(tab);
    }
}
