import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {ConstantsService} from '../../../../../shared/constants/constants.service';
import {ContractsService} from '../../../trading/contracts/contracts.service';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {CounterpartyLimitsFilterService} from './counterparty-limits-filter.service';
import {CounterpartyLimitsService} from './counterparty-limits.service';
import {CounterPartyAccount} from '../../accounts/account.model';
import {CounterpartyService} from '../../counterparties/counterparty.service';
import {CounterParty} from '../../counterparties/counterparty.model';
import {map} from 'rxjs/operators';
import {_filter} from '../../../trading/trade-form/trade-form.component';

@Component({
  selector: 'app-counterparty-limits-filter',
  templateUrl: './counterparty-limits-filter.component.html',
  styleUrls: ['./counterparty-limits-filter.component.scss']
})
export class CounterpartyLimitsFilterComponent implements OnInit, OnDestroy, AfterViewInit {

    filterChangedSubscription: Subscription;
    clearFilterControlsSubscription: Subscription;
    contraAccounts: Observable<CounterParty[]>;

    public formFilter: FormGroup;
    public accountsList = [{accountId: '0294', accountName: '0294'}, {accountId: '6079', accountName: '6079'}];
    private counterPartyAccounts: CounterPartyAccount[] = [];
    // private filteredCounterPartyAccounts: CounterPartyAccount[] = [];

    constructor(private fb: FormBuilder,
                public filterService: CounterpartyLimitsFilterService,
                public constantsService: ConstantsService,
                public contractsService: ContractsService,
                public counterpartyLimitsService: CounterpartyLimitsService,
                public counterpartiesService: CounterpartyService,
                private counterpartyService: CounterpartyService) {
        this.filterChangedSubscription = filterService.setFilterControlsEvent.subscribe(filter => this.setFilterControls(filter));
        this.clearFilterControlsSubscription = filterService.clearFilterControlsEvent.subscribe(applyFilter => this.clearFilter(applyFilter));

        this.counterPartyAccounts = this.counterpartyService.getAllAccounts();
        // this.filteredCounterPartyAccounts = this.counterPartyAccounts;
    }

    // onKey(value) {
    //     this.filteredCounterPartyAccounts = this.search(value);
    // }
    //
    // search(value: string) {
    //     const filter = value.toLowerCase();
    //     return this.counterPartyAccounts.filter(option => option.name.toLowerCase().startsWith(filter) ||
    //         option.dtcId.toLowerCase().startsWith(filter) ||
    //         option.loanetId.toLowerCase().startsWith(filter));
    // }



    ngOnInit() {
        this.formFilter = this.fb.group({
            firm: [],
            account: [],
            counterParty: [],
            creditUsed: [],
            warn: [],
            critical: [],
        });
    }

    ngAfterViewInit(): void {
        this.contraAccounts = this.formFilter.get('counterParty')!.valueChanges
            .pipe(
                map(value => this._filterGroup(value))
            );
    }

    private _filterGroup(value: string): CounterParty[] {
        if (value) {
            return this.counterpartiesService.combinedCounterParties
                .map(contra => ({
                    counterPartyId: contra.counterPartyId,
                    entityId: contra.entityId,
                    name: contra.name,
                    hasLoanNet: contra.hasLoanNet,
                    canReturnOnRecall: contra.canReturnOnRecall,
                    canRecallPartial: contra.canRecallPartial,
                    occMemberId: contra.occMemberId,
                    bic: contra.bic,
                    billingReference: contra.billingReference,
                    complianceReference: contra.complianceReference,
                    currencyId: contra.currencyId,
                    createdOn: contra.createdOn,
                    modifiedOn: contra.modifiedOn,
                    counterPartyTypeId: contra.counterPartyTypeId,
                    counterPartyStatusId: contra.counterPartyStatusId,
                    defaultRoundRuleValue: contra.defaultRoundRuleValue,
                    defaultMargin: contra.defaultMargin,
                    accounts: _filter(contra.accounts, value),
                    shortCode: contra.shortCode,
                    lendLimit: contra.lendLimit,
                    borrowLimit: contra.borrowLimit,
                    bizEmail: contra.bizEmail,
                    opsEmail: contra.opsEmail,
                    internal: contra.internal
                }))
                .filter(contra => contra.accounts && contra.accounts.length > 0);
        }

        return this.counterpartiesService.combinedCounterParties;
    }

    ngOnDestroy(): void {
        this.filterChangedSubscription.unsubscribe();
        this.clearFilterControlsSubscription.unsubscribe();
    }

    applyFilter() {
        const firm = this.formFilter.get('firm').value !== null ? this.formFilter.get('firm').value.toString().trim().toUpperCase() : null;
        const firms = [];
        // const contractNoArray = this.formFilter.get('contractNo').value !== null ? this.formFilter.get('contractNo').value.toString().toString().trim().toUpperCase().split(' ', 50) : null;
        // const contractNumbers = [];
        const counterpartyArray = this.formFilter.get('counterParty').value !== null ? this.formFilter.get('counterParty').value.toString().trim().toUpperCase().split(' ', 10) : null;
        const counterparties = [];
        // const side: [] = this.formFilter.get('side').value !== null ? this.formFilter.get('side').value : [];
        const accounts = this.formFilter.get('account').value !== null ? this.formFilter.get('account').value : [];
        const accountsArray = [];

        const creditUsed = this.formFilter.get('creditUsed').value !== null ? this.formFilter.get('creditUsed').value : null;
        const warn = this.formFilter.get('warn').value !== null ? this.formFilter.get('warn').value : null;
        const critical = this.formFilter.get('critical').value !== null ? this.formFilter.get('critical').value : null;

        if (accounts != null && accounts.length > 0) {
            accounts.forEach(function (value) {
                if (value === 'Blank') {
                    accountsArray.push(null);
                } else {
                    accountsArray.push(value);
                }
            });
        }

        if (firm !== null && firm.length > 0) {
            firms.push(firm);
        }

        // if (contractNoArray !== null && contractNoArray.length > 0) {
        //     contractNoArray.forEach(function (value) {
        //         if (value.length === 9) {
        //             contractNumbers.push(value);
        //         }
        //     });
        // }

        if (counterpartyArray !== null && counterpartyArray.length > 0) {
            counterpartyArray.forEach(function (value) {
                if (value.length === 4) {
                    counterparties.push(value);
                }
            });
        }

        const filter = {
            firm: {
                filterType: 'text',
                type: 'contains',
                filter: firms.length > 0 ? firms[0] : null
            },
            account: {
                filterType: 'set',
                values: accountsArray.length > 0 ? accountsArray : null
            },
            counterParty: {
                filterType: 'set',
                values: counterparties.length > 0 ? counterparties : null
            },
            // lPerUsed: {
            //     filterType: 'number',
            //     type: 'greaterThanOrEqual',
            //     filter: creditUsed ? creditUsed : null
            // },
            // bPerUsed: {
            //     filterType: 'number',
            //     type: 'greaterThanOrEqual',
            //     filter: creditUsed ? creditUsed : null
            // },
            creditUsed: {
                filterType: 'set',
                values: creditUsed ? creditUsed : null
            },
            warn: {
                filterType: 'set',
                values: warn ? warn : null
            },
            critical: {
                filterType: 'set',
                values: critical ? critical : null
            }
        };

        this.filterService.filterChanged(filter);
    }

    clearFilter(applyFilter: boolean) {
        if (this.formFilter) {
            this.formFilter.reset({ creditUsed: 75, warn: 75, critical: 100 });
            if (applyFilter) {
                this.applyFilter();
            }
        }
    }

    setFilterControls(filter: any[]) {
        if (this.formFilter) {
            this.formFilter.reset();
            if (filter) {
                let columns = Object.keys(filter);

                for (let id in columns) {
                    const controlName = columns[id].toString();

                    const control = this.formFilter.get(controlName);
                    if (control) {
                        switch (controlName) {
                            case'account': {
                                const accountArray = [];

                                if (filter[columns[id]].values) {
                                    filter[columns[id]].values.forEach(function (value) {
                                        if (value === null) {
                                            accountArray.push('Blank');
                                        } else {
                                            accountArray.push(value);
                                        }
                                    });

                                    control.setValue(accountArray);
                                }
                                break;
                            }
                            case 'firm': {
                                let val = '';
                                if (filter['firm'] && filter['firm'].filter) {
                                    val += filter['firm'].filter.toString().replace(/,/g, ' ') + ' ';
                                }

                                control.setValue(val);
                                break;
                            }
                            case 'counterParty': {
                                if (filter[columns[id]].values){
                                    const contraAccount: CounterPartyAccount = this.counterpartiesService.getAccountByLoanetIdOrName(filter[columns[id]].values[0]);

                                    if (contraAccount) {
                                        this.formFilter.patchValue({counterParty: contraAccount.loanetId + ' - ' + contraAccount.name});
                                        // control.setValue(contraAccount.loanetId + ' - ' + contraAccount.name);
                                    }
                                }
                                break;
                            }
                            default:
                                if (filter[columns[id]].values) {
                                    control.setValue(filter[columns[id]].values.toString().replace(/,/g, ' '));
                                }
                        }
                    }
                }
            }
        }
    }

    textInput(input: string) {
        this.applyFilter();
        return input.toUpperCase();
    }

    verifyContraInput(): void {
        let dtcInput: string;
        let contraAccount: CounterPartyAccount;
        dtcInput = this.formFilter.get('counterParty').value;
        // this.contra = dtcInput;

        if (dtcInput) {
            contraAccount = this.counterpartiesService.getAccountByLoanetIdOrName(dtcInput);
        }

        if (contraAccount) {
            // this.setContraAccount(contraAccount);
            this.formFilter.patchValue({counterParty: contraAccount.loanetId + ' - ' + contraAccount.name});
        } else {
            // this.externalCounterPartyLimit = null;
            // this.formFilter.controls['counterParty'].setErrors({'invalid': true});
        }
        this.applyFilter();
    }

}
