import {Identifiable} from '../../../../../shared/firestore-collection.service';

export class ContractDetailPreferences implements Identifiable {
    primaryKey: any;
    name: string;
    state: any[];
    sort: any[];
    filter: any[];

    constructor(preferences) {
        this.name = preferences.name;
        this.state = preferences.state;
        this.sort = preferences.sort;
        this.filter = preferences.filter;
        this.primaryKey = preferences.primaryKey;
    }
}
