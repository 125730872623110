import {Component, OnInit} from '@angular/core';
import {ReportsService} from './reports.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Moment} from 'moment';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

    reportsForm: FormGroup;
    reports = [
        {
            id: 'StockLoanInterestV2',
            name: 'Stock Loan Interest'
        },
        // {
        //     id: 'StockLoanInterest',
        //     name: 'Stock Loan Interest',
        // }
    ];
    selectedReport = this.reports[0];
    public fromDate;
    public toDate;
    maxDate = new Date();
    startOfDay = new Date();
    public noGrouping = 'No Grouping';

    constructor(private formBuilder: FormBuilder,
                private reportsService: ReportsService) {
        this.reportsForm = this.createReportsForm();

    }

    createReportsForm() {
        return this.formBuilder.group({
            reportSelect: [this.reports[0].id]
        });
    }

    ngOnInit() {
        this.selectedReport = this.reports[0];

        this.startOfDay.setHours(0, 0, 0, 0);
        this.maxDate.setHours(23, 59, 59, 999);

        this.startOfDay.setDate(this.startOfDay.getDate() - 1);
        this.maxDate.setDate(this.maxDate.getDate() - 1);

        this.fromDate = new FormControl(this.startOfDay);
        this.toDate = new FormControl(this.maxDate);

        this.reportsService.updateShortLoanInterestFilters(this.fromDate.value, this.toDate.value);
    }

    reportSelectUpdate(report) {
        report = report;
    }

    applyFilter() {
        this.dateFilterChange();
    }

    dateFilterChange() {
        let to: Moment = this.toDate.value;
        to.set("hour", 22);
        this.reportsService.updateShortLoanInterestFilters(this.fromDate.value, this.toDate.value);
    }

    toggleGrouping(value){
        this.reportsService.changeReportGrouping(value);
    }

}
