import {Component, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ContractFilterService} from '../../../../../contract-filter/contract-filter.service';
import {ConstantsService} from '../../../../../shared/constants/constants.service';
import {ContractsService} from '../contracts.service';
import {Subscription} from 'rxjs';
import {ContractDetailPreferences} from '../contract-detail-preferences/contract-detail-preferences';
import {ContractDetailPreferencesService} from '../contract-detail-preferences/contract-detail-preferences.service';

@Component({
    selector: 'app-contract-detail-filter',
    templateUrl: './contract-detail-filter.component.html',
    styleUrls: ['./contract-detail-filter.component.scss']
})

export class ContractDetailFilterComponent implements OnInit, OnDestroy {
    filterChangedSubscription: Subscription;
    clearFilterControlsSubscription: Subscription;

    maxDate = new Date();
    startOfDay = new Date();

    constructor(private fb: FormBuilder,
                public filterService: ContractFilterService,
                public constantsService: ConstantsService,
                public contractsService: ContractsService,
                public contractDetailPreferences: ContractDetailPreferencesService) {
        this.filterChangedSubscription = filterService.setFilterControlsEvent.subscribe(filter => this.setFilterControls(filter));
        this.clearFilterControlsSubscription = filterService.clearFilterControlsEvent.subscribe(applyFilter => this.clearFilter(applyFilter));
    }

    public formFilter: FormGroup;

    ngOnInit() {
        this.formFilter = this.fb.group({
            symbol: [],
            contractNo: [],
            side: [],
            contraDepositoryNo: [],
            contraLoanetId: [],
            status: [],
            profitCenter: [],
            startOn: ['off'],
            fromDate: [],
            toDate: [],
            contraCurrencyId: []
        });
    }

    ngOnDestroy(): void {
        this.filterChangedSubscription.unsubscribe();
        this.clearFilterControlsSubscription.unsubscribe();
    }

    applyFilter() {
        var symbolCusipArray = this.formFilter.get('symbol').value !== null ? this.formFilter.get('symbol').value.toString().trim().toUpperCase().split(' ', 50) : null;
        var symbolArray = [];
        var cusipArray = [];
        var contractNoArray = this.formFilter.get('contractNo').value !== null ? this.formFilter.get('contractNo').value.toString().toString().trim().toUpperCase().split(' ', 50) : null;
        var contractNumbers = [];
        var contraDepositoryArray = this.formFilter.get('contraDepositoryNo').value !== null ? this.formFilter.get('contraDepositoryNo').value.toString().trim().toUpperCase().split(' ', 10) : null;
        var contraDepositoryNumbers = [];

        var contraLoanetIdArray = this.formFilter.get('contraLoanetId').value !== null ? this.formFilter.get('contraLoanetId').value.toString().trim().toUpperCase().split(' ', 10) : null;
        var contraLoanetIds = [];

        var side: [] = this.formFilter.get('side').value !== null ? this.formFilter.get('side').value : [];
        var profitCenters = this.formFilter.get('profitCenter').value !== null ? this.formFilter.get('profitCenter').value : [];
        var profitCenterArray = [];
        const fromDate = new Date(this.formFilter.get('fromDate').value);
        const toDate = new Date(this.formFilter.get('toDate').value);
        const startOn = this.formFilter.get('startOn').value;
        const contraCurrencyId = this.formFilter.get('contraCurrencyId').value;

        fromDate.setHours(0, 0, 0, 0);
        toDate.setHours(0, 0, 0, 0);

        if (profitCenters != null && profitCenters.length > 0) {
            profitCenters.forEach(function (value) {
                if (value === 'Blank') {
                    profitCenterArray.push(null);
                } else {
                    profitCenterArray.push(value);
                }
            });
        }

        if (symbolCusipArray !== null && symbolCusipArray.length > 0) {
            symbolCusipArray.forEach(function (value) {
                if (value.length > 0) {
                    if (value.length === 9) {
                        cusipArray.push(value);
                    } else {
                        symbolArray.push(value);
                    }
                }
            });
        }

        if (contractNoArray !== null && contractNoArray.length > 0) {
            contractNoArray.forEach(function (value) {
                if (value.length == 9) {
                    contractNumbers.push(value);
                }
            });
        }

        if (contraDepositoryArray !== null && contraDepositoryArray.length > 0) {
            contraDepositoryArray.forEach(function (value) {
                if (value.length > 0) {
                    contraDepositoryNumbers.push(value);
                }
            });
        }

        if (contraLoanetIdArray !== null && contraLoanetIdArray.length > 0) {
            contraLoanetIdArray.forEach(function (value) {
                if (value.length > 0) {
                    contraLoanetIds.push(value);
                }
            });
        }

        const filter = {
            symbol: {
                filterType: 'set',
                values: symbolArray.length > 0 ? symbolArray : null
            },
            cusip: {
                filterType: 'set',
                values: cusipArray.length > 0 ? cusipArray : null
            },
            contractNo: {
                filterType: 'set',
                values: contractNumbers.length > 0 ? contractNumbers : null
            },
            contraDepositoryNo: {
                filterType: 'set',
                values: contraDepositoryNumbers.length > 0 ? contraDepositoryNumbers : null
            },
            contraLoanetId: {
                filterType: 'set',
                values: contraLoanetIds.length > 0 ? contraLoanetIds : null
            },
            profitCenter: {
                filterType: 'set',
                values: profitCenterArray.length > 0 ? profitCenterArray : null
            },
            startOn: {
                filterType: 'text',
                type: 'equals',
                filter: JSON.stringify({fromDate: fromDate, toDate: toDate, startOn: startOn})
            }
        };


        this.filterService.filterChanged(filter);
    }

    clearFilter(applyFilter: boolean) {
        if (this.formFilter) {
            this.formFilter.reset();
            if (applyFilter) {
                this.applyFilter();
            }
        }
    }

    setFilterControls(filter: any[]) {
        if (this.formFilter) {
            this.formFilter.reset();
            if (filter) {
                let columns = Object.keys(filter);
                for (let id in columns) {
                    let controlName = columns[id].toString();
                    if (controlName === 'cusip') {
                        controlName = 'symbol';
                    }
                    let control = this.formFilter.get(controlName);
                    if (control) {
                        switch (controlName) {
                            case'profitCenter': {
                                let pcArray = [];

                                if (filter[columns[id]].values) {
                                    filter[columns[id]].values.forEach(function (value) {
                                        if (value === null) {
                                            pcArray.push('Blank');
                                        } else {
                                            pcArray.push(value);
                                        }
                                    });

                                    control.setValue(pcArray);
                                }
                                break;
                            }
                            case 'symbol':
                            case 'cusip': {
                                let val = '';
                                if (filter['symbol'] && filter['symbol'].values) {
                                    val += filter['symbol'].values.toString().replace(/,/g, ' ') + ' ';
                                }
                                if (filter['cusip'] && filter['cusip'].values) {
                                    val += filter['cusip'].values.toString().replace(/,/g, ' ') + ' ';
                                }
                                control.setValue(val);
                                break;
                            }
                            case 'startOn': {
                                if (filter['startOn'] && filter['startOn'].filter && JSON.parse(filter['startOn'].filter).startOn) {
                                    const value = JSON.parse(filter['startOn'].filter);

                                    this.formFilter.controls.fromDate.setValue(
                                        new Date(value.fromDate)
                                    );

                                    this.formFilter.controls.toDate.setValue(
                                        new Date(value.toDate)
                                    );

                                    this.formFilter.controls.startOn.setValue(value.startOn);

                                    if (value.startOn !== 'custom') {
                                        this.datePresetChanged({value: value.startOn});
                                    }
                                }
                                break;
                            }
                            default:
                                if (filter[columns[id]].values) {
                                    control.setValue(filter[columns[id]].values.toString().replace(/,/g, ' '));
                                }
                        }
                    }
                }
            }
        }
    }

    textInput(input: string) {
        return input.toUpperCase();
    }

    firstDayOfWeek(weekOffset: number): Date {
        var curr = new Date(); // get current date
        var daysBack = weekOffset * 7;
        var first = curr.getDate() - daysBack - curr.getDay() + 1; // First day is the day of the month - the day of the week
        var firstDay = new Date(curr.setDate(first));
        firstDay.setHours(0, 0, 0, 0);
        return firstDay;
    }

    firstDayOfMonth(monthOffset: number): Date {
        var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        if (m === 0 && monthOffset > 0) {  //If we are in January and User selects previous month
            m = 12;
            y--;
        }
        var firstDay = new Date(y, m - monthOffset, 1);
        firstDay.setHours(0, 0, 0, 0);
        return firstDay;
    }

    datePresetChanged($event) {
        this.formFilter.controls.toDate.setValue(this.maxDate);
        switch ($event.value) {
            case 'off':
                this.formFilter.controls.fromDate.setValue(null);
                this.formFilter.controls.toDate.setValue(null);
                break;
            case 'week':
                this.formFilter.controls.fromDate.setValue(this.firstDayOfWeek(0));
                break;
            case 'month':
                this.formFilter.controls.fromDate.setValue(this.firstDayOfMonth(0));
                break;
            case 'lastweek' :
                this.formFilter.controls.fromDate.setValue(this.firstDayOfWeek(1));
                break;
            case 'lastmonth' :
                this.formFilter.controls.fromDate.setValue(this.firstDayOfMonth(1));
                break;
            default:
                this.formFilter.controls.fromDate.setValue(this.startOfDay);
                break;
        }

        this.applyFilter();

    }
}
