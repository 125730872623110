import {Identifiable} from '../../../../shared/firestore-collection.service';

export class FplPosition implements  Identifiable{
    primaryKey: any;
    fplPositionId: number;
    accountId: number;
    correspondentCode: string;
    office: string;
    accountNumber: string;
    accountType: string;
    status: string;
    iClearAccount: string;
    name: string;
    symbol: string;
    cusip: string;
    quantity: number;
    borrowQuantity: number;
    returnQuantity: number;
    available: number;
    amountToBorrow: number;
    amountToReturn: number;
    borrowPrice: number;
    revSplit: number;
    dtcQuantity: number;
    rebateAvg: number;
    newRebateAvg: number;

    constructor(accountPosition?) {
        this.primaryKey = accountPosition.primaryKey;
        this.fplPositionId = parseInt(accountPosition.fplPositionId);
        this.accountId = parseInt(accountPosition.accountId);
        this.correspondentCode = accountPosition.corr;
        this.office = accountPosition.office;
        this.accountNumber = accountPosition.acctNo;
        this.accountType = accountPosition.acctType;
        this.status = accountPosition.status;
        this.iClearAccount = accountPosition.corr + '-' + accountPosition.office + '-'  + accountPosition.acctNo;
        this.name = accountPosition.name;
        this.quantity = accountPosition.tclose;
        this.symbol = accountPosition.symbol;
        this.cusip = accountPosition.cusip;
        this.returnQuantity = accountPosition.returnQuantity;
        this.borrowQuantity = accountPosition.borrowQuantity;
        this.available = accountPosition.available;  //this.quantity >= 0 ? this.quantity - this.borrowQuantity : 0;
        this.revSplit = accountPosition.revSplit;
        this.rebateAvg = accountPosition.rebateAvg;
        this.newRebateAvg = accountPosition.newRebateAvg;
    }
}
