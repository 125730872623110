import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {UserPreferencesService} from '../user-preferences.service';
import {HighlightColor} from './highlight.color';

@Component({
    selector: 'color-picker-dialogue-component',
    template: `
        <div>
            <span>
                <input style="width: 230px;" [(colorPicker)]="highlightColor" [style.background]="highlightColor" cpPosition="bottom" [cpCloseClickOutside]="false"/>

                <div fxLayout="row" style="margin-top: 310px;">
                  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="50" fxLayoutGap="5px">
                      <button style="width:95%" mat-raised-button color="primary" (click)="this.highlightColor = '#ffffff'" class="btn">Clear</button>
                  </div>
                  <div fxLayout="row" fxFlex fxLayoutAlign="end center">
                      <button style="width:95%" mat-raised-button color="primary" (click)="save()" class="btn">Save</button>
                  </div>
                </div>
            </span>
        </div>
    `,
    styles: [
        `.btn {
            height: 20px;
            line-height: 0.5;
            width: 75px;
        }
        `
    ]
})
export class ColorPickerDialogueComponent {
    public params: any;
    public available: number;
    public accountId: number;
    highlightColor: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                public dialogRef: MatDialogRef<ColorPickerDialogueComponent>,
                public accountsPreferencesService: UserPreferencesService) {
        this.highlightColor = data.highlightColor;
        this.accountId = data.account.accountId;
    }

    save() {
        const newHighlightColor: HighlightColor = new HighlightColor(this.accountId, this.highlightColor);
        this.accountsPreferencesService.addAccountPreferences(newHighlightColor);
        this.dialogRef.close();
    }
}
