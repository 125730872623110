import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {UserService} from '../../../../../../auth/user/user.service';
import {fuseAnimations} from '../../../../../../../@fuse/animations';
import {GridOptions, ColDef, ColumnApi, GridApi} from 'ag-grid-community';
import {ContractImportService} from './contract-import.service';
import {ContractPrototype} from './contract-prototype';
import {Subscription} from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-contract-import',
    templateUrl: './contract-import.component.html',
    styleUrls: ['./contract-import.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ContractImportComponent implements OnInit, OnDestroy {

    gridOptions: GridOptions = <GridOptions>{};

    defaultColDef = {
        editable: true,
        enableCellChangeFlash: true,
        sortable: true,
        filter: true,
        resizable: true
    };

    rowData: ContractPrototype[];
    gridColumnApi: ColumnApi;
    gridApi: GridApi;
    subscriptions: Subscription[];

    private enableFirestoreUpdates = true;
    private prototypesToUpdate: ContractPrototype[];
    private prototypeUpdateUniqueCheck;

    private numBlankRows = 200;

    constructor(public userService: UserService,
                public contractImportService: ContractImportService,
                public snackBar: MatSnackBar) {
        this.addRowData();
        this.subscriptions = [];
        this.gridOptions = {
            columnDefs: this.getColumnDefs(),
            rowSelection: 'multiple',
            rowClassRules: {
                'invalid-prototype': (params) => {
                    const prototype: ContractPrototype = params.data;
                    if (prototype.quantity) {
                        return (!(prototype.isValid()) && prototype.status !== '');
                    } else {
                        return isNaN(prototype.quantity);
                    }
                }
            },
            enableRangeSelection: true,
            floatingFilter: true,
        };


    }

    ngOnInit() {
    }

    private getColumnDefs(): ColDef[] {
        const colDefs = [];
        colDefs.push(
            {
                headerName: 'Borrowing From',
                field: 'borrowContra',
                checkboxSelection: true,
                headerCheckboxSelection: true,
            },
            {
                headerName: 'CUSIP',
                field: 'cusip',
                filter: 'agTextColumnFilter',
            },
            {
                headerName: 'Symbol',
                field: 'symbol',
                filter: 'agTextColumnFilter',
            },
            {
                headerName: 'Shares',
                field: 'quantity',
                valueFormatter: this.numberFormatter,
                valueParser: this.numberParser
            },
            {
                headerName: 'PC Price',
                field: 'pcPrice'
            },
            {
                headerName: 'Price',
                field: 'price'
            },
            {
                headerName: 'Value',
                field: 'value'
            },
            {
                headerName: 'Rates To Us',
                field: 'borrowRate'
            },
            {
                headerName: 'Rate Out',
                field: 'loanRate'
            },
            {
                headerName: 'Conduit',
                field: 'conduit'
            },
            {
                headerName: 'Loan to',
                field: 'loanContra'
            },
            {
                headerName: 'PRC',
                field: 'profitCenter'
            },
            {
                headerName: 'Comments',
                field: 'comments'
            },
            {
                headerName: 'L BatchCode',
                field: 'loanBatchCode'
            },
            {
                headerName: 'L SpecFlag',
                field: 'loanSpecFlag'
            },
            {
                headerName: 'Status',
                field: 'status',
                hide: false,
                editable: false
            }
        );
        return colDefs;
    }

    private addRowData(): void {
        if (this.rowData === undefined) {
            this.rowData = [];
        }
        for (let i = 0; i < this.numBlankRows; i++) {
            this.rowData.push();
        }
    }

    autoSizeAll(event) {
        this.gridColumnApi = event.columnApi;
        this.gridColumnApi.autoSizeColumns(this.gridColumnApi.getAllColumns());
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.setUpSubscriptions();
        this.autoSizeAll(params);
    }

    setUpSubscriptions() {
        this.subscriptions.push(
            this.contractImportService.onCollectionAdded
                .subscribe(
                    (added) => {
                        if (this.gridApi) {
                            this.gridApi.updateRowData({
                                add: added
                            });
                        }
                    }
                )
        );

        this.subscriptions.push(
            this.contractImportService.onCollectionChanged
                .subscribe(
                    (modified) => {
                        // //console.log('modified: ', modified);
                        if (this.gridApi) {
                            this.gridApi.updateRowData({
                                update: modified
                            });
                        }
                    }
                )
        );

        this.subscriptions.push(
            this.contractImportService.onCollectionRemoved
                .subscribe(
                    (removed) => {
                        if (this.gridApi) {
                            this.gridApi.updateRowData({
                                remove: removed
                            });
                        }
                    }
                )
        );
    }

    clearGrid() {
        const contractPrototypes: ContractPrototype[] = this.gridApi.getSelectedRows();
        this.contractImportService.clearPrototypes(contractPrototypes);
    }

    importPrototypes() {
        const contractPrototypes: ContractPrototype[] = this.gridApi.getSelectedRows();
        this.contractImportService.importPrototypes(contractPrototypes)
            .subscribe(
                (next) => {
                    //console.log('success: ', next);
                }, (err) => {
                    //console.log('err: ', err);
                    this.snackBar.open('Contract import failed.', 'Dismiss');
                }
            );
    }

    selectInvalidPrototypes() {
        if (this.gridApi) {
            this.gridApi.deselectAll();
            this.gridApi.forEachNode(
                (node) => {
                    const contractPrototype: ContractPrototype = node.data;
                    if (!contractPrototype.isValid()) {
                        if (contractPrototype.status !== '') {
                            node.setSelected(true);
                        }
                    }
                }
            );
        }
    }

    selectVisiblePrototypes() {
        if (this.gridApi) {
            this.gridApi.deselectAll();
            this.gridApi.forEachNodeAfterFilter(
                (node) => {
                    node.setSelected(true);
                }
            );
        }
    }

    public getRowNodeId(data) {
        return data.primaryKey;
    }

    ngOnDestroy(): void {
        for (const sub of this.subscriptions) {
            sub.unsubscribe();
        }
    }

    pasteStart(event): void {
        // //console.log('paste start: ', event);
        // remove cell change listener?
        this.enableFirestoreUpdates = false;
        this.prototypesToUpdate = [];
        this.prototypeUpdateUniqueCheck = {};
    }

    cellValueChanged(event): void {
        //console.log('cell change: ', event);
        if (this.enableFirestoreUpdates) {
            // //console.log('event data: ', event);
            this.contractImportService.updatePrototype(event.data);
        } else {
            const key = event.data.primaryKey;
            if (this.prototypeUpdateUniqueCheck[key]) {
                return;
            } else {
                // //console.log('update ', event.data.primaryKey);
                this.prototypesToUpdate.push(event.data);
                this.prototypeUpdateUniqueCheck[key] = true;
            }

        }
    }


    pasteEnd(event): void {
        // //console.log('paste end: ', event);
        // add cell change listener?
        // //console.log('unique map ', this.prototypeUpdateUniqueCheck);
        this.enableFirestoreUpdates = true;
        this.contractImportService.updatePrototypeBatch(this.prototypesToUpdate);
    }

    numberFormatter(params) {
        if (params.value) {
            return Math.floor(params.value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1');
        }
        else {
            return params.value;
        }
    }

    numberParser(params) {
        return Number(params.newValue);
    }

}
