import { take, tap, map, mergeMap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AngularFireAuth } from "angularfire2/auth";
import { UserService } from "./user/user.service";

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private afAuth: AngularFireAuth,
        private router: Router,
        private userService: UserService,
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
        //Redirection to Reset-password screen and extract mode and oobCode
        if (
            window.location.href.includes("/pages/auth/reset-password?mode=") &&
            window.location.href.includes("oobCode=")
        ) {
            const resetDetails = {
                href: window.location.href,
                oobCode: extractoobCodeFromUrl(window.location.href),
                mode: extractModeFromUrl(window.location.href),
            };

            const resetDetailsJson = JSON.stringify(resetDetails);
            localStorage.setItem("Reset-Password", resetDetailsJson);

            this.router.navigate([window.location.href]);
            this.router.navigate(["pages/auth/reset-password"]);

            return;
        }

        if (this.afAuth.auth.currentUser) {
            if (this.userService.user) {
                return true;
            }
            return this.userService.updateUserInfo().pipe(
                map((user) => !!user),
                tap((loggedIn) => {
                    if (!loggedIn) {
                        //console.log('access denied (upper)');
                        this.router.navigate(["/pages/auth/login"]);
                    }
                }),
            );
        }

        return this.afAuth.authState
            .pipe(take(1))
            .pipe(mergeMap((user) => this.userService.updateUserInfo()))
            .pipe(
                map((user) => !!user),
                tap((loggedIn) => {
                    if (!loggedIn) {
                        //console.log('access denied');
                        this.router.navigate(["/pages/auth/login"]);
                    }
                }),
            );
    }
}

//Functions to Extract Mode and oobCode from URL
function extractModeFromUrl(url: string): string | null {
    const match = url.match(/[?&]mode=([^&]+)/);
    return match ? match[1] : null;
}

function extractoobCodeFromUrl(url: string): string | null {
    let startIndex = url.indexOf("oobCode=");
    if (startIndex !== -1) {
        startIndex += "oobCode=".length;
        const endIndex = url.indexOf("&", startIndex);
        if (endIndex !== -1) {
            return url.substring(startIndex, endIndex);
        } else {
            return url.substring(startIndex);
        }
    }
    return null;
}
